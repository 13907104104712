<template>
	<div class="insurance-info">
		<h3 class="medium secondary-color insurance-info__address">
			{{ getInsuranceDetail.address }}
		</h3>

		<div class="insurance-info__body">
			<div class="insurance-info__content">

				<div class="green-block">
					<AppTimeAction color="success" :date="{
						dateStart: getInsuranceDetail.startAt,
						dateEnd: getInsuranceDetail.endAt,
					}" />


					<a target="_blank" :href="`https://cdn.jfservice.ru/insurance/${getInsuranceDetail.insuranceCase}`"
						class="insurance-info__how-get-btn">
						Как получить страховку?
					</a>
				</div>


				<div class="logo_and_name">
					<div class="name">
						<div class="insurance-info__company">
							{{ getInsuranceDetail.providerName }}
						</div>
						<AppLoading :height="20" v-if="getFileLoading" />
						<BtnDownloadCloud v-else class="insurance-info__download" text="Полис страхования"
							@click.native="getPolicy" />
					</div>
					<div class="logo">
						<img :src="`https://cdn.jfservice.ru/insurance/${getInsuranceDetail.logo}`" alt="" />
					</div>

				</div>


				<div class="compensation">
					<h4 class="compensation__title medium secondary-color">
						Сумма возмещения
					</h4>
					<AppAccordion class="compensation__accordion" v-for="item in getInsuranceDetail.items" :key="item.id">
						<template slot="label">
							<div class="accordion__title">
								<div class="accordion__title-name">{{ item.name }}</div>
								<div class="accordion__title-sum">{{ item.insuranceAmount + "₽" }}</div>
							</div>
						</template>
						<template slot="body">
							<div class="compensation__accordion-body">
								{{ item.description }}
							</div>
						</template>
					</AppAccordion>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import BtnDownloadCloud from "../../../components/components-other/btn-dowload-cloud.vue";
import { mapGetters } from 'vuex'
export default {
	components: {
		BtnDownloadCloud,
	},
	props: {
		props: Object,
		titleHide: {
			type: Boolean,
			default: false
		}
	},
	data() {
		return {

		};
	},
	methods: {
		getPolicy() {
			const payload = {
				apartmentId: this.$route.params.id,
				insuranceId: this.getInsuranceDetail.insuranceId
			}
			this.$store.dispatch('getPolicy', payload)
		}
	},
	computed: {
		...mapGetters(['getInsuranceDetail', 'getFileLoading'])
	},
};
</script>

<style lang="scss" scoped>
.green-block {
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-bottom: 1.6em;
}

.logo_and_name {
	display: flex;
	justify-content: space-between;
	gap: 2em;
}

.name {
	flex-shrink: 0;
}

.logo {
	display: flex;
	height: calc(64px + 1em);

	& img {
		object-fit: contain;
		max-height: 100%;
		max-width: calc(128px + 1em);
	}
}

.insurance-info {
	color: var(--color-secondary);
	font-size: 16px;

	&__address {
		margin-bottom: 30px;
		font-size: 1.5em;
	}

	&__body {
		display: flex;
	}

	&__content {
		flex-shrink: 5;
	}

	&__company {
		margin-bottom: 13px;
		font-size: 18px;
	}

	&__company span {
		font-weight: 500;
		font-size: 20px;
	}

	&__download {
		margin-bottom: 6px;
	}

	&__column-right {
		display: flex;
		flex-direction: column;
		align-items: flex-end;
		margin-left: 40px;
	}

	&__how-get-btn {
		background: none;
		border: none;
		color: var(--color-success);
		font-weight: 500;
		font-size: 16px;
		cursor: pointer;
		text-align: end;
	}
}

.compensation {
	&__accordion-body {
		margin-bottom: 20px;
	}

	&__accordion {
		margin-bottom: 21px;
		color: var(--color-secondary-two);
		font-weight: 400;
	}

	&__title {
		margin-bottom: 13px;
	}
}

.accordion {
	&__title-sum {
		margin-bottom: 22px;
		font-size: 16px;
	}

	&__title-name {
		margin-bottom: 5px;
		font-size: 14px;
	}
}
</style>
