<template>
	<AppCard class="card-supplier-cat">
		<template #body>
			<div class="card-supplier-cat__body">
				<div
					class="card-supplier-cat__card"
					tabindex="0"
					@click="clickToCard"
					@keyup.space="clickToCard"
					@keyup.enter="clickToCard"
				>
					<div
						class="card-supplier-cat__image"
						:style="`background: url(${image})`"
					></div>
				</div>
				<div class="card-supplier-cat__description">
					<div class="card-supplier-cat__title">
						{{ title }}
					</div>
					<AppBtn icon text classes="card-supplier-cat__add-card" @click="clickToCard">
						<AppIcon name="chevron-right" size="20px" style="color: #A5A5A4" />
					</AppBtn>
				</div>
			</div>
		</template>
	</AppCard>
</template>

<script>
export default {
	data() {
		return {};
	},
	props: {
		title: {
			type: String,
			default: ""
		},
		image: {
			type: String,
			default: ""
		},
		fullInfo: {
			type: Object,
			default: () => {}
		}
	},
	methods: {
		clickToCard() {
			this.$emit("clickCard", this.fullInfo.id);
		}
	}
};
</script>
