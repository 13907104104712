import $axios from "@/plugins/axios-instance";

export default {
	state: {
		apartment: {
			data: [],
			loading: true
		},
		apartmentRename: {
			loading: false,
			error: null
		},
		apartmentRemove: {
			loading: false,
			error: null
		},
		apartmentConfirm: {
			loading: false,
			error: null
		},
		apartmentBanners: {
			loading: false,
			error: null,
			banners: []
		}
	},
	getters: {
		getApartment(state) {
			return state.apartment;
		},
		getApartmentRemove(state) {
			return state.apartmentRemove;
		},
		getApartmentConfirm(state) {
			return state.apartmentConfirm;
		},
		getBanners(state) {
			return state.apartmentBanners.banners;
		}
	},
	mutations: {
		setApartmentData(state, data) {
			state.apartment.data = data;
		},
		setApartmentLoad(state, load) {
			state.apartment.loading = load;
		},

		setApartmentRenameLoading(state, load) {
			state.apartmentRename.loading = load;
		},
		setApartmentRenameData(state, data) {
			for (let item of state.apartment.data) {
				if (item.id === data.id) {
					item.description = data.description;
				}
			}
		},

		setApartmentRemoveData(state, data) {
			state.apartment.data = state.apartment.data.filter(i => i.id !== data.id);
			state.apartmentRemove.error = null;
		},
		setApartmentRemoveLoad(state, load) {
			state.apartmentRemove.loading = load;
		},
		setApartmentRemoveError(state, error) {
			state.apartmentRemove.error = error;
		},
		setApartmentBannersData(state, data) {
			state.apartmentBanners.banners = data;
		},
		setApartmentBannersLoad(state, load) {
			state.apartmentBanners.loading = load;
		},

		setApartmentConfirmLoad(state, load) {
			state.apartmentConfirm.loading = load;
		},
		setApartmentConfirmError(state, error) {
			state.apartmentConfirm.error = error;
		}
	},

	actions: {
		apartmentList(context, data) {
			context.commit("setApartmentLoad", true);
			return $axios
				.get(`/apartment`)
				.then(response => {
					context.commit("setApartmentData", response.data);
					return response.data;
				})
				.catch(e => {
					throw e;
				})
				.finally(() => {
					context.commit("setApartmentLoad", false);
				});
		},

		paperReceiptsSubscription(context, data) {
			const { id, isDisabled } = data;
			return $axios.post(`/apartment/${id}/disable/${isDisabled}`);
		},

		apartmentRename(context, data) {
			context.commit("setApartmentRenameLoading", true);
			return $axios
				.post(`/apartment/${data.id}?description=${data.description}`)
				.then(response => {
					context.commit("setApartmentRenameData", data);
					return response.data;
				})
				.catch(e => {
					throw e;
				})
				.finally(() => {
					context.commit("setApartmentRenameLoading", false);
				});
		},

		apartmentConfirm({ commit }, data) {
			commit("setApartmentConfirmLoad", true);
			return $axios
				.post(`/apartment/${data.id}/verify/${data.isConfirmed}`)
				.then(response => {
					return response;
				})
				.catch(e => {
					commit("setApartmentConfirmError", e.response);
					throw e;
				})
				.finally(() => {
					commit("setApartmentConfirmLoad", false);
				});
		},
		apartmentRemove({ commit, dispatch }, data) {
			commit("setApartmentRemoveLoad", true);
			$axios
				.delete(`/apartment/${data.id}`)
				.then(response => {
					commit("setApartmentRemoveData", data);
				})
				.catch(e => {
					commit("setApartmentRemoveError", e.response);
					throw e;
				})
				.finally(() => {
					commit("setApartmentRemoveLoad", false);
					dispatch("getTotalDebt");
				});
		},
		apartmentBanners(context, data) {
			context.commit("setApartmentBannersLoad", true);
			return $axios
				.get(`/banners`)
				.then(response => {
					context.commit("setApartmentBannersData", response.data);
					return response.data;
				})
				.catch(e => {
					throw e;
				})
				.finally(() => {
					context.commit("setApartmentBannersLoad", false);
				});
		},
		fetchApartmentProducts(context, id) {
			return $axios
				.get(`/shop/apartments/products/exist?apartmentId=${id}`)
				.then(response => {
					return response.data;
				})
				.catch(e => {
					throw e;
				});
		}
	}
};
