<template>

    <i :class="prefix ? prefix+name : 'fas fa-'+name" :style="`font-size: ${size}; ${styleCss}`"></i>

</template>

<script>

export default {
    data() {
        return {

        }
    },
    props: {
        name: {
            type: String,
            default: ''
        },
        size: {
            type: String,
            default: '12px'
        },
        styleCss: {
            type: String,
            default: ''
        },
        prefix: {
            type: String,
            default: 'fas fa-'
        }
    }
}
</script>