<template>
	<div>
		<input
			type="checkbox"
			class="checkbox"
			id="checkbox"
			@change="toggleTheme"
		/>
		<label
			for="checkbox"
			class="label"
			:style="getTheme === 'dark-theme' && { backgroundColor: '#2F2F2F !important' }"
		>
			<i class="fas fa-moon"></i>
			<i class="fas fa-sun"></i>
			<div
				class="ball"
				:class="{ 'switch-toggle-checked': getTheme === 'dark-theme' }"
			/>
		</label>
	</div>
</template>


<script>
import { mapGetters } from "vuex";

export default {
	mounted() {
		const theme = this.$useTheme();
		this.setTheme(theme);
	},

	data() {
		return {};
	},

	methods: {
		toggleTheme() {
			const activeTheme = localStorage.getItem("user-theme");
			if (activeTheme === "default") {
				this.setTheme("dark-theme");
				this.$store.dispatch("toggleTheme", "dark-theme");
			} else {
				this.setTheme("default");
				this.$store.dispatch("toggleTheme", "default");
			}
		},

		setTheme(theme) {
			localStorage.setItem("user-theme", theme);
			document.querySelector("html").setAttribute("data-theme", theme);
		},
	},

	computed: {
		...mapGetters(["getTheme"]),
	},
};
</script>


<style scoped>
* {
	box-sizing: border-box;
}
body {
	display: flex;
	justify-content: center;
	align-items: center;
	height: 100vh;
	margin: 0;
}

.checkbox {
	opacity: 0;
	position: absolute;
}

/* #4B4B54 ligth */
/* #2F2F2F dark */

.label {
	width: 49px;
	height: 24px;
	background-color: #4B4B54 !important;
	display: flex;
	border-radius: 50px;
	align-items: center;
	justify-content: space-between;
	padding: 3px;
	position: relative;
	transform: scale(1);
}

.ball:hover {
	border: 2px solid #671fda;
}

.ball {
	width: 22px;
	height: 22px;
	background-color: #FFFFFF;
	position: absolute;
	top: 1px;
	left: 1px;
	border-radius: 50%;
	transition: transform 0.2s linear;
}

.switch-toggle-checked {
	transform: translateX(24px);
}

.fa-moon {
	color: #ffd837 !important;
}

.fa-sun {
	color: #ffd837 !important;
}
</style>
