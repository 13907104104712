import Vue from "vue";
import Vuex from "vuex";
import header from "./store/header";
import token from "./store/token";
import account from "./store/account";
import organization from "./store/organization";
import profile from "./store/profile";
import userRequest from "./store/userRequest";
import accruals from "./store/accruals";
import counters from "./store/counters";
import notifications from "./store/notifications";
import payment from "./store/payment";
import signalR from "./store/signalR";
import suplier from "./store/suplier";
import cart from "./store/cart";
import apartment from "./store/apartment";
import suplierInfo from "./store/supplier-info";
import accountAdd from "./store/account-add";
import shop from "./store/shop";
import theme from "./store/theme";
import insurance from "./store/insurance";
import favourites from "./store/favourites";
import discounts from "./store/discounts";
import alerts from "./store/alerts";
import gazService from "./store/gazService";

Vue.use(Vuex);

export default new Vuex.Store({
	modules: {
		header,
		token,
		account,
		organization,
		profile,
		userRequest,
		accruals,
		counters,
		notifications,
		payment,
		signalR,
		suplier,
		suplierInfo,
		cart,
		apartment,
		accountAdd,
		shop,
		theme,
		insurance,
		favourites,
		discounts,
		alerts,
		gazService
	},
	state: {},
	mutations: {},
	actions: {}
});
