import $axios from '@/plugins/axios-instance'

export default {
    state: {

    },
    getters: {

    },

    mutations: {

    },
    actions: {
        fetchDiscountsHistory({ commit }, data) {
            return $axios.get(`/discounts/history`)
                .then((response) => {
                    return response.data
                }).catch((e) => {
                    throw (e)
                })
        },
        sendCouponCode({ commit }, code) {
            return $axios.post(`/discounts/activateCoupon`, { code })
                .then((response) => {
                    return
                }).catch((e) => {
                    throw (e)
                })
        },

    }
}
