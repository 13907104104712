<template>
	<AppCard>
		<template #header>
			<h4 class="medium mb-3">Оценить</h4>
		</template>
		<template #body>
			<div class="rating-container">
				<star-rating
					:increment="1"
					:show-rating="false"
					:padding="5"
					@rating-selected="setRating"
					:star-size="30"
					:star-points="starPoints"
					style="margin-bottom: 40px"
					active-color="#EB982C"
				/>
				<div class="rating-img-wrapper">
					<img
						class="rating-img"
						:src="
							getTheme === 'dark-theme'
								? '/img/dark-theme/rating-man-dark.svg'
								: '/img/rating-man.png'
						"
					/>
				</div>
			</div>
			<AppTextarea
				type="text"
				label="Ваш комментарий:"
				cols="45"
				rows="6"
				required
				v-model.trim="$v.form.description.$model"
				:error="$v.form.description.$error"
				:requiredError="!$v.form.description.$error"
			>
				<div slot="required">Не более 1000 символов</div>
			</AppTextarea>

			<div class="mt-2 d-flex align-center just-end">
				<AppBtn :disabled="disableBtn" @click="send">Оценить</AppBtn>
			</div>
		</template>
	</AppCard>
</template>


<script>
import StarRating from "vue-star-rating";
import { maxLength } from "vuelidate/lib/validators";
import { mapGetters } from "vuex";

export default {
	components: {
		StarRating
	},
	data() {
		return {
			form: {
				description: ""
			},
			disableBtn: false,
			star: 5,
			starPoints: [
				23,
				2,
				14,
				17,
				0,
				19,
				10,
				34,
				7,
				50,
				23,
				43,
				38,
				50,
				36,
				34,
				46,
				19,
				31,
				17
			]
		};
	},
	computed: {
		...mapGetters(["getTheme"])
	},

	methods: {
		setRating(star) {
			this.star = star;
		},
		async send() {
			this.$v.form.$touch();
			if (!this.$v.form.$invalid) {
				let formData = {
					orderId: this.$route.params.id,
					description: this.form.description,
					grade: this.star
				};
				this.disableBtn = true
				try {
					await this.$store.dispatch("getShopGrade", formData);
					this.$emit("hide-rating");
				} catch (e) {
					this.$errorsMod(e);
				} finally {
					this.disableBtn = false
				}
			}
		}
	},

	validations(val) {
		return {
			form: {
				description: {
					maxLength: maxLength(1000)
				}
			}
		};
	}
};
</script>
<style scoped>
.rating-container {
	display: flex;
	justify-content: center;
	margin-bottom: -2em;
}
.rating-img-wrapper {
	height: 100px;
	transform: scale(-1, 1);
}
.rating-img {
	height: 100%;
	width: 100%;
	object-fit: contain;
	margin-right: 3em;
}

@media screen and (max-width: 768px) {
	.rating-container {
		display: flex;
		flex-direction: column-reverse;
		justify-content: center;
		align-items: center;
		margin-bottom: 0;
		gap: 1em;
	}
}
</style>
