import $axios from '@/plugins/axios-instance'

export default {
	state: {
		userRequestList: {
			data: [],
			totalPages: 1
		},
		userRequestDetailsList: [],
		//countUnreadMessage: 0,
		userRequestAddress: {
			data: [],
			loading: false,
			error: false,
		},
		createMessage: {
			data: null,
			loading: true,
			error: null
		},
		requestById: {
			data: {
				requestMessages: []
			},
			loading: true,
			error: null
		}
	},
	getters: {
		getUserRequestList(state) {
			return state.userRequestList
		},
		getUserRequestDetailsList(state) {
			return state.userRequestDetailsList
		},
		/* getCountUnreadMessage (state) {
			return state.countUnreadMessage
		},
		 */

		getUserRequestAddress(state) {
			return state.userRequestAddress
		},
		getRequestMes(state) {
			return state.createMessage
		},
		getRequestByIdInfo(state) {
			return state.requestById
		},
	},

	mutations: {
		setUserRequestAddressLoad(state, load) {
			state.userRequestAddress.loading = load
		},
		setUserRequestAddressData(state, data) {
			state.userRequestAddress.data = data
		},


		setRequestMesLoad(state, load) {
			state.createMessage.loading = load
		},
		setRequestMesData(state, data) {
			state.createMessage.data = data
			state.requestById.data.requestMessages.push(data)
		},
		setRequestMesError(state, error) {
			state.createMessage.error = error
		},


		setRequestByIdLoad(state, load) {
			state.requestById.loading = load
		},
		setRequestByIdData(state, data) {
			state.requestById.data = data
		},
		setRequestByIdError(state, error) {
			state.requestById.error = error
		},
	},

	actions: {
		userRequestList(context, data) {
			let skip = data.page * 10
			return $axios.get(`/UserRequest/GetRequestList?skip=${skip}&take=10`)
				.then((response) => {
					context.state.userRequestList = response.data
				}).catch((e) => {
					throw (e)
				})
		},

		/* userRequestListSubjects (context, data) {
			return $axios.get(`/UserRequest/ListSubjects`)
			.then((response) => {
				return response.data
			}).catch((e)=>{
				throw(e)
			})
		}, */

		getRequestById(context, data) {
			context.commit('setRequestByIdLoad', true)
			return $axios.get(`/UserRequest/getRequestById?id=${data}`)
				.then((response) => {
					context.commit('setRequestByIdData', response.data)
					return response.data
				}).catch((e) => {
					context.commit('setRequestByIdError', false)
					throw (e)
				}).finally(() => context.commit('setRequestByIdLoad', false))
		},

		/* userRequestCountUnreadMessage (context, data) {
			return $axios.get(`/UserRequest/CountUnreadMessage`)
			.then((response) => {
				context.state.countUnreadMessage = response.data
			}).catch((e)=>{
				throw(e)
			})
		}, */

		/* userRequestListMessages (context, data) {
			return $axios.get(`/UserRequest/ListMessages/${data.id}`)
			.then((response) => {
				return response.data
			}).catch((e)=>{
				throw(e)
			})
		},
 */
		userRequestInfo(context) {
			context.commit('setUserRequestAddressLoad', true)
			return $axios.get(`/UserRequest/Info`)
				.then((response) => {
					context.commit('setUserRequestAddressData', response.data)
					return response.data
				}).catch((e) => {
					throw (e)
				}).finally(() => {
					context.commit('setUserRequestAddressLoad', false)
				})
		},

		userRequestCreate(context, data) {
			return $axios.post(`/UserRequest/Create`, data)
				.catch((e) => {
					throw (e)
				})
		},


		supportBlock(context, data) {
			return $axios.post(`/UserRequest/support`, data)
				.then((response) => {
					return true
				}).catch((e) => {
					throw (e)
				})
		},

		createMessage(context, data) {
			context.commit('setRequestMesLoad', true)
			return $axios.post(`/UserRequest/createMessage`, data)
				.then((response) => {
					context.commit('setRequestMesData', response.data)
				}).catch((e) => {
					context.commit('setRequestMesError', e)
					throw (e)
				}).finally(() => context.commit('setRequestMesLoad', false))
		},

	}
}
