<template>
	<div :class="senderClass">
		<div class="comment">
			<div class="time">
				{{
					new Date(message.createdDate).toLocaleTimeString("ru-RU", {
						hour: "2-digit",
						minute: "2-digit"
					})
				}}
			</div>

			<div class="cloud">

				<div class="cloud-text" >
					{{ message.content }}
				</div>
				<div
					class="attachments"
					v-if="message.attachments && message.attachments.length"
				>
					<a
						class="attachment"
						v-for="attachment in message.attachments"
						:href="attachment.url"
					>
						{{ attachment.fileName }}
					</a>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
import MessageStatus from "./MessageStatus.vue";
export default {
	components: {
		MessageStatus
	},
	props: ["message"],
	computed: {
		senderClass() {
			if (this.message.outgoing) {
				return "outgoing";
			}
			return "incoming";
		}
	}
};
</script>

<style scoped>
.comment {
	display: flex;
	align-items: end;
	gap: 0.5em;
}
.status-time {
	display: flex;
	align-items: center;
	gap: 0.25em;
}
.time {
	font-weight: 400;
	font-size: 12px;
	color: var(--a-chart-datetime-color);
}

.cloud {
	padding: 1em;
	font-weight: 400;
	font-size: 14px;
	max-width: calc(100% - 30px - 0.5em);
}

.cloud-text {
	text-overflow: ellipsis;
	overflow: hidden;
}
.attachments {
	margin-top: 0.5em;
}
.attachment {
	display: block;
	margin: 0;
	color: inherit;
	text-decoration: underline;
}
.incoming {
	align-self: flex-start;
	max-width: 90%;
}
.incoming .comment {
	flex-direction: row-reverse;
}

.incoming .cloud {
	border-radius: 1em 1em 1em 0;
	background: var(--a-chart-incoming-msg-bg);
	color: var(--a-chart-incoming-msg-txt);
}
.incoming .status {
	display: none;
}
.incoming .attachment {
	text-align: start;
}
.outgoing {
	align-self: flex-end;
	max-width: 90%;
}

.outgoing .cloud {
	border-radius: 1em 1em 0 1em;
	background: var(--a-chart-outgoing-msg-bg);
	color: var(--a-chart-outgoing-msg-txt);
}

.outgoing .attachment {
	text-align: end;
}
</style>
