<template>
	<div
		class="insurance-state"
		:class="{
			'insurance-state_active': activeStatus === 3,
			'insurance-state_completed': activeStatus === 4,
			'insurance-state_future':
				activeStatus === 0 ||
				activeStatus === 1 ||
				activeStatus === 2 ||
				activeStatus === 5 ||
				activeStatus === 6 ||
				activeStatus === 7,
			'insurance-state-icon': type === 'icon',
			'insurance-state-string': type === 'string'
		}"
	>
		<svg
			v-if="type === 'icon'"
			xmlns="http://www.w3.org/2000/svg"
			width="18.793"
			height="24.302"
			viewBox="0 0 18.793 24.302"
		>
			<g
				id="Group_63560"
				data-name="Group 63560"
				transform="translate(0.5 0.533)"
			>
				<path
					id="Path_123658"
					data-name="Path 123658"
					d="M16.143,2.669,8.9,0,1.72,2.633C.8,3.077,0,3.343,0,4.218V8.577a16.791,16.791,0,0,0,2.364,8.674,14.85,14.85,0,0,0,6.6,5.981c2.933-.977,5.123-3.583,6.462-5.811a16.791,16.791,0,0,0,2.364-8.675V4.827a2.046,2.046,0,0,0-1.649-2.158"
					transform="translate(0 0)"
					fill="none"
					stroke="#fff"
					stroke-width="1"
				/>
				<path
					v-if="activeStatus === 3 || activeStatus === 4"
					id="Path_123659"
					data-name="Path 123659"
					d="M3.946,8.491.171,4.717a.581.581,0,0,1,0-.821l0,0L1,3.07a.581.581,0,0,1,.821,0l0,0L4.355,5.617,9.8.171a.58.58,0,0,1,.821,0l0,0,.823.823a.58.58,0,0,1,0,.821l0,0L4.767,8.491a.581.581,0,0,1-.821,0l0,0Z"
					transform="translate(3.279 7.647)"
					fill="#fff"
				/>

				<path
					v-if="
						activeStatus === 0 ||
						activeStatus === 1 ||
						activeStatus === 2 ||
						activeStatus === 5 ||
						activeStatus === 6 ||
						activeStatus === 7
					"
					id="Subtraction_29"
					data-name="Subtraction 29"
					d="M11.787,14.094a.9.9,0,0,1-.643-.266l-2.57-2.57L6,13.828a.909.909,0,1,1-1.285-1.285l2.57-2.57L4.719,7.4A.908.908,0,1,1,6,6.116l2.57,2.57,2.57-2.57A.908.908,0,1,1,12.43,7.4L9.86,9.972l2.57,2.57a.909.909,0,0,1-.643,1.551Z"
					transform="translate(0.5 1.1)"
					fill="#fff"
				/>
			</g>
		</svg>
		<span v-if="type === 'string'">
			<template v-if="activeStatus === 3">Активная страховка</template>
			<template v-else-if="activeStatus === 4">Завершенная страховка</template>
			<template v-else-if="activeStatus === 5">Будущая страховка</template>
			<template v-else>Нет страховки</template>
		</span>
	</div>
</template>

<script>
export default {
	props: {
		type: {
			type: String,
			required: true
		},
		activeStatus: {
			type: Number,
			default: 5
		}
	}
};
</script>

<style scoped>
/* Icon */
.insurance-state-icon {
	width: 40px;
	height: 40px;
	display: flex;
	justify-content: center;
	align-items: center;

	border-radius: 50%;
}

.insurance-state-icon.insurance-state_active {
	background-image: radial-gradient(
		circle at 0 0,
		hsl(127deg 97% 75%) 0%,
		hsl(133deg 72% 67%) 25%,
		hsl(139deg 56% 58%) 50%,
		hsl(147deg 47% 49%) 75%,
		hsl(158deg 61% 37%) 100%
	);
}
.insurance-state-icon.insurance-state_future {
	background-image: radial-gradient(
		circle at 0 0,
		hsl(357deg 97% 75%) 0%,
		hsl(358deg 88% 73%) 20%,
		hsl(360deg 72% 68%) 40%,
		hsl(3deg 57% 60%) 60%,
		hsl(7deg 46% 49%) 80%,
		hsl(12deg 61% 37%) 100%
	);
}

/* String */
.insurance-state-string {
	border-radius: 12px;
	padding: 5px 10px;
	display: inline-block;
	white-space: nowrap;
	color: #fff;
}

.insurance-state-string.insurance-state_active {
	background-color: #34bc7c;
}

.insurance-state-string.insurance-state_completed {
	background-color: #ea9734;
}

.insurance-state-string.insurance-state_future {
	background-color: #a5a5a5;
}
</style>
