<template>
    <!-- <div class="content-news">
    	<div v-for="(item, index) in dataNews" :key="index">
    		<span>{{ dateReturnFormat(item.createdDate) }}</span>
    		<a :href="'https://'+item.host+'/news/'+item.id" target="_blank">{{ item.title }}</a>
    	</div>

    </div> -->
	<AppCard classCss="account-cards">
		<template #header>
			<div class="text-title mb-3">
				Новости
			</div>
		</template>
		<template #body>
			<div v-for="(item, index) in dataNews" :key="index" class="mb-3">
				<div class="account-cards-news__date">
					{{ dateReturnFormat(item.date) }}
				</div>
				<a target="_blank" :href="'https://'+item.host+'/news/'+item.id" class="account-cards-news__title">
					{{ item.title  }}
				</a>
			</div>
			<div v-if="!dataNews.length" class="account-cards-news__empty">
				<div>
					<img src="/img/news-empty.svg" width="220px" />
				</div>
				<div class="account-cards-news__empty-text">Новости отсутствуют</div>
			</div>
		</template>
		<template #footer>
			<div class="account-cards-news__footer" v-if="dataNews.length">
				<a href="#">
					Показать все новости
					<AppIcon name="angle-down" size="18px" class="ml-1" />
				</a>
			</div>
		</template>
	</AppCard>

</template>

<script>
import { mapGetters } from 'vuex'
import { dateReturnFormat } from '@/plugins/methods'

export default {
	data () {
		return {
			dataNews: []
		}
	},
	mounted () {
		this.organizationNews()
	},
	methods: {
		async organizationNews () {
			try {
				this.dataNews = await this.$store.dispatch('organizationNews', {
					organizationId: this.getAccountDetails.organizationId
				})
			} catch (e) {
				this.$errorsMod(e.response)
			}
		},
		dateReturnFormat (date) {
			return dateReturnFormat(date, false)
		}
	},
	computed: {
		...mapGetters([
			'getAccountDetails'
		])
	}
}
</script>
