<template>
	<div class="suppliers__list mt-2">
		<div
			class="suppliers__card"
			v-for="(item, index) in getGroupList.data"
			:key="index"
		>
			<AppCardSupplierCat
				:title="item.name"
				:image="`https://квартплата.онлайн/files/static/${item.image}`"
				@clickCard="clickCard"
				:fullInfo="item"
			/>
		</div>
	</div>
</template>
<script>
import { mapGetters } from "vuex";
import AppCardSupplierCat from "@/components/components-other/app-card-supplier-cat";
export default {
	components: {
		AppCardSupplierCat
	},
	data() {
		return {
			error: false
		};
	},
	computed: {
		...mapGetters(["getGroupList", "getActiveTownId"]),
		apartmentId() {
			return this.$route.query.apartmentId;
		}
	},
	methods: {
		clickCard(id) {
			// this.$router.push({ path: `/shop/services/${id}` });
			console.log(this.apartmentId);
			const toRoute = {
				name: "suplier-services",
				params: {
					id
				}
			};
			if (this.apartmentId) {
				Object.assign(toRoute, { query: { apartmentId: this.apartmentId } });
			}
			this.$router.push(toRoute);
		},
		async loadCatalog() {
			this.error = false;
			try {
				await this.$store.dispatch("shopGroupList");
			} catch (e) {
				if (e.response) {
					this.$errorsMod(e.response);
				} else {
					this.error = true;
					this.$errorsMod(e.response);
				}
			}
		},
		async fetchApartmentGroups() {
			this.error = false;
			try {
				await this.$store.dispatch("fetchApartmentGroups", this.apartmentId);
			} catch (e) {
				if (e.response) {
					this.$errorsMod(e.response);
				} else {
					this.error = true;
					this.$errorsMod(e.response);
				}
			}
		}
	},
	mounted() {
		if (this.apartmentId) {
			this.fetchApartmentGroups();
		} else if (this.getActiveTownId) {
			this.loadCatalog();
		}
	}
};
</script>
