export default {
	state: {
		notice: false,
		menuBar: false,
		wrapper: false,
		// titleTemp: ''

		//new
		sideOpen: false
	},
	getters: {
		getNotice (state) {
			return state.notice
		},
		getMenyBar (state) {
			return state.menuBar
		},
		getWrapper (state) {
			return state.wrapper
		},

		//new
		sidebarOpen(state) {
			return state.sideOpen
		}
	},
	mutations: {
		noticeMut (state) {
			state.wrapper = !state.wrapper
			state.notice = !state.notice
			// state.menuBar = false
		},
		menuBarClick (state) {
			state.wrapper = !state.wrapper
			state.menuBar = !state.menuBar
		},
		blockAll (state) {
			state.menuBar = false
			state.notice = false
			state.wrapper = false
		},
		// mutTitleTemp(state, text) {
		//    state.titleTemp = text
		// }

		//new
		sideBarMut(state) {
			state.sideOpen = !state.sideOpen
		},
		sideBarClose(state) {
			state.sideOpen = false
		}
	},
	actions: {

	}
}
