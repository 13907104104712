export const getStatus = (status) => {
	return [
		{
			"name": "New",
			"title": "Новая",
			"value": 0
		},
		{
			"name": "Canceled",
			"title": "Отменена",
			"value": 1
		},
		{
			"name": "Created",
			"title": "Создана",
			"value": 2
		},
		{
			"name": "InWork",
			"title": "В работе",
			"value": 3
		},
		{
			"name": "Resolved",
			"title": "Завершена",
			"value": 4
		},
		{
			"name": "Closed",
			"title": "Закрыта",
			"value": 5
		},
		{
			"name": "Suspended",
			"title": "Приостановлена",
			"value": 6
		},
		{
			"name": "Sended",
			"title": "Отправлена",
			"value": 7
		}
	].find(item => item.value === status)?.title || '-'

}