<template>
	<div class="buy-insurance-card">
		<AppCard>
			<template #body>
				<InsurancePeriod :date="card.date" class="buy-insurance-card__period" />
				<div class="buy-insurance-card__address">
					{{ card.address }}
				</div>
				<div v-if="!card.disabled" class="buy-insurance-card__line">
					<div class="buy-insurance-card__months">
						Месяцев: <Counter :value="card.months" @input="changeBasket" />
					</div>
					<div class="buy-insurance-card__price">
						{{ $numberToString(totalValue) + "₽" }}
					</div>
				</div>

				<div v-if="payment" class="buy-insurance-card__payment payment">
					<CardSelected />
					<AppBtn
						large
						@click.native="paymentSubmit"
						customClass="payment__button"
					>
						<AppIcon name="arrow-right" size="2rem" />
					</AppBtn>
				</div>
			</template>
		</AppCard>
	</div>
</template>

<script>
import { mapGetters } from "vuex";
import InsurancePeriod from "./insurance-period.vue";
import Counter from "../../../suplier/component/basket-calc.vue";
import CardSelected from "@/views/payment-all/components/card-selection.vue";

export default {
	props: {
		payment: {
			type: Boolean,
			default: true
		},
		card: {
			type: Object
		},
		indexCard: {
			type: Number,
		}
	},
	components: {
		InsurancePeriod,
		Counter,
		CardSelected
	},
	methods: {
		changeBasket({ val }) {
			this.$emit('changeMonths', val, this.indexCard)
		},
		paymentSubmit() {
			console.log(123);
		},

	},
	computed: {
		...mapGetters(["getTheme"]),
		totalValue() {
			return this.card.months * this.card.price
		}
	}
};
</script>

<style lang="scss" scoped>
.buy-insurance-card {
	max-width: 416px;
	color: var(--color-secondary-two);

	&__period {
		margin-bottom: 17px;
	}
	&__address {
		margin-bottom: 14px;
	}
	&__line {
		display: flex;
		justify-content: space-between;
	}
	&__months {
		display: flex;
		align-items: center;
	}
	&__months {
		display: flex;
		align-items: center;
	}
	&__price {
		font-size: 24px;
		font-weight: 500;
	}
}

.payment {
	display: flex;
	justify-content: space-between;
	margin-top: 20px;

	&__button {
		width: 60px;
		height: 60px;
		border-radius: 50%;
	}
}
</style>
