<template>
	<div class="content" v-if="$route.query.group">
		<router-link class="back" :to="{ name: 'alerts', query: {} }">
			<img src="../icons/Back.svg" alt="back" />
			<span class="back-text"> Назад </span>
		</router-link>
		<!-- <div v-if="loading" class="loading">
			<AppLoading :width="50" :height="50" />
		</div> -->
		<div ref="intersectionContainer" class="chart-container">
			<div
				v-if="!messages.length && isMessagingAvailable"
				style="flex: 1 1 auto"
				class="no-group-text"
			>
				Если у Вас есть вопросы по работе сервиса "Квартплата.Онлайн", напишите
				нам, и мы обязательно поможем. По вопросам к обслуживающей организации
				(перегорела лампочка в подъезде, не вывезли мусор, слабый напор воды и
				пр.) воспользуйтесь, пожалуйста, разделом
				<router-link style="text-decoration: underline;" to="/request"
					>«Заявки»</router-link
				>.
			</div>
			<div v-for="group in grouppedMessages" class="date-group">
				<AppMessage v-for="message in group" :message="message" />
				<div class="date">
					{{ getCustomDate(group[0].createdDate) }}
				</div>
			</div>
			<div class="observer-trigger" ref="observerTrigger"></div>
		</div>
		<SendingForm v-if="isMessagingAvailable" @refresh="refresh" />
	</div>
	<div v-else class="no-group">
		<img src="../images/GroupsNotSelect.png" alt="Группа не выбрана" />
		<div class="no-group-text">Выберите группу сообщений</div>
	</div>
</template>
<script>
import AppMessage from "./AppMessage.vue";
import SendingForm from "./SendingForm.vue";

export default {
	components: { AppMessage, SendingForm },
	props: ["tab", "isMessagingAvailable"],
	data() {
		return {
			messages: [],
			loading: false,
			page: 1
		};
	},
	computed: {
		grouppedMessages() {
			const obj = {};
			this.messages.forEach(item => {
				const messageDate = new Date(item.createdDate).toLocaleDateString();
				obj[messageDate]
					? (obj[messageDate] = [...obj[messageDate], item])
					: (obj[messageDate] = [item]);
			});
			return obj;
		}
	},
	methods: {
		getCustomDate(date) {
			const months = [
				"января",
				"февраля",
				"марта",
				"апреля",
				"мая",
				"июня",
				"июля",
				"августа",
				"сентября",
				"октября",
				"ноября",
				"декабря"
			];
			return `${new Date(date).getDate()} ${months[new Date(date).getMonth()]}`;
		},

		async loadGroupMessages() {
			if (this.$route.query.group) {
				this.loading = true;
				try {
					const result = await this.$store.dispatch("fetchGroupMessages", {
						groupId: this.$route.query.group,
						page: this.page
					});
					if (result && Array.isArray(result) && result.length > 0) {
						this.messages = [...this.messages, ...result];
						if (result.length % 20 !== 0) {
							if (this.observer) this.unobserveIntersecting();
						} else {
							this.page++;
						}
					}
				} catch (e) {
					console.log("Ошибка при загрузке сообщений - fetchGroupMessages", e);
				} finally {
					this.loading = false;
					this.markAsReadedMesseges();
				}
			}
		},
		async markAsReadedMesseges() {
			if (this.messages[0]) {
				const lastmessage = this.messages[0];
				if (lastmessage && !lastmessage.readed) {
					try {
						await this.$store.dispatch("markAsRead", {
							groupId: this.$route.query.group,
							messageId: lastmessage.id
						});

						this.$store.commit("setGroupReaded", +this.$route.query.group);
						lastmessage.readed = true;
					} catch (e) {
						console.log(
							"Ошибка при отправке информации о прочитанном сообщении",
							e
						);
					}
				}
			}
		},
		createObserver() {
			const callback = async e => {
				if (e[0].isIntersecting) {
					const beforeH = this.$refs.intersectionContainer.scrollTop;
					await this.loadGroupMessages();
					this.$refs.intersectionContainer.scrollTop = beforeH;
					// console.log(this.$refs.intersectionContainer.scrollTop);
				}
			};
			const options = {
				root: this.$refs.intersectionContainer,
				rootMargin: "0px",
				threshold: 0
			};
			this.observer = new IntersectionObserver(callback, options);
			this.observer.observe(this.$refs.observerTrigger);
		},
		unobserveIntersecting() {
			if (this.$refs.observerTrigger) {
				this.observer.unobserve(this.$refs.observerTrigger);
			}
		},
		clearState() {
			this.page = 1;
			this.messages = [];
			this.loading = false;
			if (this.observer) {
				this.unobserveIntersecting();
				this.observer = null;
			}
		},
		async refresh() {
			this.clearState();
			await this.loadGroupMessages();

			if (this.messages.length === 20) {
				this.createObserver();
			}
		}
	},
	// watch: {
	// 	tab() {
	// 		this.refresh();
	// 	}
	// },
	mounted() {
		this.refresh();
	},
  
};
</script>
<style scoped>
.no-group {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
	flex-direction: column;
}
.content {
	height: 100%;
	width: calc(100% - var(--a-group-width));

	padding: 2em 0;
	display: flex;
	flex-direction: column;
}

.no-group > img {
	max-height: 197px;
	max-width: 183px;
}
.no-group-text {
	color: var(--a-chart-datetime-color);
	font-weight: bold;
}
.chart-container {
	flex: 1 1 auto;
	min-width: 0;
	overflow: hidden auto;
	display: flex;
	flex-direction: column-reverse;
	justify-content: end;
	gap: 1em;
	padding: 0 2em;
}
.date-group {
	display: flex;
	flex-direction: column-reverse;
	justify-content: end;
	gap: 1em;
}
.date {
	font-size: 0.8em;
	font-weight: 800;
	color: var(--a-chart-datetime-color);
	align-self: center;
}
.loading {
	display: flex;
	justify-content: center;
	align-items: center;
}
.back {
	margin-top: 1em;
	margin-left: 1em;
	font-weight: 400;
	color: var(--a-back-btn-text);
}
.back-text {
	margin-left: 0.5em;
}

.message-date {
	align-self: center;
	font-weight: 600;
	font-size: 12px;
	color: var(--a-chart-datetime-color);
}
.observer-trigger {
	height: 1px;
	flex-shrink: 0;
}
.back {
	display: none;
	margin-bottom: 1em;
}
@media (max-width: 768px) {
	.back {
		display: block;
	}
	.content {
		width: 100%;
	}
}
</style>
