<template>
	<div class="row mt-3">
		<div class="col-xl-9 col-lg-12">
			<AppCard class="chat">
				<template #body>
					<div class="chat__title">Переписка с организацией</div>
					<div
						v-if="getRequestByIdInfo.data.requestMessages.length === 0"
						class="text-center mt-4 mb-4"
					>
						Сообщений нет
					</div>
					<div
						style="height: 200px; overflow: hidden auto; padding: 0 1em; display: flex; flex-direction: column-reverse;"
					>
						<div
							class="messages"
							v-for="(item, index) of requestMessagesReverse"
							:key="index"
						>
							<div
								v-if="item.message"
								:class="item.author == 0 ? 'message' : 'message message--right'"
							>
								<div class="message__content">
									<div class="message__header"></div>
									<div class="message__text" v-html="item.message" />
									<div class="message__date">
										{{ dateReturnFormat(item.createdDate) }}
									</div>
								</div>
							</div>

							<div class="message__files" v-if="item.attachments.length">
								<div
									:class="
										item.author == 0 ? 'message' : 'message message--right'
									"
								>
									<a
										class="message__files-item mr-1"
										v-for="(file, index) of item.attachments"
										:key="index"
										:href="file.url"
										target="_blank"
									>
										<AppIcon name="paperclip" />
										<span class="message__file-name">{{ file.fileName }}</span>
									</a>
									<div class="message__date">
										{{ dateReturnFormat(item.createdDate) }}
									</div>
								</div>
							</div>
						</div>
					</div>
					<form
						@submit.prevent=""
						v-if="getRequestByIdInfo.data.canSendComments"
					>
						<AppTextarea
							type="text"
							required
							v-model.trim="$v.form.messageRequest.$model"
							label="Ваше сообщение"
							rows="3"
							:error="$v.form.messageRequest.$error"
							class="chat__textarea"
						>
							<template slot="required">Введите сообщение</template>
						</AppTextarea>
						<div class="d-flex">
							<AppBtn
								@click.native="addMessage"
								styleCSS="margin-right: 8px"
								:disabled="sumSize > 10"
								:loading="loading"
							>
								Отправить
							</AppBtn>

							<label
								for="file-upload"
								type="file"
								class="chat__clip-button"
								color="success"
							>
								<img src="/img/paper-clip.svg" />
							</label>
							<input @change="previewFiles" id="file-upload" type="file" />
						</div>
					</form>

					<div class="files mt-2">
						<div
							v-show="sumSize > 10"
							class="shake mt-2 danger-color"
							style="margin-bottom: 10px"
						>
							Отправить можно, не более 10 мб.
						</div>

						<span
							class="files__title"
							v-if="getRequestByIdInfo.data.canSendComments"
						>
							Не более 10 Мб. (JPEG, PNG, PDF, DOC)
						</span>
						<div
							v-for="(file, index) of arrayFiles"
							:key="index"
							class="files__item d-flex just-between mt-1"
						>
							<span class="mr-1">{{ file.name }}</span>
							<div>
								<span>{{
									" " + (file.size / 1000000).toFixed(1) + " Мб"
								}}</span>
								<AppBtn
									@click="deleteItemFile"
									icon
									text
									class="ml-2 card-supplier-cat__add-card"
								>
									<AppIcon name="times" size="1rem" styleCss="color: #A5A5A4" />
								</AppBtn>
							</div>
						</div>
					</div>
				</template>
			</AppCard>
		</div>
	</div>
</template>

<script>
import { required } from "vuelidate/lib/validators";
import { mapGetters } from "vuex";
import { dateReturnFormat } from "@/plugins/methods";

export default {
	data() {
		return {
			arrayFiles: [],
			form: {
				messageRequest: ""
			},
			loading: false
		};
	},
	computed: {
		...mapGetters(["getRequestByIdInfo"]),
		sumSize() {
			return this.arrayFiles.reduce(function(total, item) {
				return total + item.size / 1000000;
			}, 0);
		},
		requestMessagesReverse() {
			const rev = [...this.getRequestByIdInfo.data.requestMessages];
			rev.reverse();
			return rev;
		}
	},
	methods: {
		async addMessage(e) {
			this.$v.form.$touch();

			if (!this.$v.form.$invalid) {
				this.$v.form.$reset();

				let formData = new FormData();

				formData.append("UserRequestId", this.$route.params.id);
				formData.append("Message", this.form.messageRequest);

				for (let item of this.arrayFiles) {
					formData.append("Files", item);
				}
				this.loading = true;
				try {
					await this.$store.dispatch("createMessage", formData);
					this.$nextTick(() => {
						this.form.messageRequest = "";
						this.arrayFiles = [];
					});
				} catch (e) {
					this.$errorsMod(e.response);
				}
				this.loading = false;
			}
		},
		previewFiles(event) {
			this.arrayFiles = [...this.arrayFiles, ...event.target.files];
		},

		deleteItemFile(index) {
			this.arrayFiles.splice(index, 1);
		},
		dateReturnFormat(date) {
			return dateReturnFormat(date, true);
		}
	},
	validations: {
		form: {
			messageRequest: {
				required
			}
		}
	}
};
</script>
