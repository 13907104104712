var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('AppGlobalLayout',{attrs:{"error":_vm.error,"loading":_vm.loading},on:{"reload":_vm.loadInfo}},[_c('div',{attrs:{"slot":"body"},slot:"body"},[_c('ModalHistory'),_c('ModalComment',{on:{"close":_vm.loadInfo}}),_c('ModalRating',{on:{"close":_vm.loadInfo}}),_c('div',[_c('AppTable',{attrs:{"header":_vm.headerTable,"data":_vm.getOrderHistory.data,"messageNoData":"История заказов отсутствует"},scopedSlots:_vm._u([{key:"thead",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.name))])]}},{key:"tbody",fn:function(ref){
var item = ref.item;
return [_c('td',{on:{"click":function($event){return _vm.openInfo(item)}}},[_c('span',{staticClass:"app-table__title-mob"},[_vm._v(" № заказа ")]),_vm._v(" "+_vm._s(item.id)+" ")]),_c('td',{on:{"click":function($event){return _vm.openInfo(item)}}},[_c('span',{staticClass:"app-table__title-mob"},[_vm._v(" Дата и время ")]),_vm._v(" "+_vm._s(_vm.dateReturnFormat(item.date))+" ")]),_c('td',{on:{"click":function($event){return _vm.openInfo(item)}}},[_c('span',{staticClass:"app-table__title-mob"},[_vm._v(" Поставщик ")]),_vm._v(" "+_vm._s(item.suplierName)+" ")]),_c('td',{on:{"click":function($event){return _vm.openInfo(item)}}},[_c('span',{staticClass:"app-table__title-mob"},[_vm._v(" Адрес ")]),_vm._v(" "+_vm._s(item.address)+" ")]),_c('td',{on:{"click":function($event){return _vm.openInfo(item)}}},[_c('span',{staticClass:"app-table__title-mob"},[_vm._v(" Сумма ")]),_vm._v(" "+_vm._s(item.sum)+" ")]),_c('td',{on:{"click":function($event){return _vm.openInfo(item)}}},[_c('span',{staticClass:"app-table__title-mob"},[_vm._v(" Статус ")]),_vm._v(" "+_vm._s(_vm.getStatus(item.status))+" ")]),_c('td',[(item.status === 3 || item.status === 0)?_c('div',{staticClass:"order-table-btn"},[_c('AppBtn',{staticStyle:{"white-space":"nowrap"},on:{"click":function($event){return _vm.openCommentModal(item)}}},[_vm._v(" Добавить комментарий ")])],1):_c('div',{staticClass:"order-table-btn"},[(item.grade === null)?_c('AppBtn',{on:{"click":function($event){return _vm.openRatingModal(item)}}},[_vm._v("Оценить")]):_c('star-rating',{attrs:{"increment":1,"show-rating":false,"read-only":true,"padding":5,"rating":item.grade,"star-size":10,"star-points":[
									23,
									2,
									14,
									17,
									0,
									19,
									10,
									34,
									7,
									50,
									23,
									43,
									38,
									50,
									36,
									34,
									46,
									19,
									31,
									17
								],"active-color":"#EB982C"}})],1)])]}}])})],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }