import $axios from '@/plugins/axios-instance'
import { lowerFirst } from 'lodash'

export default {
	state: {
		profileInfo: {},
		errorsProfile: null,
		cards: [],
		notificationSettings: []
	},
	getters: {
		getProfileInfoGetters(state) {
			return state.profileInfo
		},
		getErrorsProfile(state) {
			return state.errorsProfile
		},
		getProfileCards(state) {
			return state.cards
		},
		getNotificationSettings(state) {
			return state.notificationSettings
		}
	},

	mutations: {
		setErrorsProfile(state, data) {
			state.errorsProfile = JSON.stringify(data)
		},
		setRemoveCards(state, id) {
			state.cards = state.cards.filter(i => i.id !== id)
		},
		setProfileCards(state, data) {
			state.cards = data
		},
		switchNotificationChannel(state, {settingId, settingIndex, enabled}) {
			let index = state.notificationSettings[settingIndex].channels.findIndex(item=>item.settingId === settingId)
			state.notificationSettings[settingIndex].channels[index].enabled = enabled
		},
		setNotificationSettings(state, data){
			state.notificationSettings = data
		},
		setProfileInfo(state, data){
			state.profileInfo = data
		}
	},
	actions: {
		getProfileInfo({commit}, data) {
			return $axios.get(`/Profile/GetProfileInfo`)
				.then((response) => {
					commit('setProfileInfo' ,response.data)
					return response.data
				}).catch((e) => {
				}).catch((e) => {
					context.state.errorsProfile = JSON.stringify(e)
					throw (e)
				})
		},
		fetchNotificationSettings({commit}, data) {
			return $axios.get(`/Notifications/settings`)
				.then((response) => {
					commit('setNotificationSettings', response.data.settings)
					return response.data
				}).catch((e) => {
					context.state.errorsProfile = JSON.stringify(e)
					throw (e)
				})
		},
		postNotificationSetting({commit}, {id, enabled, settingIndex}) {
			return $axios.post(`/Notifications/settings/${id}?enabled=${enabled}`)
				.then((response) => {
					commit('switchNotificationChannel', {settingId: id, settingIndex, enabled})
					return response.data
				}).catch((e) => {
					throw (e)
				})
		},
		profileSaveLogin(context, data) {
			return $axios.post(`/Profile/SaveLogin`, {
				login: data
			})
				.then((response) => {
					return response.data
				}).catch((e) => {
					throw (e)
				})
		},

		sendConfirmLogin(context, data) { //1
			return $axios.post(`/Profile/SendConfirmLogin`, {
				login: data
			})
				.then((response) => {
					return response.data
				}).catch((e) => {
					throw (e)
				})
		},

		changeLogin(context, data) {
			return $axios.post(`/Profile/changeLogin`, data)
				.then((response) => {
					return response.data
				}).catch((e) => {
					throw (e)
				})
		},

		changePassword(context, data) {
			return $axios.post(`/Profile/ChangePassword`, data)
				.then((response) => {
					return response.data
				}).catch((e) => {
					throw (e)
				})
		},

		confirmPhoneNumber(context, data) {
			return $axios.post(`/Profile/ConfirmPhoneNumber`, data)
				.then((response) => {
					return response.data
				}).catch((e) => {
					throw (e)
				})
		},

		logout() {
			return $axios.post(`/Profile/logout`)
				.then(() => {
					return true
				}).catch((e) => {
					throw (e)
				})
		},

		getCards({ commit }) {
			return $axios.get(`/profile/uc`)
				.then((response) => {
					commit('setProfileCards', response.data)
					return response.data
				}).catch((e) => {
					throw (e)
				})
		},

		cardRemove({ commit }, id) {
			$axios.delete(`/profile/uc/${id}`)
				.then((response) => {
					commit('setRemoveCards', id)
				}).catch((e) => {
					throw (e)
				})
		},
		removeUser() {
			$axios.delete(`/profile/user`)
				.then((response) => {
					this.dispatch('logout')
					return true
				}).catch((e) => {
					console.log('Ошибка удаления')
					throw (e)
				})
		},
	}
}
