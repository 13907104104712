<template>
	<div class="row">
		<div class="col-lg-7 col-12">
			<faq />
		</div>
		<div class="col-lg-5 col-12">
			<AppCard classCss="account-cards" class="card-support">
				<template #header>
					<div class="account-cards-news__header">Отправить сообщение</div>
				</template>
				<template #body>
					<p class="support-block__text mt-2">
						Если у Вас остались вопросы по работе сервиса "Квартплата.Онлайн",
						напишите нам, и мы обязательно поможем. По вопросам к обслуживающей
						организации (перегорела лампочка в подъезде, не вывезли мусор,
						слабый напор воды и пр.) воспользуйтесь, пожалуйста, разделом
						«Заявки».
					</p>

					<AppBtn
					class="mt-2"	
						@click.native="
							$router.push({ name: 'alerts', query: { group: 2 } })
						"
					>
						Написать в техподдержку
					</AppBtn>

					<!-- <form @submit.prevent="">
						<div class="mb-2 mt-2">
							<AppInput
								placeholder="Ваша электронная почта"
								type="text"
								v-model.trim="$v.form.email.$model"
								:error="$v.form.email.$error"
								required
								:requiredError="$v.form.email.required"
								:emailError="$v.form.email.$error"
								label="Ваша электронная почта"
								:disabled="false"
							>
								<template slot="required">Введите Email</template>
								<template slot="email"
									>Адрес электронной почты должен содержать символ "@"</template
								>
							</AppInput>
						</div>

						<AppTextarea
							placeholder="Текст сообщения"
							type="text"
							v-model.trim="$v.form.message.$model"
							:error="$v.form.message.$error"
							required
							:requiredError="$v.form.message.required"
							label="Сообщение"
							cols="45"
							rows="10"
						>
							<template slot="required">Заполните это поле</template>
						</AppTextarea>



						<FilesAdd @changeList="changeListFiles"/>

						<div class="d-flex just-end mt-1">
							<AppBtn
								@click.native="submitForm()"
								:loading="loading"
								:disabled="submit || $v.form.$error || !$v.form.$dirty"
								class="mt-2 mb-1"
								color="success"
							>
								Отправить
							</AppBtn>
							<div class="mt-1 mb-1" v-if="submit">Сообщение отправлено</div>
						</div>
					</form> -->

					<div class="card-support__text">
						Специалисты техподдержки работают с понедельника по пятницу с 9:00 до 17:00 по московскому времени.
					</div>
				</template>
			</AppCard>
		</div>
	</div>
</template>

<script>
import faq from "./questions/questions-main";
// import { required, minLength, email } from "vuelidate/lib/validators";
// import FilesAdd from "./files-add.vue";

export default {
	// data() {
	// 	return {
	// 		form: {
	// 			email: "",
	// 			message: "",
	// 			session: null,
	// 			file: []
	// 		},
	// 		loading: false,
	// 		submit: false
	// 	};
	// },
	// beforeMount() {
	// 	ym(54842275, "getClientID", clientID => {
	// 		this.form.session = clientID;
	// 	});
	// },
	// methods: {
	// 	changeListFiles(list) {
	// 		this.form.file = [...list];
	// 	},
	// 	async submitForm() {
	// 		this.$v.$touch();
	// 		if (!this.$v.form.$invalid) {
	// 			this.loading = true;
	// 			try {
	// 				const formData = new FormData();

	// 				formData.append("email", this.form.email);
	// 				formData.append("message", this.form.message);
	// 				formData.append("session", this.form.session);
	// 				formData.append("file", this.form.file[0]);

	// 				await this.$store.dispatch("supportBlock", formData);
	// 				this.submit = true;
	// 			} catch (e) {
	// 				this.$errorsMod(e.response);
	// 			}
	// 			this.loading = false;
	// 		}
	// 	}
	// },
	// validations: {
	// 	form: {
	// 		email: {
	// 			required,
	// 			email
	// 		},
	// 		message: {
	// 			required
	// 		}
	// 	}
	// },
	components: {
		faq
		// FilesAdd
	}
};
</script>
