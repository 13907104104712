var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('ModalCompanyInfo'),_c('ModalProductInfo'),_c('AppTable',{attrs:{"header":_vm.headerTable,"data":_vm.data,"messageNoData":"...","emptyImage":"/img/payments-empty.svg","emptyText":"...","childRow":false},scopedSlots:_vm._u([{key:"thead",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.name))])]}},{key:"tbody",fn:function(ref){
var item = ref.item;
return [_c('td',{class:{ disabled: item.isNotAvailable },on:{"click":function($event){return _vm.openProductModal(item)}}},[_c('span',{staticClass:"app-table__title-mob",staticStyle:{"display":"none"}},[_vm._v("Название:")]),_c('span',[_vm._v(_vm._s(item.name))])]),_c('td',{staticClass:"app-table__company-mob",class:{ disabled: item.isNotAvailable },on:{"click":function($event){return _vm.openCompanyModal(item)}}},[_c('div',{staticStyle:{"background-color":"white","height":"100%","padding":".7em","display":"flex","justify-content":"center","border-radius":".7em"}},[_c('img',{staticStyle:{"width":"auto","max-height":"60px"},attrs:{"src":("https://квартплата.онлайн/files/static/" + (item.suplierImage))}})])]),_c('td',{class:{ disabled: item.isNotAvailable }},[_c('star-rating',{attrs:{"rating":item.grade,"read-only":true,"increment":0.1,"star-size":20,"show-rating":false,"active-color":"#EB982C","star-points":[
						23,
						2,
						14,
						17,
						0,
						19,
						10,
						34,
						7,
						50,
						23,
						43,
						38,
						50,
						36,
						34,
						46,
						19,
						31,
						17
					]}})],1),(!item.isNotAvailable)?_c('td',[_c('span',{staticClass:"app-table__title-mob"},[_vm._v("Цена:")]),_c('span',[_vm._v(" "+_vm._s(( item.price * (_vm.countInBasket({ id: item.id, apartmentId: _vm.$route.query.apartmentId }) || 1) ).toLocaleString("ru"))+" ₽ ")])]):_vm._e(),_c('td',{staticClass:"before-favourites",class:{ disabled: item.isNotAvailable },attrs:{"colspan":item.isNotAvailable ? 2 : 1}},[(item.isNotAvailable)?_c('div',{staticClass:"disabled-price"},[_vm._v(" товар недоступен в выбранном городе ")]):_c('div',[(
							_vm.inTheBasket({
								id: item.id,
								apartmentId: _vm.$route.query.apartmentId
							})
						)?_c('BasketCalc',{attrs:{"value":_vm.countInBasket({
								id: item.id,
								apartmentId: _vm.$route.query.apartmentId
							})},on:{"input":function($event){return _vm.setProductCount({
								product: item,
								apartmentId: _vm.$route.query.apartmentId,
								count: $event.val
							})},"remove":function($event){return _vm.removeProductFromCart({
								id: item.id,
								apartmentId: _vm.$route.query.apartmentId
							})}}}):_c('AppBtn',{staticStyle:{"white-space":"nowrap"},attrs:{"outlined":_vm.inTheBasket({
								id: item.id,
								apartmentId: _vm.$route.query.apartmentId
							})},on:{"click":function($event){return _vm.setProductCount({
								product: item,
								apartmentId: _vm.$route.query.apartmentId,
								count: 1
							})}}},[_vm._v(" "+_vm._s(_vm.inTheBasket({ id: item.id, apartmentId: _vm.$route.query.apartmentId }) ? "В корзине" : "В корзину")+" ")])],1)]),(!_vm.$route.query.apartmentId)?_c('td',{staticClass:"favourites",class:{ disabled: item.isNotAvailable }},[(item.isFavourite)?_c('AppBtn',{staticStyle:{"font-size":"2em"},attrs:{"icon":"","text":""},on:{"click":function($event){return _vm.removeFromFavourites(item.id)}}},[_c('i',{staticClass:"fas fa-heart",staticStyle:{"color":"var(--color-danger)"}})]):_c('AppBtn',{staticStyle:{"font-size":"2em"},attrs:{"icon":"","text":""},on:{"click":function($event){return _vm.addToFavourites(item.id)}}},[_c('i',{staticClass:"far fa-heart",staticStyle:{"color":"var(--color-danger)"}})])],1):_vm._e()]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }