import $axios from '@/plugins/axios-instance'
import {router} from '../../router'
import * as _ from 'lodash';

export default {
	state: {
		//Поиск по ИНН
		searchInn:{
			data: [],
			loading: false,
			error: null
		},
		//Поиск по ЛС
		searchById: {
			data: [],
			loading: false,
			error: null
		},
		accountAdd: {
			data: [],
			loading: false,
			error: null
		},
		accountAddSelectedIdOrg: null, //выбранная организация на этапе поиска по ИНН
		accountAddDivisionIdOrg: null, //выбранный департамент на этапе поиска по ИНН

	},
	getters: {
		getSearchInn(state) {
			return state.searchInn
		},
		getSearchById(state) {
			return state.searchById
		},
		getAccountAdd(state) {
			return state.accountAdd
		},


		getAccountAddSelectedIdOrg (state) { 
			//выбранная организация на этапе поиска по ИНН
			return state.accountAddSelectedIdOrg
		},
		getAccountAddDivisionIdOrg (state) { 
			//выбранный департамент на этапе поиска по ИНН
			return state.accountAddDivisionIdOrg
		},

	},
	mutations: {
		setSearchInnData(state, data) {
			state.searchInn.data = data
		},
		setSearchInnLoad(state, load) {
			if(load) state.searchInn.error = null
			state.searchInn.loading = load
		},
		setSearchInnError(state, error) {
			state.searchInn.error = error
		},



		setSearchByIdData(state, data) {
			state.searchById.data = data
		},
		setSearchByIdLoad(state, load) {
			if(load) state.searchById.error = null
			state.searchById.loading = load
		},
		setSearchByIdError(state, error) {
			state.searchById.error = error
		},



		setAccountAddData(state, data) {
			state.accountAdd.data = data
		},
		setAccountAddLoad(state, load) {
			if(load) state.accountAdd.error = null
			state.accountAdd.loading = load
		},
		setAccountAddError(state, error) {
			state.accountAdd.error = error
		},

		accountAddSelectedIdOrgState(state, id) {
			state.accountAddSelectedIdOrg = id
		},

		accountAddSelectedIdDivisionState(state, id) {
			state.accountAddDivisionIdOrg = id
		},

	},
	actions: {
		searchByInn ({commit}, inn) {
			commit('setSearchInnLoad', true)
            return $axios.get(`/organization/orginfo?inn=${inn}` )
            .then(async (response) => {
				commit('setSearchInnData', response.data)
				if(response.data.length == 0) {
					await new Promise(r => setTimeout(r, 1200));
				}
				return response.data
            }).catch((e)=>{
				commit('setSearchInnError', e.response)
				throw (e)
            }).finally(() => {
				commit('setSearchInnLoad', false)
			})
		},


		searchById ({commit}, data) {
			commit('setSearchByIdLoad', true)
			const division = data.divisionId ? `&divisionId=${data.divisionId}` : '';
			
            return $axios.get(`/Account/searchById?orgId=${data.orgId}&number=${data.number}${division}`)
            .then( async (response) => {
				commit('setSearchByIdData', response.data)
				if(response.data.length == 0) {
					await new Promise(r => setTimeout(r, 1200));
				}

				return response.data
            }).catch((e)=>{
				commit('setSearchByIdError', e.response)
                throw(e)
            }).finally(() => {
				commit('setSearchByIdLoad', false)
			})
		},


		accountAdd ({commit, state, dispatch}, data) {
			commit('setAccountAddLoad', true)
            return $axios.post(`/Account/Add`, data)
            .then(async (response) => {
				await dispatch('getTotalDebt');
				commit('setAccountAddData', response.data);
            }).catch((e)=>{
				commit('setAccountAddError', e.response)
                throw(e)
            }).finally(() => {
				commit('setAccountAddLoad', false)
			})
		},
	}
}
