<template>
	<div>
		<ModalCompanyInfo />
		<ModalProductInfo />
		<AppTable
			:header="headerTable"
			:data="data"
			messageNoData="..."
			emptyImage="/img/payments-empty.svg"
			emptyText="..."
			:childRow="false"
		>
			<template v-slot:thead="{ item }">
				<span>{{ item.name }}</span>
			</template>

			<template v-slot:tbody="{ item }">
				<td
					:class="{ disabled: item.isNotAvailable }"
					@click="openProductModal(item)"
				>
					<span class="app-table__title-mob" style="display: none"
						>Название:</span
					>
					<span>{{ item.name }}</span>
				</td>
				<td
					:class="{ disabled: item.isNotAvailable }"
					@click="openCompanyModal(item)"
					class="app-table__company-mob"
				>
					<div
						style="background-color: white;  height:100%; padding:.7em; display: flex; justify-content: center; border-radius: .7em;"
					>
						<img
							style="width: auto; max-height: 60px"
							:src="
								`https://квартплата.онлайн/files/static/${item.suplierImage}`
							"
						/>
					</div>
				</td>

				<td :class="{ disabled: item.isNotAvailable }">
					<star-rating
						:rating="item.grade"
						:read-only="true"
						:increment="0.1"
						:star-size="20"
						:show-rating="false"
						active-color="#EB982C"
						:star-points="[
							23,
							2,
							14,
							17,
							0,
							19,
							10,
							34,
							7,
							50,
							23,
							43,
							38,
							50,
							36,
							34,
							46,
							19,
							31,
							17
						]"
					></star-rating>
				</td>

				<td v-if="!item.isNotAvailable">
					<span class="app-table__title-mob">Цена:</span>

					<span>
						{{
							(
								item.price *
								(countInBasket({
									id: item.id,
									apartmentId: $route.query.apartmentId
								}) || 1)
							).toLocaleString("ru")
						}}
						₽
					</span>
				</td>

				<td
					:class="{ disabled: item.isNotAvailable }"
					:colspan="item.isNotAvailable ? 2 : 1"
					class="before-favourites"
				>
					<div v-if="item.isNotAvailable" class="disabled-price">
						товар недоступен в выбранном городе
					</div>
					<div v-else>
						<BasketCalc
							v-if="
								inTheBasket({
									id: item.id,
									apartmentId: $route.query.apartmentId
								})
							"
							:value="
								countInBasket({
									id: item.id,
									apartmentId: $route.query.apartmentId
								})
							"
							@input="
								setProductCount({
									product: item,
									apartmentId: $route.query.apartmentId,
									count: $event.val
								})
							"
							@remove="
								removeProductFromCart({
									id: item.id,
									apartmentId: $route.query.apartmentId
								})
							"
						/>
						<AppBtn
							style="white-space: nowrap;"
							v-else
							:outlined="
								inTheBasket({
									id: item.id,
									apartmentId: $route.query.apartmentId
								})
							"
							@click="
								setProductCount({
									product: item,
									apartmentId: $route.query.apartmentId,
									count: 1
								})
							"
						>
							{{
								inTheBasket({
									id: item.id,
									apartmentId: $route.query.apartmentId
								})
									? "В корзине"
									: "В корзину"
							}}
						</AppBtn>
					</div>
				</td>

				<td
					:class="{ disabled: item.isNotAvailable }"
					class="favourites"
					v-if="!$route.query.apartmentId"
				>
					<AppBtn
						v-if="item.isFavourite"
						icon
						text
						style="font-size: 2em;"
						@click="removeFromFavourites(item.id)"
					>
						<i style="color: var(--color-danger)" class="fas fa-heart"></i>
					</AppBtn>
					<AppBtn
						v-else
						icon
						text
						style="font-size: 2em;"
						@click="addToFavourites(item.id)"
					>
						<i style="color: var(--color-danger)" class="far fa-heart"></i>
					</AppBtn>
				</td>
			</template>
		</AppTable>
	</div>
</template>

<script>
import StarRating from "vue-star-rating";
import MontageTable from "./montage-table";
import { mapGetters, mapMutations } from "vuex";
import ModalCompanyInfo from "../modal/company-info.vue";
import ModalProductInfo from "../modal/modal-product-info.vue";
import BasketCalc from "./basket-calc.vue";

export default {
	props: {
		data: Array,
		loadInfo: Function
	},
	data() {
		return {
			activeTab: 0
		};
	},
	computed: {
		...mapGetters([
			"getActiveTown",
			"getSortKey",
			"getCart",
			"countInBasket",
			"inTheBasket"
		]),
		headerTable() {
			const headerTable = [
				{
					name: "Название",
					width: "35%"
				},
				{
					name: "Компания",
					width: "10%"
				},
				{
					name: "",
					width: "25%"
				},
				{
					name: "Цена",
					width: "10%"
				},
				{
					name: "",
					width: "10%"
				}
			];
			if (this.$route.query.apartmentId) {
				return headerTable;
			} else {
				return [
					...headerTable,
					{
						name: "",
						width: "4%"
					}
				];
			}
		}
	},
	methods: {
		...mapMutations(["setProductCount", "removeProductFromCart"]),

		openCompanyModal(item) {
			this.$modal.show("company-info", { ...item });
		},
		openProductModal(item) {
			this.$modal.show("product-info", { ...item });
		},

		async addToFavourites(id) {
			await this.$store.dispatch("addToFavourites", id);
			await this.loadInfo();
		},
		async removeFromFavourites(id) {
			await this.$store.dispatch("removeFromFavourites", id);
			await this.loadInfo();
		}
	},
	components: {
		MontageTable,
		StarRating,
		BasketCalc,
		ModalCompanyInfo,
		ModalProductInfo
	}
};
</script>
<style scoped>
.disabled {
	opacity: 0.4;
	pointer-events: none;
}

.disabled-price {
	text-align: center;
	font-size: 1em;
}

@media (max-width: 768px) {
	.favourites {
		position: absolute;
		top: 0;
		right: 0;
		border: none !important;
	}

	.before-favourites {
		padding-bottom: 1em;
	}
}
</style>
