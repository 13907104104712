<template>
	<div>
		<AppTabs :data="tabs">
			<template v-slot:content="{ active }">
				<div v-if="active == 0">
					<Profile />
				</div>
				<div v-if="active == 1">
					<Cards />
				</div>
				<div v-if="active == 2">
					<Notifications />
				</div>
				<div v-if="active == 3">
					<Discounts />
				</div>
			</template>
		</AppTabs>
	</div>
</template>

<script>
import Profile from "./profile-tab.vue";
import Cards from "./cards-tab.vue";
import Notifications from "./notifications-tab.vue";
import Discounts from "./discounts-tab.vue";

export default {
	data() {
		return {
			tabs: [
				{
					name: "Личные данные",
					iconSvg: "info-list",
					href: "profile"
				},
				{
					name: "Карты",
					iconSvg: "card",
					href: "cards"
				},
				{
					name: "Уведомления",
					iconSvg: "ring",
					href: "notifications"
				},
				{
					name: "Cкидки",
					iconSvg: "badge-percent",
					href: "discounts"
				}
			]
		};
	},
	mounted() {},
	computed: {},
	methods: {},
	components: {
		Discounts,
		Profile,
		Cards,
		Notifications
	}
};
</script>
