<template>
	<div class="wrapper">
		<AppCard>
			<template #body>
				<h3 class="medium mb-3">Заказ № {{ $route.params.id }}</h3>

				<div class="suplier-order">
					<div class="suplier-order__content">
						<div class="suplier-order__row">
							<div class="suplier-order__title-b">
								Дата:
							</div>
							<div class="suplier-order__title-t">
								{{ dateReturnFormat(order.date) }}
							</div>
						</div>
						<div class="suplier-order__row">
							<div class="suplier-order__title-b">
								Статус:
							</div>
							<div class="suplier-order__title-t">
								{{ status }}
							</div>
						</div>
						<div class="suplier-order__row">
							<div class="suplier-order__title-b">
								Поставщик:
							</div>
							<div class="suplier-order__title-t">
								{{ order.suplierName }}
							</div>
						</div>
						<div class="suplier-order__row">
							<div class="suplier-order__title-b">
								Адрес:
							</div>
							<div class="suplier-order__title-t">
								{{ order.address }}
							</div>
						</div>
						<div class="suplier-order__row">
							<div class="suplier-order__title-b">
								Телефон:
							</div>
							<div class="suplier-order__title-t">
								{{ order.phone }}
							</div>
						</div>
						<div class="suplier-order__row">
							<div class="suplier-order__title-b">
								Сумма заказа:
							</div>
							<div class="suplier-order__title-t">{{ order.sum }} ₽</div>
						</div>
						<div class="suplier-order__row" style="align-items: flex-start;">
							<div class="suplier-order__title-b">
								Комментарий:
							</div>
							<div v-if="order.description" class="suplier-order__title-t">
								<p
									v-for="item in order.description
										.replace(/]/g, ' ')
										.split('[')"
								>
									{{ item }}
								</p>
							</div>
						</div>
					</div>
					<div class="suplier-order__row" v-if="order.statusDescription">
						<div class="suplier-order__title-b">
							Комментарий поставщика:
						</div>
						<div class="suplier-order__title-t">
							{{ order.statusDescription }}
						</div>
					</div>
				</div>
			</template>
		</AppCard>

		<AppCard>
			<template #header>
				<h4 class="medium mb-3">
					Список услуг/товаров
				</h4>
			</template>
			<template #body>
				<AppTable
					:header="headerTable"
					:data="order.items"
					messageNoData="Список пуст"
					class="order-list"
				>
					<template v-slot:thead="{ item }">
						<span>{{ item.name }}</span>
					</template>
					<template v-slot:tbody="{ item }">
						<td>
							{{ item.name }}
						</td>
						<td>
							<span class="app-table__title-mob">
								Количество:
							</span>
							{{ item.count }}
						</td>
						<td>
							<span class="app-table__title-mob">
								Сумма:
							</span>
							{{ item.sum }} р.
						</td>
					</template>
				</AppTable>
			</template>
		</AppCard>

		<AppCard v-if="showComments">
			<template #header>
				<h4 class="medium mb-3">
					Комментарии
				</h4>
			</template>
			<template #body>
				<div class="comments-box">
					<div class="comment" v-for="comment in order.comments">
						<div class="message__text">{{ comment.text }}</div>
						<div class="message__date">
							{{ dateReturnFormat(comment.created) }}
						</div>
					</div>
				</div>
				<template v-if="order.status === 3 || order.status === 0">
					<AppTextarea
						type="text"
						label="Ваш комментарий:"
						cols="45"
						rows="6"
						required
						v-model.trim="$v.form.comment.$model"
						:error="$v.form.comment.$error"
						:requiredError="!$v.form.comment.$error"
					>
						<div slot="required">Не более 250 символов</div>
					</AppTextarea>
					<div class="mt-2" style="display: flex; justify-content: end;">
						<AppBtn :disabled="commentBtnDisabled" @click="send"
							>Отправить</AppBtn
						>
					</div>
				</template>
			</template>
		</AppCard>
		<RatingCard v-if="order.grade === null" @hide-rating="loadInfo" />
	</div>
</template>

case 0: return "Новый"; case 2: return "Выполнен"; case 3: return "В работе";
default: return "Отменен";

<script>
import { mapGetters } from "vuex";
import { dateReturnFormat } from "@/plugins/methods";
import { maxLength } from "vuelidate/lib/validators";
import RatingCard from "./rating-card.vue";

export default {
	components: { RatingCard },
	data() {
		return {
			test: "test",
			commentBtnDisabled: false,
			headerTable: [
				{
					name: "Наименование",
					width: "40%"
				},
				{
					name: "Количество",
					width: "30%"
				},
				{
					name: "Цена",
					width: "30%"
				}
			],
			order: {
				id: null,
				suplierName: "",
				address: "",
				phone: "",
				description: "",
				date: "",
				status: 0,
				statusDescription: null,
				sum: 1500,
				grade: null,
				items: [
					// {
					//     "name": "Тест Общий 2",
					//     "price": 500,
					//     "count": 3,
					//     "sum": 1500
					// }
				],
				comments: []
			},
			form: {
				comment: ""
			}
		};
	},
	computed: {
		...mapGetters(["getTheme"]),
		status() {
			switch (this.order.status) {
				case 0:
					return "Новый";
				case 2:
					return "Выполнен";
				case 3:
					return "В работе";
				default:
					return "Отменен";
			}
		},
		showComments() {
			return (
				this.order.status === 3 ||
				this.order.status === 0 ||
				(this.order.comments && this.order.comments.length)
			);
		}
	},
	methods: {
		async loadInfo() {
			this.order = await this.$store.dispatch(
				"fetchOrder",
				this.$route.params.id
			);
		},
		dateReturnFormat(val) {
			return dateReturnFormat(val);
		},
		async send() {
			this.$v.form.$touch();
			if (!this.$v.form.$invalid) {
				let formData = {
					orderId: this.$route.params.id,
					text: this.form.comment
				};
				this.commentBtnDisabled = true;
				try {
					await this.$store.dispatch("sendOrderComment", formData);
					await this.loadInfo();
					this.form.comment = "";
				} catch (e) {
					this.$errorsMod(e);
				} finally {
					this.commentBtnDisabled = false;
				}
			}
		}
	},
	mounted() {
		this.loadInfo();
	},
	validations(val) {
		return {
			form: {
				comment: {
					maxLength: maxLength(250)
				}
			}
		};
	}
};
</script>

<style scoped>
.wrapper {
	display: flex;
	flex-direction: column;
	gap: 1em;
}

.comments-box {
	display: flex;
	flex-direction: column;
	align-items: flex-end;
	gap: 1em;
	margin-bottom: 1em;
}

.comment {
	padding: 0.5em;
	border: 1px solid var(--color-primary-two);
	border-radius: 0.5em;
	position: relative;
	z-index: 5;
	background: var(--color-secondary-five);
	margin-right: 1em;
}

.comment::before {
	content: "";
	position: absolute;
	width: 0;
	height: 0;
	left: calc(100% - 1em);
	bottom: calc(0% - 1px);
	border: 1em solid;
	border-color: transparent transparent var(--color-primary-two) transparent;
	z-index: 4;
}

.comment::after {
	content: "";
	position: absolute;
	width: 0;
	height: 0;
	left: calc(100% - 1em);
	bottom: calc(0%);
	border: calc(1em - 1px) solid;
	border-color: transparent transparent var(--color-secondary-five) transparent;
	z-index: 5;
}

::v-deep .order-list tr {
	border-radius: 0;
	box-shadow: none;
}

::v-deep .order-list .app-table__th {
	background: none;
	padding: 0;
	border: none;
}

::v-deep .order-list .app-table__tr-body td {
	background: none;
	padding: 0;
	border: none;
}

::v-deep .order-list .app-table__tr-body td:nth-child(1) {
	border-radius: 0;
	padding: 0;
	border: none;
}

::v-deep .order-list .app-table__tr-body td:last-child {
	border-radius: 0;
	padding: 0;
	border: none;
}

::v-deep .order-list .app-table {
	border-spacing: 0.5em 0.5em;
}
</style>
