<template>
	<AppModals
		name="counter-warning"
		title="Предупреджение"
		@beforeOpen="beforeOpen"
		:hideFooter="true"
	>
		<template #content="{}">
			<div class="modal-content">
				<div class="text">
					Проверьте передаваемые показания по выбранным счетчикам
				</div>
				<div class="counters">
					<div
						v-for="{
							serviceName,
							value,
							userValue,
							counterID,
							unitName
						} in counters"
						:key="counterID"
						class="counter"
					>
						<div class="counter-name">
							{{ serviceName }}
						</div>
						<span class="value">{{
							`${userValue.toLocaleString()} ${unitName}`
						}}</span>
						<span class="offset">{{
							`( + ${(userValue - value).toLocaleString()} ${unitName})`
						}}</span>
					</div>
				</div>
				<div class="btns">
					<AppBtn @click="modalClose" outlined>Отмена</AppBtn>
					<AppBtn @click="confirm" color="success">Все верно</AppBtn>
				</div>
			</div>
		</template>
	</AppModals>
</template>

<script>
export default {
	data() {
		return {
			counters: []
		};
	},
	methods: {
		modalClose() {
			this.$modal.hide("counter-warning");
		},
		confirm() {
			this.$modal.hide("counter-warning");
			this.$emit("submit", "Jnghfdktyj");
		},

		async beforeOpen(event) {
			this.counters = event.warningCounters;
		}
	}
};
</script>

<style scoped>
.modal-content {
	font-size: 1em;
}
.text {
	margin-top: 2em;
	font-size: 1.3em;
}
.counters {
	display: flex;
	flex-direction: column;
	margin-top: 1em;
}
.counter {
	color: var(--color-secondary-two);
	font-size: 1.1em;
	padding: 0.5em 0;
	border-bottom: 1px dotted var(--color-secondary-three);
}
.counter-name {
	margin-bottom: 0.5em;
}
.value {
	color: var(--color-secondary);
	margin-right: 1em;
}
.offset {
	color: var(--color-warning);
}
.btns {
	display: flex;
	justify-content: center;
	gap: 2em;
	width: 100%;
	margin-top: 2em;
}
.btns > button {
	flex-grow: 1;
	height: 2em;
	font-size: 1.2em;
}
</style>
