var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"mt-3--xs",staticStyle:{"margin-top":"-5px"}},[_c('AppCard',{attrs:{"classCss":"main-banner-card mb-5"},scopedSlots:_vm._u([{key:"body",fn:function(){return [_c('div',{staticClass:"main-banner"},[_c('div',{staticClass:"main-banner__description",style:([_vm.getTheme === 'dark-theme' ? _vm.darkStyle : _vm.defaultStyle])},[_vm._v(" Если лицевого счета нет в списке, значит по нему невозможно передать показания. ")]),_c('div',{staticClass:"main-banner__description",style:([_vm.getTheme === 'dark-theme' ? _vm.darkStyleDescription : _vm.defaultStyle])},[_vm._v(" Обычно это связано с тем, что у приборов учета истек срок поверки или управляющая организация не предоставила возможность передачи показаний. ")])])]},proxy:true}])}),_c('AppTable',{attrs:{"header":_vm.tableHeader,"data":_vm.getAccountswithcounters.data,"messageNoData":"Счетчики отстутсвуют","childRow":false},scopedSlots:_vm._u([{key:"thead",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.name))])]}},{key:"tbody",fn:function(ref){
var item = ref.item;
return [_c('td',{on:{"click":function($event){return _vm.routerLink(item)}}},[_c('span',[_vm._v(" "+_vm._s(item.description))])]),_c('td',{on:{"click":function($event){return _vm.routerLink(item)}}},[_c('span',{staticClass:"app-table__title-mob"},[_vm._v(" Адрес: ")]),_c('span',[_vm._v(" "+_vm._s(item.address))])]),_c('td',{on:{"click":function($event){return _vm.routerLink(item)}}},[_c('span',{staticClass:"app-table__title-mob"},[_vm._v(" Лицевой счет: ")]),_c('span',[_vm._v(" "+_vm._s(item.accountName))])]),_c('td',{on:{"click":function($event){return _vm.routerLink(item)}}},[(item.mustVerification)?_c('div',{staticClass:"warning-status"},[_vm._v(" Требуется поверка ")]):_vm._e()]),_c('td',{on:{"click":function($event){return _vm.routerLink(item)}}},[_c('span',{staticClass:"app-table__title-mob"},[_vm._v(" Поставщик: ")]),_c('span',[_vm._v(" "+_vm._s(item.organization))])]),_c('td',[_c('div',{staticClass:"d-flex align-center just-end"},[(item.hasValues)?_c('AppIcon',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
								content: 'Показания переданы',
								//show: true,
								trigger: 'hover',
								placement: 'bottom'
							}),expression:"{\n\t\t\t\t\t\t\t\tcontent: 'Показания переданы',\n\t\t\t\t\t\t\t\t//show: true,\n\t\t\t\t\t\t\t\ttrigger: 'hover',\n\t\t\t\t\t\t\t\tplacement: 'bottom'\n\t\t\t\t\t\t\t}"}],staticClass:"mr-3",staticStyle:{"color":"#33BC7D"},attrs:{"prefix":"far fa-","name":"check-circle","size":"30px"}}):_vm._e(),_c('AppBtn',{on:{"click":function($event){return _vm.routerLink(item)}}},[_vm._v("Передать показания")])],1)])]}}])})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }