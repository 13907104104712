var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"buy-insurance__list"},[(_vm.getInsurancePrepareLoading)?_c('div',{staticClass:"loading-wrapper"},[_c('AppLoading',{attrs:{"height":70}})],1):_c('div',[_c('AppTable',{staticClass:"insurance-history-table",attrs:{"header":_vm.headerTable,"data":_vm.getInsurancePrepare,"messageNoData":"У Вас нет привязанных лицевых счетов или договоров. Нажмите на кнопку \"Добавить\", чтобы добавить ЛС или договор.","childRow":false},scopedSlots:_vm._u([{key:"thead",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.name))])]}},{key:"tbody",fn:function(ref){
var item = ref.item;
return [_c('td',{on:{"click":function($event){return _vm.$emit('click-card', item.id)}}},[_c('span',[_vm._v(" "+_vm._s(item.address))])]),_c('td',{on:{"click":function($event){return _vm.$emit('click-card', item.id)}}},[_c('span',[_vm._v(" "+_vm._s(item.program.providerName)+" ")])]),_c('td',{staticClass:"insurance-history-table__date",on:{"click":function($event){return _vm.$emit('click-card', item.id)}}},[_c('span',[_c('InsurancePeriod',{attrs:{"date":{ dateStart: null, dateEnd: item.lastInsuranceDate }}})],1)]),_c('td',{staticClass:"insurance-history-table__price",on:{"click":function($event){return _vm.$emit('click-card', item.id)}}},[_c('span',{staticClass:"app-table__title-mob"},[_vm._v(" Цена: ")]),_c('span',[_vm._v(" "+_vm._s(item.program.price + "₽")+" ")])]),_c('td',{on:{"click":function($event){return _vm.$emit('click-card', item.id)}}},[_c('div',{staticClass:"periodCell"},[_c('span',{staticClass:"app-table__title-mob"},[_vm._v(" Месяцы: ")]),(item.canInsurance && item.maxDurationMonths > 0)?_c('span',[_c('Counter',{attrs:{"value":item.monthCount,"maxValue":item.maxDurationMonths},on:{"input":function($event){return _vm.changeCount(item.apartmentId, $event.val)}}})],1):_c('div',{staticClass:"monthCell"},[_c('span',{staticClass:"cantInsurance"},[_vm._v(" Недоступно ")]),_c('span',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
									content:
										item.message ||
										'Вы используете максимально возможный срок страховки',
									trigger: 'hover',
									placement: 'top'
								}),expression:"{\n\t\t\t\t\t\t\t\t\tcontent:\n\t\t\t\t\t\t\t\t\t\titem.message ||\n\t\t\t\t\t\t\t\t\t\t'Вы используете максимально возможный срок страховки',\n\t\t\t\t\t\t\t\t\ttrigger: 'hover',\n\t\t\t\t\t\t\t\t\tplacement: 'top'\n\t\t\t\t\t\t\t\t}"}]},[_c('AppIcon',{staticStyle:{"color":"#A5A5A4"},attrs:{"name":"question-circle"}})],1)])])])]}}])}),(
				_vm.getInsurancePrepare.length &&
					_vm.getInsurancePrepare.some(function (item) { return item.canInsurance; })
			)?[_c('div',{staticClass:"buy-insurance__footer"},[(_vm.isGroup)?[_c('div',{staticClass:"buy-insurance__total"},[_vm._v(" Итого по страховке: "),_c('span',{staticClass:"buy-insurance__sum"},[_vm._v(_vm._s(_vm.$numberToString(_vm.getTotalPrice))+" ₽")])]),(this.getInsurancePrepare.length > 0 && this.getTotalPrice)?_c('AppBtn',{staticClass:"mb-2",on:{"click":_vm.prepareToPay}},[_vm._v(" Застраховать ")]):_vm._e(),_c('AppBtn',{attrs:{"outlined":""},on:{"click":_vm.backToPaymentPage}},[_vm._v(" Назад ")])]:_vm._e(),_c('div',{staticClass:"insurance-provider-links"},[_c('p',[_vm._v(" *С правилами можно ознакомиться по "),_c('a',{attrs:{"href":("https://cdn.jfservice.ru/insurance/" + (_vm.getInsurancePrepare[0].program.insuranceRulesOferta)),"target":"_blank"}},[_vm._v(" ссылке ")])]),_c('p',[_vm._v(" *Условия страховки отражены в "),_c('a',{attrs:{"href":("https://cdn.jfservice.ru/insurance/" + (_vm.getInsurancePrepare[0].program.programRulesOferta)),"target":"_blank"}},[_vm._v(" полисе-оферте ")])])])],2),(!_vm.isGroup)?_c('payment-footer'):_vm._e()]:_vm._e()],2)])}
var staticRenderFns = []

export { render, staticRenderFns }