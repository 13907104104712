<template>
	<div 
		class="app-input" 
		:style="`padding: 0; border-right: none; display: block; z-index: ${
			100 - index
		}; position: relative`"
	>
		<div
			style="
				padding: 0;
				border-right: none;
				display: block;
				z-index: 10;
				position: relative;
			">
			<input class="app-input__input" :class="{
				'app-input__error-inp': error,
				'app-input__input--white': white,
			}" type="text" :disabled="disabled" :value="value" @input="changeInput($event.target.value)" oninput="this.value = (()=>{
                    const f = x => ( (x.toString().includes('.')) ? (x.toString().split('.').pop().length) : (0) );
                    this.value = this.value.replace(',','.').replace(/^\.|[^\d\.]|\.(?=.*\.)|^0+(?=\d)/g, '')

					if (+this.value >=999999.99) {
						return 999999.99
					}
					
                    if(/^(([1-9]\d*)|\d)(\.)?(\d{1,2})?$/.test(this.value)) {
                        return this.value.replace(',','.').replace(/^\.|[^\d\.]|\.(?=.*\.)|^0+(?=\d)/g, '')
                    }
                    if(f(this.value) > 2) {
                        return parseFloat(this.value.replace(',','.').replace(/^\.|[^\d\.]|\.(?=.*\.)|^0+(?=\d)/g, '')).toFixed(3).slice(0,-1)
                    } else {
                        return this.value.replace(',','.').replace(/^\.|[^\d\.]|\.(?=.*\.)|^0+(?=\d)/g, '')
                    }
                })()" />

		</div>
		<div
			v-if="required"
			v-tooltip="{
			content: 'Заполните это поле',
			show: true,
			trigger: 'manual',
			placement: 'bottom',
			}"
		></div>

		<div
			v-if="errorMin && !required"
			v-tooltip="{
			content: 'Сумма должна быть больше 1 руб',
			show: true,
			trigger: 'manual',
			placement: 'bottom',
			}"
		></div>

		<div
			v-if="errorMax && !required"
			v-tooltip="{
			content: 'Сумма не должна быть больше 300 000',
			show: errorMax && !required,
			trigger: 'manual',
			placement: 'bottom',
			}"
		></div>
	</div>
</template>

<script>
export default {
	data() {
		return {
			sum: this.value,
			valid: true,
		};
	},
	props: {
		type: {
			type: String,
			default: "Text",
		},
		value: {
			default: "",
		},
		error: {
			type: Boolean,
			default: false,
		},
		placeholder: {
			type: String,
			default: "",
		},
		canPay: {
			type: Boolean,
			default: true,
		},
		disabled: {
			type: Boolean,
			default: false,
		},
		errorMin: {
			type: Boolean,
			default: false,
		},
		errorMax: {
			type: Boolean,
			default: false,
		},
		required: {
			type: Boolean,
			default: false,
		},
		index: {
			type: Number,
			default: 1,
		},
		white: {
			type: Boolean,
			default: false,
		},
	},
	mounted() { },
	methods: {
		changeInput(val) {
			this.validTest();
			this.$emit("input", val);
		},
		validTest() {
			this.valid = /^[0-9]+(\.[0-9]{1,2})?$/.test(this.value);

			if (!this.valid) {
				this.valid = false;
			} else {
				this.valid = true;
			}
		},
	},
};
</script>

<style>
.error-message-payment {
	padding: 3px;
	background: rgb(50, 50, 50);
	width: 200px;
	height: 35px;
	position: absolute;
	z-index: 200 !important;
	text-align: center !important;
	color: #fff;
	bottom: -45px;
	opacity: 0.8;
	border-radius: 2px;
}

.error-message-payment::after {
	content: "";
	position: absolute;
	left: 35px;
	top: -15px;
	border: 8px solid transparent;
	border-bottom: 10px solid rgb(50, 50, 50);
}

@media (max-width: 800px) {
	.error-message-payment {
		bottom: -45px;
	}
}
</style>


                     