<template>
    <AppModals
        name="account-disconnect"
        title="Открепить ЛС"
		@beforeOpen="beforeOpen"
        @confirm="removeAccount"
		colorConfirm="danger"
		confirmButtonText="Открепить"
		:loadingConfirm="getAccountSplit.loading"
    >
        <template  v-slot:content="{}">
            <div>
                <div style="text-align: left; color: #777776;" class="mt-3 mb-3">Используйте эту функцию, если хотите вернуть этот лицевой счет в помещение, в котором он был изначально. </div>
            </div>
        </template>
    </AppModals>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
	data () {
		return {
			accountId: null,
			accountName: null
		}
	},
	props: {

	},
	computed: {
		...mapGetters([
			'getAccountSplit'
		])
	},
	methods: {
		async removeAccount () {
			try {
				await this.$store.dispatch('accountSplit', {
					accountId: this.accountId,
					apartmentId: this.$route.params.id
				})
				this.$modal.hide('account-disconnect')
			} catch (e) {
				this.$errorsMod(e.response)
			}
		},
		async beforeOpen (event) {
            this.accountName = event.accountName
            this.accountId = event.accountId
		}
	}
}
</script>