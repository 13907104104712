import $axios from "@/plugins/axios-instance";
import { router } from "../../router";

const activeTownIdLS = localStorage.getItem("activeTownId");

// const towns = process.env.NODE_ENV === 'development' ? [{ id: 0, town: 'Город не выбран' }] : []

export default {
	state: {
		towns: {
			activeTownId: activeTownIdLS ? JSON.parse(activeTownIdLS) : 0,
			sortKey: 1,
			towns: [],
			loading: true,
			error: null
		},
		groupList: {
			data: [],
			loading: true,
			error: null
		},
		categoryList: {
			data: [],
			loading: true,
			error: null
		},
		suppliers: {
			data: [],
			loading: true,
			error: null
		},
		suppliersCatalog: {
			data: [],
			loading: true,
			error: null
		},
		suppliersProduct: {
			data: [],
			loading: true,
			error: null
		},
		orderHistory: {
			data: [],
			loading: true,
			error: null
		},
		shopCategory: {
			data: [{ name: "" }],
			loading: true,
			error: null
		},
		shopCategoryInfo: {
			data: { image: "" },
			loading: true,
			error: null
		},
		shopGrade: {
			data: null,
			loading: true,
			error: null
		}
	},
	getters: {
		getTowns(state) {
			return state.towns.towns;
		},
		getActiveTownId(state) {
			return state.towns.activeTownId;
		},
		getActiveTown(state) {
			return state.towns.towns.find(
				item => item.id === state.towns.activeTownId
			);
		},
		getTownLoading(state) {
			return state.towns.loading;
		},
		getActiveTownQuery(state) {
			const query = state.towns.activeTownId
				? `?townId=${state.towns.activeTownId}`
				: "";
			return query;
		},
		getSortKey(state) {
			return state.towns.sortKey;
		},
		getGroupList(state) {
			return state.groupList;
		},
		getCategoryList(state) {
			return state.categoryList;
		},
		getSuppliers(state) {
			return state.suppliers;
		},
		getSuppliersCatalog(state) {
			return state.suppliersCatalog;
		},
		getSuppliersProduct(state) {
			return state.suppliersProduct;
		},
		getOrderHistory(state) {
			return state.orderHistory;
		},
		getCategory(state) {
			return state.shopCategory;
		},
		getCategoryInfo(state) {
			return state.shopCategoryInfo;
		},
		getShopGrade(state) {
			return state.shopGrade;
		}
	},
	mutations: {
		setTowns(state, data) {
			state.towns.towns = data;
		},
		setTownLoading(state, data) {
			state.towns.loading = data;
		},
		setActiveTown(state, id) {
			state.towns.activeTownId = id;
			localStorage.setItem("activeTownId", id);
		},
		setSortKey(state, sortKey) {
			state.towns.sortKey = sortKey;
		},

		setGroupListLoading(state, load) {
			state.groupList.loading = load;
		},
		setGroupListData(state, data) {
			state.groupList.data = data;
		},
		setGroupListError(state, error) {
			state.groupList.error = error;
		},
		setCategoryListLoading(state, load) {
			state.categoryList.loading = load;
		},
		setCategoryListData(state, data) {
			state.categoryList.data = data;
		},
		setCategoryListError(state, error) {
			state.categoryList.error = error;
		},

		setSuppliersLoading(state, load) {
			state.suppliers.loading = load;
		},
		setSuppliersData(state, data) {
			state.suppliers.data = data;
		},
		setSuppliersError(state, error) {
			state.suppliers.error = error;
		},

		setSuppliersCatalogLoading(state, load) {
			state.suppliersCatalog.loading = load;
		},
		setSuppliersCatalogData(state, data) {
			state.suppliersCatalog.data = data;
		},
		setSuppliersCatalogError(state, error) {
			state.suppliersCatalog.error = error;
		},

		setSuppliersProductLoading(state, load) {
			state.suppliersProduct.loading = load;
		},
		setSuppliersProductData(state, data) {
			state.suppliersProduct.data = data;
		},
		setSuppliersProductError(state, error) {
			state.suppliersProduct.error = error;
		},

		setOrderHistoryLoading(state, load) {
			state.orderHistory.loading = load;
		},
		setOrderHistoryData(state, data) {
			state.orderHistory.data = data;
		},
		setOrderHistoryError(state, error) {
			state.orderHistory.error = error;
		},

		setCategoryData(state, data) {
			return (state.shopCategory.data = data);
		},
		setCategoryLoading(state, load) {
			return (state.shopCategory.loading = load);
		},
		setCategoryError(state, error) {
			return (state.shopCategory.error = error);
		},

		setCategoryDataInfo(state, data) {
			return (state.shopCategoryInfo.data = data);
		},
		setCategoryLoadingInfo(state, load) {
			return (state.shopCategoryInfo.loading = load);
		},
		setCategoryErrorInfo(state, error) {
			return (state.shopCategoryInfo.error = error);
		},

		setShopGradeData(state, data) {
			return (state.shopGrade.data = data);
		},
		setShopGradeLoading(state, load) {
			return (state.shopGrade.loading = load);
		},
		setShopGradeError(state, error) {
			return (state.shopGrade.error = error);
		}
	},
	actions: {
		async fetchTowns({ state, commit }) {
			commit("setTownLoading", true);
			return await $axios
				.get(`/shop/towns`)
				.then(response => {
					commit("setTowns", response.data);
				})
				.catch(e => {
					commit("setGroupListError", e.response);
					throw e;
				})
				.finally(() => {
					commit("setTownLoading", false);
				});
		},
		async shopGroupList({ getters, commit }) {
			commit("setGroupListLoading", true);
			return await $axios
				.get(`/shop${getters.getActiveTownQuery}`)
				.then(response => {
					commit("setGroupListData", response.data);
					return response.data;
				})
				.catch(e => {
					commit("setGroupListError", e.response);
					throw e;
				})
				.finally(() => {
					commit("setGroupListLoading", false);
				});
		},

		async shopCategoryList({ getters, commit }, id) {
			commit("setCategoryListLoading", true);
			return await $axios
				.get(`/shop/${id}${getters.getActiveTownQuery}`)
				.then(response => {
					commit("setCategoryListData", response.data);
					return response.data;
				})
				.catch(e => {
					commit("setCategoryListError", e.response);
					throw e;
				})
				.finally(() => {
					commit("setCategoryListLoading", false);
				});
		},

		async shopSuppliers({ commit }, id) {
			commit("setSuppliersLoading", true);
			return await $axios
				.get(`/shop/${id}/supliers`)
				.then(response => {
					commit("setSuppliersData", response.data);
					return response.data;
				})
				.catch(e => {
					commit("setSuppliersError", e.response);
					throw e;
				})
				.finally(() => {
					commit("setSuppliersLoading", false);
				});
		},

		async shopSupplierCatalog({ commit, dispatch }, id) {
			commit("setSuppliersCatalogLoading", true);
			return await $axios
				.get(`/shop/suplier/${id}`)
				.then(async response => {
					commit("setSuppliersCatalogData", response.data);

					if (response.data.length > 0) {
						await dispatch("shopSupplierProduct", {
							suplierId: router.history.current.params.id,
							categoryId:
								router.history.current.query.serviceId || response.data[0].id
						});
					}
					return response.data;
				})
				.catch(e => {
					commit("setSuppliersCatalogError", e.response);
					throw e;
				})
				.finally(() => {
					commit("setSuppliersCatalogLoading", false);
				});
		},

		async shopSupplierProduct({ commit }, data) {
			commit("setSuppliersProductLoading", true);
			return await $axios
				.get(`/shop/suplier/${data.suplierId}/${data.categoryId}`)
				.then(response => {
					commit("setSuppliersProductData", response.data);
					return response.data;
				})
				.catch(e => {
					commit("setSuppliersProductError", e.response);
					throw e;
				})
				.finally(() => {
					commit("setSuppliersProductLoading", false);
				});
		},

		async shopOrderHistory({ commit }, data) {
			commit("setOrderHistoryLoading", true);
			return await $axios
				.get(`/shop/orders`)
				.then(response => {
					commit("setOrderHistoryData", response.data);
					return response.data;
				})
				.catch(e => {
					commit("setOrderHistoryError", e.response);
					throw e;
				})
				.finally(() => {
					commit("setOrderHistoryLoading", false);
				});
		},
		async fetchOrder({ commit }, id) {
			return await $axios
				.get(`/shop/orders/${id}`)
				.then(response => {
					return response.data;
				})
				.catch(e => {
					throw e;
				});
		},

		getShopCategory({ commit, state }, id = 0) {
			commit("setCategoryLoading", true);

			return $axios
				.get(`/shop/category/${id}`, {
					params: {
						townId: state.towns.activeTownId,
						SortKey: state.towns.sortKey // 0, 1, 2
					}
				})
				.then(response => {
					let temp = [...response.data];
					temp = temp.sort(function(a, b) {
						if (a.productWorkType < b.productWorkType) {
							return -1;
						}
						if (a.productWorkType > b.productWorkType) {
							return 1;
						}
						return 0;
					});
					commit("setCategoryData", temp);
					return response.data;
				})
				.catch(e => {
					commit("setCategoryError", e);
					throw e;
				})
				.finally(() => {
					commit("setCategoryLoading", false);
				});
		},

		getShopCategoryInfo(context, id = 0) {
			context.commit("setCategoryLoadingInfo", true);
			return $axios
				.get(`/shop/category/${id}/info`)
				.then(response => {
					context.commit("setCategoryDataInfo", response.data);
					return response.data;
				})
				.catch(e => {
					context.commit("setCategoryErrorInfo", e);
					throw e;
				})
				.finally(() => {
					context.commit("setCategoryLoadingInfo", false);
				});
		},

		getShopGrade(context, data) {
			context.commit("setShopGradeLoading", true);
			return $axios
				.post(`/shop/vote/${data.orderId}`, data)
				.then(response => {
					context.commit("setShopGradeData", response.data);
					return response.data;
				})
				.catch(e => {
					context.commit("setShopGradeError", e);
					throw e;
				})
				.finally(() => {
					context.commit("setShopGradeLoading", false);
				});
		},
		sendOrderComment({ commit }, data) {
			return $axios
				.post(`/shop/comment`, data)
				.then(response => {
					commit("setShopGradeData", response.data);
					return response.data;
				})
				.catch(e => {
					throw e;
				});
		},
		async fetchApartmentGroups({ commit }, apartmentId) {
			commit("setGroupListLoading", true);

			return await $axios
				.get(`shop/apartments/groups?apartmentId=${apartmentId}`)
				.then(response => {
					commit("setGroupListData", response.data);
					return response.data;
				})
				.catch(e => {
					commit("setGroupListError", e.response);
					throw e;
				})
				.finally(() => {
					commit("setGroupListLoading", false);
				});
		},
		async fetchApartmentCategores({ commit }, { id, apartmentId }) {
			commit("setCategoryListLoading", true);

			
			return await $axios
				.get(`shop/apartments/categories?groupId=${id}&apartmentId=${apartmentId}`)
				.then(response => {
					commit("setCategoryListData", response.data);
					return response.data;
				})
				.catch(e => {
					commit("setCategoryListError", e.response);
					throw e;
				})
				.finally(() => {
					commit("setCategoryListLoading", false);
				});
		},
		async fetchApartmentService({ commit, state }, { id, apartmentId }) {
			commit("setCategoryLoading", true);
			return $axios
				.get(
					`shop/apartments/products?categoryId=${id}&sortKey=${state.towns.sortKey}&apartmentId=${apartmentId}`
				)
				.then(response => {
					let temp = [...response.data];
					temp = temp.sort(function(a, b) {
						if (a.productWorkType < b.productWorkType) {
							return -1;
						}
						if (a.productWorkType > b.productWorkType) {
							return 1;
						}
						return 0;
					});
					commit("setCategoryData", temp);
					return response.data;
				})
				.catch(e => {
					commit("setCategoryError", e);
					throw e;
				})
				.finally(() => {
					commit("setCategoryLoading", false);
				});
		}
	}
};
