<template>
	<div class="insurance">
		<div class="insurance__list">
			<div class="insurance__header">
				<h3 class="medium secondary-color">История страховки</h3>
				<AppBtn
					v-if="getInsurance.canInsurance"
					class="insurance__header-btn"
					color="success"
					@click="toBuy"
				>
					Застраховать</AppBtn
				>
			</div>
			<div class="loading-wrapper" v-if="getInsuranceLoading">
				<AppLoading :height="100" />
			</div>
			<div>
				<div v-if="!getInsurance.canInsurance">{{ getInsurance.message }}</div>
				<InsuranceHistoryTable @click-card="checkActiveCard" />
			</div>
		</div>

		<InsuranceModal />
	</div>
</template>

<script>
import InsuranceModal from "./modals/modal-insurance-info.vue";
import InsuranceHistoryTable from "./components/insurance-history-table.vue";
import { mapGetters } from "vuex";

export default {
	components: {
		InsuranceModal,
		InsuranceHistoryTable
	},
	data() {
		return {};
	},
	methods: {
		checkActiveCard(id) {
			const apartmentId = this.$route.params.id;
			this.$store.dispatch("fetchInsuranceDetail", {
				apartmentId,
				insuranceId: id
			});
			this.$modal.show("insurance-info");
		},
		toBuy() {
			this.$router.push({
				name: "buy-insurance",
				params: {
					apartmentId: this.$route.params.id
				}
			});
		}
	},
	computed: {
		...mapGetters(["getInsurance", "getInsuranceLoading"])
	},
	mounted() {
		this.$store.dispatch("fetchInsurance", this.$route.params.id);
	}
};
</script>

<style scoped>
.insurance {
	display: flex;
	padding-top: 31px;
}

.insurance__list {
	width: 100%;
}

.insurance__item {
	margin-bottom: 20px;
}

.insurance__header {
	display: flex;
	justify-content: space-between;
	margin-bottom: 32px;
}

.insurance__header-btn {
	background: var(--color-success) !important;
}

.loading-wrapper {
	display: flex;
	justify-content: center;
	align-items: center;
	height: 300px;
}
</style>
