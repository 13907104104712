<template>
    <div v-show="show" class="block-notify" :class="color">{{ text }}</div>
</template>

<script>
export default {
	data () {
		return {
			show: false,
			text: 'Успешно',
			color: 'info'
		}
	},
	methods: {
		showSnackbar (text = 'Успешно', color = 'info') {
			this.text = text
			this.color = color
			this.show = true
			setTimeout(() => {
				this.show = false
			}, 3000)
		}
	}
}
</script>

<style>
	.error {
		background: var(--color-danger) !important;
	}
	.success {
		background: #72b038 !important;
	}
	.info {
		background-color: #333 !important;
	}

	.block-notify {
		min-width: 250px;
		margin-left: -125px;
		background-color: #333;
		color: #fff;
		text-align: center;
		border-radius: 2px;
		padding: 16px;
		position: fixed;
		z-index: 10;
		left: 50%;
		bottom: 30px;
		visibility: visible;
		-webkit-animation: fadein 0.5s, fadeout 0.5s 2.5s;
		animation: fadein 0.5s, fadeout 0.5s 2.5s;
	}

	.block-notify .show {
		visibility: visible;
		-webkit-animation: fadein 0.5s, fadeout 0.5s 2.5s;
		animation: fadein 0.5s, fadeout 0.5s 2.5s;
	}

	@-webkit-keyframes fadein {
		from {bottom: 0; opacity: 0;}
		to {bottom: 30px; opacity: 1;}
	}

	@keyframes fadein {
		from {bottom: 0; opacity: 0;}
		to {bottom: 30px; opacity: 1;}
	}

	@-webkit-keyframes fadeout {
		from {bottom: 30px; opacity: 1;}
		to {bottom: 0; opacity: 0;}
	}

	@keyframes fadeout {
		from {bottom: 30px; opacity: 1;}
		to {bottom: 0; opacity: 0;}
	}
</style>
