<template>
	<modal
		name="bank-approved"
		:width="500"
		:height="'auto'"
		:adaptive="true"
		@before-open="beforeOpen"
		:clickToClose="false"
		:scrollable="true"
	>
		<div>
			<div
				class="content-modal-loading d-flex column align-center just-center"
				v-if="loading"
			>
				<div class="success-mesage">Проверка статуса оплаты</div>
				<div class="error-mesage-info">
					<div style="height: 90px; padding: 20px 0">
						<AppLoading :width="40" :height="40" color="#404370" />
					</div>
				</div>
			</div>
			<template v-else>
				<div class="content-modal-approve" v-if="checkState.successed">
					<div class="modal-approve">
						<div class="modal-approve__text-container">
							<p class="modal-approve__subtitle">Оплата прошла успешно!</p>
							<h3 class="modal-approve__title">Вам доступен <span class="modal-approve__title_span">(1)</span> подарок</h3>
						</div>

						<img src="@/img/present.png" class="modal-approve__image" alt="Подарок" />

						<div class="modal-approve__buttons-container">
							<a
								class="modal-approve__first-button"
								href="https://квартплата.онлайн/gift?bid=5890"
								target="_blank"
							>
								Выбрать
							</a>
							<button
								class="modal-approve__second-button"
								@click="modalClose"
							>
								Отказаться
							</button>
						</div>
					</div>

					<div v-if="!params.noSavecard" class="content-modal-approve__save">
						<div class="content-modal-approve__save-head">
							<div>
								Сохранить карту
							</div>
							<label
								class="content-modal-approve__save-toggle"
								:class="isCheked ? 'active' : ''"
							>
								<input v-model="isCheked" type="checkbox" />
								<div></div>
							</label>
						</div>
						<div class="content-modal-approve__save-description">
							Функция «Запомнить карту» - это возможность оплачивать квитанции
							одной кнопкой, без ввода номера карты, CVC и кода из СМС.
						</div>
						<button
							class="content-modal-approve__save-button"
							@click="modalButtonHandler"
						>
							{{ isCheked ? "Сохранить" : "Закрыть" }}
						</button>
					</div>
				</div>

				<div class="content-modal-approve__error" v-else>
					<div
						class="error-mesage"
						style="
							display: flex;
							width: 100%;
							justify-content: center;
							font-size: 55px;
						"
					>
						<i class="fas fa-exclamation-triangle"></i>
					</div>
					<div class="content-modal-approve__error-mesage">
						При оплате произошла ошибка
					</div>
					<div class="content-modal-approve__error-info">
						{{ checkState.Message }}
					</div>
					<div class="ontent-modal-approve__error-button">
						<AppBtn @click="modalClose" large block>
							Закрыть
						</AppBtn>
					</div>
				</div>
			</template>
		</div>
	</modal>
</template>

<script>
export default {
	data() {
		return {
			isCheked: false,
			message: "",
			params: {
				orderId: "",
				noSavecard: true
			},
			url: process.env.VUE_APP_URL,
			loading: true,
			checkState: {
				orderId: "",
				successed: true,
				Message: ""
			}
		};
	},

	computed: {},
	methods: {
		modalButtonHandler() {
			if (this.isCheked) {
				this.saveCard();
				this.modalClose();
			} else {
				this.modalClose();
			}
		},
		modalClose() {
			this.$modal.hide("bank-approved");
		},

		async beforeOpen(event) {
			this.params.orderId = event.params.orderId;
			this.prepareCheckState(event.params.noSavecard);
			console.log("REFERRER", window.document.referrer);
		},

		async saveCard() {
			await this.$store.dispatch("addCard", this.params.orderId);
		},

		async prepareCheckState(noSavecardParam) {
			this.loading = true;
			try {
				let query = await this.$store.dispatch(
					"prepareCheckState",
					this.params.orderId
				);

				if (query.successed) {
					this.canSaveCard(noSavecardParam);
				} else {
					this.params.noSavecard = true
				}

				this.checkState = { ...query };
			} catch (e) {
				this.$errorsMod(e.response);
			}
			this.loading = false;
			history.pushState(null, null, "/account/");
			location.hash = "";
		},

		async canSaveCard(noSavecardParam) {
			let query = await this.$store.dispatch(
				"canSaveCard",
				this.params.orderId
			);

			if (query && !noSavecardParam) {
				this.params.noSavecard = false
			} else {
				this.params.noSavecard = true
			}
		}
	}
};
</script>

<style scoped>
.modal-approve {
	display: flex;
	flex-direction: column;
	align-items: center;
	background: linear-gradient(180deg, #E2F1EE 0, #E4F0F8 100%);
	padding: 28px 62px;
	box-shadow: 1px 1px 6px 0 #0000000F;
	border-radius: 20px;
}

.modal-approve__text-container {
	display: flex;
	flex-direction: column;
	gap: 9px;
	text-align: center;
}

.modal-approve__subtitle {
	font-size: 20px;
	font-weight: 600;
	line-height: 24.2px;
	color: #000000;
	margin: 0;
	padding: 0;
}

.modal-approve__title {
	font-size: 26px;
	font-weight: 600;
	line-height: 31.47px;
	color: #000000;
	margin: 0;
	padding: 0;
}

.modal-approve__title_span {
	color: #E6575D;
}

.modal-approve__image {
	width: 171px;
	height: 166px;
	margin: 31px 0 44px 0;
}

.modal-approve__buttons-container {
	display: flex;
	gap: 18px;
	width: 100%;
}

.modal-approve__first-button {
	background-color: #0B2A61;
	display: flex;
	width: 100%;
	justify-content: center;
	align-items: center;
	margin: 0;
	font-size: 16px;
	font-weight: 600;
	line-height: 19.36px;
	color: #FFFFFF;
	border-radius: 40px;
	text-decoration: unset;
	cursor: pointer;
	height: 41px;
}

.modal-approve__first-button:hover {
	opacity: 0.7;
}

.modal-approve__second-button {
	display: flex;
	width: 100%;
	justify-content: center;
	align-items: center;
	border: 1.5px solid #0B2A61;
	color: #0B2A61;
	background-color: inherit;
	border-radius: 40px;
	font-size: 16px;
	font-weight: 600;
	line-height: 19.36px;
	cursor: pointer;
	margin: 0;
	box-sizing: border-box;
	height: 41px;
}

.modal-approve__second-button:hover {
	opacity: 0.7;
}

@media screen and (max-width: 768px) {
	.modal-approve {
		padding: 20px 52px;
	}

	.modal-approve__buttons-container {
		flex-direction: column;
		gap: 10px;
	}

	.modal-approve__title {
		font-size: 22px;
		line-height: 26px;
	}

	.modal-approve__image {
		width: 129px;
		height: 125px;
		margin: 19px 0 24px 0;
	}

	.modal-approve__first-button {
		height: 32px;
		font-size: 16px;
		line-height: 20px;
	}

	.modal-approve__second-button {
		height: 32px;
		font-size: 16px;
		line-height: 20px;
	}
}
</style>
