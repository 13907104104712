<template>

    <div 
        class="sidebar"
        :class="{
            'sidebar__open': sidebarOpen
        }"
    >
        <LogOutModal />

        <div class="sidebar__logo">
            <img v-if="getTheme === 'dark-theme'" src="/img/dark-theme/dark-logo.svg" />
            <img v-else src="/img/logo.svg"/>
        </div>
        <MenuList />
        <!-- <div class="vm--container" v-if="sidebarOpen">
            <div class="vm--overlay"  @click.self="sideBarMut">
            </div>
        </div> -->
        <!-- <div class="wrapper-overlay" :class="{'wrapper-overlay__open' : sidebarOpen}"></div> -->
        <div class="sidebar__footer">
            <div class="sidebar__footer-links">

                <!-- <router-link 
                    class="navigation__item sidebar__footer--item-navigate" 
                    to="/support"
                    title="Тех. поддержка "
                >
                    <div class="navigation__icon" >
                        <AppIcon name="users-cog" size="17px" />
                    </div>
                    <div class="navigation__text"> Тех. поддержка </div>
                </router-link> -->

                <a style="margin-bottom: 0px" href="#" class="navigation__item sidebar__footer--item-navigate" title="Учетные данные" @click="$modal.show('output-application')">
                    <div class="navigation__icon">
                        <AppIconSvg 
                            name="exit"
                            width="1.5rem"
                            height="28px"
                        />
                    </div>
                    <div class="navigation__text"> Выйти </div>
                </a>
<!--                 <a href="#" class="navigation__sub-link" @click="$router.push({ name: 'other'})">
                    Вспомогательные ресурсы
                </a> -->

            </div>
        </div>
    </div>

</template>

<script>
import LogOutModal from './confirm-output'
import MenuList from './menu-list'
import { mapGetters, mapMutations } from 'vuex'

export default {
    data() {
        return {
           
        }
    },
    computed: {
        ...mapGetters([
            'sidebarOpen',
            'getTheme'
        ])
    },
    mounted () {
		this.$store.dispatch('getTotalDebt')

		let version = window.$cookies.get('version_site')
        const theme = this.$useTheme()
        this.theme = theme

		if(version === '1') {
			this.versionGlasses = true
		}
    },
    methods: {
        ...mapMutations([
            'sideBarMut'
        ])
    },
    components: {
        MenuList, LogOutModal
    },
}
</script>
