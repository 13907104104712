import $axios from '@/plugins/axios-instance'

export default {
	state: {
		insurance: {
			canInsurance: false,
			message: '',
			history: []
		},
		insuranceLoading: false,

		insuranceDetail: null,
		detailLoading: false,

		insurancePrepare: [],
		insurancePrepareLoading: false,

		apartmentIds: [],

		insurancePayments: [],
		// сохранение списка платежей перед тем как перейти на страницу страховок
		paymentsFromPaymentPage: [],

		fileLoading: false,
	},
	mutations: {
		setInsurance(state, payload) {
			state.insurance = payload
		},
		setInsuranceLoading(state, payload) {
			state.insuranceLoading = payload
		},


		setInsuranceDetail(state, payload) {
			state.insuranceDetail = payload
		},
		clearInsuranceDetail(state) {
			state.insuranceDetail = null
		},
		setDetailLoading(state, payload) {
			state.detailLoading = payload
		},

		setInsurancePrepare(state, payload) {
			state.insurancePrepare = payload
		},
		setInsurancePrepareLoading(state, payload) {
			state.insurancePrepareLoading = payload
		},
		setInsurancePrepareItemCount(state, { id, count }) {
			const index = state.insurancePrepare.findIndex(item => item.apartmentId === id)
			if (index >= 0) {
				state.insurancePrepare[index].monthCount = count
			}
		},
		setApartmentIds(state, apartmentIds) {
			state.apartmentIds = apartmentIds
		},

		setInsurancePayments(state, payload) {
			state.insurancePayments = payload
		},
		// сохранение списка платежей перед тем как перейти на страницу страховок
		setPaymentsFromPaymentPage(state, payload) {
			state.paymentsFromPaymentPage = payload
		},
		setFileLoading(state, payload) {
			state.fileLoading = payload
		}
	},

	getters: {
		getInsurance(state) {
			return state.insurance
		},
		getInsuranceLoading(state) {
			return state.insuranceLoading
		},

		getInsuranceDetail(state) {
			return state.insuranceDetail
		},
		getDetailLoading(state) {
			return state.detailLoading
		},

		getInsurancePrepare(state) {
			return state.insurancePrepare
		},
		getInsurancePrepareLoading(state) {
			return state.insurancePrepareLoading
		},
		getTotalPrice(state) {
			return state.insurancePrepare
				.filter(item => item.canInsurance)
				.reduce((ac, item) => {
					return ac + item.program.price * item.monthCount
				}, 0)
		},

		getApartmentIds(state) {
			return state.apartmentIds
		},

		getInsurancePayments(state) {
			return state.insurancePayments
		},
		// сохранение списка платежей перед тем как перейти на страницу страховок
		getPaymentsFromPaymentPage(state) {
			return state.paymentsFromPaymentPage
		},

		getFileLoading(state, payload) {
			return state.fileLoading
		}
	},
	actions: {
		fetchInsurance({ commit }, id) {
			commit('setInsuranceLoading', true)
			return $axios.get(`/Insurance/${id}`)
				.then((response) => {
					commit('setInsurance', response.data)
				}).catch((e) => {
					throw (e)
				}).finally(() => {
					commit('setInsuranceLoading', false)
				})
		},
		fetchInsuranceDetail({ commit }, { apartmentId, insuranceId }) {

			commit('setDetailLoading', true)
			return $axios.get(`/Insurance/${apartmentId}/${insuranceId}`)
				.then((response) => {
					commit('setInsuranceDetail', response.data)
				}).catch((e) => {
					throw (e)
				}).finally(() => {
					commit('setDetailLoading', false)
				})
		},
		fetchInsurancePrepare({ commit }, payload) {
			commit('setInsurancePrepareLoading', true)
			return $axios.post(`/Insurance/Prepare`, payload)
				.then((response) => {
					const dataWithCounts = response.data
						// .filter(item=>item.canInsurance)
						.map(item => ({ ...item, monthCount: 1 }))
					commit('setInsurancePrepare', dataWithCounts)
				}).catch((e) => {
					throw (e)
				}).finally(() => {
					commit('setInsurancePrepareLoading', false)
				})
		},
		async sendInsuranceCreate({ state, commit }) {
			function sendInsuransePayment(item) {
				return $axios.post(`/Insurance/${item.apartmentId}`, { programId: item.program.id, monthCount: item.monthCount })
					.then((response) => {
						return response.data
					}).catch((e) => {
						throw (e)
					})
			}

			const requests = state.insurancePrepare.map(item => sendInsuransePayment(item))
			return await Promise.all(requests)
				.then(responces => commit('setInsurancePayments', responces.map(item => item.polices).reduce((ac, item) => [...ac, ...item], [])))

		},
		getPolicy({ commit }, payload) {
			commit('setFileLoading', true)
			return $axios.get(`/insurance/${payload.apartmentId}/${payload.insuranceId}/policy`, {
				responseType: 'blob'
			})
				.then((response) => {
					console.log('1');
					const blob = new Blob(
						[response.data],
						{ type: 'application/pdf' });
					console.log(blob);
					const link = document.createElement('a');
					link.download = 'policy.pdf';
					link.href = URL.createObjectURL(blob);
					link.click();
					URL.revokeObjectURL(link.href);
				}).catch((e) => {
					throw (e)
				}).finally(() => commit('setFileLoading', false))
		},
	}
}
