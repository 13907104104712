<template>
	<div>
		<template v-if="data.length === 0">
			<div class="app-table__empty">
				<div class="app-table__empty-text">{{ emptyText }}</div>
				<div class="mt-2">
					<img v-if="getTheme === 'dark-theme'" src="/img/dark-theme/not-lists-dark.svg" width="255px" />
					<img v-else src="/img/no-content.svg" width="255px" />

				</div>
			</div>
		</template>

		<table class="app-table" :class="{
			'app-table--rounded': rounded,
			'app-table--masterRow': masterRow,
			'app-table--hover-hide': hoverHide,
			'app-table--small': small,
			'app-table--only': only,
		}" v-else>
			<!-- ОБЫЧНАЯ ТАБЛИЦА -->
			<thead class="app-table__thead" v-if="!masterRow">
				<tr class="app-table__tr-head">
					<template v-for="(item, index) in header">
						<th :key="index" class="app-table__th" :width="item.width">
							<slot name="thead" :item="item"> </slot>
						</th>
					</template>
				</tr>
			</thead>

			<!-- ТАБЛИЦА С РАСКРЫВАЮЩЕМСЯ БЛОКОМ -->
			<thead class="app-table__thead" v-else>
				<tr class="app-table__tr-head">
					<th width="2%"></th>
					<template v-for="(item, index) in header">
						<th :key="index" class="app-table__th" :width="item.width">
							<slot name="thead" :item="item"> </slot>
						</th>
					</template>
				</tr>
			</thead>

			<tbody class="app-table__tbody">
				<!-- ОБЫЧНАЯ ТАБЛИЦА -->
				<template v-if="!masterRow">
					<template v-for="(item, index) in data">
						<tr class="animate__animated animate__fadeIn app-table__tr-body" :class="[
							{
								'app-table__tr-body--rounded': rounded,
							},
							'animate__delay-' + 6 * index,
						]" :key="index" @click="openInfo(item)">
							<slot name="tbody" :item="item" :index="index"> </slot>
						</tr>
					</template>
				</template>

				<!-- ТАБЛИЦА С РАСКРЫВАЮЩЕМСЯ БЛОКОМ -->
				<template v-else>
					<template v-for="(item, index) in [...masterData]">
						<tr class="
								animate__animated animate__fadeIn
								app-table__tr-body app-table__tr-body--rounded
							" :class="[
								{
									'app-table__opened': item.showInfo,
								},
								'animate__delay-' + 6 * index,
							]" :key="index" @click="openInfo(item)"
							:style="item.showInfo ? 'border-radius: 24px 24px 0 0 !important' : null">
							<td @click="openMaster(index)" class="app-table__col-open">
								<span class="app-table__col-open-info" style="z-index: 1">
									<i class="fas fa-chevron-down" v-if="item.showInfo"></i>
									<i class="fas fa-chevron-right" v-else></i>
								</span>
							</td>
							<slot :name="rowTemplate" :item="item" :index="index"> </slot>
						</tr>
						<tr class="app-table__tr-body--master" v-show="item.showInfo" :key="index + 1000">
							<slot name="masterRow" :item="item" :index="index"> </slot>
						</tr>
					</template>
				</template>
			</tbody>
		</table>
		<div ref="observerTrigger"></div>
	</div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
	data() {
		return {
			mounted: false,
			masterData: [],
			masterHeader: [],
			observer: null
		};
	},
	props: {
		header: {
			type: Array,
			default: [],
		},
		data: {
			type: [Array, Object],
			default: () => [],
		},
		messageNoData: {
			type: String,
			default: "Данные отсутствуют",
		},
		rowTemplate: {
			type: String,
			default: "",
		},
		clickRow: {
			type: Boolean,
			default: false,
		},
		masterRow: {
			type: Boolean,
			default: false,
		},
		rounded: {
			type: Boolean,
			default: false,
		},
		hoverHide: {
			type: Boolean,
			default: false,
		},
		small: {
			type: Boolean,
			default: false,
		},
		emptyImage: {
			type: String,
			default: "/img/no-content.svg",
		},
		emptyDarkImage: {
			type: String,
			default: "/img/dark-theme/not-lists-dark.svg",
		},
		emptyText: {
			type: String,
			default: "Список пуст",
		},
		isObserve: {
			type: Boolean,
			default: false
		}
	},
	mounted() {
		this.renderMaster();
		if (this.isObserve) {
			this.createObserver()
		}
	},
	updated() { },
	computed: {
		//стилизация таблицы при свойстве  small требуется когда в таблице всего 1 строка
		only() {
			if (Array.isArray(this.data)) {
				return this.data.length === 1;
			} else if (typeof this.data === "object") {
				return !this.data.hasOwnProperty("1");
			} else {
				return false;
			}
		},

		...mapGetters(["getPaymentList", "getTheme"]),
	},
	methods: {
		createObserver() {
			const callback = (e) => {
				if (e[0].isIntersecting) {
					this.$emit('intersect')
				}
			}
			const options = {
				rootMargin: "400px",
				threshold: 1.0,
			}
			this.observer = new IntersectionObserver(callback, options)
			this.observer.observe(this.$refs.observerTrigger)
		},
		unobserveIntersecting() {
			this.observer.unobserve(this.$refs.observerTrigger)
		},
		openInfo(item) {
			this.$emit("rowClick", item);
		},
		openChildContent(index) { },
		renderMaster() {
			const keys = Object.keys(this.$scopedSlots);
			if (this.masterRow) {
				if (Array.isArray(this.data)) {
					if (this.data.length) {
						this.masterData = this.data.map((item) => ({
							...item,
							showInfo: false,
						}));
					}
				} else {
					let tempData = [];
					for (let key in this.data) {
						tempData.push(this.data[key]);
					}

					for (let i = 0; i < tempData.length; i++) {
						tempData[i].showInfo = false;
					}

					this.masterData = tempData;
				}

				if (this.header.length) this.masterHeader = { ...this.header };
			}
		},
		openMaster(index) {
			this.masterData[index].showInfo = !this.masterData[index].showInfo;
		},
	},
	watch: {
		data(val) {
			try {
				this.renderMaster();
			} catch (e) {
				console.log("e", e);
			}
		},
	},
};
</script>
