<template>
	<div class="balance">
		<div class="balance__info">
			<app-shop-select-city v-if="showSelectCity" />
			<div
				:class="[getTheme === 'dark-theme' ? dark : '', ligth]"
				@click="toggleTheme"
				v-if="!versionGlasses"
			>
				<img v-if="getTheme !== 'dark-theme'" src="/img/sun.svg" />
				<img v-else src="/img/moon.svg" />
			</div>
			<AppBtn
				icon
				text
				class="mr-2"
				@click="versionPage"
				alt="Версия для слабовидящих"
				title="Версия для слабовидящих"
			>
				<AppIcon name="eye" prefix="far fa-" size="1.375rem" />
			</AppBtn>

			<div
				v-if="getRouteSuplier >= 0"
				class="cart__header"
				@click="$router.push({ name: 'cart' })"
			>
				<AppBtn class="mr-1" icon text alt="Корзина" title="Корзина">
					<AppIcon name="shopping-cart" size="1.25rem" />
				</AppBtn>
				<div
					class="cart__count"
					:class="{ 'animate__animated animate__heartBeat': activeAnim }"
					v-if="getTotalCartCount !== 0"
				>
					{{ getTotalCartCount }}
				</div>
			</div>

			<p
				v-else
				style="cursor: pointer"
				class="mr-2"
				@click="
					$router.push({ name: 'paymentPage', params: { allPayment: true } })
				"
			>
				<AppBtn
					class="mr-1"
					icon
					text
					alt="Оплата всех долгов"
					title="Оплата всех долгов"
				>
					<AppIcon name="wallet" size="1.25rem" />
				</AppBtn>
				<span
					class="block-debt"
					:class="
						getTotalDebt <= 0 ? 'block-debt--success' : 'block-debt--danger'
					"
					>{{ numberWithCommas }} ₽</span
				>
			</p>
		</div>

		<div class="balance__bell">
			<div class="balance__notice" @click="toAlertPage">
				<AppBtn icon text alt="Уведомления" title="Уведомления">
					<AppIcon name="bell" prefix="far fa-" size="1.5rem" />
				</AppBtn>

				<div class="balance__notice-count" v-if="getNewMessagesCount > 0">
					{{ getNewMessagesCount }}
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { mapGetters } from "vuex";
import { dateReturnFormat } from "@/plugins/methods";
import AppShopSelectCity from "@/components/components-other/app-shop-select-city";
import { mixin as clickaway } from "vue-clickaway";

export default {
	mixins: [clickaway],
	data() {
		return {
			color: ["primary-color", "warning-color", "danger-color"],
			versionGlasses: false,
			activeAnim: false,
			dark: "toggle-icon-wrapper-dark",
			ligth: "toggle-icon-wrapper"
		};
	},
	mounted() {
		this.loadNotify();

		let version = window.$cookies.get("version_site");
		const theme = this.$useTheme();

		if (version === "1") {
			this.versionGlasses = true;
			document.querySelector("html").setAttribute("data-theme", "visually");
		}

		this.setTheme(theme);
	},
	watch: {
		getTotalCartCount: {
			handler() {
				this.activeAnim = true;
				setTimeout(() => {
					this.activeAnim = false;
				}, 400);
			},
			deep: true
		}
	},
	methods: {
		toAlertPage() {
			this.$router.push({ name: "alerts" }).catch(err => {});
		},
		away() {
			setTimeout(() => {
				this.activeNotify = false;
			}, 60);
		},
		dateReturnFormat(date) {
			return dateReturnFormat(date, true);
		},
		show(modalName) {
			this.$modal.show(modalName);
		},
		async loadNotify() {
			try {
				await this.$store.dispatch("fetchGroups");
			} catch (e) {
				this.$errorsMod(e.response);
			}
		},

		versionPage() {
			if (this.versionGlasses == false) {
				this.versionGlasses = true;
				window.$cookies.set(
					"version_site",
					1,
					null,
					null,
					process.env.VUE_APP_COOCKIE
				);
				const theme = this.$useTheme();

				if (theme === "dark-theme") {
					this.$store.dispatch("toggleTheme", "default");
					localStorage.setItem("user-theme", "default");
				}
				document.querySelector("html").setAttribute("data-theme", "visually");
			} else {
				this.versionGlasses = false;
				window.$cookies.remove(
					"version_site",
					"/",
					process.env.VUE_APP_COOCKIE
				);
				document.querySelector("html").setAttribute("data-theme", "default");
			}
		},
		toggleTheme() {
			const activeTheme = localStorage.getItem("user-theme");
			if (activeTheme === "default") {
				this.setTheme("dark-theme");
				this.$store.dispatch("toggleTheme", "dark-theme");
			} else {
				this.setTheme("default");
				this.$store.dispatch("toggleTheme", "default");
			}
		},

		setTheme(theme) {
			localStorage.setItem("user-theme", theme);
			document.querySelector("html").setAttribute("data-theme", theme);
		}
	},
	computed: {
		...mapGetters([
			"getTotalDebt",
			"getAccountDetails",
			"getUserRequestDetailsList",
			"getNewMessagesCount",
			"getTotalCartCount",
			"getTheme",
			"getTowns",
			"getActiveTown"
		]),
		numberWithCommas() {
			return this.$digitNumber(this.getTotalDebt);
		},
		getRouteSuplier() {
			return this.$route.path.indexOf("shop");
		},
		showSelectCity() {
			return (
				this.$route.path.includes("shop") && !this.$route.query.apartmentId
			);
		}
	},
	components: {
		AppShopSelectCity
	}
};
</script>

<style scoped>
.toggle-icon-wrapper {
	position: relative;
	width: 35px;
	height: 35px;
	cursor: pointer;
	margin-right: 10px;
}

.toggle-icon-wrapper-dark {
	position: relative;
	width: 35px;
	height: 35px;
	cursor: pointer;
	margin-right: 10px;
}

.toggle-icon-wrapper img {
	position: absolute;
	margin: auto;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
}

.toggle-icon-wrapper:hover {
	background: #d4d4d4;
	border: 1px solid #d4d4d4;
	border-radius: 50%;
}

.toggle-icon-wrapper-dark:hover {
	background: #2f2f2f;
	border: 1px solid #2f2f2f;
	border-radius: 50%;
}

.toggle-wrapper {
	position: relative;
}

.slide-fade-enter-active {
	transition: all 0.03s ease;
}

.slide-fade-leave-active {
	transition: all 0.2s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-fade-enter,
.slide-fade-leave-to {
	transform: translateX(200px);
	opacity: 0;
}
</style>
