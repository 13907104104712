<template>
	<AppGlobalLayout :error="getCategory.error">
		<div slot="body">
			<div>
				<AppCard class="new-shop">
					<template #body>
						<div class="new-shop__content">
							<div>
								<div
									class="new-shop__image"
									:style="
										`background: url(https://квартплата.онлайн/files/static/${getCategoryInfo.data.image});`
									"
								/>
							</div>
							<div class="new-shop__text">
								<div class="new-shop__title">
									{{ getCategoryInfo.data.name }}
								</div>
								<div class="new-shop__desc">
									{{ getCategoryInfo.data.description }}
								</div>
							</div>
						</div>
					</template>
				</AppCard>
				<AppTabs :data="getTabs" :activeTab="activeTab" class="mt-2">
					<template #header>
						<div class="sorting-controller">
							<span>
								<AppSelect
									:labelVisible="false"
									style="width: 220px;"
									valueSelect="id"
									textSelect="name"
									:error="false"
									@selected="setSortKeyValue"
									:value="sortKeyValue"
									:options="sortKeyOptions"
								/>
							</span>
						</div>
					</template>

					<template v-slot:content="{ active }">
						<ProductList
							:data="getCategory.data[active].products"
							:loadInfo="loadInfo"
						/>
					</template>
				</AppTabs>
			</div>
		</div>
	</AppGlobalLayout>
</template>

<script>
import StarRating from "vue-star-rating";
import MontageTable from "./component/montage-table";
import { mapGetters, mapMutations } from "vuex";

import BasketCalc from "./component/basket-calc.vue";
import ProductList from "./component/product-list.vue";

export default {
	components: {
		MontageTable,
		StarRating,
		BasketCalc,
		ProductList
	},
	data() {
		return {
			activeTab: 0,
			headerTable: [
				{
					name: "Название",
					width: "35%"
				},
				{
					name: "Компания",
					width: "10%"
				},
				{
					name: "",
					width: "25%"
				},
				{
					name: "Цена",
					width: "10%"
				},
				{
					name: "",
					width: "20%"
				},
				{
					name: "",
					width: "20%"
				}
			],
			sortKeyOptions: [
				{ id: 0, name: "Сначала дешёвые" },
				{ id: 1, name: "Сначала дорогие" },
				{ id: 2, name: "С высоким рейтингом" }
			]
		};
	},
	computed: {
		...mapGetters([
			"getCategory",
			"getCategoryInfo",
			"getActiveTown",
			"getSortKey",
			"getActiveTownId"
		]),
		getTabs() {
			return this.getCategory.data.map(item => ({
				name: item.productWorkType
			}));
		},
		sortKeyValue() {
			return this.sortKeyOptions.find(item => item.id === this.getSortKey);
		},

		apartmentId() {
			return this.$route.query.apartmentId;
		}
	},

	methods: {
		...mapMutations(["setSortKey"]),
		setProductCount(id, count) {
			this.$store.commit("setProductCount", { id, count });
		},

		setSortKeyValue({ id }) {
			this.setSortKey(id);
			this.loadInfo();
		},
		loadInfo() {
			try {
				if (this.apartmentId) {
					this.$store.dispatch("fetchApartmentService", {
						id: this.$route.params.id,
						apartmentId: this.apartmentId
					});
				} else {
					this.$store.dispatch("getShopCategory", this.$route.params.id);
				}
			} catch (error) {
				console.log(error);
			}
		}
	},
	mounted() {
		this.loadInfo();
		this.$store.dispatch("getShopCategoryInfo", this.$route.params.id);
	}
};
</script>

<style lang="sass"></style>
<style scoped>
.sorting-controller {
	display: flex;
	gap: 1em;
	align-items: center;
}
</style>
