<template>
    <div>
        <AppCard>
            <template #body>
                <div class="mb-1">
                   <AppLink
                        href="https://www.freepik.com/"
                        class="secondary-3-color"
                    >
                        https://www.freepik.com/
                    </AppLink>
                </div>
                <div>
                   <AppLink
                        href="https://tilda.cc/ru/free-icons/"
                        class="secondary-3-color"
                    >
                        https://tilda.cc/ru/free-icons/
                    </AppLink>
                </div>
            </template>
        </AppCard>
    </div>
</template>

<script>
export default {
    data() {
        return {

        }
    }
}
</script>