export const AnimateCard = (el, binding, vnode) => {

	let card = el;

	card.addEventListener('mousemove', function(e) {
		const halfHeight = card.offsetHeight / 2;
		const halfWidth = card.offsetWidth / 2;
		let xAxis = (-e.offsetY - halfHeight) / 30;
		let yAxis = (e.offsetX - halfWidth) / 30;
		card.style.transform = `rotateX(${xAxis}deg) rotateY(${yAxis}deg)`;
	})

	card.addEventListener('mouseout', function() {
		card.style.transform = `rotateX(${0}deg) rotateY(${0}deg)`;
	})
}