export default function(id) {
	switch (id) {
		case 0:
			return '/img/counter/1.svg'
		case 1:
			return '/img/counter/0.svg'
		case 2:
			return '/img/counter/2.svg'
		case 3:
			return '/img/counter/3.svg'
		case 4:
			return '/img/counter/4.svg'
		case 5:
			return '/img/counter/5.svg'
		default:
			return '/img/counter/5.svg'
	}
}