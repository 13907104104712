<template>
	<div class="app-select">
		<label v-if="labelVisible" class="app-select__label">{{ label }}</label>

		<v-select
			:options="options"
			:label="valueSelect"
			:placeholder="placeholder"
			:get-option-label="
				option => (textSelect ? option[textSelect] : option)
			"
			@input="changeInput($event)"
			:disabled="disabled"
			:searchable="searchable"
			:closeOnSelect="true"
			class="app-select__component"
			:class="{
				'app-select__component--error-sel': error,
				'app-select__component--white': white
			}"
			@option:selecting="selected($event)"
			:value="value"
		>
			<template slot="option" slot-scope="option">
				<slot name="item" :item="option">
					<div>{{ textSelect ? option[textSelect] : option }}</div>
					<div v-if="option[subTitle]" class="app-select__sub-title">
						{{ subTitle ? option[subTitle] : option }}
					</div>
				</slot>
			</template>
			<span slot="no-options">
				Список пуст
			</span>
			<template #selected-option="option">
				<slot name="selected" :item="option">
					<div>
						{{ textSelect ? option[textSelect] : option }}
					</div>
				</slot>
			</template>
		</v-select>
		<span class="app-select__error shake" v-if="error && errorAnimate">{{
			this.error
		}}</span>
	</div>
</template>

<script>
export default {
	data() {
		return {
			errorAnimate: true
		};
	},
	props: {
		labelVisible: {
			type: Boolean,
			default: true
		},
		placeholder: {
			type: String,
			default: ""
		},
		label: {
			type: String,
			default: ""
		},
		valueSelect: {
			type: String,
			default: "value"
		},
		subTitle: {
			type: String,
			default: ""
		},
		options: {
			type: Array,
			default: []
		},
		error: {
			default: "Некорректное значение"
		},
		white: {
			type: Boolean,
			default: false
		},
		searchable: {
			type: Boolean,
			default: false
		},
		disabled: {
			type: Boolean,
			default: false
		},
		textSelect: {
			type: String,
			default: ""
		},
		value: {
			type: Object,
			default: () => {}
		}
	},
	mounted() {
		//console.log("this.$slots", this.$slots);
	},
	methods: {
		changeInput(val) {
			this.$emit("input", val);
		},
		selected(val) {
			this.$emit("selected", val);
		}
	},
	watch: {
		error(val) {
			if (val) {
				setTimeout(() => {
					this.errorAnimate = !this.errorAnimate;
				}, 1);
				setTimeout(() => {
					this.errorAnimate = !this.errorAnimate;
				}, 2);
			}
		}
	}
};
</script>

<style lang="sass">
@import "./select"
</style>
