<template>
    <div class="step__header">
        <div class="step__title">{{ title }}</div>
        <div class="step__sub-title" v-if="subTitle">{{ subTitle }}</div>
    </div>
</template>


<script>
export default {
    data() {
        return {
            
        }
    },
    props: {
        title: {
            type: String,
            default: null
        },
        subTitle: {
            type: String,
            default: null
        },
    }
}
</script>