<template>
	<div
		class="dropdown"
		:class="{
			'is-open': isOpen
		}"
	>
		<div class="dropdown__header">
			<slot name="label"></slot>
			<button class="dropdown__btn" @click="isOpen = !isOpen">
				<div class="dropdown__btn-icon">
					<svg
						xmlns="http://www.w3.org/2000/svg"
						width="12"
						height="7.504"
						viewBox="0 0 12 7.504"
					>
						<path
							id="Path_22650"
							data-name="Path 22650"
							d="M12,.751A.75.75,0,0,0,10.686.257L8.85,2.356,6,5.614,1.315.257a.75.75,0,1,0-1.129.988l5.249,6a.75.75,0,0,0,1.129,0l5.251-6A.747.747,0,0,0,12,.751Z"
							fill="#777776"
							opacity="0.9"
						/>
					</svg>
				</div>
			</button>
		</div>
		<div class="dropdown__body">
			<slot name="body"></slot>
		</div>
	</div>
</template>

<script>
export default {
	data() {
		return {
			isOpen: false,
			show: true
		};
	}
};
</script>

<style scoped>
.dropdown {
	width: 100%;
	border-bottom: 2px dotted #a7afc7;
}
.dropdown__header {
	display: flex;
	justify-content: space-between;
}
.dropdown__body {
	max-height: 0;
	overflow: hidden;
	transition: 0.5s;
}
.dropdown__btn {
	width: 20px;
	height: 20px;
	border: none;
	background: none;
	cursor: pointer;
}

.dropdown__btn-icon {
	transform-origin: center;
	transition: 0.3s;
}
.dropdown.is-open .dropdown__btn-icon {
	transform: rotate(180deg);
}

.dropdown.is-open .dropdown__body {
	max-height: 500px;
}
</style>
