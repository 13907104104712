<template>
	<div class="message-sender-wrapper">
		<AppTextarea
			v-model="$v.content.$model"
			:error="$v.content.$error"
			:requiredError="$v.content.customRule"
			:minLength="$v.content.maxLength"
			rows="3"
		>
			<div slot="required">
				Поле обязательно для заполнения
			</div>

			<div slot="minLength">
				Максимальное количество символов
				{{ $v.content.$params.maxLength.max }}
			</div>
		</AppTextarea>
		<div class="message-sender-controls">
			<FilesAdd class="file-uploader" v-model="files" :sumSize="sumSize" />
			<AppBtn
				@click="submit"
				:loading="loading"
				:disabled="$v.$invalid || sumSize >= 6"
				large
			>
				Отправить
			</AppBtn>
		</div>
	</div>
</template>

<script>
import FilesAdd from "./files-add.vue";
import { maxLength } from "vuelidate/lib/validators";

const customRule = (val, ctx) => {
	if (ctx.files && ctx.files.length) {
		return true;
	} else {
		return !!val;
	}
};

export default {
	components: { FilesAdd },
	data() {
		return {
			content: null,
			files: [],
			session: null,
			loading: false
		};
	},
	computed: {
		sumSize() {
			return this.files.reduce((total, item) => {
				return total + item.size / (1024 * 1024);
			}, 0);
		}
	},
	methods: {
		async submit() {
			this.loading = true;
			const formData = new FormData();
			if (this.content) {
				formData.append("content", this.content);
			}

			formData.append("session", this.session);
			this.files.forEach(file => {
				formData.append("files", file);
			});
			this.clearForm();

			try {
				await this.$store.dispatch("sendSupportMessages", formData);
			} catch (error) {
				this.$errorsMod(error);
			} finally {
				this.loading = false;
				this.$emit("refresh");
				this.$store.dispatch("fetchGroups");
			}
		},
		clearForm() {
			this.$v.$reset();
			this.content = null;
			this.files = [];
		}
	},
	validations: {
		content: {
			customRule,
			maxLength: maxLength(500)
		}
	},
	beforeMount() {
		ym(54842275, "getClientID", clientID => {
			this.session = clientID;
		});
	}
};
</script>
<style scoped>
.message-sender-wrapper {
	padding: 1em 1em 0;
}
.message-sender-controls {
	margin-top: 1.5em;
	display: flex;
	flex-wrap: wrap;
	gap: 1em;
	align-items: flex-start;
}
.file-uploader {
	flex: 1;
}
</style>
