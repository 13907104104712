<template>
    <AppModals
        name="account-remove"
        title="Удаление"
		@beforeOpen="beforeOpen"
        @confirm="removeAccount"
		colorConfirm="danger"
		confirmButtonText="Удалить"
		:loadingConfirm="getAccountDelete.loading"
    >
        <template  v-slot:content="{}">
            <div>
                <div style="text-align: left; color: #777776;" class="mt-3 mb-3">Вы действительно хотите удалить ЛС {{accountName }}?</div>
            </div>
        </template>
    </AppModals>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
	data () {
		return {

			accountId: null,
			accountName: null
		}
	},
	props: {

	},
	computed: {
		...mapGetters([
			'getAccountDelete'
		])
	},
	methods: {
		async removeAccount () {
			try {
				await this.$store.dispatch('accountDelete', {
					accountId: this.accountId,
				})
				this.$modal.hide('account-remove')
			} catch (e) {
				this.$errorsMod(e.response)
			}
		},
		async beforeOpen (event) {
            this.accountName = event.accountName
            this.accountId = event.accountId
		}
	}
}
</script>