var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('AppTable',{ref:"tableCounterArch",attrs:{"header":_vm.header,"data":_vm.getCounterArch,"messageNoData":"Счетчики отсутствуют","rowTemplate":"dataRowTemplate","rounded":"","masterRow":""},scopedSlots:_vm._u([{key:"thead",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.name))])]}},{key:"dataRowTemplate",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [_c('td',{on:{"click":function($event){return _vm.openInfoCounter(index)}}},[_c('span',{staticClass:"app-table__title-mob mr-10"},[_vm._v(" Счетчик: ")]),_c('span',[_vm._v(" "+_vm._s(item.serviceName)+" "+_vm._s(item.counterNumber ? ("№ " + (item.counterNumber)) : '')+" ")]),(_vm.getVerificationNextDateStyle(item.verificationDate, item.verificationInterval) == 'danger-color')?_c('span',{staticClass:"counter-table__col-counter-info"},[_c('i',{staticClass:"fas fa-exclamation-circle danger-color"})]):_vm._e()]),_c('td',[_c('span',{staticClass:"app-table__title-mob"},[_vm._v(" Предыдущее значение: ")]),_vm._v(" "+_vm._s(item.value + ' ' + item.unitName)+" ")]),_c('td',[_c('span',{staticClass:"app-table__title-mob"},[_vm._v(" Дата закрытия: ")]),_c('span',{staticClass:"counter-table__col-text mr-2"},[_vm._v(_vm._s(_vm.dateReturnFormat(item.closingDate)))])]),_c('td',[_c('AppBtn',{attrs:{"outlined":""},nativeOn:{"click":function($event){return _vm.openHistory(item.counterID)}}},[_vm._v(" История ")])],1)]}},{key:"masterRow",fn:function(ref){
var item = ref.item;
return [_c('td',{attrs:{"colspan":"6"}},[_c('div',{staticClass:"counter-table__info"},[_c('div',{staticClass:"counter-table__info-block"},[_c('div',{staticClass:"counter-table__info-item"},[_c('span',[_vm._v("Счетчик:")]),_vm._v(" "+_vm._s(item.serviceName || '-')+" ")]),_c('div',{staticClass:"counter-table__info-item"},[_c('span',[_vm._v("Серийный номер:")]),_vm._v(" "+_vm._s(item.serialNumber || '-')+" ")]),_c('div',{staticClass:"counter-table__info-item"},[_c('span',[_vm._v("Производитель:")]),_vm._v(" "+_vm._s(item.mark || '-')+" ")]),_c('div',{staticClass:"counter-table__info-item"},[_c('span',[_vm._v("Модель:")]),_vm._v(" "+_vm._s(item.model || '-')+" ")])]),_c('div',{staticClass:"counter-table__info-block counter-table__info-block--border"},[_c('div',{staticClass:"counter-table__info-item"},[_c('span',[_vm._v("Дата поверки:")]),_vm._v(" "+_vm._s(_vm.dateReturnFormat(item.verificationDate) || '-')+" ")]),_c('div',{staticClass:"counter-table__info-item"},[_c('span',[_vm._v("Интервал поверки:")]),_vm._v(" "+_vm._s(item.verificationInterval ? item.verificationInterval + ' лет' : '-')+" ")]),_c('div',{class:'counter-table__info-item ' + _vm.getVerificationNextDateStyle(item.verificationDate, item.verificationInterval)},[_c('span',[_vm._v("Следующая дата поверки:")]),_vm._v(" "+_vm._s(_vm.getVerificationNextDate(item.verificationDate, item.verificationInterval) || '')+" ")])])])])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }