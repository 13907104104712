<template>
    <div>

        <div v-if="video">
            <QRCodeVideo 
                @nextStepVideo="nextStepVideo"
                @manualStep="manualStep"
                @errorAccess="errorAccess"
            />
        </div>

        <div class="d-flex align-center d-column mt-5" v-else>
            <img src="/img/qr-code.svg" width="250px" />

            <div class="mb-4 mt-3 text-center secondary-color medium"> 
                Отсканируйте QR-code в квитанции для добавления ЛС. 
            </div>

            <div class="account-add__text account-add__text--error mt-2" v-if="error">
                Для сканирования QR-кода требуется доступ к камере. Обновите страницу и попробуйте еще раз.
            </div>

            <div @click="manualStep" class="mb-4 secondary-3-color" style="text-decoration: underline">
                Ввести данные вручную
            </div>

            <AppBtn block @click.native="nextStepVideo(true)">
                Продолжить
            </AppBtn>
        </div>

    </div>
</template>

<script>
import QRCodeVideo from './mobile-video-scan'

export default {
    data() {
        return {
            video: false,
            error: false
        }
    },
    methods: {
        manualStep() {
            this.$emit('manualStep')
        },

        nextStepVideo(video) {
            this.video = video
        },
        errorAccess() {
            this.error = true
        }
    },
    components: {
        QRCodeVideo
    }
}
</script>