<template>
    <div class="app-content">

        <AppHeader />
        <div class="app-content__wrapper">
            <slot name="content">

            </slot>
            <div v-if="isVisible" class="app-content__store">
                <div class="sidebar__store-image">
                    <a href="https://play.google.com/store/apps/details?id=com.myhousereactnativemigration&amp;hl=ru"
                        target="_blank">
                        <img src="/img/icon_Google.svg">
                    </a>
                </div>
                <div class="sidebar__store-image">
                    <a href="https://apps.apple.com/ru/app/%D0%BA%D0%B2%D0%B0%D1%80%D1%82%D0%BF%D0%BB%D0%B0%D1%82%D0%B0-%D0%BE%D0%BD%D0%BB%D0%B0%D0%B9%D0%BD/id1482895258"
                        target="_blank">
                        <img src="/img/icon_App_Store.svg">
                    </a>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
export default {
    computed: {
        isVisible(){
            return !(this.$route.name==='accountPage' && this.$route.fullPath.split('#').slice(-1)[0] === 'info')
        }
    }
}
</script>
