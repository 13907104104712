<template>
	<AppGlobalLayout :error="error" :loading="loading" @reload="loadInfo">
		<div slot="body">
			<ModalHistory />
			<ModalComment @close="loadInfo" />
			<ModalRating @close="loadInfo" />
			<div>
				<AppTable
					:header="headerTable"
					:data="getOrderHistory.data"
					messageNoData="История заказов отсутствует"
				>
					<template v-slot:thead="{ item }">
						<span>{{ item.name }}</span>
					</template>

					<template v-slot:tbody="{ item }">
						<td @click="openInfo(item)">
							<span class="app-table__title-mob"> № заказа </span>
							{{ item.id }}
						</td>
						<td @click="openInfo(item)">
							<span class="app-table__title-mob"> Дата и время </span>
							{{ dateReturnFormat(item.date) }}
						</td>
						<td @click="openInfo(item)">
							<span class="app-table__title-mob"> Поставщик </span>
							{{ item.suplierName }}
						</td>
						<td @click="openInfo(item)">
							<span class="app-table__title-mob"> Адрес </span>
							{{ item.address }}
						</td>
						<td @click="openInfo(item)">
							<span class="app-table__title-mob"> Сумма </span>
							{{ item.sum }}
						</td>
						<td @click="openInfo(item)">
							<span class="app-table__title-mob"> Статус </span>
							{{ getStatus(item.status) }}
						</td>
						<td>
							<div
								class="order-table-btn"
								v-if="item.status === 3 || item.status === 0"
							>
								<AppBtn
									style="white-space: nowrap;"
									@click="openCommentModal(item)"
								>
									Добавить комментарий
								</AppBtn>
							</div>
							<div v-else class="order-table-btn">
								<AppBtn
									v-if="item.grade === null"
									@click="openRatingModal(item)"
									>Оценить</AppBtn
								>
								<!-- <AppBtn v-if="item.grade === null && (item.status === 0 || item.status === 3)"
									@click="openRatingModal(item)">Добавить комментарий к заказу</AppBtn>
								<AppBtn v-else-if="item.grade === null && (item.status === 1 || item.status === 2)"
									@click="openRatingModal(item)">Оценить</AppBtn> -->
								<star-rating
									v-else
									:increment="1"
									:show-rating="false"
									:read-only="true"
									:padding="5"
									:rating="item.grade"
									:star-size="10"
									:star-points="[
										23,
										2,
										14,
										17,
										0,
										19,
										10,
										34,
										7,
										50,
										23,
										43,
										38,
										50,
										36,
										34,
										46,
										19,
										31,
										17
									]"
									active-color="#EB982C"
								>
								</star-rating>
							</div>
						</td>
					</template>
				</AppTable>
			</div>
		</div>
	</AppGlobalLayout>
</template>

<script>
import { dateReturnFormat } from "@/plugins/methods";
import ModalHistory from "./modal/suplier-hist-info";
import ModalRating from "./modal/rating";
import ModalComment from "./modal/comment";
import { mapGetters } from "vuex";
import StarRating from "vue-star-rating";

function getStatus(status) {
	switch (status) {
		case 0:
			return "Новый";
		case 2:
			return "Выполнен";
		case 3:
			return "В работе";
		default:
			return "Отменен";
	}
}

export default {
	data() {
		return {
			error: false,
			loading: false,
			dataHistory: [],
			headerTable: [
				{
					name: "№",
					width: "7%"
				},
				{
					name: "Дата и время",
					width: "15%"
				},
				{
					name: "Поставщик",
					width: "15%"
				},
				{
					name: "Адрес",
					width: "40%"
				},
				{
					name: "Сумма",
					width: "10%"
				},
				{
					name: "Статус",
					width: "8%"
				},
				{
					name: "",
					width: "5%"
				}
			]
		};
	},
	mounted() {
		this.loadInfo();
	},
	computed: {
		...mapGetters(["getOrderHistory"])
	},
	methods: {
		getStatus(status) {
			return getStatus(status);
		},

		dateReturnFormat(date) {
			return dateReturnFormat(date, true);
		},

		async loadInfo() {
			try {
				await this.$store.dispatch("shopOrderHistory");
			} catch (e) {
				if (e.response) {
					this.$errorsMod(e.response);
				} else {
					this.error = true;
					this.$errorsMod(e);
				}
			}
		},

		openInfo(item) {
			// console.log(item);
			this.$router.push({ name: "order-info", params: { id: item.id } });
			// this.$modal.show("suplier-history", item);
		},
		openRatingModal(item) {
			this.$modal.show("rating-modal", { params: item });
		},
		openCommentModal(item) {
			this.$modal.show("comment-modal", { params: item });
		}
	},
	components: {
		ModalHistory,
		ModalRating,
		StarRating,
		ModalComment
	}
};
</script>

<style>
.order-table-btn {
	display: flex;
	justify-content: center;
	align-items: center;
}
@media screen and (max-width: 768px) {
	.order-table-btn {
		justify-content: end;
	}
}
</style>
