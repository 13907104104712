export default {
	install (Vue) {
		Vue.prototype.$digitNumber = function (methodOptions) {

			if (process.env.NODE_ENV !== 'production') {
				//console.log('digitNumber', methodOptions)
            }
            
			if(typeof methodOptions == 'number' && methodOptions !== 0) {
                return (methodOptions  * -1).toLocaleString('ru')
            } else if (typeof methodOptions == 'string' && !!Number(methodOptions)) {
                return Number(methodOptions).toLocaleString('ru')
            } else {
                return methodOptions
            }
			
		}
	}
}
