import $axios from '@/plugins/axios-instance'

export default {
	state: {
		organizationInfo: {
			organization: {},
			contacts: []
		}
	},
	mutations: {
		setOrganizationInfo (state, data) {
			state.organizationInfo = data
		},
	},

	getters: {
		getOrganizationInfo (state) {
			return state.organizationInfo
		}
	},
	actions: {
		organizationInfo (context, data) {
			return $axios.get(`/Organization/Info?accountId=${data.accountId}&organizationId=${data.organizationId}`)
			.then((response) => {
				context.commit('setOrganizationInfo', response.data)
			}).catch((e)=>{
				throw(e)
			})
		},

		organizationNews (context, data) {
			return $axios.get(`/Organization/news?organizationId=${data.organizationId}`)
			.then((response) => {
				return response.data
			}).catch((e)=>{
				throw(e)
			})
		},

		

	}
}
