<template>
	<div class="groups">
		<div v-if="getGroupsLoading" class="loading">
			<AppLoading :width="50" :height="50" />
		</div>
		<ul v-else class="">
			<AllertLink v-for="group in getGroups" :group="group" />
		</ul>
	</div>
</template>
<script>
import AllertLink from "./AllertLink.vue";
import { mapGetters } from "vuex";
export default {
	data() {
		return {
			groups: []
		};
	},
	computed: {
		...mapGetters(["getGroupsLoading", "getGroups", "getNewMessagesCount"])
	},
	methods: {
		async loadGroups() {
			try {
				await this.$store.dispatch("fetchGroups");
			} catch (e) {
				console.log(e);
			}
		}
	},
	mounted() {
		this.loadGroups();
	},
	components: { AllertLink }
};
</script>
<style scoped>
.groups {
	flex-shrink: 0;
	width: var(--a-group-width);
	overflow: hidden scroll;
}

.loading {
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
}
@media (max-width: 768px) {
	.groups {
		overflow: hidden auto;
	}
}
</style>
