<template>
    <AppModals
        name="apartment-remove"
        title="Удаление"
		@beforeOpen="beforeOpen"
        @confirm="removeApartment"
		colorConfirm="danger"
		confirmButtonText="Удалить"
		:loadingConfirm="getApartmentRemove.loading"
    >
        <template  v-slot:content="{}">
            <div>
                <div style="text-align: left; color: #777776;" class="mt-3 mb-3">Вы действительно хотите удалить - {{name }}?</div>
            </div>
        </template>
    </AppModals>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
	data () {
		return {

			id: null,
			name: null
		}
	},
	props: {

	},
	computed: {
		...mapGetters([
			'getApartmentRemove'
		])
	},
	methods: {
		async removeApartment () {
			try {
				await this.$store.dispatch('apartmentRemove', {
					id: this.id,
				})
				this.$modal.hide('apartment-remove')
			} catch (e) {
				this.$errorsMod(e.response)
			}
		},
		async beforeOpen (event) {
            this.name = event.name
            this.id = event.id
		}
	}
}
</script>