<template>
	<!-- <div class="app-modal__root"> -->
	<modal
		:name="name"
		:max-width="maxWidth"
		:min-width="minWidth"
		:width="width"
		:min-height="355"
		height="auto"
		:adaptive="true"
		@before-open="beforeOpen"
		@before-close="beforeClose"
		:clickToClose="clickToClose"
		:scrollable="true"
		:shiftY="0.4"
	>
		<div
			class="app-modal"
			:class="{
				'app-modal--grey': greyBack
			}"
		>
			<div class="app-modal__header">
				<div class="app-modal__title">
					{{ title }}
				</div>
				<div class="app-modal__close">
					<AppBtn
						@click.native="hideModal"
						icon
						text
						alt="Закрыть модальное окно"
						title="Закрыть модальное окно"
					>
						<AppIcon name="times" size="1.25rem" styleCss="color: var(--color-primary-three)" />
					</AppBtn>
				</div>
			</div>

			<div class="app-modal__content">
				<slot name="content" :paramsModal="paramsModal"> </slot>
			</div>

			<div class="app-modal__footer" v-if="!hideFooter">
				<slot name="footer" :paramsModal="paramsModal">
					<AppBtn
						grey
						:styleCSS="
							'width: 125px;' + (hideConfirm ? '' : 'margin-right: 1rem')
						"
						color="primary"
						outlined
						@click.native="hideModal"
					>
						{{ cancelButtonText }}
					</AppBtn>

					<AppBtn
						@click.native="$emit('confirm')"
						styleCSS="min-width: 125px"
						:color="colorConfirm"
						:disabled="disabledConfirm"
						:loading="loadingConfirm"
						v-if="!hideConfirm"
					>
						{{ confirmButtonText }}
					</AppBtn>
				</slot>
			</div>
		</div>
	</modal>
	<!--  </div> -->
</template>

<script>
export default {
	data() {
		return {
			paramsModal: {
				model: {}
			}
		};
	},
	props: {
		type: {
			type: String,
			default: "default"
		},
		name: {
			type: String,
			default: "default"
		},
		maxWidth: {
			type: [Number, String],
			default: 560
		},
		minWidth: {
			type: Number,
			default: 560
		},
		width: {
			type: Number,
			default: 560
		},
		clickToClose: {
			type: Boolean,
			default: true
		},
		scrollable: {
			type: Boolean,
			default: false
		},
		loadingConfirm: {
			type: Boolean,
			default: false
		},
		disabledConfirm: {
			type: Boolean,
			default: false
		},
		hideConfirm: {
			type: Boolean,
			default: false
		},
		hideFooter: {
			type: Boolean,
			default: false
		},
		title: {
			type: String,
			default: ""
		},
		cancelButtonText: {
			type: String,
			default: "Отмена"
		},
		confirmButtonText: {
			type: String,
			default: "Подтвердить"
		},
		colorConfirm: {
			type: String,
			default: "primary"
		},
		greyBack: {
			type: Boolean,
			default: false
		}
	},
	methods: {
		hideModal() {
			this.$emit("hideModal");
			this.$modal.hide(this.name);
		},
		async beforeOpen(event) {
			// console.log("___EVENT_MODAL", event);
			this.paramsModal = { ...event.params };
			this.$emit("beforeOpen", { ...event.params });
		},
		async beforeClose() {
			// console.log("MODAL CLOSE 2");
			this.$emit("beforeClose");
		}
	}
};
</script>
