import $axios from '@/plugins/axios-instance'
import {router} from '../router'

export default {
	state: {

		totalDebt: 0,
		accountDetails: {
			debt: 0
		},
		orgInfoSearch: [],
		accountListByAppartment: {
			data: [],
			loading: true,
			error: false
		},
		accountDelete: {
			loading: false,
			error: null
		},
		accountMove: {
			loading: false,
			error: null
		},
		accountSplit: {
			loading: false,
			error: null
		},
	},
	getters: {

		getTotalDebt (state) {
			return state.totalDebt
		},
		getAccountSplit (state) {
			return state.accountSplit
		},
		getAccountDetails (state) {
			return state.accountDetails
		},
		getAccountAddSearchById (state) { 
			return state.accountAddSearchById
		},


		getAccountListByAppartment(state) {
			return state.accountListByAppartment
		},


		getAccountDelete(state) {
			return state.accountDelete
		},

		getAccountMove(state) {
			return state.accountMove
		},


	},
	mutations: {
		totalDebtState(state, debt) {
			state.totalDebt = debt
		},
		accountDetailsState(state, data) {
			state.accountDetails = data
		},
		setAccountDeleteData(state, accountId) {
			state.accountListByAppartment.data = state.accountListByAppartment.data.filter(i => i.id !== accountId)
		},
		setAccountDeleteLoad(state, load) {
			state.accountDelete.loading = load
		},
		setAccountDeleteError(state, error) {
			state.accountDelete.error = error
		},
		accountRenameState(state, data) {
			state.accountDetails.balanceType = data.Description
			for(let item of state.accountListByAppartment.data) {
				if(item.id == data.accountId) {
					item.type = data.Description
				}
			}
		},

		setAccountListByAppartmentData(state, data) {
			state.accountListByAppartment.data = data
			state.accountListByAppartment.error = null
		},
		setAccountListByAppartmentLoad(state, load) {
			state.accountListByAppartment.loading = load
		},
		setAccountListByAppartmentError(state, error) {
			state.accountListByAppartment.error = error
		},
		setAccountListByAppartmentErrorList(state, data) {
			state.accountListByAppartment.data[data.index].error = data.error
		},



		setAccountMoveLoad(state, load) {
			state.accountListByAppartment.loading = load
			state.accountListByAppartment.error = null
		},
		setAccountMoveError(state, error) {
			state.accountListByAppartment.error = error
		},



		setAccountSplitLoad(state, load) {
			state.accountSplit.loading = load
			state.accountSplit.error = null
		},
		setAccountSplitError(state, error) {
			state.accountSplit.error = error
		},

	},
	actions: {

		accountDetails (context, data) {
            return $axios.get(`/Account/Details/${data}`)
            .then((response) => {
				context.commit('accountDetailsState', response.data)
            }).catch((e)=>{
                throw(e)
            })
		},

		accountListAddresses (context, data) {
            return $axios.get(`/Account/ListAddresses`)
            .then((response) => {
				return response.data
            }).catch((e)=>{
                throw(e)
            })
		},

		getTotalDebt (context, data) {
            return $axios.get(`/Account/GetTotalDebt`)
            .then((response) => {
				context.commit('totalDebtState', response.data)
            }).catch((e)=>{
                throw(e)
            })
		},

		accountDelete (context, data) {
			context.commit('setAccountDeleteLoad', true)
            return $axios.post(`/Account/Delete`, data.accountId, {
				headers: {
					'Content-Type': 'application/json'
				}
			})
            .then(async (response) => {
				context.commit('setAccountDeleteData', data.accountId)
				await context.dispatch('getTotalDebt')
				if(context.state.accountListByAppartment.data.length == 0) {
					router.push('/account/')
				}
				
            }).catch((e)=>{
				context.commit('setAccountDeleteError', e.response)
				throw (e)
            }).finally(() => {
				context.commit('setAccountDeleteLoad', false)
			})
		},

		accountRename (context, data) {
            return $axios.post(`/Account/rename`, data )
            .then(() => {
				context.commit('accountRenameState', data)
            }).catch((e)=>{
				throw (e)
            })
		},

		listByAppartment (context, id) {
			context.commit('setAccountListByAppartmentLoad', true)
            return $axios.get(`/Account/ListByApartment?apartmentId=${id}` )
            .then((response) => {
				context.commit('setAccountListByAppartmentData', response.data)
				return response.data
            }).catch((e)=>{
				context.commit('setAccountListByAppartmentError', e.response)
				throw (e)
            }).finally(() => {
				context.commit('setAccountListByAppartmentLoad', false)
			})
		},


		accountMove({commit, dispatch}, data) {
			commit('setAccountMoveLoad', true)
			$axios.post(`/account/move?apartmentId=${data.apartmentId}&accountId=${data.accountId}` )
            .then((response) => {
				router.push(`/account/list/${data.apartmentId}`)
				dispatch('listByAppartment', data.apartmentId)
            }).catch((e)=>{
				commit('setAccountMoveError', e.response)
				throw (e)
            }).finally(() => {
				commit('setAccountMoveLoad', false)
			})
		},

		accountSplit({commit, dispatch}, data) {
			commit('setAccountSplitLoad', true)
			$axios.post(`/account/split?apartmentId=${data.apartmentId}&accountId=${data.accountId}` )
            .then((response) => {
				dispatch('listByAppartment', data.apartmentId)
            }).catch((e)=>{
				commit('setAccountSplitError', e.response)
				throw (e)
            }).finally(() => {
				commit('setAccountSplitLoad', false)
			})
		},





	}
}
