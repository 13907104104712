import notifications from './notifications'
const signalR = require('@aspnet/signalr')

export default {
	state: {
		connection: null
	},
	mutations: {
		disconect(state) {
			state.connection = null
		},
	},
	actions: {
		async connectSignalR(context, data) {
			if (process.env.NODE_ENV !== 'production') {
				// console.log('type', process.env.NODE_ENV)
			}

			context.state.connection == null
				? context.state.connection = await new signalR.HubConnectionBuilder().withUrl(process.env.VUE_APP_URL + '/userRequestHub', {
					skipNegotiation: true,
					transport: signalR.HttpTransportType.WebSockets
				}).build()
				: false

			let reconectSignalTime = 5000

			function start() {
				context.state.connection.start().catch(() => {
					setTimeout(function () {
						start()
						reconectSignalTime += 5000
					}, reconectSignalTime)
				})
			}

			context.state.connection.onclose(function () {
				if (context.state.connection != null) {
					reconectSignalTime = 5000
					start()
				}
			})

			context.state.connection.on('userRequest', (data) => {
				//console.log(data)
				notifications.state.notificationsList.push(data)
				//context.dispatch('userRequestCountUnreadMessage', null, { root: true })
			})

			start()

			if (data.connect == 'off') {
				//console.log('disconnect', data.connect)
				context.state.connection.stop()
				context.commit('disconect');
			}
		}
	}
}
