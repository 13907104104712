<template>
	<div>
		<div class="mt-2 account-info">
			<!-- <div class="col-xl-9 col-nt-8 col-12 mb-5"> -->
			<div class="account-info__left">
				<AppCard>
					<template #body>
						<div class="card-content__content">
							<div class="row">
								<div class="col-12 col-md-4">
									<div class="account-image-brand">
										<img v-if="getTheme === 'dark-theme'" src="/img/dark-theme/house-dark.png"/>
										<img v-else src="/img/city-default.png"/>
									</div>
								</div>
								<div class="col-12 col-md-8">
									<div class="account-title">
										<div class="text-title">
											{{ getOrganizationInfo.organization.organizationName }}
										</div>
										<div class="account-title__logo">
											<!-- <svg xmlns="http://www.w3.org/2000/svg" width="43" height="39.86" viewBox="0 0 43 39.86">
                                                <g id="Сгруппировать_11166" data-name="Сгруппировать 11166" transform="translate(-554.42 -705.298)">
                                                    <path id="Контур_24615" data-name="Контур 24615" d="M568.524,731.072V728.4h10.491V717.933H581.7v13.139Z" transform="translate(15.723 14.085)" fill="#2e2e2d"/>
                                                    <path id="Контур_24616" data-name="Контур 24616" d="M554.42,731.072V717.933H557.1V728.4h10.491v2.673Z" transform="translate(0 14.085)" fill="#2e2e2d"/>
                                                    <path id="Контур_24617" data-name="Контур 24617" d="M579.018,718.437V707.973H568.524V705.3H581.7v13.139Z" transform="translate(15.723 0)" fill="#2e2e2d"/>
                                                    <path id="Контур_24618" data-name="Контур 24618" d="M554.42,718.437V705.3h13.173v2.675H557.1v10.464Z" transform="translate(0 0)" fill="#2e2e2d"/>
                                                    <path id="Контур_24619" data-name="Контур 24619" d="M585.166,708.969H558.177v5.374h26.989Z" transform="translate(4.188 4.092)" fill="#2e2e2d"/>
                                                    <path id="Контур_24620" data-name="Контур 24620" d="M585.166,717.933H558.177v5.376h26.989Z" transform="translate(4.188 14.085)" fill="#2e2e2d"/>
                                                    <path id="Контур_24621" data-name="Контур 24621" d="M579.689,713.451H558.177v5.376h21.512Z" transform="translate(4.188 9.089)" fill="#2e2e2d"/>
                                                </g>
                                            </svg> -->
										</div>
									</div>
									<div class="text-row">
										<span class="text-row__title"> ИНН: </span>
										<span class="text-row__text">
											{{ getOrganizationInfo.organization.inn }}
										</span>
									</div>
									<div class="text-row">
										<span class="text-row__title"> ОГРН: </span>
										<span class="text-row__text">
											{{ getOrganizationInfo.organization.ogrn }}
										</span>
									</div>
									<div class="text-row">
										<span class="text-row__title"> Юридический адрес: </span>
										<span class="text-row__text">
											{{ getOrganizationInfo.organization.legalAddress }}
										</span>
									</div>
									<div class="text-row">
										<span class="text-row__title"> Фактический адрес: </span>
										<span class="text-row__text">
											{{ getOrganizationInfo.organization.actualAddress }}
										</span>
									</div>
									<div class="text-row">
										<span class="text-row__title"> ФИО руководителя: </span>
										<span class="text-row__text">
											{{ getOrganizationInfo.organization.director }}
										</span>
									</div>
									<div
										class="text-row"
										v-if="getOrganizationInfo.organization.receptionHours"
									>
										<span class="text-row__title"> Приемные часы: </span>
										<span class="text-row__text">
											{{ getOrganizationInfo.organization.receptionHours }}
										</span>
									</div>
									<div class="text-row">
										<span class="text-row__title"> Телефон: </span>
										<span class="text-row__text">
											<AppLink 
												:href="'tel:'+ getPhone(getOrganizationInfo.organization.phone, '+7XXXXXXXXXX')"
												:style="getTheme === 'dark-theme' && { color: '#CDCDD6' }"
												>{{getPhone(getOrganizationInfo.organization.phone, '+ 7 (XXX) XXX-XX-XX')}}</AppLink>
										</span>
									</div>
									<div class="text-row">
										<span class="text-row__title"> E-mail: </span>
										<span class="text-row__text">
											<AppLink
												:style="getTheme === 'dark-theme' && { color: '#CDCDD6' }"
												:href="
													'mailto:' + getOrganizationInfo.organization.email
												"
												>{{ getOrganizationInfo.organization.email }}</AppLink
											>
										</span>
									</div>
									<div
										class="text-row"
										v-if="getOrganizationInfo.organization.website"
									>
										<span class="text-row__title"> Сайт: </span>
										<span class="text-row__text">
											<AppLink
												target="_blank"
												:href="getOrganizationInfo.organization.website"
												>{{ getOrganizationInfo.organization.website }}</AppLink
											>
										</span>
									</div>

									<!-- <div class="text-row">
										<span class="text-row__title"> Банк: </span>
										<span class="text-row__text">
											{{ getOrganizationInfo.organization.bank }}
										</span>
									</div>
									<div class="text-row">
										<span class="text-row__title"> БИК: </span>
										<span class="text-row__text">
											{{ getOrganizationInfo.organization.bik }}
										</span>
									</div>
									<div class="text-row">
										<span class="text-row__title"> Расчетный счет: </span>
										<span class="text-row__text">
											{{ getOrganizationInfo.organization.checkingAccount }}
										</span>
									</div>
									<div class="text-row">
										<span class="text-row__title">
											Корреспондентский счет:
										</span>
										<span class="text-row__text">
											{{
												getOrganizationInfo.organization.correspondentAccount
											}}
										</span>
									</div> -->
								</div>
							</div>
						</div>
					</template>
				</AppCard>
			</div>

			<!--  <div class="col-xl-3 col-nt-4 col-12 mt-4--lg mb-5"> -->
			<div class="account-info__right">
				<!-- <accountNews /> -->
				<AppCard classCss="account-cards">
					<template #header>
						<div class="text-title mb-3">
							{{ getAccountDetails.balanceType }}
						</div>
					</template>
					<template #body>
						<div>
							<div class="text-row">
								<span class="text-row__title"
									>{{ getAccountDetails.address }}
								</span>
							</div>
							<div class="text-row">
								<span class="text-row__title">Лицевой счет: </span>
								<span class="text-row__text">
									{{ getAccountDetails.number }}
								</span>
							</div>
							<div class="text-row">
								<span class="text-row__title">Баланс: </span>
								<span
									class="text-row__text"
									:class="{
										'danger-color': getAccountDetails.debt > 0,
										'success-color': getAccountDetails.debt <= 0,
									}"
								>
									{{ $digitNumber(getAccountDetails.debt) }} ₽
								</span>
							</div>
							<!-- <div class="text-row">
								<span class="text-row__title">Дата последнего платежа: </span>
								<span class="text-row__text">
									{{
										getAccountDetails.paymentDate
											? dateReturnFormat(getAccountDetails.paymentDate)
											: "Информация отсутствует"
									}}
								</span>
							</div>
							<div class="text-row">
								<span class="text-row__title">Сумма последнего платежа: </span>
								<span class="text-row__text">{{
									getAccountDetails.paymentSum
										? (getAccountDetails.paymentSum).toLocaleString('ru')+ " ₽"
										: "Информация отсутствует"
								}}</span>
							</div> -->
						</div>
					</template>
				</AppCard>
			</div>
		</div>

		<AppCard classCss="main-banner-card mt-3">
			<template #body>
				<div class="main-banner">
					<div class="main-banner__title">
						Мобильное приложение Квартплата.онлайн
					</div>
					<div class="main-banner__description">
						Вся информация всегда под рукой.<br />
						Для тех, кто ценит свое время.
					</div>
					<div class="main-banner__link">
						<div class="main-banner__store-image">
							<a
								href="https://play.google.com/store/apps/details?id=com.myhousereactnativemigration&amp;hl=ru"
								target="_blank"
							>
								<img src="/img/icon_Google.svg" />
							</a>
						</div>
						<div class="main-banner__store-image">
							<a
								href="https://apps.apple.com/ru/app/%D0%BA%D0%B2%D0%B0%D1%80%D1%82%D0%BF%D0%BB%D0%B0%D1%82%D0%B0-%D0%BE%D0%BD%D0%BB%D0%B0%D0%B9%D0%BD/id1482895258"
								target="_blank"
							>
								<img src="/img/icon_App_Store.svg" />
							</a>
						</div>
					</div>
				</div>
			</template>
		</AppCard>
	</div>
</template>

<script>
import { mapGetters } from "vuex";
import accountNews from "../account-news/account-news";
import { dateReturnFormat, getPhone } from "@/plugins/methods";

export default {
	data() {
		return {};
	},
	computed: {
		...mapGetters(["getOrganizationInfo", "getAccountDetails", "getTheme"]),
	},
	mounted() {},
	methods: {
		dateReturnFormat(date) {
			return dateReturnFormat(date, false);
		},
		getPhone (num, mask){
			return getPhone(num, mask)
		}
	},
	components: {
		accountNews,
	},
};
</script>

<style scoped lang="sass">
.my-input 
	color: black
	font-family: 'Rubik', sans-serif
	font-weight: 500
	outline: none
	border: none
	background-image: none
	background-color: transparent
	-webkit-box-shadow: none
	-moz-box-shadow: none
	box-shadow: none

</style>