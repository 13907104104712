<template>
	<AppModals
		name="company-info"
		title="О компании"
		cancelButtonText="Закрыть"
		:clickToClose="false"
		greyBack
		:maxWidth="900"
		:width="900"
		@beforeOpen="openInfo"
		hideConfirm
	>
		<template #content="{}">
			<div class="company-info-modal">
				<div class="company-info-modal__logo">
					<img
						:src="
							`https://квартплата.онлайн/files/static/${companyInfo.suplierImage}`
						"
					/>
				</div>

				<div class="company-info-modal__description">
					<div class="company-info-modal__title">
						{{ companyInfo.suplierName }}
					</div>
					<div :style="getTheme === 'dark-theme' ? 'color: #9797A5' : ''">
						{{ companyInfo.suplierDescription }}
					</div>

					<div style="margin-top: 1em;">
						{{ companyInfo.suplierOrganizationName }}
						<br />
						ИНН:
						{{ companyInfo.suplierOrganizationInn }}
					</div>
				</div>
			</div>
		</template>
	</AppModals>
</template>

<script>
import { mapGetters } from "vuex";
export default {
	data() {
		return {
			companyInfo: {
				title: "",
				id: null,
				description: ""
			}
		};
	},
	computed: {
		...mapGetters(["getTheme"])
	},
	methods: {
		openInfo(event) {
			this.companyInfo = { ...event };
		}
	}
};
</script>
