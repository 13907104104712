import Vue from "vue";
import VueRouter from "vue-router";
import NotFoundComponent from "@/components/layouts/pageError";
import AccountList from "./views/account/account-list";
import AccountFlatList from "./views/account/apartment-list";
import TechnicalService from "./views/technical-service";

import Accounts from "./views/account";
import Shop from "./views/suplier";
import OrderInfo from "@/views/order-info";

import accountPage from "./views/account/account-page";
import buyInsurancePage from "./views/account/insurance/buy-insurance-page.vue";
import payment from "./views/payment/payment";
import support from "./views/support/support-main";
import request from "./views/request/request-main";
import requestDetail from "./views/request/request-detail";
import partner from "./views/suplier/partner-main";
import services from "./views/suplier/services";
import company from "./views/suplier/company";
import cart from "./views/suplier-cart";
import suplierHistory from "./views/suplier-history";
import AccountAdd from "./views/account-add";
import OtherResource from "./views/other-resource";
import SendCounter from "./views/send-counter";
import SendCounterList from "./views/send-counter/counter-list";
import PriceList from "./views/suplier/price-list";
import PaymentPage from "./views/payment-all";

import Profile from "./views/profile";

import AlertsPage from "./views/alerts/AlertsPage.vue";

import token from "./store/token";

Vue.use(VueRouter);

const routes = [
	{
		path: "",
		redirect: { name: "account-flat-list" }
	},
	{
		name: "account",
		path: "/account",
		component: Accounts,
		meta: {
			name: "Главная",
			breadcrumbsDynamic: false
		},
		children: [
			{
				name: "account-flat-list",
				path: "/account/",
				component: AccountFlatList,
				meta: {
					name: "Главная",
					breadcrumbsDynamic: false
				}
			},
			{
				name: "account-flat",
				path: "/account/list/:id",
				component: AccountList,
				meta: {
					name: "",
					breadcrumbsDynamic: false
				}
			},

			{
				name: "buy-insurance",
				path: "/account/buy-insurance/:apartmentId",
				component: buyInsurancePage,
				meta: {
					name: "Купить страховку",
					breadcrumbsDynamic: false
				}
			},

			{
				name: "accountPage",
				path: "/account/info/:id",
				component: accountPage,
				props: { name: "" },
				meta: {
					name: "",
					breadcrumbsDynamic: true
				}
			},
			{
				name: "accountAdd",
				path: "/account/add",
				component: AccountAdd,
				props: { name: "" },
				meta: {
					name: "",
					breadcrumbsDynamic: false
				}
			}
		]
	},

	{
		name: "payment",
		path: "/payment",
		component: payment,
		props: { name: "" },
		meta: {
			name: "Платежи"
		}
	},

	{
		name: "send-cnt",
		path: "/send-cnt",
		component: SendCounter,
		props: { name: "" },
		meta: {
			name: "Счетчики"
		}
	},
	{
		name: "sendCntList",
		path: "/send-cnt/:id",
		component: SendCounterList,
		props: { name: "" },
		meta: {
			name: "Передача показаний"
		}
	},
	{
		name: "paymentPage",
		path: "/payment-page",
		component: PaymentPage,
		props: true,
		meta: {
			name: "Оплата"
		}
	},
	{
		name: "bank-rejected",
		path: "/bank/rejected",
		component: AccountFlatList,
		props: { name: "" },
		meta: {
			name: "Лицевые счета"
		}
	},
	{
		name: "bank-approved",
		path: "/bank/approved",
		component: AccountFlatList,
		props: { name: "" },
		meta: {
			name: "Лицевые счета"
		}
	},
	{
		name: "profile",
		path: "/profile",
		component: Profile,
		props: { name: "" },
		meta: {
			name: "Учетные данные"
		}
	},
	{
		name: "request",
		path: "/request",
		component: request,
		props: { name: "" },
		meta: {
			name: "Заявки"
		}
	},
	{
		name: "requestDetail",
		path: "/request/:id",
		component: requestDetail,
		props: { name: "" },
		meta: {
			name: "Информация по заявке"
		}
	},
	{
		name: "support",
		path: "/support",
		component: support,
		props: { name: "" },
		meta: {
			name: "Тех. поддержка"
		}
	},

	{
		path: "/shop",
		redirect: { name: "shop-main" }
	},

	{
		name: "shop",
		path: "/shop",
		component: Shop,
		meta: {
			name: "Магазин"
		},
		children: [
			{
				name: "shop-main",
				path: "/shop/main/",
				component: partner,
				meta: {
					name: "Магазин"
				}
			},
			{
				name: "suplier-services",
				path: "/shop/services/:id",
				component: services,
				meta: {
					name: "Магазин"
				}
			},
			{
				name: "suplier-company",
				path: "/shop/company/:id",
				component: company,
				meta: {
					name: "Магазин"
				}
			},
			{
				name: "cart",
				path: "/shop/cart",
				component: cart,
				meta: {
					name: "Корзина"
				}
			},
			{
				name: "suplierHistory",
				path: "/shop/history/",
				component: suplierHistory,
				meta: {
					name: "История заказов"
				}
			},
			{
				name: "price-list",
				path: "/shop/price-list/:id",
				component: PriceList,
				meta: {
					name: "Магазин"
				}
			},
			{
				name: "order-info",
				path: "/shop/order-info/:id",
				component: OrderInfo,
				meta: {
					name: "Информация о заказе"
				}
			}
		]
	},

	{
		name: "alerts",
		path: "/alerts",
		component: AlertsPage,
		meta: {
			name: "Уведомления"
		}
	},
	{
		name: "tech-service",
		path: "/technical-service/:unitId",
		component: TechnicalService,
		meta: {
			name: "Техническое обслуживание"
		}
	},
	{
		name: "other",
		path: "/other",
		component: OtherResource,
		meta: {
			name: "Вспомогательные ресурсы"
		}
	},
	{
		path: "/404",
		name: "404",
		mode: "history",
		component: NotFoundComponent
	},
	{
		path: "*",
		redirect: "/404"
	}
];
let _TOKEN = window.$cookies.get("time_token_die");

export const router = new VueRouter({
	routes,
	_TOKEN,
	mode: "history"
});

router.beforeEach((to, from, next) => {
	_TOKEN = window.$cookies.get("time_token_die");

	if (process.env.NODE_ENV !== "production") {
		console.log("_TOKEN development", _TOKEN);
	}

	if (_TOKEN) {
		token.state.tokenValid = true;
		let dateExp = new Date() / 1000 + (60 * 60 * 24 * 30, 60 * 60 * 24 * 30);
		window.$cookies.set(
			"time_token_die",
			dateExp,
			60 * 60 * 24 * 30,
			null,
			process.env.VUE_APP_COOCKIE
		);

		const versionApi = localStorage.getItem("ver");
		const clientVer = localStorage.getItem("clientVer");

		if (versionApi !== clientVer) {
			localStorage.setItem("clientVer", versionApi);
			next();
			window.location.reload();
		} else {
			next();
		}
	} else {
		token.state.tokenValid = false;
		window.$cookies.remove("time_token_die", "/", process.env.VUE_APP_COOCKIE);
		window.location.replace(process.env.VUE_APP_REDIRECT);
	}
});
