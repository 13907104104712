<template>
  <div>
    <CounterRename />
    <ModalSendApproved />
    <div v-if="getCounterActual.settings">Идут технические работы...</div>
    <div v-else-if="getCounterActual.counters.length == 0" class="d-flex d-column">
      <h5 class="mb-3 mt-5" :style="getTheme === 'dark-theme' ? 'color: #fefdff' : ''">
        На этом лицевом счете мы не нашли ни одного счетчика, по которому можно передать
        показания. Скорее всего, причина в следующем:
      </h5>

      <ul :style="getTheme === 'dark-theme' ? 'color: #9797A5' : ''">
        <li class="mt-1">1. У приборов учета истек срок поверки.</li>
        <li class="mt-1">
          2. Управляющая организация решила снимать показания самостоятельно.
        </li>
        <li class="mt-1">
          3. Компания, обслуживающая лицевой счет, не предоставила возможность передачи
          показаний.
        </li>
      </ul>
    </div>
    <div v-else>
      <AppTable
        :header="header"
        :data="$v.getCounterActual.counters.$each.$iter"
        messageNoData="Счетчики отсутствуют или показания снимает Ваша управляющая организация"
        rowTemplate="dataRowTemplate"
        rounded
        masterRow
        ref="tableCounter"
        class="table-counter"
      >
        <template v-slot:thead="{ item }">
          <span>{{ item.name }}</span>
        </template>

        <template #dataRowTemplate="{ item, index }">
          <td @click="openInfoCounter(index)">
            <span class="d-flex align-center just-between">
              <div class="table-counter__image-block mr-2 mobile-hide">
                <img
                  :src="counterImage(item.$model.counterType)"
                  class="table-counter__image"
                />
              </div>
              <div class="table-counter__text-block">
                {{ item.$model.description || item.$model.serviceName }}
                {{
                  !item.$model.description && item.$model.counterNumber
                    ? ` № ${item.$model.counterNumber}`
                    : ""
                }}
              </div>
            </span>
          </td>
          <td>
            <span
              v-if="item.$model.counterWarning > 0 && !item.showInfo"
              :class="getStatusColor(item.$model.counterWarning)"
              style="text-align: center"
            >
              Требуется поверка
            </span>
          </td>

          <td>
            <span class="app-table__title-mob"> Предыдущее значение: </span>
            <span>
              {{
                item.$model.value.toLocaleString("ru", {
                  maximumFractionDigits: 5,
                }) +
                " " +
                item.$model.unitName
              }}
            </span>
          </td>

          <td>
            <span>
              <span v-if="!item.$model.mustGet">
                <p class="warning-color medium">Снимает организация</p>
              </span>
              <span v-else>
                <span
                  v-if="!item.$model.inputValuesAllowance.canSend"
                  :class="'danger-color'"
                  style="font-weight: 400"
                >
                  {{ item.$model.inputValuesAllowance.message }}
                </span>
                <span v-else>
                  <span class="app-table__title-mob">Текущие значение:</span>
                  <!-- <pre>{{ item }}</pre> -->
                  <input
                    :class="item.userValue.$invalid ? 'novalidinput' : ''"
                    type="text"
                    oninput="this.value = this.value.replace(',','.').replace(/^\.|[^\d\.]|\.(?=.*\.)|^0+(?=\d)/g, '')"
                    v-model="item.$model.userValue"
                    class="app-input__input mt-1--xs"
                    style="max-width: 300px"
                    :style="
                      item.$model.userValueLimits &&
                      item.$model.userValueLimits.warningLimit &&
                      !item.userValue.$invalid &&
                      +item.$model.userValue >
                        +item.$model.userValueLimits.warningLimit + +item.$model.value
                        ? 'border: 1px solid var(--color-warning) !important'
                        : ''
                    "
                  />

                  <div
                    v-show="item.userValue.$invalid"
                    class="danger-color"
                    style="
                      margin-top: 0.5em;
                      font-size: 0.7em;
                      text-align: center;
                      max-width: 300px;
                    "
                  >
                    <span v-if="!item.userValue.required"
                      >Поле обязательно для заполнения</span
                    >
                    <span v-else-if="!item.userValue.format"
                      >Не более 6 цифр до запятой и не более 5 после</span
                    >
                    <span v-else-if="!item.userValue.minValueTest && item.$model.value">
                      Значение должно быть больше, чем предыдущее ({{
                        item.$model.value.toLocaleString()
                      }})
                    </span>

                    <span
                      v-else-if="
                        item.$model.userValueLimits && !item.userValue.errorLimit
                      "
                    >
                      Ошибка: Расход превышает
                      {{ item.$model.userValueLimits.errorLimit.toLocaleString() }}
                      {{ item.$model.unitName }} Передача не возможна!
                    </span>
                  </div>

                  <div
                    style="
                      margin-top: 0.5em;
                      font-size: 0.7em;
                      text-align: center;
                      max-width: 300px;
                    "
                    v-if="
                      item.$model.userValueLimits &&
                      item.$model.userValueLimits.warningLimit &&
                      !item.userValue.$invalid &&
                      +item.$model.userValue >
                        +item.$model.userValueLimits.warningLimit + +item.$model.value
                    "
                  >
                    <span
                      style="color: var(--color-warning)"
                      v-if="item.$model.userValueLimits.warningLimit"
                    >
                      Внимание! Расход превышает
                      {{ (+item.$model.userValueLimits.warningLimit).toLocaleString() }}
                      {{ item.$model.unitName }} Проверьте показания!
                    </span>
                  </div>

                  <!-- <button @click="test(item)">test</button> -->
                </span>
              </span>
            </span>
          </td>
          <td>
            <div class="d-flex just-end mb-2--xs">
              <AppBtn @click.native="openHistory(item.$model.counterID)" outlined>
                История
              </AppBtn>
            </div>
          </td>
          <td class="table-counter__menu">
            <div>
              <AppDropdown>
                <template #activator="{ showChange }">
                  <AppBtn icon text @click.native="showChange">
                    <AppIcon name="ellipsis-v" size="1rem" style="color: #777776" />
                  </AppBtn>
                </template>
                <div slot="dropdown">
                  <AppList :listData="listData">
                    <template #list-item>
                      <AppListItem
                        v-for="(menu, index) in listData"
                        :name="menu.name"
                        @click.native="clickMenuItem(menu.id, item.$model)"
                        :key="index"
                      />
                    </template>
                  </AppList>
                </div>
              </AppDropdown>
            </div>
          </td>
        </template>

        <template #masterRow="{ item }" v-show="item.$model.showInfo">
          <!-- <tr class="app-table__tr-body--master" v-show="item.$model.showInfo"> -->
          <td
            style="
              border: 1px solid var(--border-color-dark);
              border-radius: 0 0 24px 24px !important;
              padding-top: 25px;
            "
            colspan="7"
          >
            <div :class="[getTheme === 'dark-theme' ? darkClass : '', defaultClass]">
              <div class="counter-table__info-block mb-2">
                <div class="counter-table__info-item">
                  <span>Счетчик:</span
                  >{{ item.$model.description || item.$model.serviceName || "-" }}
                </div>
                <div class="counter-table__info-item">
                  <span>Серийный номер:</span>{{ item.$model.serialNumber || "-" }}
                </div>
                <div style="margin-bottom: 0" class="counter-table__info-item">
                  <span>Производитель:</span>{{ item.$model.mark || "-" }}
                </div>
              </div>
              <div class="counter-table__info-block">
                <div class="counter-table__info-item">
                  <span>Модель:</span>{{ item.$model.model || "-" }}
                </div>
                <div
                  style="margin-bottom: 0"
                  :class="getVerificationColor(item.$model.counterWarning)"
                >
                  <span :class="getVerificationColor(item.$model.counterWarning)"
                    >Следующая дата поверки:</span
                  >
                  {{ dateReturnFormat(item.$model.nextVerificationDate) || "-" }}
                </div>
              </div>
            </div>
          </td>
        </template>
      </AppTable>

      <div class="content-text content-text--counter">
        <div class="send" v-if="getCounterActual.counters.length != 0">
          <AppBtn
            @click.native="submitCounter"
            :loading="loading"
            :disabled="
              !getCounterActual.counters.some(
                (item) => item.inputValuesAllowance.canSend && item.mustGet
              ) || $v.$invalid
            "
          >
            Отправить
          </AppBtn>
        </div>
        <snackbar ref="snackbar" />
      </div>
    </div>
  </div>
</template>

<script>
import { dateReturnFormat } from "@/plugins/methods";
import counterEnum from "@/plugins/counter-enum";
import { mapGetters } from "vuex";
import CounterRename from "../modal/counter-rename.vue";
import ModalSendApproved from "../modal/ModalSendApproved";
import CounterWarning from "../modal/counter-warning.vue";

const minValueTest = (val, param) => {
  if (param.mustGet && param.isActive && val) {
    return param.value <= +val;
  } else {
    return true;
  }
};

const format = (val, param) => {
  if (param.mustGet && param.isActive && val) {
    return /^[\d]{1,6}(\.[\d]{1,5})?$/.test(val.toString());
  } else {
    return true;
  }
};

const required = (val, param) => {
  if (!param.inputValuesAllowance.canSend || !param.mustGet) {
    return true;
  } else {
    return !(val === undefined || val === "" || val === null);
  }
};

const errorLimit = (val, params) => {
  if (params.userValueLimits && params.userValueLimits.errorLimit) {
    if (+params.userValueLimits.errorLimit < +val - +params.value) {
      return false;
    }
  }
  return true;
};

export default {
  data() {
    return {
      loading: false,
      darkClass: "counter-table--dark",
      defaultClass: "counter-table__info",
      header: [
        {
          name: "Счетчик",
          width: "35%",
        },
        {
          name: "",
          width: "10%",
        },

        {
          name: "Предыдущее значение",
          width: "25%",
        },
        {
          name: "Текущее значение",
          width: "20%",
        },
        {
          name: "",
          width: "10%",
        },
      ],
      listData: [
        {
          id: 0,
          name: "Переименовать",
        },
      ],
    };
  },
  computed: {
    ...mapGetters(["getCounterActual", "getTheme"]),
  },
  mounted() {
    if (this.$refs.tableCounter && this.$refs.tableCounter.masterData) {
      this.$refs.tableCounter.masterData = this.$refs.tableCounter.masterData.map(
        (item) => {
          if (item.$model.counterWarning === 2) {
            return { ...item, showInfo: true };
          } else {
            return { ...item, showInfo: false };
          }
        }
      );
    }
  },

  methods: {
    clickMenuItem(id, counter) {
      if (id == 0) {
        this.$modal.show("counter-rename", {
          counter,
          accountId: this.$route.params.id,
        });
      }
    },
    getVerificationColor(status) {
      switch (status) {
        case 0:
          return "success-color";
        case 1:
          return "warning-color";
        case 2:
          return "danger-color";
        default:
          return "secondary-color";
      }
    },
    getStatusColor(status) {
      switch (status) {
        case 1:
          return "warning-status";
        case 2:
          return "danger-status";
        default:
          return "";
      }
    },

    openHistory(id) {
      this.$modal.show("counter-history", { counterId: id });
    },

    dateReturnFormat(date) {
      return dateReturnFormat(date, false);
    },

    openInfoCounter(index) {
      this.$refs.tableCounter.openMaster(index);
    },

    counterImage(counterType) {
      return counterEnum(counterType);
    },

    async submitCounter() {
      this.$v.getCounterActual.$touch();
      if (!this.$v.$error) {
        this.loading = true;
        const counterValues = this.getCounterActual.counters.reduce((ac, item) => {
          if (item.mustGet && item.inputValuesAllowance.canSend) {
            return [
              ...ac,
              {
                counterId: item.counterID,
                counterValue: item.userValue,
              },
            ];
          } else {
            return ac;
          }
        }, []);

        try {
          await this.$store.dispatch("counterSetValues", {
            accountId: this.$route.params.id,
            counterValues,
          });
          this.$modal.show("counter-approved");
        } catch (e) {
          this.$errorsMod(e.response);
        }
        this.loading = false;
      }
    },
  },
  validations: {
    getCounterActual: {
      counters: {
        $each: {
          userValue: {
            minValueTest,
            format,
            required,
            errorLimit,
          },
        },
      },
    },
  },
  components: {
    CounterRename,
    ModalSendApproved,
    CounterWarning,
  },
};
</script>

<style scoped lang="sass">
.novalidinput
	border: 1px solid #e6575d !important


.send
	display: flex
	justify-content: flex-end
	flex-direction: column
	align-items: flex-end

.counter-table--dark
	.counter-table
		&__info-block--border
			border: 1px dashed #4B4B54
		&__info-item
			color: #CDCDD6

.counter-table__info
	display: flex
	padding: 0px
	flex-wrap: wrap
	width: 100%
	margin-left: 2.5rem

.counter-table__info-block
	margin-right: 2.5rem

.counter-table__info-item
	margin: 0px 0px 0.75rem 0
	font-weight: 500

.counter-table__info-item span
	font-weight: 400
	padding-right: 0.5rem

@media (max-width: 767px)
	.counter-table__info
		margin-left: 0
		width: calc(100vw - 90px) // костыль


.warning-status
	border-radius: 12px
	padding: 5px 10px
	display: inline-block
	white-space: nowrap
	color: var(--color-warning)
	background: rgba(235, 152, 44, 0.1)

.danger-status
	border-radius: 12px
	padding: 5px 10px
	display: inline-block
	white-space: nowrap
	color: var(--color-danger)
	background: rgba(230, 87, 93, 0.1)
</style>
