import $axios from "@/plugins/axios-instance";
import { stringReplace } from "../plugins/stringSpec";

export default {
	state: {
		counterList: {
			settings: {},
			counters: []
		},
		counterListActual: {
			settings: {},
			counters: []
		},
		counterArch: [],
		accountswithcounters: {
			data: [],
			loading: false,
			error: null
		}
	},
	getters: {
		getCounterList(state) {
			return state.counterList;
		},
		getCounterArch(state) {
			return state.counterArch;
		},
		getCounterActual(state) {
			return state.counterListActual;
		},

		getAccountswithcounters(state) {
			return state.accountswithcounters;
		}
	},
	mutations: {
		counterListMut(state, counter) {
			state.counterList = counter;
		},
		counterActualMut(state, counter) {
			state.counterListActual = counter;
			let filterAccount = counter.counters.filter(item => item.isActive);
			state.counterListActual.counters = filterAccount.map(item => ({
				...item
			}));
		},
		counterArchMut(state, counter) {
			let filterAccount = counter.counters.filter(item => !item.isActive);
			state.counterArch = filterAccount.map(item => ({ ...item }));
		},

		setAccountswithcountersData(state, data) {
			return (state.accountswithcounters.data = data);
		},
		setAccountswithcountersLoading(state, load) {
			return (state.accountswithcounters.loading = load);
		},
		setAccountswithcountersError(state, error) {
			return (state.accountswithcounters.error = error);
		}
	},
	actions: {
		counterList(context, data) {
			return $axios
				.get(`/cnt/listnew/${data}`)
				.then(response => {
					context.commit("counterListMut", response.data);
					context.commit("counterArchMut", response.data);
					context.commit("counterActualMut", response.data);
					return response.data;
				})
				.catch(e => {
					throw e;
				});
		},

		counterSetValues(context, data) {
			return $axios
				.post(`/cnt/SetValues/`, data)
				.then(response => {
					context.state.counterList[data.index];
				})
				.catch(e => {
					throw e;
				});
		},

		getCounterHistory(context, id) {
			return $axios
				.get(`/cnt/history/${id}`)
				.then(response => {
					return response.data;
				})
				.catch(e => {
					throw e;
				});
		},

		accountswithcountersGet(context) {
			context.commit("setAccountswithcountersLoading", true);
			return $axios
				.get(`/cnt/accountswithcounters/`)
				.then(response => {
					context.commit("setAccountswithcountersData", response.data);
					return response.data;
				})
				.catch(e => {
					context.commit("setAccountswithcountersError", e);
					throw e;
				})
				.finally(() => {
					context.commit("setAccountswithcountersLoading", false);
				});
		},

		counterRename(context, data) {
			const query = data.name ? `?description=${stringReplace(data.name)}` : "";
			return $axios
				.post(`/cnt/rename/${data.id}${query}`)
				.then(async response => {
					await context.dispatch("counterList", data.accountId);
					return response.data;
				})
				.catch(e => {
					throw e;
				});
		}
	}
};
