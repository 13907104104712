<template>
	<div
		class="app-card"
		:class="{
			[classCss]: classCss,
			'': ''
		}"
	>
		<div class="app-card__left-block">
			<slot name="left-block"></slot>
		</div>
		<div class="app-card__wrapper">
			<div class="app-card__header">
				<slot name="header"> </slot>
			</div>

			<div class="app-card__content">
				<slot name="body"> </slot>
			</div>

			<div class="app-card__image">
				<slot name="image"> </slot>
			</div>

			<div class="app-card__footer">
				<slot name="footer"> </slot>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	data() {
		return {};
	},
	props: {
		type: {
			type: String,
			default: "border"
		},
		classCss: {
			type: String,
			default: ""
		},
		secondary: {
			type: Boolean,
			default: false
		}
	}
};
</script>
