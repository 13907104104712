<template>
	<AppGlobalLayout :loading="loading" :error="error" @reload="getRequestById">
		<div slot="body">
			<div class="row">
				<div class="col-xl-9 col-lg-12">
					<AppCard>
						<template #body>
							<div class="card-content__content">
								<div class="row">
									<div class="col-12 col-md-12">
										<h3
											class="medium mb-3"
											:style="getTheme === 'dark-theme' ? 'color: #FEFDFF' : ''"
										>
											Заявка № {{ detail.requestNumber }}
										</h3>

										<div class="request-detail row">
											<div class="col-12 col-md-6">
												<div class="text-row">
													<span class="text-row__title"> Дата: </span>
													<span class="text-row__text">
														{{ dateReturnFormat(detail.createdDate) }}
													</span>
												</div>

												<div class="text-row">
													<span class="text-row__title"> Организация: </span>
													<span class="text-row__text">
														{{ detail.organizationName }}
													</span>
												</div>

												<div class="text-row">
													<span class="text-row__title"> Тема: </span>
													<span class="text-row__text">
														{{ detail.subject }}
													</span>
												</div>
												<div class="text-row">
													<span class="text-row__title"> Статус: </span>
													<span class="text-row__text">
														{{ getStatus(detail.status) }}
													</span>
												</div>

												<div class="text-row">
													<span class="text-row__title"> Адрес: </span>
													<span class="text-row__text">
														{{ detail.address }}
													</span>
												</div>
												<div class="text-row">
													<span class="text-row__title"> ЛС / Договор: </span>
													<span class="text-row__text">
														{{ detail.accountName }}
													</span>
												</div>
												<div
													class="text-row"
													v-if="detail.attachments && detail.attachments.length"
												>
													<span class="text-row__title">
														Прикрепленные файлы:
													</span>
													<span class="text-row__text">
														<a
															:href="item.url"
															v-for="item in detail.attachments"
															target="_blank"
														>
															{{ item.fileName }}
														</a>
													</span>
												</div>
											</div>

											<div class="col-12 col-md-6">
												<div class="text-row">
													<div class="text-row__title">Ваше сообщение:</div>
													<div
														class="mt-1 medium"
														:style="
															getTheme === 'dark-theme' ? 'color: #CDCDD6' : ''
														"
													>
														{{ detail.message }}
													</div>
												</div>
											</div>

											<div v-if="detail.status === 7" class="text-row mt-3 pl-2">
												<div
													class="request-detail__str--sub"
													:style="
														getTheme === 'dark-theme' ? 'color: #CDCDD6' : ''
													"
												>
													Сообщение было отправлено на электронную почту вашей
													организации. Ожидайте ответа на почту или телефон.
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</template>
					</AppCard>
				</div>
			</div>

			<Chat
				v-if="
					getRequestByIdInfo.data.requestMessages.length > 0 ||
						getRequestByIdInfo.data.canSendComments
				"
			/>
		</div>
	</AppGlobalLayout>
</template>

<script>
import { dateReturnFormat } from "@/plugins/methods";
import { getStatus } from "./methods/status";
import Chat from "./components/chat";
import { mapGetters } from "vuex";

export default {
	data() {
		return {
			loading: false,
			error: false,
			detail: {
				subject: "",
				status: 0,
				requestNumber: 0,
				createdDate: new Date(),
				address: "",
				accountName: "",
				organizationName: "",
				requestMessages: []
			}
		};
	},
	computed: {
		...mapGetters(["getRequestByIdInfo", "getTheme"])
	},
	mounted() {
		this.getRequestById();
	},
	methods: {
		getStatus(status) {
			return getStatus(status);
		},
		async getRequestById() {
			this.loading = true;
			try {
				let query = await this.$store.dispatch(
					"getRequestById",
					this.$route.params.id
				);
				this.detail = { ...query };
			} catch (e) {
				this.error = true;
				this.$errorsMod(e.response);
			}
			this.loading = false;
		},
		dateReturnFormat(date) {
			return dateReturnFormat(date, true);
		}
	},
	components: {
		Chat
	}
};
</script>
