<template>
	<div
		class="insunace-pediod insunace-pediod--orange"
		v-if="dateStart && dateEnd"
	>
		Страховка с {{ dateStart }} по {{ dateEnd }}
	</div>
	<div class="insunace-pediod insunace-pediod--green" v-else-if="dateEnd">
		Страховка до {{ dateEnd }}
	</div>
</template>

<script>
import { dateReturnFormat } from "@/plugins/methods";
import { mapGetters } from 'vuex'

export default {
	props: {
		index: {
			type: Number
		}
	},
	computed: {
		...mapGetters(['getInsurance']),
		currentInsurance() {
			return this.getInsurance.history[this.index];
		},
		dateStart() {
			return this.currentInsurance ? dateReturnFormat(this.currentInsurance.startAt) : '';
		},
		dateEnd() {
			return this.currentInsurance ? dateReturnFormat(this.currentInsurance.endAt) : '';
		},
	},
};
</script>

<style scoped>
.insunace-pediod {
	display: inline-block;
	padding: 5px 12px;

	white-space: nowrap;
	font-size: 12px;
	font-weight: 400;
	border-radius: 16px;
	color: #fff;
	background: #707070;
}
.insunace-pediod--green {
	background: #33BC7D;
}
.insunace-pediod--orange {
	background: #EB982C;
}
</style>
