var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"d-flex card-account__box",staticStyle:{"flex-wrap":"wrap"}},[_vm._l((_vm.info),function(item,index){return [_c('AppCardAccount',{key:index,staticClass:"animate__animated animate__fadeIn",class:'animate__delay-' + 6 * index,attrs:{"info":item},on:{"click":function($event){return _vm.routerLink(item)}}})]}),_c('div',{staticClass:"card-account__root card-account__root--add"},[_c('AppCard',{staticClass:"animate__animated animate__fadeIn",class:'animate__delay-' + 6 * _vm.info.length,scopedSlots:_vm._u([{key:"body",fn:function(){return [_c('div',{staticClass:"card-account card-account--add"},[_c('div',{staticClass:"card-account__card-add",on:{"click":function($event){return _vm.$router.push({
								name: 'accountAdd',
								params: {
									text: 'Добавление помещения',
								},
							})}}},[_c('i',{staticClass:"fas fa-plus"})]),_c('div',{staticClass:"card-account__footer card-account__footer--add"},[_c('AppBtn',{nativeOn:{"click":function($event){return _vm.$router.push({
									name: 'accountAdd',
									params: {
										text: 'Добавление помещения',
									},
								})}}},[_vm._v(" Добавить помещение ")])],1)])]},proxy:true}])})],1)],2)}
var staticRenderFns = []

export { render, staticRenderFns }