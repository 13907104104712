<template>
	<div class="d-flex card-account__box" style="flex-wrap: wrap">
		<template v-for="(item, index) in info">
			<AppCardAccount
				class="animate__animated animate__fadeIn"
				:class="'animate__delay-' + 6 * index"
				:key="index"
				:info="item"
				@click="routerLink(item)"
			/>
		</template>

		<div class="card-account__root card-account__root--add">
			<AppCard
				class="animate__animated animate__fadeIn"
				:class="'animate__delay-' + 6 * info.length"
			>
				<template #body>
					<div class="card-account card-account--add">
						<div
							class="card-account__card-add"
							@click="
								$router.push({
									name: 'accountAdd',
									params: {
										text: 'Добавление помещения',
									},
								})
							"
						>
							<i class="fas fa-plus"></i>
						</div>
						<div class="card-account__footer card-account__footer--add">
							<AppBtn
								@click.native="
									$router.push({
										name: 'accountAdd',
										params: {
											text: 'Добавление помещения',
										},
									})
								"
							>
								Добавить помещение
							</AppBtn>
						</div>
					</div>
				</template>
			</AppCard>
		</div>
	</div>
</template>

<script>
	import AppCardAddAccount from "@/components/components-other/app-card-add-account";
	import AppCardAccount from "@/components/components-other/app-card-account";

	export default {
		data() {
			return {};
		},
		props: {
			info: {},
		},
		mounted() {},
		methods: {
			routerLink(item) {
				this.$router.push({
					name: "account-flat",
					params: { id: item.id },
				});
			},
		},
		components: {
			AppCardAddAccount,
			AppCardAccount,
		},
	};
</script>
