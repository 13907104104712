<template>
	<AppModals :maxWidth="700" :width="700" name="insurance-info" hideFooter :title="title" @before-close="beforeClose">
		<template v-slot:content>
			<div class="loading-wrapper" v-if="getDetailLoading">
				<AppLoading :height="90" />
			</div>
			<InsuranceInfo v-else />
		</template>
	</AppModals>
</template>

<script>
import InsuranceInfo from "../insurance-info.vue";
import { mapGetters } from "vuex"
export default {
	components: {
		InsuranceInfo
	},
	data() {
		return {
			title: null
		};
	},
	methods: {
		beforeClose() {
			this.$store.commit('clearInsuranceDetail')
		}
	},
	computed: {
		...mapGetters(['getDetailLoading'])
	}
};
</script>

<style scoped>
.loading-wrapper {
	display: flex;
	justify-content: center;
	align-items: center;
	height: 400px;
}
</style>
