<template>
	<AppGlobalLayout
		:loading="loadingPage"
		:error="error"
		@reload="loadCardsInfo"
	>
		<div slot="body" style="margin-top: 20px">
			<RemoveCardModal />
			<div class="app-table__empty" v-if="getProfileCards.length === 0">
				<div class="app-table__empty-text">Карты отсутствуют</div>
				<div class="mt-2">
					<img
						v-if="getTheme === 'dark-theme'"
						src="/img/dark-theme/not-lists-dark.svg"
						width="255px"
					/>
					<img v-else src="/img/no-content.svg" width="255px" />
				</div>
			</div>

			<div class="cards-wrapper">
				<div style="margin-bottom: 10px" class="cards-block" v-for="item in getProfileCards" :key="item.id">
					<NewCardComponent
						:expDate="item.expDate"
						:id="item.id"
						:logo="item.logo"
						:pan="item.pan"
					/>
				</div>
			</div>
		</div>
	</AppGlobalLayout>
</template>

<script>
import { mapGetters } from "vuex";
import AppCardProductCat from "@/components/components-other/app-card-supplier-cat";
import CardComponent from "./card-component.vue";
import RemoveCardModal from "./remove-card-modal.vue";
import NewCardComponent from "./new-card-component";

export default {
	data() {
		return {
			loading: false,
			loadingPage: false,
			error: false,
		};
	},
	mounted() {
		this.loadCardsInfo();
	},
	computed: {
		...mapGetters(["getProfileCards", "getTheme"]),
	},
	methods: {
		// async loadCards() {
		// 	await this.$store.dispatch("getCards");
		// },

		async loadCardsInfo() {
			this.loadingPage = true;
			this.error = false;
			try {
				await this.$store.dispatch("getCards");
			} catch (e) {
				if (e.response) {
					this.$errorsMod(e.response);
				} else {
					this.error = true;
					this.$errorsMod(e);
				}
			}
			this.loadingPage = false;
		},
	},
	components: {
		NewCardComponent,
		AppCardProductCat,
		CardComponent,
		RemoveCardModal,
	},
	watch: {},
	validations: {},
};
</script>

<style scoped>
.cards-wrapper {
	display: flex;
	flex-direction: column;
}
.cards {
	text-align: right;
}
</style>
