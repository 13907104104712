<template>
	<div class="cart-count">
		<!-- <div class="cart-count__button" @click="decrement"> - </div> -->
		<AppBtn @click.native="decrement" icon text>
			<AppIcon name="minus" size="1rem" />
		</AppBtn>
		<input
			maxlength="2"
			class="cart-count__input"
			:value="value"
			@input="onInput($event.target.value)"
			oninput="this.value = (()=>{
	                    if(this.value == 0) return 1
	                    if(/\D+/g.test(this.value)) return 1
	                    if(!this.value) return '1'
	                    return this.value.replace(/\D+/g, '')
	            })()"
		/>
		<!--  <div class="cart-count__value">{{ value }}</div> -->
		<AppBtn @click.native="increment" icon text :style="value < maxValue ?  '' : 'visibility: hidden'">
			<AppIcon name="plus" size="1rem" />
		</AppBtn>
		<!-- <div class="cart-count__button" @click="increment"> + </div> -->
	</div>
</template>

<script>
export default {
	data() {
		return {};
	},
	props: {
		maxValue: {
			default: 99,
			type: String | Number
		},
		value: {
			default: "0",
			type: String | Number
		},
		index: {
			default: 0,
			type: Number
		},
		fullItem: {
			default: () => {}
		}
	},
	methods: {
		increment() {
			if (this.value < this.maxValue) {
				let temp = Number(this.value) + 1;
				this.$emit("input", {
					val: temp,
					index: this.index,
					item: this.fullItem
				});
			}
		},

		decrement() {
			if (this.value > 0) {
				let temp = Number(this.value) - 1;
				this.$emit("input", {
					val: temp,
					index: this.index,
					item: this.fullItem
				});
			} else {
				this.$emit("remove");
			}
		},
		onInput(e) {
			let temp = Number(this.value);
			if (temp > -1 && temp < this.maxValue) {
				this.$emit("input", {
					val: e,
					index: this.index,
					item: this.fullItem
				});
			} else {
				this.$emit("input", {
					val: this.value + 1,
					index: this.index,
					item: this.fullItem
				});
			}
		}
	}
};
</script>
