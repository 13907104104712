export default {
	state: {
		tokenValid: false
	},
	getters: {
		getTokenValid (state) {
			return state.tokenValid
		}
	},
	actions: {

	}
}
