<template>
	<AppGlobalLayout
		:error="getCategoryList.error"
		@reload="loadCategories"
		:loading="getCategoryList.loading"
	>
		<div slot="body">
			<div class="suppliers__list mt-5">
				<div
					class="card-supplier-sub-cat__card"
					v-for="(item, index) in getCategoryList.data"
					:key="index"
				>
					<AppCardProductSubCat
						:title="item.name"
						:image="`https://квартплата.онлайн/files/static/${item.image}`"
						:fullInfo="item"
						:redirect="{
							name: `price-list`,
							params: { id: item.id },
							query: $route.query
						}"
					/>
				</div>
			</div>
		</div>
	</AppGlobalLayout>
</template>

<script>
import { mapGetters } from "vuex";
import AppCardProductSubCat from "@/components/components-other/app-card-supplier-sub-cat";
export default {
	data() {
		return {};
	},
	mounted() {
		this.loadCategories();
	},
	computed: {
		...mapGetters(["getCategoryList", "getActiveTown"]),
		apartmentId() {
			return this.$route.query.apartmentId;
		}
	},
	methods: {
		async loadCategories() {
			try {
				if (!this.apartmentId) {
					await this.$store.dispatch("shopCategoryList", this.$route.params.id);
				} else {
					await this.$store.dispatch("fetchApartmentCategores", {
						id: this.$route.params.id,
						apartmentId: this.apartmentId
					});
				}
			} catch (e) {
				if (e.response) {
					this.$errorsMod(e.response);
				} else {
					this.$errorsMod(e.response);
				}
			}
		}
	},
	components: {
		AppCardProductSubCat
	},
	watch: {
		getActiveTown() {
			this.loadCategories();
		}
	}
};

const TESTDATA2 = [
	{
		id: Symbol(1),
		image:
			"https://avatars.mds.yandex.net/get-marketcms/1534436/img-d6eece0e-3a73-406f-9c26-825ad40b965e.jpeg/optimize",
		name: "Поддерживающая уборка"
	},
	{
		id: Symbol(1),
		image:
			"https://crystalgp.ru/wp-content/uploads/2018/12/generalnaya-uborka.jpg",
		name: "Генеральная уборка"
	},
	{
		id: Symbol(1),
		image:
			"https://mactailor.ru/upload/iblock/612/612c753b7a948c1776562465dc202978.jpg",
		name: "Уборка после ремонта"
	},
	{
		id: Symbol(1),
		image:
			"https://total-cleaning.ru/uploads/images/total_cleaning_191107_chr_full.jpg",
		name: "Мойка окон"
	},
	{
		id: Symbol(1),
		image:
			"https://lab-clean.ru/wp-content/uploads/2019/02/shutterstock_1097662844-min-1160x773.jpg",
		name: "Химчистка"
	},
	{
		id: Symbol(1),
		image:
			"https://crystalgp.ru/wp-content/uploads/2018/12/uborka-prilegayushchej-territorii.jpg",
		name: "Уборка придомовой территории"
	},
	{
		id: Symbol(1),
		image: "https://picsum.photos/700/400",
		name: "Уборка офисных помещений"
	}
];
</script>
