<template>
    <button class="app-list__item">
        <span>{{ name }}</span>
    </button>
</template>

<script>
export default {
    data() {
        return {

        }
    },
    props: {
        name: {
            type: String,
            default: ''
        }
    }
}
</script>