<template>
    <AppModals
        name="delete-card"
        title="Подтвердите действие"
        @confirm="deleteCard"
        confirmButtonText="Удалить"
        colorConfirm="danger"
        @beforeOpen="beforeOpen"
    >
        <template  v-slot:content="{}">
            <div>
                <div style="text-align: left; color: #777776" class="mt-3 mb-3">
                    Вы уверены, что хотите удалить карту?
                </div>
            </div>
        </template>

    </AppModals>
</template>

<script>

export default {
    data() {
        return {
            id: ''
        }
    },
    methods: {
       async deleteCard() {
            await this.$store.dispatch("cardRemove", this.id);
            const id = JSON.parse(localStorage.getItem("card-id"));
            if (id.id === this.id) localStorage.setItem("card-id", JSON.stringify({ pan: "", id: "" }));
            this.$modal.hide('delete-card')
        },

        async beforeOpen (event) {
        	this.id = event.id

		},
    }
    
}
</script>