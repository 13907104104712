var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"insurance-state",class:{
		'insurance-state_active': _vm.activeStatus === 3,
		'insurance-state_completed': _vm.activeStatus === 4,
		'insurance-state_future':
			_vm.activeStatus === 0 ||
			_vm.activeStatus === 1 ||
			_vm.activeStatus === 2 ||
			_vm.activeStatus === 5 ||
			_vm.activeStatus === 6 ||
			_vm.activeStatus === 7,
		'insurance-state-icon': _vm.type === 'icon',
		'insurance-state-string': _vm.type === 'string'
	}},[(_vm.type === 'icon')?_c('svg',{attrs:{"xmlns":"http://www.w3.org/2000/svg","width":"18.793","height":"24.302","viewBox":"0 0 18.793 24.302"}},[_c('g',{attrs:{"id":"Group_63560","data-name":"Group 63560","transform":"translate(0.5 0.533)"}},[_c('path',{attrs:{"id":"Path_123658","data-name":"Path 123658","d":"M16.143,2.669,8.9,0,1.72,2.633C.8,3.077,0,3.343,0,4.218V8.577a16.791,16.791,0,0,0,2.364,8.674,14.85,14.85,0,0,0,6.6,5.981c2.933-.977,5.123-3.583,6.462-5.811a16.791,16.791,0,0,0,2.364-8.675V4.827a2.046,2.046,0,0,0-1.649-2.158","transform":"translate(0 0)","fill":"none","stroke":"#fff","stroke-width":"1"}}),(_vm.activeStatus === 3 || _vm.activeStatus === 4)?_c('path',{attrs:{"id":"Path_123659","data-name":"Path 123659","d":"M3.946,8.491.171,4.717a.581.581,0,0,1,0-.821l0,0L1,3.07a.581.581,0,0,1,.821,0l0,0L4.355,5.617,9.8.171a.58.58,0,0,1,.821,0l0,0,.823.823a.58.58,0,0,1,0,.821l0,0L4.767,8.491a.581.581,0,0,1-.821,0l0,0Z","transform":"translate(3.279 7.647)","fill":"#fff"}}):_vm._e(),(
					_vm.activeStatus === 0 ||
					_vm.activeStatus === 1 ||
					_vm.activeStatus === 2 ||
					_vm.activeStatus === 5 ||
					_vm.activeStatus === 6 ||
					_vm.activeStatus === 7
				)?_c('path',{attrs:{"id":"Subtraction_29","data-name":"Subtraction 29","d":"M11.787,14.094a.9.9,0,0,1-.643-.266l-2.57-2.57L6,13.828a.909.909,0,1,1-1.285-1.285l2.57-2.57L4.719,7.4A.908.908,0,1,1,6,6.116l2.57,2.57,2.57-2.57A.908.908,0,1,1,12.43,7.4L9.86,9.972l2.57,2.57a.909.909,0,0,1-.643,1.551Z","transform":"translate(0.5 1.1)","fill":"#fff"}}):_vm._e()])]):_vm._e(),(_vm.type === 'string')?_c('span',[(_vm.activeStatus === 3)?[_vm._v("Активная страховка")]:(_vm.activeStatus === 4)?[_vm._v("Завершенная страховка")]:(_vm.activeStatus === 5)?[_vm._v("Будущая страховка")]:[_vm._v("Нет страховки")]],2):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }