import { router } from '@/router'


export default {
	install(Vue) {
		Vue.prototype.$errorsMod = function (methodOptions) {

			if (process.env.NODE_ENV !== 'production') {
				try {
					console.log('error development', methodOptions)
					console.log('error data', methodOptions.data)
					console.log('error development array', Array.isArray(methodOptions.data))
				} catch (e) {
					console.log(e)
				}

			}

			if (methodOptions === undefined) {
				this.$notify({
					group: 'foo',
					type: 'error',
					title: 'Произошла ошибка.',
					text: `Обратитесь в техподдержку`,
					position: 'right',
					speed: 1000,
				})
			} else {
				if (methodOptions.status === 400) {


					if (Array.isArray(methodOptions.data)) {
						for (let i = 0; i < methodOptions.data.length; i++) {
							setTimeout(() => {
								this.$notify({
									group: 'foo',
									type: 'error',
									title: 'Произошла ошибка',
									text: methodOptions.data[i].message,
									position: 'right',
									speed: 1000
								})
							}, 200 * (i + 1))


							if (methodOptions.data[i].code === 'AccountNotConfirmed') {
								router.push('/account/')
							}
						}




					} else {
						this.$notify({
							group: 'foo',
							type: 'error',
							title: 'Произошла ошибка',
							text: `Что-то пошло не так, обратитесь в техподдержку`,
							position: 'right',
							speed: 1000
						})
					}



				} else if (methodOptions.status === 401) {
					window.$cookies.remove('time_token_die', '/', process.env.VUE_APP_COOCKIE)
					window.location.replace(process.env.VUE_APP_REDIRECT)
				} else if (methodOptions.status === 403) {
					this.$notify({
						group: 'foo',
						type: 'error',
						title: 'Произошла ошибка',
						text: `Запрещено`,
						position: 'right',
						speed: 1000
					})
				} else if (methodOptions.status === 404) {
					if (Array.isArray(methodOptions.data)) {
						for (let i = 0; i < methodOptions.data.length; i++) {
							setTimeout(() => {
								this.$notify({
									group: 'foo',
									type: 'error',
									title: 'Произошла ошибка',
									text: methodOptions.data[i].message,
									position: 'right',
									speed: 1000
								})
							}, 200 * (i + 1))


							if (methodOptions.data[i].code === 'AccountNotConfirmed') {
								router.push('/account/')
							}
						}




					} else {
						this.$notify({
							group: 'foo',
							type: 'error',
							title: 'Произошла ошибка',
							text: `Что-то пошло не так, обратитесь в техподдержку`,
							position: 'right',
							speed: 1000
						})
					}
				} else {
					this.$notify({
						group: 'foo',
						type: 'error',
						title: 'Произошла ошибка',
						text: `Что-то пошло не так, обратитесь в техподдержку`,
						position: 'right',
						speed: 1000,
					})
				}
			}
		}
	}
}
