<template>
	<div class="card-selection" @click="">
		<img
			class="card-selection__img"
			v-if="getTheme !== 'dark-theme'"
			src="/img/mini-bank-card.svg"
			alt=""
		/>
		<img
			class="card-selection__img"
			v-else
			src="/img/dark-theme/mini-bank-card.svg"
			alt=""
		/>

		Банковская карта
		<div class="card-selection__icon">
			<AppIcon name="sort-down" size="16px" styleCss="color:#4A4A49" />
		</div>
	</div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
	computed: {
		...mapGetters(["getTheme"]),
	}
};
</script>

<style lang="scss" scoped>
.card-selection {
	display: flex;
	align-items: center;
	cursor: pointer;

	&__img {
		display: flex;
		align-items: center;
		margin-right: 7px;
		user-select: none;
	}
	&__icon {
		position: relative;
		bottom: 1px;
		margin-left: 10px;
	}
}
</style>
