<template>
    <AppCard class="card-supplier-brand">
        <template #body>
           <div class="card-supplier-brand__body">
                <div
                    class="card-supplier-brand__card"

                >
                    <div class="card-supplier-brand__image"
                        @click="clickToCardBrand"
                        @keyup.space="clickToCardBrand"
                        @keyup.enter="clickToCardBrand"
                        tabindex="0"
                    >
                        <img :src="image" />
                    </div>
                    <div class="card-supplier-brand__info">
                        <AppBtn icon text @click.native="openModal">
                            <AppIcon name="info-circle" size="1.25rem" style="color: var(--color-primary-three)"  />
                        </AppBtn>
                    </div>
                </div>
                <div class="card-supplier-brand__description">
                    <div class="card-supplier-brand__title">
                        {{ title }}
                    </div>

                    <AppBtn icon text classes="card-supplier-brand__add-card">
                        <AppIcon name="chevron-right" size="1rem" style="color: #A5A5A4" />
                    </AppBtn>
                </div>

                <!-- <div class="card-supplier-brand__actions">
                    
                </div> -->
           </div>
        </template>
    </AppCard>
</template>

<script>
export default {
    data() {
        return {

        }
    },
    props: {
        title: {
            type: String,
            default: ''
        },
        image: {
            type: String,
            default: ''
        },
        fullInfo: {
            type: Object,
            default: () => {}
        },
    },
    methods: {
        clickToCardBrand() {
            this.$router.push({ path:`/shop/info/${this.fullInfo.id}`, query: {serviceId: this.$route.params.id} })
            //this.$emit('clickCard', this.fullInfo.id)
        },
        openModal() {
            this.$modal.show('company-info', {...this.fullInfo})
        }
    }
}
</script>