<template>
	<div class="app-input">
		<span class="app-input__icon" v-if="iconLeft">
			<AppIcon :name="iconLeft" />
		</span>
		<label v-if="label" class="app-input__label" :id="id">{{ label }}</label>

		<input
			v-focus="focus"
			class="app-input__input"
			:class="{
				'app-input__error-inp': error,
				'app-input__input--white': white,
				'app-input__input--big': big,
			}"
			:type="type"
			:placeholder="placeholder"
			:id="id"
			:disabled="disabled"
			@input="changeInput($event.target.value)"
			:value="value"
			:style="prepend ? 'padding-right: 30px' : null"
		/>
		<span class="app-input__prepend" v-if="prepend">
			<slot name="prepend"> </slot>
		</span>

		<span class="app-input__error shake" v-show="error">
			<slot name="email" v-if="emailError && requiredError"></slot>
			<slot name="required" v-if="!requiredError"></slot>
			<slot name="sameAsPassword" v-if="!sameAsPassword"></slot>
			<slot name="minLength" v-else-if="!minLength"></slot>
			<slot name="maxLength" v-if="!maxLengthError"></slot>
		</span>
	</div>
</template>

<script>
export default {
	data() {
		return {
			prepend: false,
		};
	},
	props: {
		value: {
			type: String,
			default: "",
		},
		grey: {
			type: Boolean,
			default: true,
		},
		white: {
			type: Boolean,
			default: false,
		},
		focus: {
			type: Boolean,
			default: false,
		},
		transparent: {
			type: Boolean,
			default: false,
		},
		disabled: {
			type: Boolean,
			default: false,
		},
		type: {
			type: String,
			default: "text",
		},
		placeholder: {
			type: String,
			default: "",
		},
		label: {
			type: String,
			default: "",
		},
		iconLeft: {
			type: String,
			default: "",
		},
		id: {
			default: "",
			type: String,
		},
		error: {
			default: false,
			type: Boolean,
		},
		big: {
			default: false,
			type: Boolean,
		},
		minLength: {
			default: false,
			type: Boolean,
		},
		maxLength: {
			default: false,
			type: Boolean,
		},
		maxLengthError: {
			default: false,
			type: Boolean,
		},
		required: {
			default: false,
			type: Boolean,
		},
        emailError: {
			default: false,
			type: Boolean,
		},
		requiredError: {
			default: false,
			type: Boolean,
		},
		sameAsPassword: {
			default: false,
			type: Boolean,
		},
	},
	mounted() {
		//console.log('TEST slot', this.$slots)

		this.prepend = this.$slots.hasOwnProperty("prepend");
	},
	methods: {
		changeInput(val) {
			this.$emit("input", val);
		},
	},
};
</script>

<style lang="sass">
@import "./input"
</style>
