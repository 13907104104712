<template>
	<AppGlobalLayout :loading="getPaymentList.loading" :error="error" @reload="loadPayments">
		<div slot="body">
			<AppCard classCss="main-banner-card mb-3" :style="[getTheme === 'dark-theme' ? darkStyle : defaultStyle]">
				<template #body>
					<div class="main-banner">
						<div class="main-banner__title">
							Оплата всей задолженности одним платежом
						</div>
						<div class="main-banner__description">
							Если у Вас несколько лицевых счетов, воспользуйтесь кнопкой ниже.
							<br />Так быстрее и удобнее.
						</div>

						<AppBtn @click.native="
							$router.push({
								name: 'paymentPage',
								params: { allPayment: true },
							})
						" classCss="mb-2">
							Оплатить всё
						</AppBtn>
					</div>
				</template>
			</AppCard>

			<AppTable ref="table" :isObserve="true" @intersect="loadPayments(paginPage)" :header="headerTable"
				:data="getPaymentList.data" messageNoData="История оплат отсутствует" hoverHide :emptyImage="getTheme === 'dark-theme'
					? '/img/payments-empty.svg'
					: '/img/dark-theme/'
				" emptyText="История оплат отсутствует">
				<template v-slot:thead="{ item }">
					<span>{{ item.name }}</span>
				</template>

				<template v-bind:index="index" v-slot:tbody="{ item }">
					<td>
						<span class="app-table__title-mob">Дата и время:</span>
						{{ dateReturnFormat(item.paymentDate) }}
					</td>
					<td>
						<span class="app-table__title-mob">Дата и время:</span>
						{{ item.address }}
					</td>
					<td>
						<span class="app-table__title-mob">ЛС / Договор:</span>
						{{ item.accountNumber }}
					</td>
					<td>
						<span class="app-table__title-mob">Сумма:</span>
						{{ $digitNumber(item.sum.toFixed(2)) }}
					</td>
					<td>
						<span class="app-table__title-mob">Статус:</span>
						{{ item.state }}
					</td>
					<td>
						<ul>
							<li v-if="item.state == 'Оплачен'">
								<a type="application/pdf" target="_blank" title="Чек" alt="Чек" v-if="!item.loading"
									:href="`${url}/Payment/getCheck/${item.paymentId}`">
									<AppIcon name="receipt" size="1.25rem" />
								</a>
							</li>
						</ul>
					</td>
				</template>
			</AppTable>
		</div>
	</AppGlobalLayout>
</template>

<script>
import { mapGetters } from "vuex";
import { dateReturnFormat } from "@/plugins/methods";
import VueAdBlockDetect from "vue-adblock-detect";

export default {
	mixins: [VueAdBlockDetect],
	data() {
		return {
			darkStyle: {
				backgroundImage:
					"url('/img/dark-theme/background-app-money-dark-1a.png') !important",
			},
			defaultStyle: {
				backgroundImage: "url('/img/pay-fon.png') !important",
			},
			paginPage: 1,
			index: 0,
			error: false,
			url: process.env.VUE_APP_URL,
			fleshLoading: false,
			blockedDetekted: true,
			headerTable: [
				{
					name: "Дата и время",
					with: "17%",
				},
				{
					name: "Потребитель",
					with: "26%",
				},
				{
					name: "ЛС / Договор",
					with: "19%",
				},
				{
					name: "Сумма",
					with: "16%",
				},
				{
					name: "Статус",
					with: "16%",
				},
				{
					name: "",
					with: "6%",
				},
			],
		};
	},
	mounted() {
		this.loadPayments(this.paginPage);
	},
	methods: {
		dateReturnFormat(date) {
			return dateReturnFormat(date, true);
		},
		show(modalName, all) {
			if (all) {
				this.$modal.show(modalName, { allPayment: true });
			} else {
				this.$modal.show(modalName);
			}
		},
		async loadPayments(page) {
			this.error = false;
			try {
				const result = await this.$store.dispatch("paymentList", page);
				this.paginPage ++
				if (result && Array.isArray(result) && result.length === 0) {
					this.$refs.table.unobserveIntersecting()
				}
			} catch (e) {
				this.$refs.table.unobserveIntersecting()
				if (e.response) {
					this.$errorsMod(e.response);
				} else {
					this.error = true;
					this.$errorsMod(e);
				}
			}
		},
	},
	computed: {
		...mapGetters(["getPaymentList", "getTheme"]),
	},
	components: {},
};
</script>

<style>
.windows8 .wBall {
	padding: 0 !important;
	border-right: none !important;
}

.windows8 .wBall .wInnerBall {
	padding: 0 !important;
	border-right: none !important;
}

.windows8 {
	padding: 0 !important;
	border-right: none !important;
}

.block-alert {
	margin: 20px 0;
}
</style>
