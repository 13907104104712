<template>
	<div class="card-account__root">
		<AppCard>
			<template #body>
				<div class="card-account">
					<div class="card-account__card">
						<div class="card-account__address" @click.prevent="$emit('click')">
							{{ info.description || info.address }}
						</div>
						<div class="card-account__menu">
							<AppDropdown>
								<template #activator="{ showChange }">
									<AppBtn icon text @click.native="showChange">
										<AppIcon
											name="ellipsis-v"
											size="15px"
											style="color: #777776"
										/>
									</AppBtn>
								</template>

								<div slot="dropdown">
									<AppList :listData="listData">
										<template #list-item>
											<template v-for="(menu, index) in listData">
												<AppListItem
													:name="menu.name"
													@click.native="clickMenuItem(menu.id)"
													:key="index"
												/>
											</template>
										</template>
									</AppList>
								</div>
							</AppDropdown>
						</div>

						<div class="card-account__price">
							<div class="card-account__sub">
								Баланс
							</div>
							<div
								class="card-account__debt"
								:class="{
									'success-color': info.debt <= 0,
									'danger-color': info.debt > 0
								}"
							>
								{{ $digitNumber(info.debt) }} ₽
							</div>
						</div>
						<div
							class="card-account__mask"
							@click.prevent="$emit('click')"
						></div>
					</div>

					<div class="card-account__footer">
						<div class="d-flex">
							<AppBtn
								styleCSS="margin-right: 8px"
								@click="
									$router.push({
										name: 'paymentPage',
										params: { apartmentId: info.id }
									})
								"
							>
								Оплатить
							</AppBtn>

							<AppBtn
								v-if="info.mustConfirm"
								color="danger"
								outlined
								@click="$modal.show('adress-confirm', { id: info.id })"
							>
								Подтвердить
							</AppBtn>
						</div>
						<AppBtn
							icon
							text
							classes="card-supplier-cat__add-card"
							@click.native="$emit('click')"
						>
							<AppIcon
								name="chevron-right"
								size="20px"
								style="color: #A5A5A4"
							/>
						</AppBtn>
					</div>
					<div
						v-if="info.gazType !== 0"
						style="margin-top: 1.25em; cursor: pointer;"
					>
						<TechnicalServiceCard
							:gazType="info.gazType"
							:unitId="info.unitId"
						/>
					</div>
				</div>
			</template>
		</AppCard>
	</div>
</template>

<script>
import TechnicalServiceCard from "@/components/components-other/technical-service-card/index";
export default {
	components: {
		TechnicalServiceCard
	},
	data() {
		return {
			visible: false,
			listData: [
				{
					id: 0,
					name: "Переименовать"
				},
				{
					id: 1,
					name: "Удалить"
				}
			]
		};
	},
	props: {
		info: {
			type: Object,
			default: () => {}
		}
	},
	methods: {
		show(modalName, accountId) {
			this.$modal.show(modalName, { allPayment: false, accountId: accountId });
		},

		changeShow(show) {
			//console.log('show')
			this.visible = show;
		},
		clickMenuItem(id) {
			if (id == 0) {
				this.$modal.show("apartment-rename", { ...this.info });
			} else if (id == 1) {
				this.$modal.show("apartment-remove", {
					name: this.info.description || this.info.address,
					id: this.info.id
				});
			}
		}
	}
};
</script>
