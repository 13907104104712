<template>
	<AppModals
		name="comment-modal"
		title="Оставить комментарий"
		cancelButtonText="Отменить"
		:clickToClose="false"
		:maxWidth="400"
		@beforeOpen="beforeOpen"
		:width="400"
		confirmButtonText="Отправить"
		@confirm="send"
		class="rating"
	>
		<template #content="{}">
			<div class="mb-3">
				<AppTextarea 
					type="text" 
					label="Ваш комментарий:" 
					cols="45" 
					rows="6"
					required
					v-model.trim="$v.form.comment.$model"
					:error="$v.form.comment.$error"
					:requiredError="!$v.form.comment.$error"
				>
					<div slot="required">Не более 250 символов</div>
				</AppTextarea>
			</div>
		</template>
	</AppModals>
</template>

<script>
	import StarRating from "vue-star-rating";
	import { maxLength } from 'vuelidate/lib/validators'
	import { mapGetters } from "vuex";

	export default {
		data() {
			return {
				orderId: null,
				form: {
					comment: '',
				},
			};
		},
		methods: {
			beforeOpen(event) {
				this.orderId =  event.params.id;
			},
			async send() {
				this.$v.form.$touch()
            	if (!this.$v.form.$invalid) {
					let formData = {
						orderId: this.orderId,
						text: this.form.comment,
					}
					try {
						await this.$store.dispatch('sendOrderComment', formData);
						this.$modal.hide('comment-modal');
						this.$emit('close')
					} catch (e) {
						this.$errorsMod(e);
					}
				}
			}
		},
		computed: {
		...mapGetters(["getTheme"])
	},
		components: {
			StarRating,
		},
		validations(val) {
			return {
				form: {
					comment: {
						maxLength: maxLength(250)
					},
				}
			}
		}
	};
</script>

<style>
</style>