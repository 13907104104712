<template>
	<AppModals
		name="delete-item-cart"
		title="Подтвердите действие"
		@confirm="deleteItem"
		confirmButtonText="Удалить"
		colorConfirm="danger"
		@beforeOpen="openInfo"
	>
		<template v-slot:content="{}">
			<div>
				<div style="text-align: left; color: #777776" class="mt-3 mb-3">
					Вы уверены, что хотите удалить товар из корзины?
				</div>
			</div>
		</template>
	</AppModals>
</template>

<script>
import { mapMutations } from "vuex";

export default {
	data() {
		return {
			id: null,
			apartmentId: null
		};
	},
	methods: {
		...mapMutations(["removeProductFromCart"]),

		openInfo(event) {
			this.id = event.id;
			this.apartmentId = event.apartmentId;
		},

		deleteItem() {
			this.removeProductFromCart({
				id: this.id,
				apartmentId: this.apartmentId
			});
			this.$modal.hide("delete-item-cart");
		}
	}
};
</script>
