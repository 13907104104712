<template>
	<div>
		<div style="margin-top: -5px" class="mt-3--xs">
			<AppCard classCss="main-banner-card mb-5">
				<template #body>
					<div class="main-banner">
						<div class="main-banner__description"
							:style="[getTheme === 'dark-theme' ? darkStyle : defaultStyle]">
							Если лицевого счета нет в списке, значит по нему невозможно
							передать показания.
						</div>
						<div class="main-banner__description"
							:style="[getTheme === 'dark-theme' ? darkStyleDescription : defaultStyle]">
							Обычно это связано с тем, что у приборов учета истек срок поверки
							или управляющая организация не предоставила возможность передачи
							показаний.
						</div>
					</div>
				</template>
			</AppCard>

			<AppTable :header="tableHeader" :data="getAccountswithcounters.data" messageNoData="Счетчики отстутсвуют"
				:childRow="false">
				<template v-slot:thead="{ item }">
					<span>{{ item.name }}</span>
				</template>

				<template v-slot:tbody="{ item }">
					<td @click="routerLink(item)">
						<span> {{ item.description }}</span>
					</td>
					<td @click="routerLink(item)">
						<span class="app-table__title-mob">
							Адрес:
						</span>
						<span> {{ item.address }}</span>
					</td>
					<td @click="routerLink(item)">
						<span class="app-table__title-mob">
							Лицевой счет:
						</span>
						<span> {{ item.accountName }}</span>
					</td>
					<td @click="routerLink(item)">
						<div v-if="item.mustVerification" class="warning-status">
							Требуется поверка
						</div>
					</td>
					<td @click="routerLink(item)">
						<span class="app-table__title-mob">
							Поставщик:
						</span>
						<span> {{ item.organization }}</span>
					</td>
					<td>
						<div class="d-flex align-center just-end">
							<AppIcon v-if="item.hasValues" prefix="far fa-" name="check-circle" class="mr-3" size="30px"
								style="color: #33BC7D;" v-tooltip="{
									content: 'Показания переданы',
									//show: true,
									trigger: 'hover',
									placement: 'bottom'
								}" />
							<AppBtn @click="routerLink(item)">Передать показания</AppBtn>
						</div>
					</td>
				</template>
			</AppTable>
		</div>
	</div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
	data() {
		return {

			darkStyle: {
				color: "#9797A5",
				maxWidth: "500px",
			},
			darkStyleDescription: {
				color: "#9797A5",
				maxWidth: "500px",
			},
			defaultStyle: {
				maxWidth: "500px",
				color: "#000"
			},
			tableHeader: [
				{
					name: "Наименование",
					width: "10%"
				},
				{
					name: "Адрес",
					width: "30%"
				},
				{
					name: "Лицевой счет",
					width: "10%"
				},
				{
					name: "",
					// width: "10%"
				},
				{
					name: "Поставщик",
					width: "10%"
				},
				{
					name: "",
					width: "20%"
				}
			]
		};
	},
	mounted() {
		this.getLoadInfo();
	},
	computed: {
		...mapGetters(["getAccountswithcounters", "getTheme"])
	},
	methods: {
		async getLoadInfo() {
			try {
				await this.$store.dispatch("accountswithcountersGet");
			} catch (e) {
				this.$errorsMod(e.response);
			}
		},
		routerLink(item) {
			this.$router.push({ path: `/send-cnt/${item.accountId}` });
		}
	}
};
</script>
<style scoped>
.warning-status {
	border-radius: 12px;
	padding: 5px 10px;
	text-align: center;
	color: var(--color-warning);
	background: rgba(235, 152, 44, 0.1);
}
</style>