<template>

	<modal name="adress-confirm" :height="'auto'" :width="500" :adaptive="true" @before-open="beforeOpen"
		:scrollable="true">
		<div class="app-modal">

			<div v-if="getApartmentConfirm.loading" class="loading">
				<AppLoading :width="40" :height="40" color="#404370" />
			</div>
			<div v-else style="height: 250px;">
				<div class="app-modal__header">
					<div style="width: 38px"></div>
					<div class="app-modal__title">
						Подтвердите адрес
					</div>
					<div class="app-modal__close">
						<AppBtn @click.native="modalClose" icon text alt="Закрыть модальное окно"
							title="Закрыть модальное окно">
							<AppIcon name="times" size="1.25rem" />
						</AppBtn>
					</div>
				</div>

				<div class="app-modal__content">
					<div class="modal-body">
						Будьте внимательны<br />
						эту информацию нельзя отменить
					</div>

				</div>

				<div class="btns">
					<AppBtn @click="confirmAdress(true)" styleCSS="min-width: 125px" :color="'success'">
						Адрес верен
					</AppBtn>

					<AppBtn grey color="primary" outlined @click.native="confirmAdress(false)">
						Адрес неверен
					</AppBtn>

					<AppBtn grey color="primary" outlined @click.native="modalClose">
						Отмена
					</AppBtn>
				</div>

			</div>

		</div>
	</modal>

</template>

<script>
import { mapGetters } from 'vuex'

export default {
	data() {
		return {
			id: null
		}
	},


	computed: {
		...mapGetters([
			'getApartmentConfirm'
		])

	},
	methods: {
		async confirmAdress(isConfirmed) {
			try {
				await this.$store.dispatch('apartmentConfirm', {
					id: this.id,
					isConfirmed
				})
			} catch (e) {
				this.$errorsMod(e.response)
			} finally {
				this.$modal.hide('adress-confirm')
				this.$emit('refresh')
			}

		},
		modalClose() {
			this.$modal.hide('adress-confirm')
		},

		async beforeOpen(event) {
			this.id = event.params.id
		},

	},
	components: {
	}
}
</script>

<style>
.succes-mesage {
	color: #33bc7d;
	text-align: center;
	margin-bottom: 10px;
	font-size: 20px;
	width: 100%;
	font-weight: bold;
}

.modal-submit-check {
	padding: .4rem 1rem;
	background: var(--color-primary);
	border-radius: 12px;
	color: #fff;
	border: none;
	position: relative;
	cursor: pointer;
	height: 2rem;
	margin-top: 10px;
	margin-bottom: 10px;
}
</style>
<style scoped>
.modal-body {
	padding-top: .5em;
	text-align: center;
	color: var(--color-secondary-two);
}

.btns {
	padding-top: 1em;
	display: flex;
	flex-direction: column;
	gap: 1rem;
	width: 40%;
	margin: 0 auto;
}

.loading {
	height: 250px;
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
}
</style>