import $axios from '@/plugins/axios-instance'

export default {
	state: {

	},
	getters: {

	},
	mutations: {


	},

	actions: {



		getSuplierList (context, skip = 0) {
			return $axios.get(`/Suplier/list?skip=${skip}&take=15`)
			.then((response) => {
				return response.data
			}).catch((e)=>{
				throw(e)
			})
		},

		getSuplierInfo2 (context, id) {
			return $axios.get(`/Suplier/Details?suplierId=${id}`)
			.then((response) => {
				return response.data
			}).catch((e)=>{
				throw(e)
			})
		},

		getSuplierCategoryInfo (context, id) {
			return $axios.get(`/Suplier/CategoryInfo?categoryId=${id}`)
			.then((response) => {
				return response.data
			}).catch((e)=>{
				throw(e)
			})
		},

		getSuplierListOrders (context, id) {
			return $axios.get(`/Suplier/ListOrders`)
			.then((response) => {
				return response.data
			}).catch((e)=>{
				throw(e)
			})
		},

		makeOrderSuplier (context, data) {
			return $axios.post(`/Suplier/MakeOrder`, data)
			.then((response) => {
				return response.data
			}).catch((e)=>{
				throw(e)
			})
		},


	}
}


