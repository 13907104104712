<template>
	<AppGlobalLayout
		:loading="getApartment.loading"
		:error="error"
		@reload="apartmentLoad"
	>
		<div slot="body">
			<ModalPayApproved />
			<ModalRejected />
			<ApartmentRemove />
			<ApartmentRename />
			<ModalAdressConfirm @refresh="apartmentLoad()" />
			<RequestedServiceModal />
			<AppCarousel :banners="getBanners" />

			<template v-if="getApartment.data.length !== 0">
				<div class="mt-4 mb-2 title-account">
					<h3 class="medium secondary-color">Помещения</h3>
					<div style="display: flex">
						<AppBtn
							class="mr-1"
							@click.native="
								$router.push({
									name: 'accountAdd',
									params: { text: 'Добавление помещения' }
								})
							"
							v-show="getApartment.data.length > 4"
						>
							Добавить помещение
						</AppBtn>

						<AppBtn
							@click="changeVisible(true)"
							class="mr-1"
							icon
							text
							:style="`opacity: ${listShow ? '1' : '0.5'}`"
						>
							<AppIcon name="list-ul" size="20px" />
						</AppBtn>
						<AppBtn
							@click="changeVisible(false)"
							:style="`opacity: ${listShow ? '0.5' : '1'}`"
							icon
							text
						>
							<AppIcon name="th-large" size="20px" />
						</AppBtn>
					</div>
				</div>

				<Table :info="getApartment.data" v-if="listShow" />

				<Card :info="getApartment.data" v-else />
			</template>

			<div style="display: flex; flex: 0.95; margin-top: 70px" v-else>
				<AppCardAddAccount />
			</div>

			<snackbar ref="snackbar" />
		</div>
	</AppGlobalLayout>
</template>

<script>
import { mapGetters } from "vuex";
import ModalRejected from "./modals/modal-rejected";
import RequestedServiceModal from "./modals/RequestedServiceModal";
import ModalAdressConfirm from "./modals/modal-adress-confirm";
import ModalPayApproved from "./modals/ModalPayApproved";
import AppCardAccount from "@/components/components-other/app-card-account";
import AppCardAddAccount from "@/components/components-other/app-card-add-account";

import ApartmentRename from "./modals/rename-apartment";
import ApartmentRemove from "./modals/remove-apartment";

import Card from "./components/cards";
import Table from "./components/table";
import AppCarousel from "@/components/app-caruosel";

import Insurance from "../insurance/index.vue";

export default {
	components: {
		ModalPayApproved,
		ModalRejected,
		AppCardAccount,
		AppCardAddAccount,
		ApartmentRename,
		ApartmentRemove,
		Card,
		Table,
		AppCarousel,
		ModalAdressConfirm,
		Insurance,
		RequestedServiceModal
	},
	data() {
		return {
			listShow: false,
			error: false
		};
	},
	methods: {
		changeVisible(val) {
			this.listShow = val;
			localStorage.setItem("listShow", val);
		},
		getVisible() {
			if (localStorage.listShow) {
				this.listShow = JSON.parse(localStorage.listShow);
			}
		},
		changeDelay(index) {
			this.delay += index;
		},
		async apartmentLoad() {
			this.error = false;
			try {
				await this.$store.dispatch("apartmentList");
			} catch (e) {
				if (e.response) {
					this.$errorsMod(e.response);
				} else {
					this.error = true;
					this.$errorsMod(e.response);
				}
			}
		},
		async apartmentBannersLoad() {
			this.error = false;
			try {
				await this.$store.dispatch("apartmentBanners");
			} catch (e) {
				if (e.response) {
					this.$errorsMod(e.response);
				} else {
					this.error = true;
					this.$errorsMod(e.response);
				}
			}
		},
		openModal() {
			if (this.$route.path.indexOf("rejected") >= 0) {
				this.$modal.show("bank-rejected", { ...this.$route.query });
			} else {
				this.$modal.show("bank-approved", { ...this.$route.query });
			}
		}
	},
	mounted() {
		this.getVisible();
		this.apartmentLoad();
		this.apartmentBannersLoad();
		if (
			this.$route.name == "bank-approved" ||
			this.$route.name == "bank-rejected"
		) {
			setTimeout(() => {
				this.openModal();
			}, 500);
		}
	},
	computed: {
		...mapGetters(["getApartment", "getBanners"])
	}
};
</script>
