<template>
    <div>
        <ProductList :data="getFavourites" :loadInfo="() => $store.dispatch('fetchFavourites', getActiveTownId)" />
    </div>
</template>
<script>
import { mapGetters } from 'vuex'
import ProductList from '../component/product-list.vue';
export default {
    components: { ProductList },
    data() {
        return {
            test: "sdsdsd"
        };
    },
    computed: {
        ...mapGetters(["getFavourites", "getActiveTownId"])
    },
    methods: {
        async loadInfo() {
            await this.$store.dispatch("fetchFavourites", this.getActiveTownId);
        }
    },
    mounted() {
        this.loadInfo();
    },
}
</script>