<template>
	<div class="d-flex d-column just-between" style="height: 100%">
		<form action="#" @submit="searchOrganization">
			<div class="mb-2 mt-2 account-add__field">
				<AppInput focus placeholder="ИНН" type="text" v-mask="'#############'" v-model.trim="$v.form.inn.$model"
					required :requiredError="$v.form.inn.$required" :error="$v.form.inn.$error"
					label="ИНН управляющей организации или РСО">
					<template slot="required">Заполните ИНН</template>
				</AppInput>

				<AppBtn class="account-add__button" @click.native="searchOrganization" :loading="getSearchInn.loading">
					Поиск
				</AppBtn>
			</div>
		</form>

		<div v-if="(getSearchInn.data.length > 1) & !getSearchInn.loading" class="mb-3">
			<AppSelect placeholder="Выберите филиал" label="Выберите филиал" valueSelect="id" :options="getSearchInn.data"
				:error="$v.filialForm.activeOffice.$error ? 'Выберите филиал' : ''"
				v-model="$v.filialForm.activeOffice.$model" textSelect="name" @selected="selectedFilial" />
		</div>

		<!-- 
            Подразделения 
        -->

		<div v-if="hasDivision && !getSearchInn.loading" class="mb-3">
			<AppSelect placeholder="Выберите подразделение" label="Выберите подразделение" valueSelect="id"
				:options="divisionOption" :error="
					$v.divisionForm.activeDivision.$error ? 'Выберите подразделение' : ''
				" v-model="$v.divisionForm.activeDivision.$model" textSelect="name"
				:value="$v.divisionForm.activeDivision.$model" />
		</div>

		<template v-if="getSearchInn.data.length > 1 && $v.filialForm.activeOffice.$model">
			<div class="account-add__block-info" v-if="!getSearchInn.loading">
				<template>
					<div class="account-add__row">
						<div class="account-add__title">Организация:</div>
						<div class="account-add__text">
							{{ $v.filialForm.activeOffice.$model.name }}
						</div>
					</div>
					<div class="account-add__row">
						<div class="account-add__title">ИНН:</div>
						<div class="account-add__text">
							{{ $v.filialForm.activeOffice.$model.inn }}
						</div>
					</div>
					<div class="account-add__row">
						<div class="account-add__title">Юридический адрес:</div>
						<div class="account-add__text">
							{{ $v.filialForm.activeOffice.$model.legalAddress }}
						</div>
					</div>
				</template>
			</div>
		</template>

		<template v-else-if="getSearchInn.data.length == 1">
			<div class="account-add__block-info" v-if="!getSearchInn.loading">
				<template>
					<div class="account-add__row">

						<div class="account-add__title">Организация:</div>
						<div class="account-add__text">{{ getSearchInn.data[0].name }}</div>
					</div>
					<div class="account-add__row">
						<div class="account-add__title">ИНН:</div>
						<div class="account-add__text">{{ getSearchInn.data[0].inn }}</div>
					</div>
					<div class="account-add__row" v-if="getSearchInn.data[0].legalAddress">
						<div class="account-add__title">Юридический адрес:</div>
						<div class="account-add__text">
							{{ getSearchInn.data[0].legalAddress }}
						</div>
					</div>
				</template>
			</div>
		</template>

		<div class="account-add__error" v-if="noResult && !getSearchInn.loading">
			<div class="account-add__image">
				<img v-if="getTheme !== 'dark-theme'" src="/img/not-fount.svg" width="180px" />
				<img v-else src="/img/dark-theme/not-found-man-dark.svg" width="180px" />
			</div>
			<div class="account-add__description">
				Данная организация еще не сотрудничает с нами.
			</div>
		</div>

		<div v-if="!validFilial">
			<template v-if="!validDivision">
				<AppBtn @click.native="nextStep" color="success" :disabled="validFilial && validDivision">
					Далее
				</AppBtn>
			</template>
		</div>
	</div>
</template>

<script>
import { required, minLength } from "vuelidate/lib/validators";
import { mapGetters, mapMutations } from "vuex";

let validNumber = (val, param) => {
	let reg = /^[0-9]+$/i;
	let test = reg.test(val);
	if (!val) return true;
	return test;
};

export default {
	data() {
		return {
			disabled: true,
			form: {
				inn: "",
			},
			filialForm: {
				activeOffice: null,
			},
			divisionForm: {
				activeDivision: null,
			},
			orgInfo: [],
			noResult: false,
			divisionOption: [],
		};
	},
	computed: {
		...mapGetters(["getSearchInn", "getTheme"]),

		hasDivision() {
			return this.divisionOption && this.divisionOption.length > 0;
		},

		validFilial() {
			if (this.getSearchInn.data.length) {
				if (this.getSearchInn.data.length > 1) {
					return !this.$v.filialForm.activeOffice.$anyDirty;
				} else {
					return false;
				}
			} else {
				return true;
			}
		},

		validDivision() {
			if (!this.divisionOption) {
				return true;
			}

			if (this.divisionOption.length > 1) {
				return !this.$v.divisionForm.activeDivision.$anyDirty;
			}
		},
	},
	mounted() {
		this.setSearchInnData([]);
		this.setSearchByIdData([]);
	},
	methods: {
		...mapMutations([
			"setSearchInnData",
			"accountAddSelectedIdOrgState",
			"accountAddSelectedIdDivisionState",
			"setSearchByIdData",
		]),

		selectedFilial(val) {
			this.divisionOption = [];
			this.divisionForm.activeDivision = null;
			this.$nextTick(() => {
				if (val.divisions.length > 1) {
					this.divisionOption = [...val.divisions];
					this.$v.divisionForm.activeDivision.$reset();
					return;
				}

				if (val.divisions.length === 1) {
					this.divisionOption = [...val.divisions];
					this.$v.divisionForm.activeDivision.$reset();
					this.$nextTick(() => {
						this.divisionForm.activeDivision = val.divisions[0];
					});

					return;
				}

				this.divisionOption = [...val.divisions];
				this.$v.divisionForm.activeDivision.$reset();
			});
		},

		clearForm() {
			this.setSearchInnData([]);
			let filialForm = {
				activeOffice: null,
			};
			this.filialForm = { ...filialForm };
			this.divisionForm = { activeDivision: null };
		},

		getDivisionId() {
			if (this.divisionOption.length === 1) {
				return this.divisionOption[0].id;
			}

			if (this.divisionOption.length > 1) {
				return this.$v.divisionForm.activeDivision.$model.id;
			}

			if (!this.hasDivision.length) {
				return null;
			}
		},

		nextStep() {
			console.log('NEXT STEP');
			this.$v.form.$touch();
			if (!this.$v.form.$error) {
				this.$emit("stepNext", 2);
				const organizationId =
					this.getSearchInn.data.length > 1
						? this.$v.filialForm.activeOffice.$model.id
						: this.getSearchInn.data[0].id;
				const divisionId = this.getDivisionId();
				this.accountAddSelectedIdOrgState(organizationId);
				this.accountAddSelectedIdDivisionState(divisionId);
			}
		},
		async searchOrganization(e) {
			e.preventDefault();
			this.$v.form.$touch();

			if (!this.$v.form.$error) {
				this.noResult = false;
				this.clearForm();
				this.$v.$reset();
				try {
					await this.$store.dispatch("searchByInn", this.form.inn);

					//проверка на количество филиалов (для подразделений)
					//если всего одна организация тогда запишем все её подразделения
					if (this.getSearchInn.data.length === 1) {
						this.filialForm.activeOffice = this.getSearchInn.data[0];
						this.divisionOption = this.getSearchInn.data[0].divisions;
					}

					if (!this.getSearchInn.data.length) {
						this.noResult = true;
					}
				} catch (e) {
					this.$errorsMod(e.response);
				}
			}
		},
	},
	validations: {
		form: {
			inn: {
				required,
				minLength: minLength(8),
				validNumber,
			},
		},
		filialForm: {
			activeOffice: {
				required,
			},
		},

		divisionForm: {
			activeDivision: {
				required,
			},
		},
	},
};
</script>