import $axios from "@/plugins/axios-instance";

// const cartDataLS = localStorage.getItem('cartData')

export default {
	state: {
		basketOrder: {
			data: [],
			loading: false,
			error: null
		},
		cart: {
			noApartment: []
		}
	},
	getters: {
		getBasketOrder(state) {
			return state.basketOrder;
		},
		getCart(state) {
			return state.cart;
		},
		getTotalCartCount(state) {
			return Object.entries(state.cart).reduce(
				(ac, item) => ac + item[1].length,
				0
			);
		},
		inTheBasket(state) {
			return ({ id, apartmentId }) => {
				if (state.cart[apartmentId || "noApartment"]) {
					const index = state.cart[apartmentId || "noApartment"].findIndex(
						item => item.id === id
					);
					if (index > -1) {
						return true;
					}
				}
				return false;
			};
		},
		countInBasket(state) {
			return ({ id, apartmentId }) => {
				if (state.cart[apartmentId || "noApartment"]) {
					const index = state.cart[apartmentId || "noApartment"].findIndex(
						item => item.id === id
					);
					if (index > -1) {
						return state.cart[apartmentId || "noApartment"][index].count;
					}
				}
				return 0;
			};
		}
	},
	mutations: {
		setBasketOrderLoad(state, load) {
			state.basketOrder.loading = load;
		},
		setBasketOrderData(state, data) {
			state.basketOrder.data = data;
		},
		setBasketOrderError(state, error) {
			state.basketOrder.error = error;
		},

		clearCart(state) {
			state.cart = { noAddress: [] };
		},

		setProductCount(state, { apartmentId, count, product }) {
			if (!state.cart[apartmentId || "noApartment"]) {
				state.cart = {
					...state.cart,
					[apartmentId || "noApartment"]: []
				};
			}

			const index = state.cart[apartmentId || "noApartment"].findIndex(
				item => item.id === product.id
			);
			if (index > -1) {
				state.cart[apartmentId || "noApartment"][index].count = count;
			} else {
				const productWithCount = { ...product, count };
				state.cart[apartmentId || "noApartment"] = [
					...state.cart[apartmentId || "noApartment"],
					productWithCount
				];
			}
		},
		removeProductFromCart(state, { id, apartmentId }) {
			if (!state.cart[apartmentId || "noApartment"]) {
				state.cart[apartmentId || "noApartment"] = [];
			}
			const index = state.cart[apartmentId || "noApartment"].findIndex(
				item => item.id === id
			);
			if (index > -1) {
				state.cart[apartmentId || "noApartment"].splice(index, 1);
			}
		},
		removeCartGroup(state, { apartmentId }) {
			state.cart[apartmentId || "noApartment"] = [];
		}
	},

	actions: {
		basketOrder(context, data) {
			context.commit("setBasketOrderLoad", true);
			return $axios
				.post(`/basket/order`, data)
				.then(response => {
					context.commit("setBasketOrderData", response.data);
					return response.data;
				})
				.catch(e => {
					context.commit("setBasketOrderError", e.response);
					throw e;
				})
				.finally(() => {
					context.commit("setBasketOrderLoad", false);
				});
		}
	}
};
