<template>
	<AppModals
		name="rating-modal"
		title="Оценка"
		cancelButtonText="Отменить"
		:clickToClose="false"
		:maxWidth="400"
		@beforeOpen="beforeOpen"
		:width="400"
		confirmButtonText="Отправить"
		@confirm="send"
		class="rating"
	>
		<template #content="{}">
			<div class="mb-3">
				<div class="d-flex align-center" style="flex-direction: column">
					<img class="rating__image" :src=" getTheme === 'dark-theme' ? '/img/dark-theme/rating-man-dark.svg' : '/img/rating-man.png'" />
					<star-rating
						:increment="1"
						:show-rating="false"
						:padding="5"
						@rating-selected="setRating"
						:star-size="30"
						:star-points="[
							23, 2, 14, 17, 0, 19, 10, 34, 7, 50, 23, 43, 38, 50, 36, 34, 46,
							19, 31, 17,
						]"
						style="margin-bottom: 40px"
						active-color="#EB982C"
					></star-rating>
				</div>
				<AppTextarea 
					type="text" 
					label="Ваш комментарий:" 
					cols="45" 
					rows="6"
					required
					v-model.trim="$v.form.description.$model"
					:error="$v.form.description.$error"
					:requiredError="!$v.form.description.$error"
				>
					<div slot="required">Не более 1000 символов</div>
				</AppTextarea>
			</div>
		</template>
	</AppModals>
</template>

<script>
	import StarRating from "vue-star-rating";
	import { maxLength } from 'vuelidate/lib/validators'
	import { mapGetters } from "vuex";

	export default {
		data() {
			return {
				orderId: null,
				form: {
					description: '',
				},
				star: 5
			};
		},
		methods: {
			beforeOpen(event) {
				this.orderId =  event.params.id;
			},
			setRating(star) {
				this.star = star
			},
			async send() {
				this.$v.form.$touch()
            	if (!this.$v.form.$invalid) {
					let formData = {
						orderId: this.orderId,
						description: this.form.description,
						grade: this.star
					}
					try {
						await this.$store.dispatch('getShopGrade', formData);
						this.$modal.hide('rating-modal');
						this.$emit('close')
					} catch (e) {
						this.$errorsMod(e);
					}
				}
			}
		},
		computed: {
		...mapGetters(["getTheme"])
	},
		components: {
			StarRating,
		},
		validations(val) {
			return {
				form: {
					description: {
						maxLength: maxLength(1000)
					},
				}
			}
		}
	};
</script>

<style>
</style>