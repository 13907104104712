<template>
    <div>
        <AppTable 
            :header="headerTable"
            :data="getOrganizationInfo.contacts"
            hoverHide
            class="mt-3--xs"
            emptyImage="/img/contact-empty.svg"
            emptyText="Контакты отсутствуют"
        >
            <template v-slot:thead="{item}">
                <span>{{ item.name }}</span>
            </template>
            
            <template v-slot:tbody="{item}">
                <td>
                    <span class="app-table__title-mob">ФИО:</span>
                    <span>{{ item.contactName }}</span>
                </td>
                <td>
                    <span class="app-table__title-mob">Телефон:</span>
                    <span>{{ item.phone }}</span>
                </td>
                <td>
                    <span class="app-table__title-mob">Должность:</span>
                    <span>{{ item.position }}</span>
                </td>
                <td>
                    <span class="app-table__title-mob">Прием:</span>
                    <span>{{ item.receptionHours }}</span>
                </td>
                <td>
                    <span class="app-table__title-mob">Комментарий:</span>
                    <span>{{ item.description }}</span>
                </td>
            </template>
        </AppTable>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
    data() {
        return {
            headerTable: [
				{
					name: 'ФИО',
					width: '30%'
				},
				{
					name: 'Телефон',
					width: '20%'
				},
				{
					name: 'Должность',
					width: '15%'
				},
				{
					name: 'Приём',
					width: '20%'
				},
				{
					name: 'Комментарий',
					width: '15%'
				},

			]
        }
    },
        computed: {
		...mapGetters([
			'getOrganizationInfo'
		])
    },
    
}
</script>