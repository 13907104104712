<template>
	<!--     <div class="support-question">
        <div class="support-question__main-title">Часто задаваемые вопросы</div>
        <br>
        <div v-for="(item, index) in data" :key="index">
            <div class="support-question__title" @click="item.show = !item.show">
                {{ item.title }}
            </div>
            <transition name="slide-fade">
                <p class="support-question__block" v-if="item.show" >
                    <span v-html="item.textData"></span>
                </p>
            </transition>
        </div>
    </div> -->
	<div class="accordion-block">
		<div class="accordion-block__main-title mb-3">Часто задаваемые вопросы</div>
		<template v-for="(item, index) in data">
			<div
				class="animate__animated animate__fadeIn accordion-block__item"
				:class="'animate__delay-' + 6 * index"
				:key="index"
				tabindex="0"
				@keyup.space="item.show = !item.show"
				@keyup.enter="item.show = !item.show"
			>
				<div
					@click="item.show = !item.show"
					:class="{
						'accordion-block__title': true,
						'accordion-block__title--open': item.show,
					}"
				>
					{{ item.title }}
				</div>
				<transition name="slide-fade">
					<div class="accordion-block__content" v-show="item.show">
						<span v-html="item.textData"></span>
					</div>
				</transition>
			</div>
		</template>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				show: false,
				show2: false,
				data: [
					/* {
	                    show: false,
	                    title: 'Где получить код подтверждения лицевого счета?',
	                    textData: `<div class="accordion-block__cloud">Если у вашей организации был подключен "Личный кабинет", то в качестве кода подтверждения используется пароль от "Личного кабинета", указанный в квитанции.</div>
	                        <br>
	                        <br>
	                        <img src="${require('@/img/oldPayDoc.jpg')}" alt="" width="400px">
	                        <br><br>
	                        <div class="accordion-block__cloud">Если ваша организация не использовала "Личный кабинет", то данный код будет отображен в квитанции за август 2019 года.</div>
	                        <br><br>
	                        <img src="${require('@/img/newPayDoc.jpg')}" alt="" width="400px">
	                        <br><br>
	                        <div class="accordion-block__cloud">Также для получения кода подтверждения вы можете обратиться в бухгалтерию своей управляющей организации.</div<`
	                }, */
					{
						show: false,
						title: "Видеоинструкция по использованию сервиса",
						textData: `<div style="width: 100%; min-width: 320px; max-width: 660px;"><iframe width="100%" height="395" src="https://www.youtube.com/embed/gu2kO8fdcQ4" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe></div>`,
					},
					{
						show: false,
						title: "Почему задолженность не пропала после оплаты?",
						textData: `<div class="accordion-block__cloud">Все зависит от способа, которым вы оплачиваете коммунальные услуги.
	                        <br><br>
	                        Если вы используете для оплаты сервис <b>«Квартплата.онлайн»</b> или одноименное мобильное приложение, то мы сразу же видим ваш платеж и информация обновляется моментально.
	                        <br><br>
	                        Но если вы оплачиваете через сторонний банк, не используя приложение, то мы не сможем узнать о вашем платеже до тех пор, пока бухгалтер управляющей организации не отметит его у себя в программе. Обычно это занимает от 3 до 5 рабочих дней, но иногда процесс затягивается.К сожалению, мы ничего не можем с этим сделать.</div>`,
					},
					{
						show: false,
						title: "Ввожу ИНН организации, но сервис ничего не находит. Почему?",
						textData: `<div class="accordion-block__cloud">Либо вы вводите неверный ИНН, либо ваша обслуживающая организация еще не сотрудничает с сервисом «Квартплата.онлайн». В ваших силах исправить эту ситуацию – расскажите им о нас.</div>`,
					},
					{
						show: false,
						title: "Как посмотреть или распечатать квитанцию?",
						textData: `<div class="accordion-block__cloud">
							<div>
								Для просмотра или формирования электронной квитанций перейдите на главную страницу. Нажмите на необходимый адрес, после нажмите на лицевой счёт и перейдите во вкладку «Начисления». Выберите период и нажмите на кнопку «Квитанция». Для вашего удобства мы оставили ссылки на инструкции в видео-формате:
							</div>

							<div style="width: 100%; min-width: 320px; max-width: 660px; margin-top: 1em">
								<iframe
									width="100%"
									height="395"
									src="https://www.youtube.com/embed/rH8zmMeDN6Q"
									frameborder="0"
									allow="accelerometer;
									autoplay;
									encrypted-media;
									gyroscope;
									picture-in-picture"
									allowfullscreen>
								</iframe>
							</div>
						</div>`,
					},
					/* {
	                    show: false,
	                    title: 'Как добавить ещё один лицевой счет?',
	                    textData: `<div class="accordion-block__cloud"><p><span>Вы можете добавить любое количество лицевых счетов. Для этого:</span></p> <ol class="list-questions"> 	<li>В левом меню нажмите на вкладку &laquo;Лицевые счета&raquo;.</li> 	<li><span>Нажмите синюю кнопку &laquo;+&raquo;, она находится под списком уже добавленных лицевых счетов.</span></li> 	<li><span>В открывшемся окне введите ИНН организации и номер своего лицевого счета (эта информация есть в квитанции). Нажмите кнопку &laquo;Поиск&raquo;.&nbsp; &nbsp;</span></li> 	<li><span>После того, как сервис найдет информацию о вашем помещении, нажмите кнопку &laquo;Добавить&raquo;.</span></li> 	<li><span>Введите код подтверждения лицевого счета (он тоже указан в квитанции) и нажмите кнопку &laquo;Подтвердить&raquo;</span></li> </ol></div>`
	                }, */
					{
						show: false,
						title: "Почему в квитанции нет ФИО?",
						textData: `<div class="accordion-block__cloud">Мы соблюдаем закон о персональных данных, поэтому не размещаем в квитанции эту информацию. </div>`,
					},
					// {
					// 	show: false,
					// 	title: "Почему при оплате нужно платить комиссию?",
					// 	textData: `<div class="accordion-block__cloud">Мы не принимаем платежи, поэтому размер комиссии при оплате зависит не столько от нас, сколько от банка-партнера – чем больше пользователей у сервиса, тем ниже комиссия. Все остальные функции приложения бесплатны (передача показаний, заявки в УК и т.д.).</div>`,
					// },
					{
						show: false,
						title: "Почему в личном кабинете нет начислений за последний месяц? ",
						textData: `<div class="accordion-block__cloud">Сервис получает данные от вашей обслуживающей организации (УК, ТСЖ, РСО). Если информации нет, значит они еще не выгрузили начисления на сайт. Обратитесь в бухгалтерию вашей обслуживающей организации и сообщите о проблеме.</div>`,
					},
					{
						show: false,
						title:
							"Почему установленный счетчик не отображается в личном кабинете?",
						textData: `<div class="accordion-block__cloud">Ваша управляющая компания не внесла счетчик в свою программу для ведения учета.</div>`,
					},
					// {
					// 	show: false,
					// 	title: "Почему мой лицевой счёт заблокирован?",
					// 	textData: `<div class="accordion-block__cloud">Вы неверно ввели код подтверждения 5 раз подряд. Блокировка автоматически снимается через 1 час, после ее снятия даётся ещё одна попытка. Если вы снова введете неверный код, то лицевой счет будет опять заблокирован.</div>`,
					// },
					{
						show: false,
						title:
							"У меня частный дом и нет ни управляющей организации, ни ТСЖ. Что мне вводить в поле «ИНН организации»?",
						textData: `<div class="accordion-block__cloud">Неважно, обслуживает ли вас управляющая компания или нет – все равно какая-то организация выставляет вам квитанции (например, за мусор, газ или электричество), в которых указан ее ИНН. Его и нужно ввести. </div>`,
					},
					{
						show: false,
						title: 'Почему не формируется квитанция АО "НЭСК", ООО "КТЭ", ПАО "ТНС ЭНЕРГО КУБАНЬ", ООО "РЕПИНО", ООО "ИРЦК", ООО "Газпром Межрегионгаз"?',
						textData: `<div class="accordion-block__cloud">В связи с техническими особенностями работы организаций, формирование электронных квитанций в личном кабинете невозможно. Рекомендуем вам обратиться напрямую в абонентский отдел любой из этих организации.</div>`
					},
					{
						show: false,
						title: 'Как удалить неактуальное помещение из личного кабинета?',
						textData: `<div class="accordion-block__cloud">В окошке с адресом помещения нужно нажать на значок "Три точки" и выбрать "Удалить".</div>`
					},
					{
						show: false,
						title: 'Как добавить счетчик?',
						textData: `<div class="accordion-block__cloud">Добавлять/изменять счетчики может только ваша обслуживающая организация (УК, ТСЖ, РСО). По данному вопросу обратитесь с соответствующим заявлением в вашу управляющую/ресурсоснабжающую организацию.</div>`
					},
					{
						show: false,
						title: 'Подал неправильные показания, что делать?',
						textData: `<div class="accordion-block__cloud">До окончания сроков передачи показаний вы можете изменить переданные ранее показания в личном кабинете и отправить повторно. Если срок передачи показаний уже истек, то вам необходимо обращаться напрямую в бухгалтерию вашей управляющей/ресурсоснабжающей организации для передачи корректных показаний счетчиков.</div>`
					},
					{
						show: false,
						title: 'Не успел передать показания, что делать?',
						textData: `<div class="accordion-block__cloud">После окончания сроков передачи показаний, которые устанавливает ваша управляющая организация, передать показания через сервис "Квартплата.Онлайн" невозможно. О других способах передачи показаний вы можете узнать в бухгалтерии вашей управляющей организации.</div>`
					},
					{
						show: false,
						title: 'Как найти телефон/E-mail УК?',
						textData: `<div class="accordion-block__cloud">В приложении: чтобы получить контакты организации нажмите на её наименование в списке лицевых счетов и кликните "Информация об организации". </br></br> В браузере: для получения контактов организации нажмите на её наименование в списке лицевых счетов.</div>`
					},
					{
						show: false,
						title: 'Что делать, если пишет, что логин занят другим пользователем?',
						textData: `<div class="accordion-block__cloud">Это означает, что номер телефона или электронная почта ранее уже использовался для регистрации в личном кабинете. Повторно использовать его не получится. Вы можете восстановить пароль от этого аккаунта воспользовавшись кнопкой "Забыли пароль?", которая находится на странице входа в личный кабинет.</div>`
					},
				],
			};
		},
		methods: {},
	};
</script>

<style scoped>
	.support-question {
		max-width: 600px;
		margin-right: 20px;
		margin-bottom: 40px;
		border-right: 1px solid #ccc;
	}

	.questions-list-title {
		font-size: 14px;
		padding: 15px 20px;
		color: rgb(37, 116, 245);
		text-decoration: none;
		cursor: pointer;
		margin-bottom: 10px;
		border: 1px solid #fff;
		margin-right: 20px;
		background: #fff;
		color: #485f7d;
		display: flex;
		justify-content: space-between;
	}

	.questions-list-title:hover {
		background: #6c7e96;
		border: 1px solid #6c7e96;
		color: #fff;
	}

	.questions-list-block {
		font-size: 13px;
		padding: 15px;
	}
	.slide-fade-enter-active {
		transition: all 0.5s ease;
	}
	.slide-fade-leave-active {
		transition: all 0.5s ease;
	}
	.slide-fade-enter,
	.slide-fade-leave-to {
		transform: translateY(-10px);
		opacity: 0;
	}

	@media (max-width: 1040px) {
		.support-question {
			border-right: none;
			border-bottom: 1px solid #ccc;
			padding-bottom: 30px;
		}
	}
</style>

<style>
	.list-questions {
		padding-left: 15px;
	}
	.list-questions li {
		list-style: decimal;
		padding: 5px 0;
	}
	.list-questions li::marker {
		font-weight: bold;
	}
</style>
