<template>
    <div class="app-textarea">
        <label class="app-textarea__label" :id="id">{{ label }}</label>

        <textarea 
            class="app-textarea__textarea"
            :class="{
                'app-textarea__error-inp': error, 
                'app-textarea__textarea--white': white, 
            }"
            :type="type" 
            :placeholder="placeholder" 
            :id="id"
            :disabled="disabled"
            @input="changetextarea($event.target.value)"
            :rows="rows"
            :value="value"
        >
        </textarea>

        <span class="app-textarea__error shake" v-show="error">
           <slot name="required" v-if="!requiredError"></slot>
           <slot name="minLength"  v-else-if="!minLength"></slot>
        </span>
    </div>
</template>

<script>
export default {
    data() {
        return {

        }
    },
    props: {
        grey: {
            type: Boolean,
            default: true
        },
        white: {
            type: Boolean,
            default: false
        },
        transparent: {
            type: Boolean,
            default: false
        },
        disabled: {
            type: Boolean,
            default: false
        },
        type: {
            type: String,
            default: 'text'
        },
        placeholder: {
            type: String,
            default: ''
        },
        label: {
            type: String,
            default: ''
        },
        iconLeft: {
            type: String,
            default: ''
        },
        id: {
            default: '',
            type: String
        },
        error: {
            default: false,
            type: Boolean
        },
        minLength: {
            default: false,
            type: Boolean
        },
        required: {
            default: false,
            type: Boolean
        },
        requiredError: {
            default: false,
            type: Boolean
        },
        rows: {
            default: '6',
            type: String
        },
        value: {
            default: '',
            type: String
        }
    },
    methods: {
        changetextarea(val) {
            this.$emit("input", val);
        }
    },
    watch: {

    }
}
</script>

<style lang="sass">
    @import "./textarea"
</style>