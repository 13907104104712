<template>
	<div>
		<div
			v-show="sumSize > 7"
			class="shake danger-color"
			style="margin-bottom: 10px"
		>
			Вы можете прикреплять к заявке файлы размером не более 7 МБ.
		</div>
		<div>
			<div
				class="request-files-downloader__form"
				:class="{
					disabledUploader: disabledUploader,
					successUploader: successUploader
				}"
			>
				<div class="request-files-downloader__download">
					<input
						type="file"
						@change="previewFiles"
						multiple
						@dragenter="dragover"
						@dragleave="dragleave"
						@dragend="dragend"
						@drop="drop"
						accept=".pdf,.jpg, .png, .jpeg, .txt, .doc, .docx, .rtf"
						:disabled="false"
					/>
					<div class="d-flex align-center">
						<div class="request-files-downloader__image">
							<img src="/img/files.svg" />
						</div>
						<div class="request-files-downloader__description">
							<div class="request-files-downloader__title-text">
								Выберите файл
							</div>
							<div class="request-files-downloader__sub-title-text">
								или перетащите его сюда
							</div>
							<div class="request-files-downloader__sub-title-text">
								Не более 6 Мб. (JPEG, PNG, PDF, DOC)
							</div>
						</div>
					</div>
				</div>
			</div>

			<div class="files" v-if="arrayFiles.length">
				<div
					class="file-item"
					v-for="(item, index) in arrayFiles"
					:key="index + 100"
				>
					<template v-if="Number(item.size / (1024 * 1024)).toFixed(2) <= 10">
						<div class="">
							{{ item.name }}
						</div>
						<div class="size">
							{{ Number(item.size / (1024 * 1024)).toFixed(2) }} мб
						</div>
						<div
							class="request-files-downloader__delete"
							@click="deleteItemFile(index)"
						>
							<AppIcon name="times" size="16px" />
						</div>
					</template>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	props: ["value", "sumSize"],
	data() {
		return {
			disabledUploader: false,
			successUploader: false
		};
	},
	computed: {
		arrayFiles: {
			get: function() {
				return this.value;
			},
			set: function(val) {
				this.$emit("input", val);
			}
		}
	},
	methods: {
		dragover(event) {
			this.validationFiles(event);
		},
		dragleave(event) {
			this.disabledUploader = false;
			this.successUploader = false;
		},
		dragend(event) {
			this.disabledUploader = false;
			this.successUploader = false;
		},
		drop(event) {
			this.validationFiles(event);
			setTimeout(() => {
				this.disabledUploader = false;
				this.successUploader = false;
			}, 300);
		},
		deleteItemFile(index) {
			this.arrayFiles.splice(index, 1);
			this.$emit("changeList", this.arrayFiles);
		},
		previewFiles(event) {
			if (!this.disabledUploader)
				this.arrayFiles = [...this.arrayFiles, ...event.target.files];
			this.changeFiles();
		},
		changeFiles() {
			this.$emit("changeList", this.arrayFiles);
		},
		validationFiles(event) {
			let supportTypes = [
				"application/pdf",
				"image/jpeg",
				"image/png",
				"image/jpg",
				"text/plain",
				"application/msword",
				"application/vnd.openxmlformats-officedocument.wordprocessingml.document"
			];

			event.dataTransfer.items.forEach(item => {
				if (item.type === "") {
					this.disabledUploader = true;
					return;
				}

				if (supportTypes.indexOf(item.type) === -1) {
					this.disabledUploader = true;
					return;
				} else {
					this.successUploader = true;
				}
			});
		}
	}
};
</script>

<style>
.disabledUploader {
	cursor: no-drop;
	border: 2px dashed #fa665c !important;
}

.successUploader {
	border: 2px dashed #54852c !important;
}
</style>
<style scoped>
.request-files-downloader__download {
	padding: 0.5em 1em;
}
.files {
	display: flex;
	flex-wrap: wrap;
	gap: 0.5em 1em;
	margin-top: 1em;
}
.file-item {
	display: flex;
	gap: 0.5em;
	align-items: center;
	background: var(--color-backfround);
	padding: 0 1em;
	border-radius: 1em;
}
</style>
