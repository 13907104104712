<template>
	<AppModals
        name="accountTicketError"
        title="Ошибка"
		cancelButtonText="Закрыть"
		@beforeOpen="beforeOpen"
		hideConfirm
    >
        <template  #content="{}">
			<div class="d-flex align-center just-center">

				<div class="d-flex just-center column align-center">
					<div class="error-mesage-modal" style="display: flex; width: 100%; justify-content: center; font-size: 45px;">
						<i class="far fa-frown"></i>
					</div>
					<div class="error-mesage-info">{{ message }}</div>
				</div>

			</div>
        </template>
    </AppModals>
</template>

<script>

export default {
	data () {
		return {
            message: ' ', 
		}
	},

	computed: {

    },
    mounted() {

    },
	methods: {
		modalClose () {
			this.$modal.hide('accountTicketError')
		},

		async beforeOpen (event) {
            this.message = event.error
		}
	}
}
</script>

<style>
	.error-mesage-modal {
		color: #90a1b8 !important;
		text-align: center;
		margin-bottom: 10px;
		font-size: 14px;
		width: 100%;
		font-weight: bold;
	}

</style>
