import $axios from '@/plugins/axios-instance'

export default {
	state: {
		accrualsList: {
			data: [],
			loading: false,
			error: true
		}
	},
	getters: {
		getAccrualsList(state) {
			return state.accrualsList
		}
	},
	mutations: {
		setAccrualsListData(state, data) {
			state.accrualsList.data = data.sort((a, b) => {
				return (b.periodId - a.periodId)
			})
		},
		setAccrualsListLoad(state, load) {
			state.accrualsList.loading = load
		},
		setAccrualsListError(state, error) {
			state.accrualsList.error = error
		},
	},
	actions: {
		accrualsList ({commit}, data) {
			commit('setAccrualsListLoad', true)
			return $axios.get(`/Accruals/List?accountId=${data}`)
            .then((response) => {
				commit('setAccrualsListData', response.data)
				return response.data
            }).catch((e)=> {
				commit('setAccrualsListError', e.response)
                throw(e)
            }).finally(() => {
				commit('setAccrualsListLoad', false)
			})
		}

	}
}
