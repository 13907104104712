import $axios from '@/plugins/axios-instance'

export default {
    state: {
        favorites: []
    },
    getters: {
        getFavourites(state) {
            return state.favorites
        },
        getFavouriteIds(state) {
            return state.favorites.map(item=>item.id)
        },
    },
    mutations: {
        setFavourites(state, payload) {
            state.favorites = payload
        }
    },
    actions: {
        async fetchFavourites({ commit }, townId) {
            return $axios.get(`/shop/favourites?townId=${townId}`)
                .then((response) => {
                    commit('setFavourites', response.data)
                }).catch((e) => {
                    throw (e)
                })
        },
        async addToFavourites(context, productId) {
            return $axios.post(`/shop/favourites/${productId}`)
                .then((response) => {
                    return response.data
                }).catch((e) => {
                    throw (e)
                })
        },
        async removeFromFavourites(context, productId) {
            return $axios.delete(`/shop/favourites/${productId}`)
                .then((response) => {
                    return response.data
                }).catch((e) => {
                    throw (e)
                })
        },
    }
}
