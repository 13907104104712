<template>
	<AppCard v-if='slide.requiredBanner' class="main-banner-card">
		<template #body>
			<div class="banner-discount-discount__block">
				<div class="banner-discount-discount__title">
					Мобильное приложение Квартплата.онлайн
				</div>

				<div class="main-banner__description">
					Вся информация всегда под рукой.<br />
					Для тех, кто ценит свое время.
				</div>

				<div class="main-banner__link">
					<div class="main-banner__store-image">
						<a href="https://play.google.com/store/apps/details?id=com.myhousereactnativemigration&amp;hl=ru"
							target="_blank">
							<img src="/img/icon_Google.svg" />
						</a>
					</div>
					<div class="main-banner__store-image">
						<a href="https://apps.apple.com/ru/app/%D0%BA%D0%B2%D0%B0%D1%80%D1%82%D0%BF%D0%BB%D0%B0%D1%82%D0%B0-%D0%BE%D0%BD%D0%BB%D0%B0%D0%B9%D0%BD/id1482895258"
							target="_blank">
							<img src="/img/icon_App_Store.svg" />
						</a>
					</div>
				</div>
			</div>
		</template>
	</AppCard>
	<div v-else class="app-card banner-discount-discount"
		:style="slide.mobileImage ? `background-image: url('https://квартплата.онлайн/files/static/${slide.mobileImage}')` : ''">

		<div class="banner-discount-discount__block pad">
			<div class="banner-discount-discount__title">
				{{ slide.description }}
			</div>
			<div class="main-banner__link">
				<div class="main-banner__store-image">
					<a href="https://play.google.com/store/apps/details?id=com.myhousereactnativemigration&amp;hl=ru"
						target="_blank">
						<img src="/img/icon_Google.svg" />
					</a>
				</div>
				<div class="main-banner__store-image">
					<a href="https://apps.apple.com/ru/app/%D0%BA%D0%B2%D0%B0%D1%80%D1%82%D0%BF%D0%BB%D0%B0%D1%82%D0%B0-%D0%BE%D0%BD%D0%BB%D0%B0%D0%B9%D0%BD/id1482895258"
						target="_blank">
						<img src="/img/icon_App_Store.svg" />
					</a>
				</div>
			</div>
		</div>

	</div>
</template>

<script>
export default {
	name: "app-slide",
	props: ['slide']
}
</script>

<style scoped>
.slide {
	height: 100%;
	width: 100%;
	user-select: none;
	font-size: 40px;
	margin: 0 16px;
}
</style>