<template>
    <AppModals
        name="output-application"
        title="Выйти"
        @confirm="output"
        confirmButtonText="Выйти"
        colorConfirm="danger"
    >

        <template  v-slot:content="{}">
            <div>
                <div style="text-align: left; color: #777776" class="mt-3 mb-3">
                    Вы уверены, что хотите выйти?
                </div>
            </div>
        </template>

    </AppModals>
</template>

<script>
export default {
	data () {
		return {

		}
	},
	mounted () {

	},
	methods: {
		output () {

/*             this.$store.dispatch('connectSignalR', {
            	connect: 'off'
            })
 */
            this.$store.dispatch('logout')

            window.$cookies.remove('time_token_die', '/', process.env.VUE_APP_COOCKIE)
            window.location.replace(process.env.VUE_APP_REDIRECT)

		},
		close () {
			this.$modal.hide('output-application')
		}
	},
	watch: {

	}
}
</script>

<style>
    .modal-submit-grey {
        background: #777 !important;
    }
</style>

<style scoped>
    @media (max-width: 490px) {
        .output-modal {
            justify-content: center;
            height: 100%;
            display: flex;
            flex-direction: column;
        }
    }
</style>
