<template>
	<AppModals
        name="partner-success-order"
        title="Успешно!"
        cancelButtonText="OK"
		hideConfirm
        :clickToClose="false"
		@beforeOpen="beforeOpen"
		@hideModal="modalClose"
    >
		<template  #content="{}">
			<div class="cart-success-modal">
				<div class="cart-success-modal__message">Заказ успешно принят!</div>
				<div class="cart-success-modal__info">{{ message }}</div>
			</div>
		</template>
	</AppModals>
</template>

<script>

export default {
	data () {
		return {
			message: ''
		}
	},
	props: {

	},
	computed: {

	},
	methods: {
		async modalClose () {
			this.$modal.hide('partner-success-order')
			this.$router.push({ path: '/shop' })
		},

		async beforeOpen (event) {
        	this.message = event.message

		},


	}
}
</script>

