<template>
	<div>
		<ModalCompanyInfo />
		<ModalProductInfo />
		<AppTable
			:header="headerTable"
			:data="items"
			messageNoData="..."
			emptyImage="/img/payments-empty.svg"
			emptyText="..."
			:childRow="false"
		>
			<template v-slot:thead="{ item }">
				<span>{{ item.name }}</span>
			</template>

			<template v-slot:tbody="{ item }">
				<td @click="openProductModal(item)">
					<span class="app-table__title-mob" style="display: none"
						>Название:</span
					>
					<span>{{ item.name }}</span>
				</td>
				<td @click="openCompanyModal(item)" class="app-table__company-mob">
						<img
							style="width: auto; max-height: 60px"
							:src="`https://квартплата.онлайн/files/static/${item.suplierImage}`"
						/>
				</td>
				<td @click="openProductModal(item)">
					<star-rating
						:rating="item.rating"
						:read-only="true"
						:increment="1"
						:star-size="20"
						:show-rating="false"
						active-color="#EB982C"
						:star-points="[
							23, 2, 14, 17, 0, 19, 10, 34, 7, 50, 23, 43, 38, 50, 36, 34, 46,
							19, 31, 17,
						]"
					></star-rating>
				</td>
				<td @click="openProductModal(item)">
					<span class="app-table__title-mob">Цена:</span>
					{{ item.price.toLocaleString("ru") }} ₽
				</td>
				<td>
					<div class="d-flex align-center just-end">
						<AppBtn @click="addBasket(item)">В корзину</AppBtn>
					</div>
				</td>
			</template>
		</AppTable>
	</div>
</template>

<script>
import ModalCompanyInfo from "../modal/company-info";
import ModalProductInfo from "../modal/modal-product-info";
import StarRating from "vue-star-rating";
import { mapMutations } from "vuex";

export default {
	data() {
		return {};
	},
	props: {
		items: {
			type: Array,
			default: () => [],
		},
		headerTable: {
			type: Array,
			default: () => [],
		},
	},
	methods: {
		...mapMutations(["setAddCart"]),
		openCompanyModal(item) {
			this.$modal.show("company-info", { ...item });
		},
		openProductModal(item) {
			this.$modal.show("product-info", { ...item, calc: 1 });
		},
		addBasket(item) {
			this.setAddCart({
				...item,
				calc: 1,
				price: item.price,
			});
		},
	},
	components: {
		ModalProductInfo,
		ModalCompanyInfo,
		StarRating,
	},
};
</script>

<style>
</style>