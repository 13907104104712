<template>
	<AppModals
		name="counter-history"
		title="История показаний"
		cancelButtonText="Закрыть"
		hideConfirm
		@beforeOpen="getHistory"
	>
		<template #content="{}">
			<div>
				<div style="padding: 0 0 20px  0">
					Отображаются только показания, переданные через сервис
					«Квартплата.Онлайн»
				</div>

				<div class="">
					<template v-if="loading">
						<div style="height: 90px;padding: 20px 0;">
							<AppLoading :width="40" :height="40" color="#404370" />
						</div>
					</template>

					<div v-else>
						<table
							class="count-history"
							border="0"
							cellpadding="0"
							cellspacing="0"
						>
							<thead>
								<tr>
									<th>Дата передачи</th>
									<th>Значение</th>
								</tr>
							</thead>

							<tbody>
								<tr v-for="(item, index) in dataHistory" :key="index">
									<td>{{ dateReturnFormat(item.date) }}</td>
									<td>{{ item.value }}</td>
								</tr>
							</tbody>
						</table>

						<!-- <AppTable
							:header="header"
							:data="dataHistory"
							messageNoData='История отсутствует'
						>
							<template v-slot:thead="{item}">
								<span>{{ item.name }}</span>
							</template>
							<template v-slot:tbody="{item}">
								<td>
									<span class="app-table__title-mob">
										Дата передачи: 
									</span>
									<span> {{ dateReturnFormat(item.date) }}</span>
								</td>
								<td>
									<span class="app-table__title-mob">
										Значение: 
									</span>
									<span> {{ item.value }}</span>
								</td>
							</template>
						</AppTable> -->

						<!-- <div v-if="!dataHistory.length" style="text-align: center; margin: 10px 0">История отсутствует</div> -->
					</div>
				</div>
			</div>
		</template>
	</AppModals>
</template>

<script>
import { dateReturnFormat } from "@/plugins/methods";
import { mapGetters } from "vuex";

export default {
	data() {
		return {
			loading: false,
			dataHistory: [],
			header: [
				{
					name: "Дата передачи",
					width: "50%"
				},
				{
					name: "Значение",
					width: "50%"
				}
			]
		};
	},
	props: {},

	computed: {
		...mapGetters(["getTheme"])
	},

	methods: {
		modalClose() {
			this.$modal.hide("counter-history");
		},

		dateReturnFormat(date) {
			return dateReturnFormat(date, false);
		},

		async getHistory(event) {
			this.loading = true;
			try {
				this.dataHistory = await this.$store.dispatch(
					"getCounterHistory",
					event.counterId
				);
			} catch (e) {
				this.$errorsMod(e.response);
			}
			this.loading = false;
		}
	}
};
</script>

<style scoped>
@media (max-width: 490px) {
	.remove-modal {
		justify-content: center;
		height: 100%;
		display: flex;
		flex-direction: column;
	}
}
</style>
