<template>
    <a :href="href" class="app-link" :target="target">
        <slot></slot>
    </a>
</template>

<script>
export default {
    props: {
        href: {
            type: String,
            default: ''
        },
        target: {
            type: String,
            default: '_self'
        }
    }
}
</script>