<template>
	<div class="btn-download">
		<img
			class="btn-download__img"
			src="./../../img/cloud-download.svg"
			alt=""
		/>
		<span target="_blank" class="btn-download__text">{{ text }}</span>
	</div>
</template>

<script>
export default {
	props: ["text"],
	
};
</script>

<style scoped>
.btn-download {
	display: flex;
	align-items: center;
	cursor: pointer;
}
.btn-download__img {
	margin-right: 8px;
}
.btn-download__text {
	color: #777776;
	text-decoration: underline;
}
</style>
