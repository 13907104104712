var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('CounterRename'),_c('ModalSendApproved'),(_vm.getCounterActual.settings)?_c('div',[_vm._v("Идут технические работы...")]):(_vm.getCounterActual.counters.length == 0)?_c('div',{staticClass:"d-flex d-column"},[_c('h5',{staticClass:"mb-3 mt-5",style:(_vm.getTheme === 'dark-theme' ? 'color: #fefdff' : '')},[_vm._v(" На этом лицевом счете мы не нашли ни одного счетчика, по которому можно передать показания. Скорее всего, причина в следующем: ")]),_c('ul',{style:(_vm.getTheme === 'dark-theme' ? 'color: #9797A5' : '')},[_c('li',{staticClass:"mt-1"},[_vm._v("1. У приборов учета истек срок поверки.")]),_c('li',{staticClass:"mt-1"},[_vm._v(" 2. Управляющая организация решила снимать показания самостоятельно. ")]),_c('li',{staticClass:"mt-1"},[_vm._v(" 3. Компания, обслуживающая лицевой счет, не предоставила возможность передачи показаний. ")])])]):_c('div',[_c('AppTable',{ref:"tableCounter",staticClass:"table-counter",attrs:{"header":_vm.header,"data":_vm.$v.getCounterActual.counters.$each.$iter,"messageNoData":"Счетчики отсутствуют или показания снимает Ваша управляющая организация","rowTemplate":"dataRowTemplate","rounded":"","masterRow":""},scopedSlots:_vm._u([{key:"thead",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.name))])]}},{key:"dataRowTemplate",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [_c('td',{on:{"click":function($event){return _vm.openInfoCounter(index)}}},[_c('span',{staticClass:"d-flex align-center just-between"},[_c('div',{staticClass:"table-counter__image-block mr-2 mobile-hide"},[_c('img',{staticClass:"table-counter__image",attrs:{"src":_vm.counterImage(item.$model.counterType)}})]),_c('div',{staticClass:"table-counter__text-block"},[_vm._v(" "+_vm._s(item.$model.description || item.$model.serviceName)+" "+_vm._s(!item.$model.description && item.$model.counterNumber ? (" № " + (item.$model.counterNumber)) : "")+" ")])])]),_c('td',[(item.$model.counterWarning > 0 && !item.showInfo)?_c('span',{class:_vm.getStatusColor(item.$model.counterWarning),staticStyle:{"text-align":"center"}},[_vm._v(" Требуется поверка ")]):_vm._e()]),_c('td',[_c('span',{staticClass:"app-table__title-mob"},[_vm._v(" Предыдущее значение: ")]),_c('span',[_vm._v(" "+_vm._s(item.$model.value.toLocaleString("ru", { maximumFractionDigits: 5, }) + " " + item.$model.unitName)+" ")])]),_c('td',[_c('span',[(!item.$model.mustGet)?_c('span',[_c('p',{staticClass:"warning-color medium"},[_vm._v("Снимает организация")])]):_c('span',[(!item.$model.inputValuesAllowance.canSend)?_c('span',{class:'danger-color',staticStyle:{"font-weight":"400"}},[_vm._v(" "+_vm._s(item.$model.inputValuesAllowance.message)+" ")]):_c('span',[_c('span',{staticClass:"app-table__title-mob"},[_vm._v("Текущие значение:")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(item.$model.userValue),expression:"item.$model.userValue"}],staticClass:"app-input__input mt-1--xs",class:item.userValue.$invalid ? 'novalidinput' : '',staticStyle:{"max-width":"300px"},style:(item.$model.userValueLimits &&
                    item.$model.userValueLimits.warningLimit &&
                    !item.userValue.$invalid &&
                    +item.$model.userValue >
                      +item.$model.userValueLimits.warningLimit + +item.$model.value
                      ? 'border: 1px solid var(--color-warning) !important'
                      : ''),attrs:{"type":"text","oninput":"this.value = this.value.replace(',','.').replace(/^\\.|[^\\d\\.]|\\.(?=.*\\.)|^0+(?=\\d)/g, '')"},domProps:{"value":(item.$model.userValue)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(item.$model, "userValue", $event.target.value)}}}),_c('div',{directives:[{name:"show",rawName:"v-show",value:(item.userValue.$invalid),expression:"item.userValue.$invalid"}],staticClass:"danger-color",staticStyle:{"margin-top":"0.5em","font-size":"0.7em","text-align":"center","max-width":"300px"}},[(!item.userValue.required)?_c('span',[_vm._v("Поле обязательно для заполнения")]):(!item.userValue.format)?_c('span',[_vm._v("Не более 6 цифр до запятой и не более 5 после")]):(!item.userValue.minValueTest && item.$model.value)?_c('span',[_vm._v(" Значение должно быть больше, чем предыдущее ("+_vm._s(item.$model.value.toLocaleString())+") ")]):(
                      item.$model.userValueLimits && !item.userValue.errorLimit
                    )?_c('span',[_vm._v(" Ошибка: Расход превышает "+_vm._s(item.$model.userValueLimits.errorLimit.toLocaleString())+" "+_vm._s(item.$model.unitName)+" Передача не возможна! ")]):_vm._e()]),(
                    item.$model.userValueLimits &&
                    item.$model.userValueLimits.warningLimit &&
                    !item.userValue.$invalid &&
                    +item.$model.userValue >
                      +item.$model.userValueLimits.warningLimit + +item.$model.value
                  )?_c('div',{staticStyle:{"margin-top":"0.5em","font-size":"0.7em","text-align":"center","max-width":"300px"}},[(item.$model.userValueLimits.warningLimit)?_c('span',{staticStyle:{"color":"var(--color-warning)"}},[_vm._v(" Внимание! Расход превышает "+_vm._s((+item.$model.userValueLimits.warningLimit).toLocaleString())+" "+_vm._s(item.$model.unitName)+" Проверьте показания! ")]):_vm._e()]):_vm._e()])])])]),_c('td',[_c('div',{staticClass:"d-flex just-end mb-2--xs"},[_c('AppBtn',{attrs:{"outlined":""},nativeOn:{"click":function($event){return _vm.openHistory(item.$model.counterID)}}},[_vm._v(" История ")])],1)]),_c('td',{staticClass:"table-counter__menu"},[_c('div',[_c('AppDropdown',{scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                  var showChange = ref.showChange;
return [_c('AppBtn',{attrs:{"icon":"","text":""},nativeOn:{"click":function($event){return showChange($event)}}},[_c('AppIcon',{staticStyle:{"color":"#777776"},attrs:{"name":"ellipsis-v","size":"1rem"}})],1)]}}],null,true)},[_c('div',{attrs:{"slot":"dropdown"},slot:"dropdown"},[_c('AppList',{attrs:{"listData":_vm.listData},scopedSlots:_vm._u([{key:"list-item",fn:function(){return _vm._l((_vm.listData),function(menu,index){return _c('AppListItem',{key:index,attrs:{"name":menu.name},nativeOn:{"click":function($event){return _vm.clickMenuItem(menu.id, item.$model)}}})})},proxy:true}],null,true)})],1)])],1)])]}},{key:"masterRow",fn:function(ref){
                  var item = ref.item;
return [_c('td',{staticStyle:{"border":"1px solid var(--border-color-dark)","border-radius":"0 0 24px 24px !important","padding-top":"25px"},attrs:{"colspan":"7"}},[_c('div',{class:[_vm.getTheme === 'dark-theme' ? _vm.darkClass : '', _vm.defaultClass]},[_c('div',{staticClass:"counter-table__info-block mb-2"},[_c('div',{staticClass:"counter-table__info-item"},[_c('span',[_vm._v("Счетчик:")]),_vm._v(_vm._s(item.$model.description || item.$model.serviceName || "-")+" ")]),_c('div',{staticClass:"counter-table__info-item"},[_c('span',[_vm._v("Серийный номер:")]),_vm._v(_vm._s(item.$model.serialNumber || "-")+" ")]),_c('div',{staticClass:"counter-table__info-item",staticStyle:{"margin-bottom":"0"}},[_c('span',[_vm._v("Производитель:")]),_vm._v(_vm._s(item.$model.mark || "-")+" ")])]),_c('div',{staticClass:"counter-table__info-block"},[_c('div',{staticClass:"counter-table__info-item"},[_c('span',[_vm._v("Модель:")]),_vm._v(_vm._s(item.$model.model || "-")+" ")]),_c('div',{class:_vm.getVerificationColor(item.$model.counterWarning),staticStyle:{"margin-bottom":"0"}},[_c('span',{class:_vm.getVerificationColor(item.$model.counterWarning)},[_vm._v("Следующая дата поверки:")]),_vm._v(" "+_vm._s(_vm.dateReturnFormat(item.$model.nextVerificationDate) || "-")+" ")])])])])]}}])}),_c('div',{staticClass:"content-text content-text--counter"},[(_vm.getCounterActual.counters.length != 0)?_c('div',{staticClass:"send"},[_c('AppBtn',{attrs:{"loading":_vm.loading,"disabled":!_vm.getCounterActual.counters.some(
              function (item) { return item.inputValuesAllowance.canSend && item.mustGet; }
            ) || _vm.$v.$invalid},nativeOn:{"click":function($event){return _vm.submitCounter($event)}}},[_vm._v(" Отправить ")])],1):_vm._e(),_c('snackbar',{ref:"snackbar"})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }