<template>
    <AppModals style="color: var(--color-primary)" name="town-modal" title="Выберите свой город" cancelButtonText="Закрыть" :clickToClose="false" greyBack
        :maxWidth="900" :width="600" hideConfirm>
        <template #content>
            <div>
                <div style="color: var(--color-secondary)">
                    Выберите свой город, чтоб мы подобрали для Вас доступные товары и услуги.
                </div>
                <div class="select-container">
                    <AppSelect :labelVisible="false" style="width: 216px; height: 40px;" placeholder="Выберите город"
                        valueSelect="id" textSelect="town" :error="false" @selected="selectCity" :value="getActiveTown"
                        :options='getTowns' />
                </div>

            </div>

        </template>
    </AppModals>
</template>

<script>
import AppShopSelectCity from "@/components/components-other/app-shop-select-city";
import { mapGetters } from "vuex";
export default {
    data() {
        return {
        }
    },
    computed: {
        ...mapGetters([
            "getTowns",
            "getActiveTown",
        ]),
    },
    methods:{
        selectCity(town){
            this.$emit('select-city', town)
        }
    },
    components: {
        AppShopSelectCity
    }
}
</script>
<style scoped>
.select-container {
    margin: 1em 0;
}
</style>