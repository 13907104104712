<template>
	<div>
		<div style="margin-top: -5px" class="mt-3--xs">
			<AppTable :header="tableHeader" :data="getAccrualsList.data" messageNoData='Начисления отсутствуют'
				:childRow="false">
				<template v-slot:thead="{ item }">
					<span>{{ item.name }}</span>
				</template>
				<template v-slot:tbody="{ item }">
					<td>
						<span class="app-table__title-mob">
							Период:
						</span>
						<span> {{ item.periodName }}</span>
					</td>
					<td>
						<span class="app-table__title-mob">
							Начальный остаток:
						</span>
						<span> {{ $digitNumber(item.inBalance) }}</span>
					</td>
					<td>
						<span class="app-table__title-mob">
							Начислено за месяц:
						</span>
						<span> {{ $digitNumber(item.sum.toFixed(2)) }}</span>
					</td>
					<td>
						<span class="app-table__title-mob">
							Пени:
						</span>
						<span> {{ item.fine.toFixed(2) }}</span>
					</td>
					<td>
						<span class="app-table__title-mob">
							Поступило:
						</span>
						<span> {{ $digitNumber(item.payed.toFixed(2)) }}</span>
					</td>
					<td style="text-align: right" class="d-flex align-center just-end d-column ">
						<div class="d-flex w-100 just-start">
							<AppBtn
								@click.native="$router.push({ name: 'paymentPage', params: { accountId: item.accountId, sum: item.sum, paymentMoth: true } })"
								color="success" styleCSS="margin-right: 10px; margin-bottom: 5px;">
								Оплатить
							</AppBtn>
							<a v-if="item.invoiceExists" target="_blank"
								:href="`${url}/Accruals/GetInvoice/${item.accountId}?period=${item.periodId}&redirectto=${windowURL}`">
								<AppBtn outlined>
									{{ getTypeButton }}
								</AppBtn>
							</a>
						</div>
					</td>
				</template>
			</AppTable>
		</div>

	</div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
	data() {
		return {

			tableHeader: [
				{
					name: 'Период начисления',
					sort: true
				},
				{
					name: 'Начальный остаток',
					sort: true
				},
				{
					name: 'Начислено за месяц',
					sort: true
				},
				{
					name: 'Пени',
					sort: true
				},
				{
					name: 'Поступило',
					sort: true
				},
				{
					name: '',
					sort: true
				},
			],
			url: process.env.VUE_APP_URL,
			windowURL: window.location,
		}
	},
	mounted() {

	},
	computed: {
		...mapGetters([
			'getAccountDetails', 'getAccrualsList'
		]),

		getTypeButton() {
			switch (this.getAccountDetails.type) {
				case 0:
					return 'Квитанция'
					break;
				case 1:
					return 'Документы'
					break;
				default:
					return 'Квитанция'
					break;
			}
		}
	},
	methods: {

		show(modalName, accountId, sum) {
			this.$modal.show(modalName, { allPayment: false, accountId: accountId, sum: sum, paymentMoth: true })
		},

	},
	components: {

	}
}
</script>
