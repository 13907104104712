<template>
	<div>
		<template v-if="loading">
			<div
				style="
					height: 100%;
					padding: 20px 0px;
					min-height: 300px;
					display: flex;
					justify-content: center;
					align-items: center;
				"
			>
				<AppLoading :width="40" :height="40" color="#404370" />
			</div>
		</template>
		<template v-else>
			<div class="erors-layout" v-if="error">
				<div class="error-layout-img">
					<img
						v-if="getTheme === 'dark-theme'"
						src="/img/dark-theme/error-dark.svg"
					/>
					<img v-else src="/img/no-internet.svg" />
				</div>
				<p
					:class="[
						getTheme === 'dark-theme'
							? darkStyle
							: defaultStyle, margin
					]"
				>
					Отсутствует интернет соединение. Проверьте подключение к сети и
					повторите попытку.
				</p>
				<AppBtn @click.native="reload()"> Повторить </AppBtn>
			</div>

			<template v-else>
				<slot name="body"></slot>
			</template>
		</template>
	</div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
	data() {
		return {
			darkStyle: "error-layout-text-dark",
			defaultStyle: "error-layout-text",
      		margin: "mt-3 mb-3",
			darkLoadingColor: "#FEFDFF",
			defaultLoadingColor: "#404370"  
		};
	},
	props: {
		error: {
			default: false,
			type: Boolean,
		},
		loading: {
			default: false,
			type: Boolean,
		},
	},
	methods: {
		reload() {
			this.$emit("reload");
		},
	},
	computed: {
		...mapGetters(["getTheme"]),
	},
};
</script>

<style scoped>
.erors-layout {
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	position: relative;
	top: 0px;
	height: 600px;
}

.error-layout-text {
	text-align: center;
	font-size: 1.25rem;
	font-weight: 500;
	color: #000;
}

.error-layout-text-dark {
	text-align: center;
	font-size: 1.25rem;
	font-weight: 500;
	color: #fefdff;
}

.button-reload {
	color: #fff;
	background: #90a1b8;
	display: inline-block;
	padding: 13px 55px;
	border: none;
	margin: 20px 0;
}

.error-layout-img {
	width: 300px;
	height: 300px;
}
</style>
