<template>
  <nav class="navigation">
    <div v-for="(item, index) in list" :key="index" @click="sideBarClose">
      <router-link
        class="navigation__item"
        :key="index"
        :to="item.to"
        :title="item.title"
        active-class="navigation__item--active"
      >
        <div class="navigation__icon" style="position: relative">
          <AppIconSvg :name="item.icon" width="1.5rem" height="31px" />
          <div
            class="cart__count nav-bar-counter"
            :class="{ 'animate__animated animate__heartBeat': activeAnim }"
            v-if="getTotalCartCount !== 0 && item.title === 'Магазин'"
          >
            {{ getTotalCartCount }}
          </div>
        </div>

        <div class="navigation__text">
          {{ item.title }}
        </div>
      </router-link>
    </div>
  </nav>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";

export default {
  data() {
    return {
      activeAnim: false,
      list: [
        {
          title: "Главная",
          icon: "home",
          to: "/account/",
        },
        {
          title: "Счетчики",
          icon: "speed",
          to: "/send-cnt/",
        },
        {
          title: "История оплат",
          icon: "card",
          to: "/payment",
        },
        {
          title: "Заявки",
          icon: "signature",
          to: "/request",
        },
        {
          title: "Магазин",
          icon: "cart",
          to: "/shop/main#list",
        },
        {
          title: "Профиль",
          icon: "settings",
          to: "/profile",
        },
        {
          title: "Тех. поддержка",
          icon: "support",
          to: "/support",
        },
        // {
        //   title: "Сообщения",
        //   icon: "ring",
        //   to: "/alerts",
        // },

        /* {
                    title: 'Выйти',
                    icon: 'sign-out-alt',
                    to: '#exit',
                }, */
      ],
    };
  },
  computed: {
    ...mapGetters(["getTotalCartCount"]),
  },
  methods: {
    ...mapMutations(["sideBarMut", "sideBarClose"]),
  },
};
</script>
<style scoped>
.navigation__item.navigation__item--active .cart__count.nav-bar-counter {
  background-color: var(--color-primary);
  color: var(--color-backfround);
}
.cart__count.nav-bar-counter {
  /* color: var(--color-primary); */
  background-color: #ec3932;
}
</style>
