<template>
	<div class="app-tabs">
		<div class="tabs-header">
			<div class="app-tabs__list">
				<div
					v-for="(item, index) in data"
					:key="index"
					class="app-tabs__item"
					@click="tabClick(item, index)"
					@keyup.enter="tabClick(item, index)"
					:class="{
						'app-tabs__item--active': active == index,
						'app-tabs__item--no-border': noBorder
					}"
					v-show="item.show !== false"
					tabindex="0"
				>
					<div
						class="app-tabs__info"
						:class="{
							'app-tabs__info--revers': mobileRevers
						}"
					>
						<i v-if="item.icon" :class="`fas fa-${item.icon} mr-1`"></i>
						<AppIconSvg
							v-if="item.iconSvg"
							:name="item.iconSvg"
							width="32px"
							height="32px"
						/>

						<div>{{ item.name }}</div>
					</div>
				</div>
			</div>
			<slot name="header"></slot>
		</div>
		<div class="app-tabs__content">
			<slot name="content" :active="active" :item="data[active]"></slot>
		</div>
	</div>
</template>

<script>
export default {
	data() {
		return {
			active: 0,
			model: {}
		};
	},
	props: {
		data: {
			type: Array,
			default: []
		},
		noBorder: {
			type: Boolean,
			default: false
		},
		activeTab: {
			type: Number,
			default: 0
		},
		mobileRevers: {
			type: Boolean,
			default: false
		},
		callback: {
			type: Function
		}
	},
	mounted() {
		this.hasActiveTab();
	},
	methods: {
		hasActiveTab() {
			//console.log('this.activeTab', this.activeTab)
			if (this.activeTab > 0) {
				this.active = this.activeTab;
			} else {
				this.hasHistoryLink();
			}
		},

		changeActiveTab(index, item) {
			if (index !== this.active) {
				this.$emit("changeActiveTab", item);
			}
		},
		tabClick(item, index) {
			if (item.callback) {
				item.callback();
			} else {
				this.changeActiveTab(index, item);

				this.active = index;
				if (item.href) {
					window.location.hash = item.href;
				}
			}
		},
		hasHistoryLink() {
			let hashHistory = window.location.hash;

			if (hashHistory) {
				let activeTab = this.data.findIndex(item => {
					let tempHash = "#" + item.href;
					if (tempHash == hashHistory) {
						return true;
					}
				});

				this.active = activeTab;
			}
		},

		hashLinkInitial() {
			if (this.data.length > 0) {
				if (this.data[0].href) {
					window.location.hash = this.data[0].href;
				}
			}
		}
	},
	watch: {
		activeTab(val) {
			this.hasActiveTab();
		}
	}
};
</script>

<style scoped>
.tabs-header {
	display: flex;
	justify-content: space-between;
	flex-wrap: wrap;
}

@media screen and (max-width: 757px) {
	.tabs-header {
		margin-bottom: 1em;
	}
}
</style>
