<template>
    <div>
        <TownModal @select-city="selectCity" />
        <AppBtn @click="showModal" class="city-select-button mr-1" icon text alt="Выбрать город" title="Выбрать город">
            <AppIcon name="city" size="1.25rem" />
        </AppBtn>
        <div class="city-select">
            <AppSelect :labelVisible="false" style="width: 216px; height: 40px;" placeholder="Выберите город"
                valueSelect="id" textSelect="town" :error="false" @selected="selectCity" :value="getActiveTown"
                :options='getTowns' />
        </div>
    </div>
</template>
<script>
import { mapGetters } from "vuex";
import TownModal from "./town-modal.vue";
export default {
    data() {
        return {};
    },
    computed: {
        ...mapGetters([
            "getTowns",
            "getActiveTown",
            "getActiveTownId",
            "getTownLoading",
        ]),
    },
    methods: {
        selectCity({ id }) {
            if (this.getActiveTownId !== id) {
                this.$store.commit("setActiveTown", id);
                this.$store.commit("clearCart");
                if (this.$route.name !== "shop-main" || this.$route.hash !== "#list") {
                    this.$router.push({ name: "shop-main", hash: '#list' });
                } else {
                    this.$store.dispatch("shopGroupList");
                }

            }
        },
        showModal() {
            this.$modal.show('town-modal')
        }
    },
    mounted() {
        if (this.getTowns.length < 1) {
            this.$store.dispatch("fetchTowns").then(() => {
                if (!this.getActiveTownId) {
                    this.showModal()
                    this.$store.commit("setActiveTown", 1)
                }
            })
        }

    },
    components: { TownModal }
}
</script>
<style scoped>
.city-select-button {
    display: none;
}

.city-select {
    margin-right: 1em;
}

@media screen and (max-width: 540px) {
    .city-select-button {
        display: block;
    }

    .city-select {
        display: none;
    }
}
</style>