export function dateReturnFormat (getDateVal, hour) {
	if (hour) {
		let getDate = new Date(getDateVal)
		let newDate = getDateVal == null
			? ''
			: (getDate.getDate() < 10 ? '0' : '') + getDate.getDate() + '.' + ((getDate.getMonth() + 1) < 10 ? '0' : '') + (getDate.getMonth() + 1) + '.' + getDate.getFullYear() + ' (' + getDate.getHours() + ':' + (getDate.getMinutes() < 10 ? '0' : '') + getDate.getMinutes() + ')'
		return newDate
	} else {
		let getDate = new Date(getDateVal)
		let newDate = getDateVal == null
			? ''
			: (getDate.getDate() < 10 ? '0' : '') + getDate.getDate() + '.' + ((getDate.getMonth() + 1) < 10 ? '0' : '') + (getDate.getMonth() + 1) + '.' + getDate.getFullYear()
		return newDate
	}
}

export function getPhone (num, mask) {
	if (mask && mask.split('').filter(item=>item==='X').length===10 && num.length >= 10 && num.length <= 12) {
        const slicedNumber = num.split('').slice(-10)
        const maskArr = mask.split('')
        let counter = 0
        let number = ''
        for (let i = 0; i < maskArr.length; i++) {
            if (maskArr[i] === 'X') {
                number += slicedNumber[counter]
                counter++
            } else {
                number +=maskArr[i]
            }
        }
        return number
    }
    return num
}


export function getNumber (number) {
	if (isFinite(value) && value === parseInt(value, 10)) {
		return number.toFixed(2)
	} else {
		return '0.0'
	}
}

export function digitNumber (number) {
	let digitNumber = (number+ '').split('.')
	return (number >= 0 ? '' : '-') + digitNumber[0].toString().replace(/[^\d]/g, '').replace(/\B(?=(?:\d{3})+(?!\d))/g, ' ') + (digitNumber[1] ? '.' + digitNumber[1] : '') 
}

