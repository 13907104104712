<template>
	<AppCard class="card" v-if="!isTable">
		<template #body>
			<div
				@click="toServicePage"
				class="tech-card"
				:class="{ 'need-service': needService }"
			>
				<div>
					<div class="title">{{ header }}</div>
					<div class="text">
						{{ text }}
					</div>
				</div>
				<div class="img" />
			</div>
		</template>
	</AppCard>
	<div v-else>
		<div
			@click.stop="toServicePage"
			class="tech-card table"
			:class="{ 'need-service': needService }"
		>
			<div class="img" />
			<div>
				<div class="title">{{ header }}</div>
				<div class="text">
					{{ text }}
				</div>
			</div>
		</div>
	</div>
</template>
<script>
import { mapGetters } from "vuex";
export default {
	props: {
		gazType: Number,
		unitId: String,
		isTable: {
			type: Boolean,
			default: false
		}
	},

	data() {
		return {
			header: "Техническое обслуживание"
		};
	},
	computed: {
		...mapGetters(["getGazServiceProviders"]),
		needService() {
			return this.gazType === 1;
		},
		serviceRequested() {
			return this.gazType === 2;
		},
		text() {
			return this.needService
				? "Требуется проведение обслуживания газового оборудования"
				: "Заявка на техническое обслуживание принята";
		}
	},
	methods: {
		async toServicePage() {
			console.log("clc");
			try {
				await this.$store.dispatch("fetchGazServiceInfo", this.unitId);
				if (this.needService && this.getGazServiceProviders.length) {
					this.$router.push({
						name: "tech-service",
						params: {
							unitId: this.unitId
						}
					});
				} else if (this.serviceRequested) {
					this.$modal.show("requested-service-modal");
				}
			} catch (e) {
				this.$errorsMod(e);
			}
		}
	}
};
</script>
<style scoped>
[data-theme="dark-theme"] .tech-card {
	--tech-title-color: #4d8fe9;
	--tech-title-color-warn: #4d8fe9;
	--tech-text-color: #fff;
}

.tech-card {
	--tech-title-color: #072b68;
	--tech-title-color-warn: #f00;
	--tech-text-color: #323232;
	display: flex;
	gap: 10px;
}

.card {
	transition: transform 0.1s linear;
}
.card:hover {
	transform: scale(101%);
}
.img {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 48px;
	flex-shrink: 0;

	background: center / contain no-repeat
		url("../../../img/tech-service/Paper.svg");
}
.need-service .img {
	background: center / contain no-repeat
		url("../../../img/tech-service/Flame.svg");
}

::v-deep .app-card__wrapper {
	padding: 10px 20px;
}
.title {
	margin-top: 10px;
	font-family: Rubik;
	font-size: 17px;
	font-style: normal;
	font-weight: 500;
	line-height: 107.5%;
	color: var(--tech-title-color);
}
.need-service .title {
	color: var(--tech-title-color-warn);
}
.text {
	margin: 10px 0;
	color: var(--tech-text-color);
	font-family: Rubik;
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	line-height: 120%;
}
.table .title {
	margin-top: 0;
	white-space: nowrap;
}
.table .text {
	margin-bottom: 0;
}

.table .img {
	background: center / contain no-repeat
		url("../../../img/tech-service/PaperMini.svg");
}

.table.need-service .img {
	background: center / contain no-repeat
		url("../../../img/tech-service/FlameMini.svg");
}
</style>
