<template>
	<div>
		<AppTable
			:header="headerTable"
			:data="info"
			messageNoData='У Вас нет привязанных лицевых счетов или договоров. Нажмите на кнопку "Добавить", чтобы добавить ЛС или договор.'
			:childRow="false"
			class="table-account"
		>
			<template v-slot:thead="{ item }">
				<span>{{ item.name }}</span>
			</template>

			<template v-slot:tbody="{ item, index }">
				<td @click="routerLink(item, index)" class="pr-5">
					<span v-if="item.description">{{ item.description }}</span>
					<span v-else>{{ item.address }}</span>
				</td>
				<td @click="routerLink(item, index)" class="pr-5">
					<TechnicalServiceCard
						v-if="item.gazType !== 0"
						:gazType="item.gazType"
						:unitId="item.unitId"
						:isTable="true"
					/>
				</td>
				<td @click="routerLink(item, index)">
					<span class="app-table__title-mob">
						Баланс:
					</span>
					<span
					style="white-space: nowrap;"
						:class="{
							'success-color': item.debt <= 0,
							'danger-color': item.debt > 0
						}"
					>
						{{ $digitNumber(item.debt) }} ₽
					</span>
				</td>
				<td></td>
				<td>
					<span class="app-table__title-mob">
						Страховка:
					</span>
				</td>
				<td>
					<div class="d-flex just-end pb-2--xs">
						<AppBtn
							styleCSS="margin-right: 8px"
							@click="
								$router.push({
									name: 'paymentPage',
									params: { apartmentId: item.id }
								})
							"
						>
							Оплатить
						</AppBtn>

						<AppBtn
							v-if="item.mustConfirm"
							color="danger"
							outlined
							@click="$modal.show('adress-confirm', { id: item.id })"
						>
							Подтвердить
						</AppBtn>
					</div>
				</td>
				<td>
					<div class="table-account__menu">
						<AppDropdown>
							<template #activator="{ showChange }">
								<AppBtn icon text @click.native="showChange">
									<AppIcon
										name="ellipsis-v"
										size="15px"
										style="color: #777776"
									/>
								</AppBtn>
							</template>

							<div slot="dropdown">
								<AppList :listData="listData">
									<template #list-item>
										<template v-for="(menu, index) in listData">
											<AppListItem
												:name="menu.name"
												@click.native="clickMenuItem(menu.id, item)"
												:key="index"
											/>
										</template>
									</template>
								</AppList>
							</div>
						</AppDropdown>
					</div>
				</td>
			</template>
		</AppTable>

		<div class="d-flex just-center">
			<AppBtn
				class="mr-1"
				@click.native="
					$router.push({
						name: 'accountAdd',
						params: { text: 'Добавление помещения' }
					})
				"
			>
				Добавить помещение
			</AppBtn>
		</div>
	</div>
</template>

<script>
import TechnicalServiceCard from "@/components/components-other/technical-service-card/index";
export default {
	components: {
		TechnicalServiceCard
	},
	data() {
		return {
			headerTable: [
				{
					name: "Наименование",
					width: "65%"
				},
				{
					name: "",
					width: "40%"
				},

				{
					name: "Баланс",
					width: "10%"
				},
				{
					name: "",
					width: "5%"
				},

				{
					name: "",
					width: "1%"
				}
			],
			listData: [
				{
					id: 0,
					name: "Переименовать"
				},
				{
					id: 1,
					name: "Удалить"
				}
			]
		};
	},
	props: {
		info: {}
	},
	mounted() {},
	methods: {
		routerLink(item) {
			this.$router.push({
				name: "account-flat",
				params: { id: item.id }
			});
		},
		clickMenuItem(id, item) {
			if (id == 0) {
				this.$modal.show("apartment-rename", { ...item });
			} else if (id == 1) {
				this.$modal.show("apartment-remove", {
					name: item.description || item.address,
					id: item.id
				});
			}
		}
	}
};
</script>
<style scoped>
::v-deep .app-table__tr-body td {
	padding: 1em;
}
</style>
