var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"app-input",style:(("padding: 0; border-right: none; display: block; z-index: " + (100 - _vm.index) + "; position: relative"))},[_c('div',{staticStyle:{"padding":"0","border-right":"none","display":"block","z-index":"10","position":"relative"}},[_c('input',{staticClass:"app-input__input",class:{
				'app-input__error-inp': _vm.error,
				'app-input__input--white': _vm.white,
			},attrs:{"type":"text","disabled":_vm.disabled,"oninput":"this.value = (()=>{\n                    const f = x => ( (x.toString().includes('.')) ? (x.toString().split('.').pop().length) : (0) );\n                    this.value = this.value.replace(',','.').replace(/^\\.|[^\\d\\.]|\\.(?=.*\\.)|^0+(?=\\d)/g, '')\n\n\t\t\t\t\tif (+this.value >=999999.99) {\n\t\t\t\t\t\treturn 999999.99\n\t\t\t\t\t}\n\t\t\t\t\t\n                    if(/^(([1-9]\\d*)|\\d)(\\.)?(\\d{1,2})?$/.test(this.value)) {\n                        return this.value.replace(',','.').replace(/^\\.|[^\\d\\.]|\\.(?=.*\\.)|^0+(?=\\d)/g, '')\n                    }\n                    if(f(this.value) > 2) {\n                        return parseFloat(this.value.replace(',','.').replace(/^\\.|[^\\d\\.]|\\.(?=.*\\.)|^0+(?=\\d)/g, '')).toFixed(3).slice(0,-1)\n                    } else {\n                        return this.value.replace(',','.').replace(/^\\.|[^\\d\\.]|\\.(?=.*\\.)|^0+(?=\\d)/g, '')\n                    }\n                })()"},domProps:{"value":_vm.value},on:{"input":function($event){return _vm.changeInput($event.target.value)}}})]),(_vm.required)?_c('div',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
			content: 'Заполните это поле',
			show: true,
			trigger: 'manual',
			placement: 'bottom',
			}),expression:"{\n\t\t\tcontent: 'Заполните это поле',\n\t\t\tshow: true,\n\t\t\ttrigger: 'manual',\n\t\t\tplacement: 'bottom',\n\t\t\t}"}]}):_vm._e(),(_vm.errorMin && !_vm.required)?_c('div',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
			content: 'Сумма должна быть больше 1 руб',
			show: true,
			trigger: 'manual',
			placement: 'bottom',
			}),expression:"{\n\t\t\tcontent: 'Сумма должна быть больше 1 руб',\n\t\t\tshow: true,\n\t\t\ttrigger: 'manual',\n\t\t\tplacement: 'bottom',\n\t\t\t}"}]}):_vm._e(),(_vm.errorMax && !_vm.required)?_c('div',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
			content: 'Сумма не должна быть больше 300 000',
			show: _vm.errorMax && !_vm.required,
			trigger: 'manual',
			placement: 'bottom',
			}),expression:"{\n\t\t\tcontent: 'Сумма не должна быть больше 300 000',\n\t\t\tshow: errorMax && !required,\n\t\t\ttrigger: 'manual',\n\t\t\tplacement: 'bottom',\n\t\t\t}"}]}):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }