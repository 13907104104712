<template>
	<div class="alerts-vars alerts">
		<AlertTabs v-model="tab" :class="tab ? 'hide' : 'show'" />
		<AlertContent
			ref="groupMessages"
			:tab="tab"
			:isMessagingAvailable="isMessagingAvailable"
			:key="key"
		/>
	</div>
</template>
<script>
import AlertTabs from "./components/AlertTabs.vue";
import AlertContent from "./components/AlertContent.vue";
import { mapGetters } from "vuex";

export default {
	components: { AlertTabs, AlertContent },
	data() {
		return {
			tab: null,
			key: "n",
		};
	},
	computed: {
		...mapGetters(["getGroups"]),
		isMessagingAvailable() {
			if (this.getGroups.length && this.tab) {
				const index = this.getGroups.findIndex(item => {
					return item.id == this.tab && item.type === 2;
				});
				if (index > -1) {
					return true;
				}
			}
			return false;
		}
	},
	mounted() {
		if (this.$route.query && this.$route.query.group) {
			this.tab = this.$route.query.group;
		}
	},
	watch: {
		"$route.query": {
			handler: function(val) {
				
				if (val && val.group) {
					this.tab = val.group;
					this.key = val.group
				} else {
					this.tab = null;
					this.key = "null"
				}
			},
			deep: true
		}
	}
};
</script>
<style>
.alerts *::-webkit-scrollbar-track {
	background-color: var(--a-scrollbar-track); /*Цвет скролла*/
}
.alerts *::-webkit-scrollbar-thumb {
	border-radius: 6px; /*Закругление ползунка*/
	background-color: var(--a-scrollbar-thumb); /*Цвет ползунка*/
}
.alerts *::-webkit-scrollbar {
	width: 12px; /*Толщина скролла*/
	height: 12px;
}

.alerts * {
	scrollbar-color: var(--a-scrollbar-track) var(--a-scrollbar-thumb); /*Цвет скролла, цвет ползунка*/
	scrollbar-width: thin; /*Толщина скролла thin, auto*/
}
</style>

<style scoped>
@import url(./fonts/Inter/inter.css);
.alerts-vars {
	--a-bg: white;
	--a-border: none;

	--a-scrollbar-track: #eaeaea;
	--a-scrollbar-thumb: #cfcece;

	--a-group-width: 457px;

	--a-link-title-color: var(--color-secondary);
	--a-link-date-color: var(--color-secondary-three);
	--a-link-subtitle-color: var(--color-secondary-two);
	--a-link-img-bg: var(--color-primary-six);
	--a-link-new-msg-mark: var(--color-primary-two);
	--a-link-border: #d2d2d2;
	--a-link-bg: none;

	--a-active-link-bg: var(--color-primary-six);
	--a-active-link-img-bg: var(--color-secondary-five);

	--a-back-btn-text: var(--color-secondary-four);

	--a-chart-datetime-color: var(--color-secondary-four);

	--a-chart-msg-status: var(--color-primary-two);

	--a-chart-outgoing-msg-bg: var(--color-primary-three);
	--a-chart-outgoing-msg-txt: var(--color-secondary-five);

	--a-chart-incoming-msg-bg: var(--color-primary-six);
	--a-chart-incoming-msg-txt: var(--color-secondary);

	/* --a-chart-textarea-bg: #f5f5f5;
	--a-chart-textarea-icons: #9f9f9f;
	--a-chart-textarea-btn-bg: linear-gradient(
		250.3deg,
		rgba(120, 179, 248, 0.96) -7.89%,
		rgba(70, 126, 230, 0.96) 86.88%
	);
	--a-chart-textarea-btn-text: #fff; */
}
[data-theme="dark-theme"] .alerts-vars {
	--a-bg: var(--sidebar-color);
	--a-border: 1px solid #4b4b54;

	--a-scrollbar-track: #4a4a4a;
	--a-scrollbar-thumb: #8e8d8d;

	--a-link-title-color: #fff;
	--a-link-date-color: #a7a7a7;
	--a-link-subtitle-color: #fff;
	--a-link-img-bg: #8bb6ff33;
	--a-link-new-msg-mark: #4478d7;
	--a-link-border: #444444;
	--a-link-bg: none;

	--a-active-link-bg: #45464a99;
	--a-active-link-img-bg: #8bb6ff4d;

	--a-back-btn-text: #989898;

	--a-chart-datetime-color: #9b9b9b;

	--a-chart-msg-status: #4478d7;

	--a-chart-outgoing-msg-bg: #4478d7;
	--a-chart-outgoing-msg-txt: #fff;

	--a-chart-incoming-msg-bg: #dbe7ff24;
	--a-chart-incoming-msg-txt: #fff;
}

.alerts {
	font-family: "Inter", Arial, Helvetica, sans-serif;
	font-size: 16px;
	background: var(--a-bg);
	border-radius: 32px;
	border: var(--a-border);
	height: calc(100vh - 135px);
	display: flex;
	overflow: hidden;
}
@media (max-width: 1280px) {
	.alerts-vars {
		--a-group-width: 364px;
	}
	.alerts {
		font-size: 14px;
	}
}

@media (max-width: 768px) {
	.alerts-vars {
		--a-group-width: 100%;
	}
	.alerts {
		position: absolute;
		height: calc(100vh - 80px);
		width: 100vw;
		top: 60px;
		left: -20px;
		border-radius: 0;
		z-index: 200;
	}
	.hide {
		display: none;
	}
}
</style>
