<template>
	<AppGlobalLayout
		:loading="getAccountListByAppartment.loading"
		:error="error"
		@reload="listByAppartment"
	>
		<div slot="body">
			<accountRename />

			<remAccount />

			<AccountMove />

			<AccountDisconnect />

			<PaperReceiptsModal
				:invoiceDisabled="invoiceDisabled"
				@refresh="apartmentList"
			/>

			<AppTabs mobileRevers :data="tabs" class="mt-2">
				<template v-slot:content="{ active }">
					<div v-if="active == 0">
						<div
							class="mt-3 d-flex align-center just-between mb-3--xs flex-wrap"
						>
							<h3 class="medium secondary-color">
								Лицевые счета
							</h3>
							<div style="display: flex; gap: 1em">
								<AppBtn
									:disabled="invoiceDisabled === null"
									@click.native="$modal.show('paper-receipts')"
								>
									<template v-if="invoiceDisabled">
										Получать бумажные квитанции
									</template>
									<template v-else="invoiceDisabled">
										Отказаться от бумажных квитанций
									</template>
								</AppBtn>
							</div>
						</div>

						<AppTable
							:header="headerTable"
							:data="getAccountListByAppartment.data"
							messageNoData='У Вас нет привязанных лицевых счетов или договоров. Нажмите на кнопку "Добавить", чтобы добавить ЛС или договор.'
							:childRow="false"
							class="table-account"
						>
							<template v-slot:thead="{ item }">
								<span>{{ item.name }}</span>
							</template>

							<template v-slot:tbody="{ item, index }">
								<td @click="routerLink(item, index)">
									<span>{{ item.type }}</span>
								</td>

								<td @click="routerLink(item)">
									<span class="app-table__title-mob">
										ЛС / Договор:
									</span>

									<span>
										{{ item.number }}
									</span>
								</td>

								<td @click="routerLink(item)">
									<span class="app-table__title-mob">
										Поставщик:
									</span>
									<span> {{ item.organizationName }}</span>
								</td>

								<td @click.self="routerLink(item)">
									<div>
										<span class="app-table__title-mob">
											Баланс:
										</span>
										{{ $digitNumber(item.debt) }} ₽
									</div>
									<div v-if="item.status.isExternal" class="debt-message">
										{{ item.status.message }}
									</div>
								</td>

								<td
									colspan="1"
									class="table-account--border-ver"
									@click.self="routerLink(item)"
								>
									<div class="d-flex just-end" @click.self="routerLink(item)">
										<div class="balance-block__action mt-1">
											<AppBtn
												color="success"
												class="mr-2 mb-1"
												@click="
													$router.push({
														name: 'paymentPage',
														params: { accountId: item.id }
													})
												"
											>
												Оплатить
											</AppBtn>

											<AppBtn outlined @click="routerLink(item)">
												Подробнее
											</AppBtn>
										</div>
									</div>
								</td>
								<td>
									<div class="table-account__menu">
										<AppDropdown>
											<template #activator="{ showChange }">
												<AppBtn icon text @click.native="showChange">
													<AppIcon
														name="ellipsis-v"
														size="1rem"
														style="color: #777776"
													/>
												</AppBtn>
											</template>
											<div slot="dropdown">
												<AppList :listData="listData">
													<template #list-item>
														<AppListItem
															v-for="(menu, index) in listData"
															:name="menu.name"
															@click.native="clickMenuItem(menu.id, item)"
															:key="index"
														/>
														<!-- <AppListItem
															v-for="(menu, index) in listData"
															:name="menu.name"
															@click.native="clickMenuItem(menu.id, item)"
															:key="index"
															v-show="showMenuItem(item, menu)"
														/> -->
													</template>
												</AppList>
											</div>
										</AppDropdown>
									</div>
								</td>
							</template>
						</AppTable>
					</div>
					<div v-if="active == 1">
						<Insurance />
					</div>
				</template>
			</AppTabs>

			<snackbar ref="snackbar" />
		</div>
	</AppGlobalLayout>
</template>

<script>
import remAccount from "./modal/account-remove";
import { mapGetters, mapActions } from "vuex";

import { dateReturnFormat } from "@/plugins/methods";
import AppCardAccount from "@/components/components-other/app-card-account";
import AccountRename from "./modal/account-rename";
import AccountMove from "./modal/account-move";
import AccountDisconnect from "./modal/account-disconnect";
import AppCaruosel from "@/components/app-caruosel/";

import Insurance from "../insurance/index.vue";
import PaperReceiptsModal from "./modal/PaperReceiptsModal.vue";

export default {
	data() {
		return {
			error: false,
			helperActive: false,
			hasProductsSuppliers: [],
			headerTable: [
				{
					name: "Имя"
				},
				{
					name: "ЛС / Договор"
				},
				{
					name: "Поставщик"
				},
				{
					name: "Баланс"
				},
				{
					name: ""
				},
				{
					name: ""
				}
			],
			listData: [
				{
					id: 0,
					name: "Переименовать"
				},
				{
					id: 1,
					name: "Перенести"
				},
				{
					id: 2,
					name: "Удалить"
				}
				// {
				// 	id: 3,
				// 	name: "Открепить"
				// }
			]
		};
	},
	computed: {
		...mapGetters(["getAccountListByAppartment", "getBanners", "getApartment"]),

		tabs() {
			const tabs = [
				{
					name: "Лицевые счета",
					iconSvg: "personal-score"
				},
				{
					name: "Страховка",
					iconSvg: "insurance"
				}
			];
			if (this.hasProductsSuppliers.length) {
				return [
					...tabs,
					{
						name: "Услуги УК",
						iconSvg: "uk-service",
						callback: () => {
							this.$router.push({
								name: "shop-main",
								query: { apartmentId: this.$route.params.id }
							});
						}
					}
				];
			} else return tabs;
		},

		invoiceDisabled() {
			const currentApartment = this.getApartment.data.find(
				item => item.id == this.$route.params.id
			);
			if (currentApartment) {
				return currentApartment.invoiceDisabled;
			} else return null;
		}
	},
	methods: {
		...mapActions(["apartmentList"]),
		// showMenuItem(item, itemMenu) {
		// 	if (item.canSplit === false && itemMenu.id == 3) {
		// 		return false;
		// 	}
		// 	return true;
		// },

		routerLink(item) {
			this.$router
				.push({ name: "accountPage", params: { id: item.id } })
				.catch(err => {
					console.log("error", err);
				});
		},

		async listByAppartment() {
			this.error = false;
			try {
				await this.$store.dispatch("listByAppartment", this.$route.params.id);
			} catch (e) {
				if (e.response) {
					this.$errorsMod(e.response);
				} else {
					this.error = true;
					this.$errorsMod(e.response);
				}
			}
		},

		dateReturnFormat(date) {
			return dateReturnFormat(date, true);
		},

		clickMenuItem(id, item) {
			if (id == 0) {
				this.$modal.show("account-rename", item);
			} else if (id == 2) {
				this.$modal.show("account-remove", {
					accountName: item.number,
					accountId: item.id
				});
			} else if (id == 1) {
				this.$modal.show("account-move", {
					accountId: item.id,
					currentApartmentId: 1
				});
			} else if (id == 3) {
				// this.$modal.show("account-disconnect", {
				// 	accountName: item.number,
				// 	accountId: item.id
				// });
			}
		},
		async apartmentBannersLoad() {
			this.error = false;
			try {
				await this.$store.dispatch("apartmentBanners");
			} catch (e) {
				if (e.response) {
					this.$errorsMod(e.response);
				} else {
					this.error = true;
					this.$errorsMod(e.response);
				}
			}
		},
		async fetchApartmentProducts() {
			try {
				this.hasProductsSuppliers = (
					await this.$store.dispatch(
						"fetchApartmentProducts",
						this.$route.params.id
					)
				)
					.filter(item => item.hasProducts)
					.map(item => item.organizationId);
			} catch (error) {
				console.log(error);
			}
		}
	},
	mounted() {
		this.listByAppartment();
		this.apartmentBannersLoad();
		this.apartmentList();
		this.fetchApartmentProducts();
	},

	components: {
		remAccount,
		AccountMove,
		AppCardAccount,
		AccountRename,
		AccountDisconnect,
		AppCaruosel,
		Insurance,
		PaperReceiptsModal
	}
};
</script>
<style scoped>
.debt-message {
	color: var(--color-danger);
	font-size: 0.8em;
	margin-top: 4px;
}
</style>
