<template>
    <span>
    <header class="header">
        <div class="header__left">
            <div 
                class="header__mobile-menu"
                :class="{
                    'header__open-menu': sidebarOpen
                }"
                @click="sideBarMut"
            >
                <span></span>
            </div>
            <div class="header__title-block">
                <span class="header__title">{{ $route.meta.name }}</span>
            </div>
            <!-- <div class="header__bread-crumbs" v-if="$route.meta.breadcrumbsDynamic">
                <span>{{  $route.meta.breadcrumbsDynamic ? $route.meta.name+ ' / ' + this.$store.getters.getAccountDetails.address  : '' }}</span>
            </div> -->
        </div>

        <HeaderBalance />
    </header>

    </span>
</template>

<script>
import HeaderBalance from './header-balance'
//import accountRename from './modal/account-rename'

import { mapGetters, mapMutations } from 'vuex'

export default {
    data() {
        return {
            
        }
    },
    components: {
        HeaderBalance
    },
    computed: {
        ...mapGetters([
            'sidebarOpen'
        ])
    },
    methods: {
        ...mapMutations([
            'sideBarMut'
        ])
    },
	watch: {
		'$route' (to) {
            // console.log('to', this.$store.getters.getAccountDetails.address)
            
		}
	}
}
</script>
