<template>
  <div class="account-add mt-3">
    <div class="account-add__content">
      <div class="add-account__sub-title">
        Если вы юридическое лицо, то вместо лицевого счета введите номер договора с
        обслуживающей организацией
      </div>
    </div>
    <form action="#" @submit="searchAccount">
      <div class="mt-2 account-add__field">
        <AppInput placeholder="Лицевой счет или договор" type="text" v-model.trim="$v.form.ls.$model" required
          :requiredError="$v.form.ls.$required" :error="$v.form.ls.$error" label="">
          <template slot="required">Заполните это поле</template>
        </AppInput>

        <AppBtn class="account-add__button" @click.native="searchAccount" :loading="getSearchById.loading">
          Поиск
        </AppBtn>
      </div>
    </form>

    <div class="account-add__block-info" v-if="getSearchById.data.length && !getSearchById.loading && !noResult">
      <template>
        <div class="account-add__row">
          <div class="account-add__title">
            Адрес
          </div>
          <div class="account-add__text">
            {{getSearchById.data[0].address}}
          </div>
        </div>
        <div class="account-add__row">
          <div class="account-add__title">Организация:</div>
          <div class="account-add__text">
            {{ getSearchById.data[0].organizationName }}
          </div>
        </div>
      </template>
    </div>

    <div class="account-add__error" v-if="noResult && !getSearchById.loading">
      <div class="account-add__image">
        <img v-if="getTheme !== 'dark-theme'" src="/img/not-fount.svg" width="180px" />
        <img v-else src="/img/dark-theme/not-found-man-dark.svg" width="180px" />
      </div>
      <div class="account-add__description">
        По вашему запросу ничего не найдено, проверьте введённые данные.
      </div>
    </div>

    <div class="mt-3">
      <AppBtn @click.native="prevStep()" outlined class="mr-1"> Назад </AppBtn>
      <AppBtn @click.native="addAccount()" :loading="getAccountAdd.loading"
        :disabled="noResult || getSearchById.loading || !getSearchById.data.length">
        Добавить
      </AppBtn>
    </div>
  </div>
</template>

<script>
import { required } from "vuelidate/lib/validators";
import { mapGetters, mapMutations } from "vuex";

export default {
  data() {
    return {
      form: {
        ls: "",
      },
      noResult: false,
      additinalStep: false,
    };
  },
  props: {
    accountSearchMessage: {
      type: String,
      default: "Для поиска своего помещения введите номер лицевого счета",
    },
  },
  computed: {
    ...mapGetters([
      "getAccountAddSelectedIdOrg",
      "getSearchById",
      "getAccountAdd",
      "getAccountAddDivisionIdOrg",
      "getTheme",
    ]),
  },
  methods: {
    ...mapMutations(["setSearchByIdData"]),
    prevStep() {
      this.$v.form.$reset();
      this.form.ls = "";
      this.setSearchByIdData([]);
      this.nextStep(1);
    },
    nextStep(step) {
      this.$emit("stepNext", step);
    },

    async addAccount() {
      this.$v.form.$touch();
      if (!this.$v.form.$error) {
        try {
          await this.$store.dispatch("accountAdd", this.getSearchById.data[0].id);
          this.$router.push('/account/')
        } catch (e) {
          this.$errorsMod(e.response);
        }
      }
    },

    async searchAccount(e) {
      e.preventDefault();
      this.$v.form.$touch();

      if (!this.$v.form.$error) {
        try {
          await this.$store.dispatch("searchById", {
            orgId: this.getAccountAddSelectedIdOrg,
            number: this.$v.form.ls.$model.toLocaleUpperCase(),
            divisionId: this.getAccountAddDivisionIdOrg,
          });

          if (this.getSearchById.data.length) {
            this.noResult = false;
          } else {
            this.noResult = true;
          }
        } catch (e) {
          this.$errorsMod(e.response);
        }
      }
    },
  },
  validations: {
    form: {
      ls: {
        required,
      },
    },
  },
};
</script>
