
var fileDownload = require('js-file-download');
import $axios from '@/plugins/axios-instance'

export default {
	state: {
		paymentList: {
			data: [],
			loading: false,
			error: false
		},

		preparePaymentsList: {
			data: [],
			loading: false,
			error: false
		},
		payOrder: {
			data: {

			},
			loading: false,
			error: false
		},
		selectedCad: {
			pan: '',
			id: '',
			selected: false
		},
	},

	mutations: {
		addToPaymentList(state, data) {
			state.paymentList.data = [...state.paymentList.data, ...data]
		},
		setPaymentList(state, data) {
			state.paymentList.data = data
		},
		setPaymentListLoad(state, load) {
			state.paymentList.loading = load
		},



		setPreparePaymentsList(state, data) {
			state.preparePaymentsList.data = data
		},
		setPreparePaymentsListLoad(state, load) {
			state.preparePaymentsList.loading = load
		},



		setPayOrderData(state, data) {
			state.payOrder.data = data
		},
		setPayOrderDataLoad(state, load) {
			state.payOrder.loading = load
		},

		setSelectedCard(state, data) {
			state.selectedCad.pan = data.pan
			state.selectedCad.id = data.id
			state.selectedCad.selected = data.id ? true : false
		},
		setSelectedNewCard(state, data) {
			state.selectedCad.pan = ''
			state.selectedCad.id = ''
			state.selectedCad.selected = false
		}
	},

	getters: {
		getPaymentList(state) {
			return state.paymentList
		},
		getPreparePaymentsList(state) {
			return state.preparePaymentsList
		},
		getPayOrderData(state) {
			return state.payOrder
		},
		getSelectedCard(state) {
			return state.selectedCad
		}
	},

	actions: {

		paymentList(context, page) {
			if (page === 1) {
				context.commit('setPaymentListLoad', true)
			}

			return $axios.get(`/Payment/List?skip=${(page - 1) * 50}`)
				.then((response) => {
					for (let item of response.data) {
						item.loading = false
					}
					if (page === 1) {
						context.commit('setPaymentList', response.data)
					} else if (page > 1) {
						context.commit('addToPaymentList', response.data)
					}
					return response.data
				}).catch((e) => {
					throw (e)
				}).finally(() => {
					if (page < 2) {
						context.commit('setPaymentListLoad', false)
					}
				})
		},




		preparePayments(context, data) {
			context.commit('setPreparePaymentsListLoad', true)

			return $axios.get(`/Payment/PreparePayments`)
				.then((response) => {

					// for (let item of arrayOfAccounts) {
					// 	item.valid = true
					// }
					// Изменился АПИ, теперь передается не массив с лицевыми счетами,
					//  а массив квартир в котором массив лицевых счетов, 
					// нужно получить массив лицевых счетов с полем apartmentId
					const arrayOfAccounts = response.data.reduce((ac, item) => {
						const result = [...ac, ...item.accounts.map(val => ({ ...val, apartmentId: item.apartmentId, valid: true }))]
						return result
					}, [])
					context.commit('setPreparePaymentsList', arrayOfAccounts)
					return arrayOfAccounts
				}).catch((e) => {
					throw (e)
				}).finally(() => {
					context.commit('setPreparePaymentsListLoad', false)
				})
		},


		preparePaymentsByAccount(context, data) {
			context.commit('setPreparePaymentsListLoad', true)

			return $axios.get(`/Payment/PreparePaymentsByAccount${data ? '?accountId=' + data.accountId : ''}`)
				.then((response) => {
					const arrayOfAccounts = response.data.reduce((ac, item) => {
						const result = [...ac, ...item.accounts.map(val => ({ ...val, apartmentId: item.apartmentId, valid: true }))]
						return result
					}, [])
					context.commit('setPreparePaymentsList', arrayOfAccounts)
					return arrayOfAccounts
				}).catch((e) => {
					throw (e)
				}).finally(() => {
					context.commit('setPreparePaymentsListLoad', false)
				})
		},

		preparePaymentsByApartment(context, data) {
			context.commit('setPreparePaymentsListLoad', true)

			return $axios.get(`/Payment/PreparePaymentsByApartment?apartmentId=${data.apartmentId}`)
				.then((response) => {
					// for (let item of arrayOfAccounts) {
					// 	item.valid = true
					// }
					// Изменился АПИ, теперь передается не массив с лицевыми счетами,
					//  а массив квартир в котором массив лицевых счетов, 
					// нужно получить массив лицевых счетов с полем apartmentId
					const arrayOfAccounts = response.data.reduce((ac, item) => {
						const result = [...ac, ...item.accounts.map(val => ({ ...val, apartmentId: item.apartmentId, valid: true }))]
						return result
					}, [])
					context.commit('setPreparePaymentsList', arrayOfAccounts)
					return arrayOfAccounts
				}).catch((e) => {
					throw (e)
				}).finally(() => {
					context.commit('setPreparePaymentsListLoad', false)
				})
		},

		prepareCheckState(context, id) {
			return $axios.get(`payment/CheckState?id=${id}`)
				.then((response) => {
					return response.data
				}).catch((e) => {
					throw (e)
				})
		},

		canSaveCard(context, id) {
			return $axios.get(`payment/canSaveCard/${id}`)
				.then((response) => {
					return response.data
				})
				.catch((e) => {
					throw(e)
				})
		},

		payOrder(context, data) {
			context.commit('setPayOrderDataLoad', true)
			return $axios.post(`/Payment/PayOrder`, data.payload) //?cardid=${data.id}
				.then((response) => {
					context.commit('setPayOrderData', response.data)
					return response.data
				}).catch((e) => {
					throw (e)
				}).finally(() => {
					context.commit('setPayOrderDataLoad', false)
				})

		},

		payOrderSelected(context, data) {
			context.commit('setPayOrderDataLoad', true)
			return $axios.post(`/Payment/PayOrder/`, data.payload)
				.then((response) => {
					context.commit('setPayOrderData', response.data)
					return response.data
				}).catch((e) => {
					throw (e)
				}).finally(() => {
					context.commit('setPayOrderDataLoad', false)
				})

		},

		getCheck(context, data) {
			return $axios.get(`/Payment/getCheck/${data.id}`, {
				responseType: 'blob'
			})
				.then((response) => {
					context.state.paymentList[data.index].loading = true
					setTimeout(() => {
						context.state.paymentList[data.index].loading = false
					}, 2000);

					const file = new Blob(
						[response.data],
						{ type: 'application/pdf' });
					//const fileURL = URL.createObjectURL(file);
					//window.open(fileURL);

					if (window.navigator && window.navigator.msSaveOrOpenBlob) {
						window.navigator.msSaveOrOpenBlob(file, 'check.pdf');
					}
					else {
						var objectUrl = URL.createObjectURL(file);
						if (data.blockedDetekted) {
							fileDownload(file, 'чек.pdf')
						} else {
							let testWindow = window.open(objectUrl);

							if (!testWindow || testWindow.closed || typeof testWindow.closed == 'undefined') {
								fileDownload(file, 'чек.pdf')
							}
						}
					}
				}).catch((e) => {
					throw (e)
				})


		},


		addCard(context, id) {
			return $axios.post(`/payment/savecard/${id}`)
				.then(() => {
					return true
				}).catch((e) => {
					throw (e)
				})
		},

	}
}
