<template>
    <div class="step__content">
        <slot>

        </slot>
    </div>
</template>


<script>
export default {
    data() {
        return {

        }
    }
}
</script>