<template>
	<AppModals
		name="add-application"
		title="Создание заявки"
		confirmButtonText="Отправить"
		cancelButtonText="Закрыть"
		@confirm="userRequestCreated"
		:loadingConfirm="loading"
		:disabledConfirm="sumSize > 6 || !$v.form.$anyDirty"
		:clickToClose="false"
		@beforeOpen="beforeOpen"
		:maxWidth="750"
		:width="750"
		:hideConfirm="!form.selectAccount.canSendRequest"
	>
		<template #content="{}">
			<div class="modal-form">
				<!-- <div>{{ { ...form, selectAccount: '', selectAddress: '' } }}</div> -->
				<div class="mb-2">
					<AppSelect
						placeholder="Выберите адрес"
						label="Адрес"
						valueSelect="id"
						:options="dataAddress"
						textSelect="address"
						v-model="$v.form.selectAddress.$model"
						:error="$v.form.selectAddress.$error ? 'Выберите адрес' : ''"
						@input="changeAddress"
					/>
				</div>

				<div v-show="form.selectAccount.canSendRequest">
					<div
						class="mb-2"
						v-if="form.selectAddress.accounts.length > 1"
						:key="'organizationName'"
					>
						<AppSelect
							placeholder="Выберите лицевой счет"
							label="Лицевой счет"
							valueSelect="id"
							:options="form.selectAddress.accounts"
							textSelect="organizationName"
							v-model="$v.form.selectAccount.$model"
							:error="$v.form.selectAccount.$error ? 'Выберите лс' : ''"
							@input="changeAccount"
						>
							<template #item="{ item }">
								<div>
									{{ `${item.organizationName} - ${item.accountName}` }}
								</div>
							</template>
							<template #selected="{ item }">
								<div>
									{{
										item.organizationName && item.accountName
											? `${item.organizationName} -
                                                                    ${item.accountName}`
											: ""
									}}
								</div>
							</template>
						</AppSelect>
					</div>

					<div
						class="mb-2"
						v-if="subjectsParents.length > 1"
						:key="'theme-change'"
					>
						<AppSelect
							placeholder="Выберите тему"
							label="Выберите тему"
							valueSelect="id"
							@input="changeSubject"
							:options="subjectsParents"
							textSelect="name"
							v-model="$v.form.selectSubject.$model"
							:error="$v.form.selectSubject.$error ? 'Выберите тему' : ''"
						/>
					</div>
					<div
						class="mb-2"
						v-if="subjectsChildren.length > 1"
						:key="'theme-change-child'"
					>
						<AppSelect
							placeholder="Выберите подкатегорию"
							label="Выберите подкатегорию"
							valueSelect="id"
							:options="subjectsChildren"
							textSelect="name"
							@input="changeSubjectChild"
							v-model="$v.form.selectSubjectChild.$model"
							:error="$v.form.selectSubjectChild.$error ? 'Выберите тему' : ''"
						/>
					</div>
					<div
						class="mb-2"
						v-if="subjectsWorkTypes.length > 1"
						:key="'theme-change-child'"
					>
						<AppSelect
							placeholder="Выберите тип работ"
							label="Выберите тип работ"
							valueSelect="id"
							:options="subjectsWorkTypes"
							textSelect="name"
							v-model="$v.form.selectSubjectWorkType.$model"
							:error="
								$v.form.selectSubjectWorkType.$error ? 'Выберите тему' : ''
							"
						/>
					</div>

					<div
						class="mb-2"
						v-if="
							form.selectAccount.userRequestSubjects.length == 0 ||
								form.selectSubject.id === 'other'
						"
						key="theme-input"
					>
						<AppInput
							placeholder="Тема обращения"
							type="text"
							v-model.trim="$v.form.subject.$model"
							:error="$v.form.subject.$error"
							required
							:requiredError="$v.form.subject.required.$error"
							label="Тема обращения"
						>
							<template slot="required">Заполните это поле</template>
						</AppInput>
					</div>

					<div>
						<AppTextarea
							placeholder="Ваше сообщение"
							type="text"
							v-model.trim="$v.form.message.$model"
							:error="$v.form.message.$error"
							required
							:requiredError="$v.form.message.required.$error"
							label="Ваше сообщение"
							:disabled="false"
							rows="4"
						>
							<template slot="required">Заполните это поле</template>
						</AppTextarea>
					</div>

					<AddFileForm @changeList="changeListFiles" />
				</div>

				<div
					style="text-align: center"
					v-show="!form.selectAccount.canSendRequest"
				>
					Создание заявок по данному адресу отключено поставщиком услуги.
					<br />
					<br />
					Просьба обращаться по телефону:
					<a
						class="link_phone"
						:href="'tel:' + form.selectAccount.phoneForRequests"
					>
						{{ form.selectAccount.phoneForRequests }}</a
					>
					<br />
					<br />
				</div>
			</div>
		</template>
	</AppModals>
</template>

<script>
import { required } from "vuelidate/lib/validators";
import AddFileForm from "./files-add";

const emptySubjectChild = {
	id: null,
	parentId: null,
	name: "Не выбрано"
};
const emptySubject = {
	id: null,
	name: "Не выбрано"
};
const emptyWorkType = {
	id: null,
	name: "Не выбрано"
};
const otherSubject = {
	id: "other",
	name: "Другое"
};

export default {
	props: ["loading"],
	data() {
		return {
			dataAddress: [],
			form: {
				subject: "",
				message: null,
				files: [],
				selectAccount: {
					accountId: null,
					accountName: null,
					organizationName: null,
					userRequestSubjects: [],
					canSendRequest: true,
					phoneForRequests: null
				},
				selectAddress: {
					address: "",
					apartmentId: null,
					accounts: []
				},
				selectSubject: { ...emptySubject },
				selectSubjectChild: { ...emptySubjectChild },
				selectSubjectWorkType: { ...emptyWorkType }
			}
		};
	},
	mounted() {
		this.loadInfo();
	},
	computed: {
		sumSize() {
			return this.form.files.reduce(function(total, item) {
				return total + item.size / 1000000;
			}, 0);
		},
		subjectsParents() {
			return [
				...this.form.selectAccount.userRequestSubjects.filter(
					item => !item.parentId
				),
				{ ...otherSubject }
			];
		},
		subjectsChildren() {
			return [
				{ ...emptySubjectChild },
				...this.form.selectAccount.userRequestSubjects.filter(item => {
					if (item.parentId) {
						return item.parentId === this.form.selectSubject.id;
					} else return false;
				})
			];
		},
		subjectsWorkTypes() {
			return [
				{ ...emptyWorkType },
				...this.form.selectAccount.userRequestSubjects.filter(item => {
					if (item.parentId) {
						return item.parentId === this.form.selectSubjectChild.id;
					} else return false;
				})
			];
		}
	},
	methods: {
		async loadInfo() {
			try {
				this.dataAddress = await this.$store.dispatch("userRequestInfo");
			} catch (e) {
				this.$errorsMod(e.response);
			}
		},

		async userRequestCreated() {
			this.$v.$touch();

			if (!this.$v.form.$invalid) {
				try {
					const tempForm = new FormData();

					if (this.form.selectSubjectWorkType.id) {
						tempForm.append("Subject", this.form.selectSubjectWorkType.name);
						tempForm.append("SubjectId", this.form.selectSubjectWorkType.id);
					} else if (this.form.selectSubjectChild.id) {
						tempForm.append("Subject", this.form.selectSubjectChild.name);
						tempForm.append("SubjectId", this.form.selectSubjectChild.id);
					} else if (
						this.form.selectSubject.id &&
						this.form.selectSubject.id !== "other"
					) {
						tempForm.append("Subject", this.form.selectSubject.name);
						tempForm.append("SubjectId", this.form.selectSubject.id);
					} else {
						tempForm.append("Subject", this.form.subject);
					}

					tempForm.append("AccountId", this.form.selectAccount.accountId);
					tempForm.append("Message", this.form.message);

					for (let item of this.form.files) {
						tempForm.append("Files", item);
					}
					this.$emit("sendRequest", tempForm);
				} catch (e) {
					this.$errorsMod(e.response);
				}
			}
		},

		beforeOpen() {
			this.$v.$reset();
			this.form = {
				subject: null,
				message: null,

				files: [],
				selectAccount: {
					accountId: null,
					accountName: null,
					organizationName: null,
					userRequestSubjects: [],
					canSendRequest: true,
					phoneForRequests: null
				},
				selectAddress: {
					address: "",
					apartmentId: null,
					accounts: []
				},
				selectSubject: { ...emptySubject },
				selectSubjectChild: { ...emptySubjectChild },
				selectSubjectWorkType: { ...emptyWorkType }
			};
		},

		changeAddress(val) {
			this.form.selectAccount = {
				accountId: null,
				accountName: null,
				organizationName: null,
				userRequestSubjects: [],
				canSendRequest: true,
				phoneForRequests: null
			};
			if (val.accounts.length == 1) {
				this.form.selectAccount = { ...val.accounts[0] };
				this.form.selectSubject = { ...emptySubject };
				this.form.selectSubjectChild = { ...emptySubjectChild };
				this.form.selectSubjectWorkType = { ...emptyWorkType };
			} else {
				this.form.selectAccount.accountId = null;
			}
		},

		changeAccount(val) {
			this.form.selectSubject = { ...emptySubject };
		},
		changeSubject(val) {
			this.form.selectSubjectChild = { ...emptySubjectChild };
		},
		changeSubjectChild(val) {
			this.form.selectSubjectWorkType = { ...emptyWorkType };
		},

		changeListFiles(list) {
			this.form.files = [...list];
		}
	},
	validations(val) {
		return {
			form: {
				subject: {
					required:
						this.form.selectAccount.userRequestSubjects.length == 0 ||
						this.form.selectSubject.id === "other"
							? required
							: false
				},
				message: {
					required
				},
				selectAccount: {
					accountId: {
						required:
							this.form.selectAddress.accounts.length > 1 ? required : false
					}
				},
				selectAddress: {
					address: {
						required
					}
				},
				selectSubject: {
					id: {
						required:
							this.form.selectAccount.userRequestSubjects.length > 0
								? required
								: false
					}
				},
				selectSubjectChild: {
					id: {
						required: false
					}
				},
				selectSubjectWorkType: {
					id: {
						required: false
					}
				}
			}
		};
	},
	components: {
		AddFileForm
	}
};
</script>
