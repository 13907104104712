<template>
	<div class="account-add">
		<div class="app-modal__close-video" @click="$emit('nextStepVideo', false)">
			<i class="fas fa-arrow-left"></i>
		</div>

		<div class="account-add__content">
			<div class="account-add__error" v-if="error">
				{{ error }}
				<div class="account-add__skip" @click="manualData">
					<span>Ввести данные вручную.</span>
				</div>
			</div>

			<transition name="fade">
				<div class="fullscreen" v-show="!result">
					<qrcode-stream @decode="onDecode" @init="onInit">
						<div class="account-add__alert" v-if="loading">
							<div class="account-add__permission">
								<div class="account-add__icon account-add__icon--photo">
									<img src="@/img/camera-permission-m.svg" />
								</div>
								<div class="account-add__title account-add__title--permission">
									Необходим доступ к камере
								</div>
								<div class="account-add__text">
									Чтобы сканировать QR-код нам необходим доступ к камере.
									Нажмите «Разрешить» для продолжения.
								</div>
							</div>
						</div>
					</qrcode-stream>
				</div>
			</transition>

			<Filials
				:filial="searchOrganization"
				v-if="stepFilial"
				@prevStep="prevStep"
				@searchAddAccount="searchAddAccount"
			/>

			<div class="account-add__adding" v-show="addingFlag">
				<div v-if="loadingAdd">
					<AppLoading :width="40" :height="40" color="#404370" />
					<div class="account-add__text">Подождите идет добавление</div>
				</div>

				<transition name="fade">
					<div class="account-add__success" v-show="successAdd">
						<div class="account-add__image">
						<!-- 	<img src="/img/success.svg" width="180px" height="180px" /> -->
                            <img v-if="getTheme !== 'dark-theme'" src="/img/success.svg" width="180px" height="180px" />
							<img v-else src="/img/dark-theme/succsess-man-dark.svg" width="180px" height="180px" />
						</div>
						<div class="account-add__description">Успешно</div>
					</div>
				</transition>

				<transition name="fade">
					<div class="account-add__noresult mt-2" v-show="noResult">
						<div class="account-add__image">
							<img v-if="getTheme !== 'dark-theme'" src="/img/not-fount.svg" width="180px"/>
							<img v-else src="/img/dark-theme/not-found-man-man-dark.svg" width="180px"/>
						</div>

						<div class="account-add__description">
							По вашему запросу ничего не найдено
						</div>

						<div class="account-add__button">
							<AppBtn @click.native="prevStep()">
								Ввести данные вручную
							</AppBtn>

							<AppBtn @click.native="$router.push({ path: '/account' })">
								Закрыть
							</AppBtn>
						</div>
					</div>
				</transition>
			</div>
		</div>
	</div>
</template>



<script>
import { QrcodeStream } from "vue-qrcode-reader";
import { mapGetters } from 'vuex';
import Filials from "./filials";

export default {
	data() {
		return {
			result: "",
			error: "",
			loading: false,
			loadingAdd: false,
			successAdd: false, // успешное добавление
			noResult: false,
			stepFilial: false, // флаг показывающий выбор филиала перед добавлением ЛС
			searchOrganization: [],
			addingFlag: true, //флаг процесса добавления ЛС (анимация)
		};
	},

	mounted() {},

	computed: {
		...mapGetters(["getTheme"]),
	},

	methods: {
		onDecode(data) {
			let reg = /(?:([^|]+)?=)?([^|]+)/g;
			let current,
				i = 1,
				tempResult = {};
			while ((current = reg.exec(data))) {
				tempResult[current[1] || "noname" + i++] =
					(+current[2]).toString() === current[2] ? +current[2] : current[2];
			}

			let result = {};
			//приводим к верхнему регистру
			for (let item in tempResult) {
				let nameUpper = item.toUpperCase();
				result[nameUpper] = tempResult[item];
			}

			if (result.PAYEEINN === undefined || result.PERSACC === undefined) {
				alert("Данный QR-code не поддерживается!");
			} else {
				this.result = result;
				this.ddAccount();
			}
		},
		prevStep() {
			this.$emit("manualStep");
		},

		closeModal() {
			//this.$modal.hide('account-add-new')
		},

		async ddAccount() {
			this.loadingAdd = true;

			try {
				this.searchOrganization = await this.$store.dispatch(
					"searchInn",
					this.result.PAYEEINN
				);

				if (this.searchOrganization.length == 1) {
					//если найдена только одна организация
					await this.searchAddAccount(this.searchOrganization[0].id);
				} else if (this.searchOrganization.length > 1) {
					//если найдены филиалы
					this.stepFilial = true;
				} else {
					this.noResult = true;
				}
			} catch (e) {
				this.$errorsMod(e.response);
			}

			this.loadingAdd = false;
		},

		async onInit(promise) {
			this.loading = true;
			try {
				await promise;
			} catch (error) {
				if (error.name === "NotAllowedError") {
					this.error = "ОШИБКА: необходимо дать доступ к камере";
					this.errorNotAllowed();
				} else if (error.name === "NotFoundError") {
					this.error = "ОШИБКА: на этом устройстве камера не обнаружена";
				} else if (error.name === "NotSupportedError") {
					this.error = "ОШИБКА: ошибка соединения (переключитесь на https)";
				} else if (error.name === "NotReadableError") {
					this.error = "ОШИБКА: Возможно камера уже используется";
				} else if (error.name === "OverconstrainedError") {
					this.error = "ОШИБКА: камера не поддерживается";
				} else if (error.name === "StreamApiNotSupportedError") {
					this.error = "ОШИБКА: Вы используете устаревший браузер.";
				}
			} finally {
				this.loading = false;
			}
		},

		async searchAddAccount(organizationId) {
			return new Promise((resolve) =>
				setTimeout(async () => {
					this.stepFilial = false; //отключаем выбор филиала
					this.addingFlag = true; // отображаем анимацию добавления

					let searchAccount = await this.$store.dispatch("searchById", {
						orgId: organizationId,
						number: this.result.PERSACC,
					});

					//найден ли ЛС
					if (searchAccount.length) {
						await this.$store.dispatch("accountAdd", searchAccount[0].id);

						if (this.result.PWD) {
							let formDataAproved = {
								id: searchAccount[0].id[0],
								code: this.result.PWD,
							};

							for await (let account of searchAccount[0].id) {
								await this.$store.dispatch("accountApprove", {
									approveCode: this.result.PWD,
									accountId: account,
									index: 0,
								});
							}
						}

						this.successAdd = true;

						await this.$store.dispatch("apartmentList");
						await this.$store.dispatch("getTotalDebt");

						setTimeout(() => {
							this.$router.push({ path: "/account/" });
						}, 2500);
					} else {
						this.noResult = true;
					}

					resolve();
				}, 0)
			);
		},
		manualData() {
			this.$emit("manualStep");
		},
		errorNotAllowed() {
			this.$emit("nextStepVideo", false);
			this.$emit("errorAccess");
		},
	},
	components: {
		QrcodeStream,
		Filials,
	},
};
</script>

<style scoped>
.fullscreen {
	position: fixed;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	z-index: 100;
	background-color: #fff;
	display: flex;
	flex-flow: column nowrap;
	justify-content: center;
}

.fade-enter-active,
.fade-leave-active {
	transition: opacity 0.7s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active до версии 2.1.8 */ {
	opacity: 0;
}
</style>

<style>
.app-modal__close-video {
	cursor: pointer;
	background: #0a2a61;
	color: #fff;
	padding: 10px;
	border-radius: 50%;
	width: 42px;
	text-align: center;
	z-index: 1000;
	position: absolute;
	left: 20px;
	top: 20px;
	border: 1px solid #fff;
}
</style>