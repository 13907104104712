var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('AppGlobalLayout',{attrs:{"loading":_vm.loading,"error":_vm.error},on:{"reload":_vm.toLoadInfo}},[_c('div',{attrs:{"slot":"body"},slot:"body"},[_c('modalTicketError'),_c('div',{},[_c('div',{staticClass:"col-12"},[_c('AppTabs',{staticClass:"mt-2",attrs:{"mobileRevers":"","data":[
							{
								name: 'Информация',
								iconSvg: 'info-list',
								href: 'info'
							},
							{
								name: 'Начисления',
								iconSvg: 'money',
								href: 'calculate',
								show: !!_vm.getAccrualsList.data.length,
							},
							{
								name: 'Счетчики',
								iconSvg: 'speed',
								//show: !!getCounterList.counters.length,
								href: 'counter'
							},
							{
								name: 'Контакты',
								iconSvg: 'contact',
								show: !!_vm.getOrganizationInfo.contacts.length,
								href: 'news'
							} ]},scopedSlots:_vm._u([{key:"content",fn:function(ref){
						var active = ref.active;
return [(active == 0)?_c('div',[_c('accountInfo')],1):_vm._e(),(active == 1)?_c('div',[_c('accountCalculate')],1):_vm._e(),(active == 2)?_c('div',[_c('accountCounter')],1):_vm._e(),(active == 3)?_c('div',[_c('Contacts')],1):_vm._e()]}}])})],1)])],1)])}
var staticRenderFns = []

export { render, staticRenderFns }