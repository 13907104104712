<template>
	<button
		class="app-button"
		@click="click"
		:class="{
			['app-button--' + color + ` ${classes}`]: color,
			['app-button__block' + ' app-button__block--' + color]: block,
			['app-button__outlined' +
			' app-button__outlined--' +
			color]: outlined,
			['app-button__text' + ' app-button__text--' + color]: text,
			'app-button__icon': icon,
			'app-button--min': min,
			'app-button--large': large,
			[customClass]: customClass
		}"
		:disabled="disabled || loading"
		:style="styleCSS"
	>
		<span
			class="app-button__content"
			:class="{
				'app-button__loading--hidden': loading
			}"
		>
			<slot></slot>
		</span>
		<span class="app-button__loading" v-if="loading">
			<AppLoading :color="color" />
		</span>
	</button>
</template>

<script>
import AppLoading from "../app-loading";

export default {
	data() {
		return {};
	},
	props: {
		color: {
			type: String,
			default: "primary"
		},
		type: {
			type: String,
			default: ""
		},
		icon: {
			type: Boolean,
			default: false
		},
		block: {
			type: Boolean,
			default: false
		},
		min: {
			type: Boolean,
			default: false
		},
		large: {
			type: Boolean,
			default: false
		},
		outlined: {
			type: Boolean,
			default: false
		},
		text: {
			type: Boolean,
			default: false
		},
		disabled: {
			type: Boolean,
			default: false
		},
		loading: {
			type: Boolean,
			default: false
		},
		classes: {
			type: String,
			default: ""
		},
		styleCSS: {
			type: String,
			default: ""
		},
		customClass: {
			type: String,
			default: ""
		}
	},
	methods: {
		click(e) {
			this.$emit("click", e);
		}
	},
	components: {
		AppLoading
	}
};
</script>
