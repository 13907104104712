<template>
	<div id="app">
		<div class="wrapper" id="wrapperApp" v-if="getTokenValid">
			<AppSidebar />
			<AppContent>
				<template slot="content">
					<transition name="fade" mode="out-in">
						<router-view />
					</transition>
				</template>
			</AppContent>
			<notifications group="foo" />
		</div>
		<portal-target name="menu" />
	</div>
</template>

<script>
import Vue from "vue";

import { mapGetters } from "vuex";
import VueMask from "v-mask";
import vmodal from "vue-js-modal";
import PortalVue from "portal-vue";
import VueCookies from "vue-cookies";
import Vuelidate from "vuelidate";
import errorsMod from "@/plugins/errors.js";
import useTheme from "@/plugins/useTheme";
import digitNumber from "@/plugins/digitNumber";
import addString from "@/plugins/addString";
import numberToString from "@/plugins/numberToString";
import Notifications from "vue-notification";
import vSelect from "vue-select";
import InfiniteLoading from "vue-infinite-loading";
import VTooltip from "v-tooltip";

import snackbar from "@/components/old-components/snackbar";
import VAnimateCss from "v-animate-css";

import AppBtn from "./components/app-button";
import AppIcon from "./components/app-icon";
import AppIconSvg from "./components/app-icon-svg";
import AppInput from "./components/app-input";
import AppInputPay from "./components/app-input-pay";
import AppTextarea from "./components/app-textarea";
import AppCheckbox from "./components/app-checkbox";
import AppTabs from "./components/app-tabs";
import AppSelect from "./components/app-select";
import AppTable from "./components/app-table";
import AppCard from "./components/app-card";
import AppLoading from "./components/app-loading";
import AppContent from "./components/layouts/content";
import AppSidebar from "./components/layouts/sidebar";
import AppHeader from "./components/layouts/header";
import AppSwitchCheckBox from "./components/app-switch-checkbox/switch-checkbox";
import AppGlobalLayout from "./components/layouts/global-layout";
import AppModals from "./components/app-modal";
import AppDropdown from "./components/app-dropdown-menu";
import AppList from "./components/app-list";
import AppLink from "./components/app-link";
import AppListItem from "./components/app-list-item";
import AppStepper from "./components/app-stepper";
import AppStepperHeader from "./components/app-stepper/step-header";
import AppStepperContent from "./components/app-stepper/step-content";
import AppAccordion from "./components/app-accordion";
import AppTimeAction from "./components/components-other/app-time-action.vue";
import AppToggleBtn from "./components/app-toggle-btn.vue";

import InsuranceState from "./views/account/insurance/components/insurance-state.vue";

import { FocusInput } from "./plugins/focus";
import { AnimateCard } from "./plugins/animateCard";

Vue.component("AppModals", AppModals);
Vue.component("AppHeader", AppHeader);
Vue.component("AppSidebar", AppSidebar);
Vue.component("AppContent", AppContent);
Vue.component("AppCard", AppCard);
Vue.component("AppTable", AppTable);
Vue.component("AppSelect", AppSelect);
Vue.component("AppBtn", AppBtn);
Vue.component("AppIcon", AppIcon);
Vue.component("AppIconSvg", AppIconSvg);
Vue.component("AppLoading", AppLoading);
Vue.component("AppInput", AppInput);
Vue.component("AppInputPay", AppInputPay);
Vue.component("AppTextarea", AppTextarea);
Vue.component("AppCheckbox", AppCheckbox);
Vue.component("AppTabs", AppTabs);
Vue.component("AppGlobalLayout", AppGlobalLayout);
Vue.component("AppDropdown", AppDropdown);
Vue.component("AppList", AppList);
Vue.component("AppLink", AppLink);
Vue.component("AppListItem", AppListItem);
Vue.component("AppStepper", AppStepper);
Vue.component("AppStepper", AppStepper);
Vue.component("AppStepperHeader", AppStepperHeader);
Vue.component("AppStepperContent", AppStepperContent);
Vue.component("AppSwitchCheckBox", AppSwitchCheckBox);
Vue.component("AppAccordion", AppAccordion);
Vue.component("AppTimeAction", AppTimeAction);
Vue.component("AppToggleBtn", AppToggleBtn);
Vue.component("snackbar", snackbar);
Vue.component("v-select", vSelect);

Vue.component("InsuranceState", InsuranceState);

Vue.directive("focus", FocusInput);
Vue.directive("animateCard", AnimateCard);

Vue.use(Notifications);
Vue.use(errorsMod);
Vue.use(digitNumber);
Vue.use(addString);
Vue.use(useTheme);
Vue.use(numberToString);
Vue.use(Vuelidate);
Vue.use(vmodal);
Vue.use(VueMask);
Vue.use(VAnimateCss, { animateCSSPath: '/css/animate.css'} );

Vue.use(VueCookies);
Vue.use(InfiniteLoading, {
	/* options */
});
Vue.use(VTooltip);
Vue.use(PortalVue);

export default {
	name: "App",
	data() {
		return {
			snak: false
		};
	},
	components: {},
	computed: {
		...mapGetters(["getNotice", "getWrapper", "getTokenValid", "getTheme"])
	},
	async beforeMount() {
		var _clientYandexId;

		if (process.env.NODE_ENV === "production") {
			(function(m, e, t, r, i, k, a) {
				m[i] =
					m[i] ||
					function() {
						(m[i].a = m[i].a || []).push(arguments);
					};
				m[i].l = 1 * new Date();
				(k = e.createElement(t)),
					(a = e.getElementsByTagName(t)[0]),
					(k.async = 1),
					(k.src = r),
					a.parentNode.insertBefore(k, a);
			})(
				window,
				document,
				"script",
				"https://mc.yandex.ru/metrika/tag.js",
				"ym"
			);

			window.ym(54842275, "init", {
				clickmap: true,
				trackLinks: true,
				accurateTrackBounce: true,
				webvisor: true
			});

			window.ym(54842275, "getClientID", clientID => {
				_clientYandexId = clientID;
			});
		}
	},
	mounted() {
		let browser = navigator.userAgent.indexOf("Chrome/49");
		let html = document.querySelector("html");
		const theme = this.$useTheme();
		if (browser > 0) {
			html.setAttribute("data-oldBrowser", "true");
		}
		if (theme === "dark-theme") {
			this.$store.dispatch("toggleTheme", "dark-theme");
		}
	}
};
</script>
<style>
.fade-enter-active,
.fade-leave-active {
	transition: opacity 0.2s;
}

.fade-enter,
.fade-leave-to {
	opacity: 0;
}
.tooltip {
	z-index: 30 !important;
}
</style>
