import Vue from 'vue'
import App from './App.vue'
import { router } from './router'
import store from './store'
import sass from './sass/main.sass'

Vue.config.productionTip = false

new Vue({
	router,
	store,
	sass,
	render: h => h(App)
}).$mount('#app')
