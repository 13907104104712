<template>
	<div>
		<div class="windows8" :style="`width:${width}px;height: ${height}px;`" v-if="type == 'windows8'">
			<div class="wBall" id="wBall_1" :style="`width:${width}px;height: ${height}px;`">
				<div 
					class="wInnerBall"
					:class="{
						['app-loading--' + color ]: color,
					}"
				></div>
			</div>
			<div class="wBall" id="wBall_2" :style="`width:${width}px;height: ${height}px;`">
				<div 
					class="wInnerBall"
					:class="{
						['app-loading--' + color ]: color,
					}"
				></div>
			</div>
			<div class="wBall" id="wBall_3" :style="`width:${width}px;height: ${height}px;`">
				<div 
					class="wInnerBall"
					:class="{
						['app-loading--' + color ]: color,
					}"
				></div>
			</div>
			<div class="wBall" id="wBall_4" :style="`width:${width}px;height: ${height}px;`">
				<div 
					class="wInnerBall" 
					:class="{
						['app-loading--' + color ]: color,
					}"
				></div>
			</div>
			<div class="wBall" id="wBall_5" :style="`width:${width}px;height: ${height}px;`">
				<div 
					class="wInnerBall" 
					:class="{
						['app-loading--' + color ]: color,
					}"
				></div>
			</div>
		</div>


		<div v-if="type == 'lds'" class="lds-ring" :style="`width:${width}px;height: ${height}px;`">
			<div :style="`border-color: ${color} transparent transparent transparent`"></div>
			<div :style="`border-color: ${color} transparent transparent transparent`"></div>
			<div :style="`border-color: ${color} transparent transparent transparent`"></div>
			<div :style="`border-color: ${color} transparent transparent transparent`"></div>
		</div>
	</div>
</template>
<script>
export default {
	data () {
		return {

		}
	},
	props: {
		width: {
			type: Number,
			default: 16
		},
		height: {
			type: Number,
			default: 9
		},
		color: {
			type: String,
			default: '#404370'
		},
		type: {
			type: String,
			default: 'windows8'
		}
	}
}
</script>


<style>
.windows8 {
    position: relative;
    margin: 0 auto;
}

.windows8 .wBall {
	position: absolute;
	width: 16px;
	height: 16px;
	opacity: 0;
	transform: rotate(225deg);
		-o-transform: rotate(225deg);
		-ms-transform: rotate(225deg);
		-webkit-transform: rotate(225deg);
		-moz-transform: rotate(225deg);
	animation: orbit 2.7225s infinite;
		-o-animation: orbit 2.7225s infinite;
		-ms-animation: orbit 2.7225s infinite;
		-webkit-animation: orbit 2.7225s infinite;
		-moz-animation: orbit 2.7225s infinite;
}

.windows8 .wBall .wInnerBall{
	position: absolute;
	width: 4px;
	height: 4px;
	background: rgb(0, 0, 0);
	left:0px;
	top:0px;
	border-radius: 4px;
}

.windows8 #wBall_1 {
	animation-delay: 0.596s;
		-o-animation-delay: 0.596s;
		-ms-animation-delay: 0.596s;
		-webkit-animation-delay: 0.596s;
		-moz-animation-delay: 0.596s;
}

.windows8 #wBall_2 {
	animation-delay: 0.113s;
		-o-animation-delay: 0.113s;
		-ms-animation-delay: 0.113s;
		-webkit-animation-delay: 0.113s;
		-moz-animation-delay: 0.113s;
}

.windows8 #wBall_3 {
	animation-delay: 0.2365s;
		-o-animation-delay: 0.2365s;
		-ms-animation-delay: 0.2365s;
		-webkit-animation-delay: 0.2365s;
		-moz-animation-delay: 0.2365s;
}

.windows8 #wBall_4 {
	animation-delay: 0.3595s;
		-o-animation-delay: 0.3595s;
		-ms-animation-delay: 0.3595s;
		-webkit-animation-delay: 0.3595s;
		-moz-animation-delay: 0.3595s;
}

.windows8 #wBall_5 {
	animation-delay: 0.473s;
		-o-animation-delay: 0.473s;
		-ms-animation-delay: 0.473s;
		-webkit-animation-delay: 0.473s;
		-moz-animation-delay: 0.473s;
}

@keyframes orbit {
	0% {
		opacity: 1;
		z-index:99;
		transform: rotate(180deg);
		animation-timing-function: ease-out;
	}

	7% {
		opacity: 1;
		transform: rotate(300deg);
		animation-timing-function: linear;
		origin:0%;
	}

	30% {
		opacity: 1;
		transform:rotate(410deg);
		animation-timing-function: ease-in-out;
		origin:7%;
	}

	39% {
		opacity: 1;
		transform: rotate(645deg);
		animation-timing-function: linear;
		origin:30%;
	}

	70% {
		opacity: 1;
		transform: rotate(770deg);
		animation-timing-function: ease-out;
		origin:39%;
	}

	75% {
		opacity: 1;
		transform: rotate(900deg);
		animation-timing-function: ease-out;
		origin:70%;
	}

	76% {
	opacity: 0;
		transform:rotate(900deg);
	}

	100% {
	opacity: 0;
		transform: rotate(900deg);
	}
}

@-o-keyframes orbit {
	0% {
		opacity: 1;
		z-index:99;
		-o-transform: rotate(180deg);
		-o-animation-timing-function: ease-out;
	}

	7% {
		opacity: 1;
		-o-transform: rotate(300deg);
		-o-animation-timing-function: linear;
		-o-origin:0%;
	}

	30% {
		opacity: 1;
		-o-transform:rotate(410deg);
		-o-animation-timing-function: ease-in-out;
		-o-origin:7%;
	}

	39% {
		opacity: 1;
		-o-transform: rotate(645deg);
		-o-animation-timing-function: linear;
		-o-origin:30%;
	}

	70% {
		opacity: 1;
		-o-transform: rotate(770deg);
		-o-animation-timing-function: ease-out;
		-o-origin:39%;
	}

	75% {
		opacity: 1;
		-o-transform: rotate(900deg);
		-o-animation-timing-function: ease-out;
		-o-origin:70%;
	}

	76% {
	opacity: 0;
		-o-transform:rotate(900deg);
	}

	100% {
	opacity: 0;
		-o-transform: rotate(900deg);
	}
}

@-ms-keyframes orbit {
	0% {
		opacity: 1;
		z-index:99;
		-ms-transform: rotate(180deg);
		-ms-animation-timing-function: ease-out;
	}

	7% {
		opacity: 1;
		-ms-transform: rotate(300deg);
		-ms-animation-timing-function: linear;
		-ms-origin:0%;
	}

	30% {
		opacity: 1;
		-ms-transform:rotate(410deg);
		-ms-animation-timing-function: ease-in-out;
		-ms-origin:7%;
	}

	39% {
		opacity: 1;
		-ms-transform: rotate(645deg);
		-ms-animation-timing-function: linear;
		-ms-origin:30%;
	}

	70% {
		opacity: 1;
		-ms-transform: rotate(770deg);
		-ms-animation-timing-function: ease-out;
		-ms-origin:39%;
	}

	75% {
		opacity: 1;
		-ms-transform: rotate(900deg);
		-ms-animation-timing-function: ease-out;
		-ms-origin:70%;
	}

	76% {
	opacity: 0;
		-ms-transform:rotate(900deg);
	}

	100% {
	opacity: 0;
		-ms-transform: rotate(900deg);
	}
}

@-webkit-keyframes orbit {
	0% {
		opacity: 1;
		z-index:99;
		-webkit-transform: rotate(180deg);
		-webkit-animation-timing-function: ease-out;
	}

	7% {
		opacity: 1;
		-webkit-transform: rotate(300deg);
		-webkit-animation-timing-function: linear;
		-webkit-origin:0%;
	}

	30% {
		opacity: 1;
		-webkit-transform:rotate(410deg);
		-webkit-animation-timing-function: ease-in-out;
		-webkit-origin:7%;
	}

	39% {
		opacity: 1;
		-webkit-transform: rotate(645deg);
		-webkit-animation-timing-function: linear;
		-webkit-origin:30%;
	}

	70% {
		opacity: 1;
		-webkit-transform: rotate(770deg);
		-webkit-animation-timing-function: ease-out;
		-webkit-origin:39%;
	}

	75% {
		opacity: 1;
		-webkit-transform: rotate(900deg);
		-webkit-animation-timing-function: ease-out;
		-webkit-origin:70%;
	}

	76% {
	opacity: 0;
		-webkit-transform:rotate(900deg);
	}

	100% {
	opacity: 0;
		-webkit-transform: rotate(900deg);
	}
}

@-moz-keyframes orbit {
	0% {
		opacity: 1;
		z-index:99;
		-moz-transform: rotate(180deg);
		-moz-animation-timing-function: ease-out;
	}

	7% {
		opacity: 1;
		-moz-transform: rotate(300deg);
		-moz-animation-timing-function: linear;
		-moz-origin:0%;
	}

	30% {
		opacity: 1;
		-moz-transform:rotate(410deg);
		-moz-animation-timing-function: ease-in-out;
		-moz-origin:7%;
	}

	39% {
		opacity: 1;
		-moz-transform: rotate(645deg);
		-moz-animation-timing-function: linear;
		-moz-origin:30%;
	}

	70% {
		opacity: 1;
		-moz-transform: rotate(770deg);
		-moz-animation-timing-function: ease-out;
		-moz-origin:39%;
	}

	75% {
		opacity: 1;
		-moz-transform: rotate(900deg);
		-moz-animation-timing-function: ease-out;
		-moz-origin:70%;
	}

	76% {
	opacity: 0;
		-moz-transform:rotate(900deg);
	}

	100% {
	opacity: 0;
		-moz-transform: rotate(900deg);
	}
}


.lds-ring {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
  margin: 20 auto;
}
.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 64px;
  height: 64px;
  margin: 8px;
  border: 4px solid #fff;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #fff transparent transparent transparent;
}
.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}
.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}
.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}
@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}




</style>
