<template>
	<AppModals
		name="apartment-rename"
		title="Переименовать"
		cancelButtonText="Закрыть"
		@beforeOpen="beforeOpen"
		confirmButtonText="Переименовать "
		@confirm="renameApartment"
		:disabledConfirm="!$v.description.maxLength"
		:loadingConfirm="loading"
	>
		<template #content="{}">
			<div class="modal-content">
				<div>
					<AppInput
						placeholder="Введите новое название"
						v-model="$v.description.$model"
						:error="$v.description.$error"
						required
						maxLength
						:maxLengthError="$v.description.maxLength"
						:requiredError="$v.description.required"
						label="Наименование"
					>
						<template slot="maxLength">Не более 30 символов</template>
					</AppInput>

					<div style="margin: 20px 0">
						Придумайте своё название для помещения, чтобы быстрее найти его в
						списке.
					</div>
				</div>
			</div>
		</template>
	</AppModals>
</template>

<script>
import { maxLength } from "vuelidate/lib/validators";

export default {
	data() {
		return {
			loading: false,
			description: "",
			id: null,
			name: ""
		};
	},
	props: {},

	mounted() {},

	methods: {
		modalClose() {
			this.$modal.hide("apartment-rename");
		},

		async renameApartment() {
			this.$v.$touch();

			if (!this.$v.$invalid) {
				this.loading = true;
				let formData = {
					id: this.id,
					description: this.description.trim()
				};
				try {
					await this.$store.dispatch("apartmentRename", formData);
					this.$modal.hide("apartment-rename");
				} catch (e) {
					this.$errorsMod(e.response);
				}

				this.loading = false;
			}
		},

		async beforeOpen(event) {
			this.description = event.description;
			this.id = event.id;
		}
	},
	validations: {
		description: {
			maxLength: maxLength(30)
		}
	}
};
</script>
