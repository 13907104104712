<template>
	<div>
		<AppGlobalLayout :error="error">
			<div slot="body">
				<AppCaruosel :banners="getBanners" />
				<AppTabs v-if="getActiveTownId" class="mt-3" :data="tabs" :active-tab="page"
					@changeActiveTab="page = $event">
					<template v-slot:content="{ active }">
						<div v-if="active == 0">
							<ProductsTab />
						</div>
						<div v-if="active == 1">
							<SuplierHistory />
						</div>
						<div v-if="active == 2">
							<FavouritesTab />
						</div>
					</template>
				</AppTabs>
			</div>

		</AppGlobalLayout>
	</div>
</template>

<script>
import { mapGetters } from "vuex";
import AppCardProductItem from "@/components/components-other/app-card-product-item";
import AppCaruosel from "@/components/app-caruosel";
import SuplierHistory from "../suplier-history";
import ProductsTab from './tabs/ProductsTab.vue'
import FavouritesTab from "./tabs/FavouritesTab.vue";

export default {
	components: {
		SuplierHistory,
		AppCardProductItem,
		AppCaruosel,
		ProductsTab,
		FavouritesTab
	},
	data() {
		return {
			page: 0,
			error: false,
			tabs: [
				{
					name: 'Товары и услуги',
					iconSvg: 'star-check',
					href: 'list',
				},
				{
					name: 'Мои заказы',
					iconSvg: 'package',
					href: 'history-order',
				},
				{
					name: 'Избранное',
					iconSvg: 'heart',
					href: 'favourites',
				},
			]
		};
	},
	methods: {
		async apartmentBannersLoad() {
			this.error = false;
			try {
				await this.$store.dispatch("apartmentBanners");
			} catch (e) {
				if (e.response) {
					this.$errorsMod(e.response);
				} else {
					this.error = true;
					this.$errorsMod(e.response);
				}
			}
		},
	},
	mounted() {
		this.apartmentBannersLoad();
	},
	computed: {
		...mapGetters(["getBanners", "getActiveTownId",])
	},
	watch: {
		'$route.hash': {
			handler: function (e) {
				switch (e) {
					case '#list':
						if (this.page !== 0) {
							this.page = 0
						}
						break
					case '#history-order':
						if (this.page !== 1) {
							this.page = 1
						}
						break
					case '#favourites':
						if (this.page !== 2) {
							this.page = 2
						}
					default:
						break;
				}
			},
		}
	}


};
</script>
