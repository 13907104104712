var render = function () {
var _obj, _obj$1;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('label',{staticClass:"app-checkbox"},[_c('span',{staticClass:"app-checkbox__label",class:{
			'app-checkbox__error-text': _vm.error, 
		}},[_vm._t("label")],2),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.model),expression:"model"}],staticClass:"app-checkbox__input",class:( _obj = {
			'app-checkbox__error': _vm.error
		}, _obj[' app-checkbox__input--' + _vm.color] = _vm.color, _obj ),attrs:{"type":"checkbox","id":_vm.id,"disabled":_vm.disabled},domProps:{"value":_vm.value,"checked":Array.isArray(_vm.model)?_vm._i(_vm.model,_vm.value)>-1:(_vm.model)},on:{"change":function($event){var $$a=_vm.model,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=_vm.value,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.model=$$a.concat([$$v]))}else{$$i>-1&&(_vm.model=$$a.slice(0,$$i).concat($$a.slice($$i+1)))}}else{_vm.model=$$c}}}}),_c('span',{staticClass:"app-checkbox__checkmark",class:( _obj$1 = {
			'app-checkbox__error': _vm.error
		}, _obj$1[' app-checkbox__checkmark--' + _vm.color] = _vm.color, _obj$1 )})])}
var staticRenderFns = []

export { render, staticRenderFns }