var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('AppTable',{staticClass:"table-account",attrs:{"header":_vm.headerTable,"data":_vm.info,"messageNoData":"У Вас нет привязанных лицевых счетов или договоров. Нажмите на кнопку \"Добавить\", чтобы добавить ЛС или договор.","childRow":false},scopedSlots:_vm._u([{key:"thead",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.name))])]}},{key:"tbody",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [_c('td',{staticClass:"pr-5",on:{"click":function($event){return _vm.routerLink(item, index)}}},[(item.description)?_c('span',[_vm._v(_vm._s(item.description))]):_c('span',[_vm._v(_vm._s(item.address))])]),_c('td',{staticClass:"pr-5",on:{"click":function($event){return _vm.routerLink(item, index)}}},[(item.gazType !== 0)?_c('TechnicalServiceCard',{attrs:{"gazType":item.gazType,"unitId":item.unitId,"isTable":true}}):_vm._e()],1),_c('td',{on:{"click":function($event){return _vm.routerLink(item, index)}}},[_c('span',{staticClass:"app-table__title-mob"},[_vm._v(" Баланс: ")]),_c('span',{class:{
						'success-color': item.debt <= 0,
						'danger-color': item.debt > 0
					},staticStyle:{"white-space":"nowrap"}},[_vm._v(" "+_vm._s(_vm.$digitNumber(item.debt))+" ₽ ")])]),_c('td'),_c('td',[_c('span',{staticClass:"app-table__title-mob"},[_vm._v(" Страховка: ")])]),_c('td',[_c('div',{staticClass:"d-flex just-end pb-2--xs"},[_c('AppBtn',{attrs:{"styleCSS":"margin-right: 8px"},on:{"click":function($event){return _vm.$router.push({
								name: 'paymentPage',
								params: { apartmentId: item.id }
							})}}},[_vm._v(" Оплатить ")]),(item.mustConfirm)?_c('AppBtn',{attrs:{"color":"danger","outlined":""},on:{"click":function($event){return _vm.$modal.show('adress-confirm', { id: item.id })}}},[_vm._v(" Подтвердить ")]):_vm._e()],1)]),_c('td',[_c('div',{staticClass:"table-account__menu"},[_c('AppDropdown',{scopedSlots:_vm._u([{key:"activator",fn:function(ref){
							var showChange = ref.showChange;
return [_c('AppBtn',{attrs:{"icon":"","text":""},nativeOn:{"click":function($event){return showChange($event)}}},[_c('AppIcon',{staticStyle:{"color":"#777776"},attrs:{"name":"ellipsis-v","size":"15px"}})],1)]}}],null,true)},[_c('div',{attrs:{"slot":"dropdown"},slot:"dropdown"},[_c('AppList',{attrs:{"listData":_vm.listData},scopedSlots:_vm._u([{key:"list-item",fn:function(){return [_vm._l((_vm.listData),function(menu,index){return [_c('AppListItem',{key:index,attrs:{"name":menu.name},nativeOn:{"click":function($event){return _vm.clickMenuItem(menu.id, item)}}})]})]},proxy:true}],null,true)})],1)])],1)])]}}])}),_c('div',{staticClass:"d-flex just-center"},[_c('AppBtn',{staticClass:"mr-1",nativeOn:{"click":function($event){return _vm.$router.push({
					name: 'accountAdd',
					params: { text: 'Добавление помещения' }
				})}}},[_vm._v(" Добавить помещение ")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }