<template>
	<div>
		<ModalCompanyInfo />
		<div class="suppliers__list mt-5">
			<div
				class="card-supplier-brand__root"
				v-for="(item, index) in getSuppliers.data"
				:key="index"
			>
				<AppCardProductBrand
					:title="item.title"
					:image="`https://квартплата.онлайн/files/static/${item.image}`"
					:fullInfo="item"
				/>
			</div>
		</div>
	</div>
</template>

<script>
	import AppCardProductBrand from "@/components/components-other/app-card-supplier-brand";
	import { mapGetters } from "vuex";
	import ModalCompanyInfo from "./modal/company-info";

	export default {
		data() {
			return {
				data: TESTDATA2,
			};
		},
		mounted() {
			this.shopSuppliers();
		},
		computed: {
			...mapGetters(["getSuppliers"]),
		},
		methods: {
			async shopSuppliers() {
				try {
					await this.$store.dispatch("shopSuppliers", this.$route.params.id);
				} catch (e) {
					this.$errorsMod(e.response);
				}
			},
		},
		components: {
			AppCardProductBrand,
			ModalCompanyInfo,
		},
	};

	const TESTDATA2 = [
		{
			id: 8,
			image: "https://cdn.jfservice.ru/suplier/8.png",
			title: "Чисто",
			description:
				"Removes the this parameter from Type. If Type has no explicitly declared this parameter, the result is simply Type. Otherwise, a new function type with no this parameter is created from Type. Generics are erased and only the last overload signature is propagated into the new function type.",
		},
		{
			id: 1,
			image:
				"https://crystal-purity.ru/wp-content/uploads/2018/07/%D0%9B%D0%BE%D0%B3%D0%BE-%D0%BA%D0%BB%D0%B8%D0%BD%D0%B8%D0%BD%D0%B3-1.png",
			title: "Кристал",
			description:
				"Removes the this parameter from Type. If Type has no explicitly declared this parameter, the result is simply Type. Otherwise, a new function type with no this parameter is created from Type. Generics are erased and only the last overload signature is propagated into the new function type.",
		},
		{
			id: Symbol(1),
			image:
				"https://cdn1.zp.ru/job/attaches/2018/10/aa/41/aa415ec8cec8f43ceb4d0bb273467ff1.png",
			title: "Аврора плюс",
		},
		{
			id: Symbol(1),
			image: "https://blagostroy-clean.ru/wp-content/uploads/2018/03/logo-1.png",
			title: "Благо чист",
		},
		{
			id: Symbol(1),
			image:
				"https://xn--80aamepajtu3alb4d3cvc.xn--p1ai/wp-content/uploads/2019/12/LOGO-Vert.png",
			title: "Академия чистоты",
		},
	];
</script>
