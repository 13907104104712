<template>
    <div class="account-add">
        <div class="account-add__content">

            <div class="account-add__sub-title">Выберите филиал компании</div>
            <div>
                <AppSelect 
                    placeholder="Выберите филиал"
                    label="Выберите филиал"
                    valueSelect="id"
                    :options="filial"
                    :error="$v.validateForm.filial.$error ? 'Выберите филиал' : '' "
                    v-model="$v.validateForm.filial.$model" 
                    textSelect="name"
                />
            </div>

            <template v-if="$v.validateForm.filial.$model.id">
                <div class="account-add__block-info">
                    <template>
                        <div class="account-add__row">
                            <div class="account-add__title">Организация:</div>
                            <div class="account-add__text">{{ $v.validateForm.filial.$model.name }}</div>
                        </div>
                        <div class="account-add__row">
                            <div class="account-add__title">ИНН:</div>
                            <div class="account-add__text">{{ $v.validateForm.filial.$model.inn }}</div>
                        </div>
                        <div class="account-add__row">
                            <div class="account-add__title">Юридический адрес:</div>
                            <div class="account-add__text">{{ $v.validateForm.filial.$model.legalAddress }}</div>
                        </div> 
                    </template>
                </div>
            </template>
        </div>

        <div class="account-add__footer account-add__footer--just">
            <AppBtn
                color="secondary"
                @click.native="prevStep"
            >
                Вернуться
            </AppBtn>

            <AppBtn
                @click.native="addAccount()"
                :disabled="!$v.validateForm.filial.$model"
            >
                Добавить
            </AppBtn>
        </div>   


    </div>
</template>

<script>
import { required } from 'vuelidate/lib/validators'

export default {
    data() {
        return {
            validateForm: {
                filial: []
            },
        }
    },
    props: {
        filial: {
            type: Array,
            default: () => ([])
        }
    },

    methods: {
        status (validation) {
			return {
				'error-input': validation.$error,
				dirty: validation.$dirty
			}
        },

        addAccount() {
            this.$emit('searchAddAccount', this.$v.validateForm.filial.$model.id);
        },

        prevStep() {
            this.$emit('prevStep')
        },
    },
    validations: {
        validateForm: {
            filial: {
                required
            }
        }

    }
}
</script>