<template>

	<AppModals
        name="bank-rejected"
        title="Результат оплаты"
		cancelButtonText="Закрыть"
		@beforeOpen="beforeOpen"
		hideConfirm
		:clickToClose="false"
    >
        <template  #content="{}">
			<div>
				<template>
					<div class="modal-paymentAll__bottom" >
						<div class="error-mesage mb-2" style="display: flex; width: 100%; justify-content: center; font-size: 25px;">
							<i class="fas fa-exclamation-triangle" style="color: red;"></i>
						</div>
						<div class="error-mesage">При оплате произошла ошибка</div>
						<div class="error-mesage-info">{{ checkState.message }}</div>
					</div>
				</template>
			</div>
        </template>
    </AppModals>

</template>

<script>

export default {
	data () {
		return {
			message: 'Оплата прошла успешно, можно закрыть это окно',
			params: {
				orderId: '',
			},
			url: process.env.VUE_APP_URL,
			loading: true,
			checkState: {
				orderId: "",
				successed: true,
				message: "",
			}
		}
	},

	computed: {

	},
	methods: {
		async modalClose () {
			this.$emit('accountListLoad')
			this.$modal.hide('bank-approved')
		},

		async beforeOpen (event) {
			console.log('EVENT', event)
			this.params.orderId = event.orderId;
			this.checkState.message = event.message
		},

	}
}
</script>

<style>
	.succes-mesage {
		color: #33bc7d;
		text-align: center;
		margin-bottom: 10px;
		font-size: 20px;
		width: 100%;
		font-weight: bold;
	}

	.modal-submit-check {
		padding: .4rem 1rem;
		background: var(--color-primary);
		border-radius: 12px;
		color: #fff;
		border: none;
		position: relative;
		cursor: pointer;
		height: 2rem;
		margin-top: 10px;
		margin-bottom: 10px;
	}

</style>
