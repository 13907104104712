<template>
	<div class="time-action" :class="{ ['time-action--' + color]: color }">
		Действует с {{ dateStart }} по {{ dateEnd }}
	</div>
</template>

<script>
import { dateReturnFormat } from "@/plugins/methods";

export default {
	props: {
		date: Object,
		color: {
			type: String,
			default: "default"
		}
	},
	data() {
		return {
			dateStart: dateReturnFormat(this.date.dateStart),
			dateEnd: dateReturnFormat(this.date.dateEnd)
		};
	},
	mounted(){
		console.log(this.date);
	}
};
</script>

<style scoped>
.time-action {
	display: inline-block;
	padding: 5px 12px;

	white-space: nowrap;
	font-size: 1em;
	font-weight: 400;
	border-radius: 16px;
}
.time-action--success {
	color: var(--color-success);
	background: rgba(51, 188, 125, 0.1);
}
.time-action--danger {
	color: var(--color-danger);
	background: rgba(230, 87, 93, 0.1);
}
.time-action--default {
	color: #777776;
	background: var(--color-backfround);
}
</style>
