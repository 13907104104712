<template>
	<div>
		<ModalHistory />
		<div style="margin-top: -5px" class="mt-3--xs">
			<div class="content-text">
			</div>
			<CounterActual />
		</div>

	</div>
</template>

<script>
import CounterActual from './component/counter-actual'
import CounterArch from './component/counter-arch'
import ModalHistory from './modal/counter-history'
import { mapGetters } from 'vuex'

export default {
	data() {
		return {
			inputMask: '',
			loading: false,
			dataCounter: {
				settings: {},
				counters: []
			},
			counterArch: [],
			tabs: 0,
		}
	},
	mounted() {
		this.counterListLoad()
	},
	computed: {
		...mapGetters([
			'getCounterList'
		]),

	},
	methods: {

		tabsClick(num) {
			this.tabs = num
		},


		async counterListLoad() {
			try {

				let query = { ...this.getCounterList }

				//объект для отображение полной информации
				for (let item of query.counters) {
					item.showInfo = false
				}

				this.dataCounter = { ...query }

				this.dataCounter.counters = query.counters.filter((item, index) => {
					return item.isActive
				})

				this.counterArch = query.counters.filter((item, index) => {
					return !item.isActive
				})

			} catch (e) {
				this.$errorsMod(e.response)
			}
		},
		updateList(index) {
			this.dataCounter.counters.push({})
			this.$nextTick(() => {
				this.dataCounter.counters.pop()
			})

		}
	},
	components: {
		CounterActual,
		CounterArch,
		ModalHistory
	},

}
</script>
