import $axios from "@/plugins/axios-instance";

export default {
	state: {
		groupsLoading: false,
		groups: []
	},
	getters: {
		getGroups(state) {
			// return state.groups.filter(item=>item.type !== 2);
			return state.groups.sort((a, b) => {
				const bx =
					b && b.lastMessage && b.lastMessage.createdDate
						? new Date(b.lastMessage.createdDate).getTime()
						: 0;
				const ax =
					a && a.lastMessage && a.lastMessage.createdDate
						? new Date(a.lastMessage.createdDate).getTime()
						: 0;
				return bx - ax;
			});
		},
		getNewMessagesCount(state) {
			const count = state.groups.reduce(
				(ac, item) =>
					item.lastMessage && !item.lastMessage.readed ? ac + 1 : ac,
				0
			);
			return count;
		},
		getGroupsLoading(state) {
			return state.groupsLoading;
		}
	},
	mutations: {
		setGroups(state, payload) {
			state.groups = payload;
		},
		setGroupsLoading(state, payload) {
			state.groupsLoading = payload;
		},
		setGroupReaded(state, groupId) {
			state.groups = state.groups.map(item => {
				if (item.id === groupId) {
					return {
						...item,
						lastMessage: { ...item.lastMessage, readed: true }
					};
				} else {
					return item;
				}
			});
		}
	},
	actions: {
		async fetchGroups({ commit }) {
			commit("setGroupsLoading", true);
			return $axios
				.get(`/alerts/groups`)
				.then(response => {
					commit("setGroups", response.data);
				})
				.catch(e => {
					throw e;
				})
				.finally(e => {
					commit("setGroupsLoading", false);
				});
		},
		async markAsRead(context, { groupId, messageId }) {
			return $axios
				.post(`/alerts/groups/${groupId}/messages/${messageId}/markAsRead`)
				.then(response => {
					return response.data;
				})
				.catch(e => {
					throw e;
				});
		},

		fetchGroupMessages(context, { groupId, page }) {
			return $axios
				.get(`/alerts/groups/${groupId}/messages`, {
					params: {
						skip: (page - 1) * 20,
						take: 20
					}
				})
				.then(response => {
					return response.data;
				})
				.catch(e => {
					throw e;
				});
		},
		async sendSupportMessages(_, formData) {
			return $axios
				.post("/alerts/messages/support", formData)
				.then(response => {
					return response.data;
				})
				.catch(e => {
					throw e;
				});
		}
	}
};
