<template>
    <div class="steps">
        <template v-for="(i) in steps">
            <div 
                class="step"
                :key="i"
                :class="{
                    'step__active': i == active,
                    'step__passed': i < active
                }"
                v-if="stepOfHide.indexOf(i) == -1"
            >
                <slot :name="i">

                </slot>
            </div>
        </template>
    </div>
</template>

<script>
export default {
    data() {
        return {

        }
    },
    props: {
        steps: {
            type: Number,
            default: 1
        },
        active: {
            type: Number,
            default: 1
        },
        stepOfHide: {
            type: Array,
            default: () => []
        },
    },
    mounted() {

    },
    computed: {

    },
    methods: {

    },


}
</script>