<template>
	<label class="app-checkbox">
		<span 
			class="app-checkbox__label"
			:class="{
				'app-checkbox__error-text': error, 
			}"
		>
			<slot name="label"></slot>
		</span>
		<input 
			type="checkbox" 
			class="app-checkbox__input"
			:class="{
				'app-checkbox__error': error, 
				[' app-checkbox__input--' + color]: color,
			}"
			:value="value" 
			:id="id" 
			v-model="model"
			:disabled="disabled"
		>
		<span 
			class="app-checkbox__checkmark"
			:class="{
				'app-checkbox__error': error, 
				[' app-checkbox__checkmark--' + color]: color,
			}"
		></span>
	</label>

</template>

<script>
export default {
	data() {
		return {
			
		}
	},
	computed: {
		model: {
		get() {
			return this.value;
		},
		set(val) {
			this.$emit('input', val);
		},
		},
	},

	props: {
		label: {
			default: '',
			type: String
		},
		required: {
			default: false,
			type: Boolean
		},
		error: {
			default: false,
			type: Boolean
		},
		id: {
			default: '',
			type: String
		},
		value: {
			default: true,
			type: Boolean
		},
		color: {
            type: String,
            default: 'primary'
		},
		disabled: {
			default: false,
			type: Boolean
		}
	},
	methods: {

	}
}
</script>


<style lang="sass">
    @import "./checkbox"
</style>