<template>
	<div>
		<div ref="carousel" class="carousel" @pointerdown="onPointerDown" @pointerup="onPointerUp"
			@pointermove="onPointerMove" @pointerleave="onPointerUp">

			<div class="slide"
				:style="`transition: ${animation1}; transform: translateX(${slide1   +   move}px); z-index:${z1};`">
				<div class="carousel-content">
					<AppSlide :slide="content1" />
				</div>
			</div>
			<div class="slide"
				:style="`transition: ${animation2}; transform: translateX(${slide2   +   move}px); z-index:${z2};`">
				<div class="carousel-content">
					<AppSlide :slide="content2" />
				</div>
			</div>
			<div class="slide"
				:style="`transition: ${animation3}; transform: translateX(${slide3   +   move}px); z-index:${z3};`">
				<div class="carousel-content">
					<AppSlide :slide="content3" />
				</div>
			</div>
		</div>
		<div v-if="banners.length > 0" class="paginator">
			<div class="point" v-for="(point, index) in slides" :class="currentPS   === index ? 'active' : ''"
				@click="goToSlide(index)" />
		</div>
		<!-- <button @click="prevSlide">PREV</button>
		<button @click="nextSlide">NEXT</button> -->
	</div>

</template>

<script>
import AppSlide from "./app-slide/index.vue";


export default {
	name: 'app-caruosel',
	props: {
		banners: Array,
		require: true
	},
	data() {
		return {
			down: 0,
			up: 0,
			move: 0,
			isPressed: false,
			width: 0,
			slide1: 0,
			slide2: 0,
			slide3: 0,
			animation1: 'none',
			animation2: 'none',
			animation3: 'none',
			currentSlide: 2,
			z1: 0,
			z2: 10,
			z3: 0,
			content1: {},
			content2: {},
			content3: {},
			currentPS: 0,
			generateNext: 0,
			generatePrev: 0,
			intervalId: null,
			slides: []
		}
	},
	mounted() {
		this.width = this.$refs.carousel.clientWidth
		this.slide1 = -this.width
		this.slide2 = 0
		this.slide3 = this.width
		this.slides = [...this.banners, {requiredBanner: true}]
		this.content1 = this.slides[this.slides.length > 1 ? this.slides.length - 1 : 0]
		this.content2 = this.slides[0]
		this.content3 = this.slides[this.slides.length > 1 ? 1 : 0]
		this.refreshInterval()
		window.addEventListener(`resize`, this.onresize);

	},
	computed: {

	},
	methods: {
		onresize() {
			this.animation1 = 'none'
			this.animation2 = 'none'
			this.animation3 = 'none'
			this.width = this.$refs.carousel.clientWidth
			this.slide1 = -this.width
			this.slide2 = 0
			this.slide3 = this.width
		},
		onPointerDown(e) {
			this.isPressed = true
			this.down = e.screenX
			this.width = this.$refs.carousel.clientWidth

			this.animation1 = 'none'
			this.animation2 = 'none'
			this.animation3 = 'none'
		},
		onPointerUp(e) {
			if (this.isPressed) {
				this.isPressed = false
				this.up = e.screenX
				this.slide1 = this.slide1 + this.move
				this.slide2 = this.slide2 + this.move
				this.slide3 = this.slide3 + this.move
				this.move = 0


				if ((this.up - this.down) > 0 && Math.abs(this.up - this.down) > 100) {
					this.prevSlide()
				} else if ((this.up - this.down) < 0 && Math.abs(this.up - this.down) > 100) {
					this.nextSlide()
				} else {
					this.thisSlide()
				}
			}

		},
		onPointerMove(e) {
			if (this.isPressed) {
				this.move = (e.screenX - this.down)
			}
		},
		thisSlide() {
			this.animation2 = 'transform 0.5s'
			this.animation3 = 'transform 0.5s'
			this.animation1 = 'transform 0.5s'
			if (this.currentSlide === 2) {
				this.slide1 = -this.width
				this.slide2 = 0
				this.slide3 = this.width
				return
			}
			if (this.currentSlide === 1) {
				this.slide3 = -this.width
				this.slide1 = 0
				this.slide2 = this.width
				return
			}
			if (this.currentSlide === 3) {
				this.slide2 = -this.width
				this.slide3 = 0
				this.slide1 = this.width
				return
			}
		},
		nextSlide() {
			this.refreshInterval()
			if (this.currentSlide === 2) {
				this.animation2 = 'transform 0.5s'
				this.animation3 = 'transform 0.5s'
				this.animation1 = 'none'
				this.currentSlide = 3
				this.slide2 = -this.width
				this.slide3 = 0
				this.slide1 = this.width
				this.z1 = 0
				this.z2 = 0
				this.z3 = 10

				let l = this.slides.length
				let n = this.currentPS

				this.next()
				this.content1 = this.slides[this.generateNext]
				return
			}
			if (this.currentSlide === 3) {
				this.animation1 = 'transform 0.5s'
				this.animation3 = 'transform 0.5s'
				this.animation2 = 'none'
				this.currentSlide = 1
				this.slide3 = -this.width
				this.slide1 = 0
				this.slide2 = this.width
				this.z1 = 10
				this.z2 = 0
				this.z3 = 0
				this.next()
				this.content2 = this.slides[this.generateNext]
				return
			}
			if (this.currentSlide === 1) {
				this.animation2 = 'transform 0.5s'
				this.animation1 = 'transform 0.5s'
				this.animation3 = 'none'
				this.currentSlide = 2
				this.slide1 = -this.width
				this.slide2 = 0
				this.slide3 = this.width
				this.z1 = 0
				this.z2 = 10
				this.z3 = 0
				this.next()
				this.content3 = this.slides[this.generateNext]
				return
			}
		},
		prevSlide() {
			this.refreshInterval()
			if (this.currentSlide === 2) {
				this.animation2 = 'transform 0.5s'
				this.animation1 = 'transform 0.5s'
				this.animation3 = 'none'
				this.currentSlide = 1
				this.slide3 = -this.width
				this.slide1 = 0
				this.slide2 = this.width
				this.z1 = 10
				this.z2 = 0
				this.z3 = 0
				this.prev()
				this.content3 = this.slides[this.generatePrev]
				return
			}
			if (this.currentSlide === 1) {
				this.animation3 = 'transform 0.5s'
				this.animation1 = 'transform 0.5s'
				this.animation2 = 'none'
				this.currentSlide = 3
				this.slide2 = -this.width
				this.slide3 = 0
				this.slide1 = this.width
				this.z1 = 0
				this.z2 = 0
				this.z3 = 10
				this.prev()
				this.content2 = this.slides[this.generatePrev]
				return
			}
			if (this.currentSlide === 3) {
				this.animation2 = 'transform 0.5s'
				this.animation3 = 'transform 0.5s'
				this.animation1 = 'none'
				this.currentSlide = 2
				this.slide1 = -this.width
				this.slide2 = 0
				this.slide3 = this.width
				this.z1 = 0
				this.z2 = 10
				this.z3 = 0
				this.prev()
				this.content1 = this.slides[this.generatePrev]
				return
			}
		},
		next() {
			this.currentPS === this.slides.length - 1 ? this.currentPS = 0 : this.currentPS++
			let x = this.currentPS + 1
			if (x > this.slides.length - 1) {
				x = x - this.slides.length
			}
			this.generateNext = x
		},
		prev() {
			this.currentPS === 0 ? this.currentPS = this.slides.length - 1 : this.currentPS--
			let x = this.currentPS - 1
			if (x < 0) {
				x = this.slides.length + x
			}
			this.generatePrev = x
		},
		refreshInterval() {
			if (this.banners.length > 0) {
				clearInterval(this.intervalId)
				this.intervalId = null
				this.intervalId = setInterval(() => {
					this.nextSlide()
				}, 5000)
			}
		},
		goToSlide(index) {
			let counter = 0
			if (index === this.currentPS) return
			if ((index - this.currentPS > 0 && Math.abs(index - this.currentPS) <= (this.slides.length / 2)) || (index - this.currentPS < 0 && Math.abs(index - this.currentPS) >= (this.slides.length / 2))) {
				let i = setInterval(() => {
					this.nextSlide()
					counter++
					if (this.currentPS === index) {
						clearInterval(i)
					}
				}, 200)
			} else {
				let i = setInterval(() => {
					this.prevSlide()
					counter++
					if (this.currentPS === index) {
						clearInterval(i)
					}
				}, 200)
			}
		}
	},
	beforeDestroy() {
		clearInterval(this.intervalId)
		this.intervalId = null
		window.removeEventListener(`resize`, this.onresize);
	},
	components: {
		AppSlide
	},
	watch: {
		banners: function () {
			this.slides = [...this.banners, {requiredBanner: true}]
		}
	},
};
</script>

<style scoped>
.carousel {
	height: 219px;
	position: relative;
	z-index: 1;
	overflow: hidden;
	margin: 0px -20px -20px -32px;
	touch-action: none;
}

.slide {
	height: 100%;
	width: 100%;
	position: absolute;
	z-index: 0;
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 20px 20px 20px 32px;

}

.carousel-content {
	height: 100%;
	width: 100%;

}

.paginator {
	display: flex;
	gap: 5px;
	position: absolute;
	z-index: 11;
	background: rgba(0, 0, 0, 0);
	left: 50%;
	transform: translateX(-50%);
	padding: 10px 0;
}

.point {
	height: 10px;
	width: 10px;
	border-radius: 100%;
	background: var(--color-secondary-five);
	border: 1px solid var(--color-primary-two);
	transition: all .5s;
	cursor: pointer;

}

.point:hover {
	box-shadow: 0px 3px 6px var(--color-primary)
}


.point.active {
	background: var(--color-primary-two);
}



@media screen and (max-width: 613px) {
	.carousel {
		height: 250px;
	}
}

@media screen and (max-width: 400px) {
	.carousel {
		height: 280px;
	}
}

@media screen and (max-width: 358px) {
	.carousel {
		height: 300px;
	}
}

@media screen and (max-width: 320px) {
	.carousel {
		height: 320px;
	}
}
</style>
