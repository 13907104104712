<template>
	<div class="card-main">
		<div class="card-header">
			<div class="left-item"></div>
			<div class="right-item" @click="removeCardModal(id)">x</div>
		</div>
		<div class="description">
			<div style="display: flex; justify-content: space-between">
				<span>{{ pan }}</span> <span>{{ expDate }}</span>
			</div>
		</div>
		<div class="card-footer">
			<div class="footer-item-top"></div>
			<div class="footer-item-bottom"></div>
		</div>
	</div>
</template>

<script>
export default {
	data() {
		return {};
	},
	mounted() {},
	computed: {},
	methods: {
		async removeCardModal(id) {
            this.$emit('removeCardModal')
            this.$modal.show("delete-card", {id});
		},
	},
	props: {
		expDate: String,
		id: String,
		logo: String,
		pan: String,
	},
	components: {},
};
</script>

<style scoped lang="sass">
.card-main
    min-width: 270px
    min-height: 140px
    background-color: #33BC7D
    border-radius: 15px
    padding: 15px
    margin-left: 15px
    margin-bottom: 15px
    &:nth-child(1)
        background-color: #0A2A61
    &:nth-child(2)
        background-color: #7A87AB
    &:nth-child(3)
        background-color: #EC6F71
    &:nth-child(4)
        background-color: #33BC7D
    &:nth-child(5)
        background-color: #171715
    &:nth-child(6)
        background-color: #EB982C
    &:nth-child(7)
        background-color: #A5A5A4
    &:nth-child(8)
        background-color: #0A2A61
    &:nth-child(9)
        background-color: #7A87AB
    &:nth-child(10)
        background-color: #EC6F71

.card-header
    display: flex
    justify-content: space-between
    position: relative

.left-item
    width: 35px
    height: 25px
    background-color: #FFC727
    border-radius: 5px

.right-item
    cursor: pointer
    width: 20px
    height: 20px
    background-color: #E6575D
    border-radius: 50%
    text-align: center
    color: #FFF
    font-weight: 700
    position: absolute
    right: -7px
    top: -7px
    &:hover
        transition: all 0.3s
        opacity: 0.6

.description
    color: #FFFFFF
    font-size: 18px
    margin-top: 20px

.footer-item-top
    background-color: #A6A6A6
    width: 80px
    height: 5px
    margin-top: 15px
.footer-item-bottom
    background-color: #A6A6A6
    width: 60px
    height: 5px
    margin-top: 5px
</style>