<template>
    <div class="app-list">
        <slot name="list-item">

        </slot>
    </div>
</template>

<script>
export default {
    data() {
        return {
            
        }
    },
    props: {
        
    },
    methods: {

    }
}
</script>