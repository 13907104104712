<template>
	<AppModals
		name="product-info"
		:title="modelOpen.name"
		cancelButtonText="Закрыть"
		hideConfirm
		:clickToClose="false"
		@hideModal="hideModal"
		@beforeOpen="openInfo"
		:maxWidth="920"
		:width="920"
	>
		<template #content="{}">
			<div class="product-modal">
				<div class="product-modal__content">
					<!--  <div class="product-modal__image">
                        <img :src="`https://квартплата.онлайн/files/static/${modelOpen.image}`" />
                    </div> -->

					<div class="product-modal__description">
						<div class="product-modal__price">
							<div class="product-modal__price-text">
								Цена:
							</div>
							<div class="product-modal__number">
								{{ $numberToString(calcPrice) }} ₽
							</div>
						</div>

						<div class="product-modal__action">
							<div class="product-modal__actions">
								<BasketCalc :value="value" @input="changeBasket" class="mr-3" />
								<AppBtn @click.native="addBasket()" class="mr-2">
									В корзину
								</AppBtn>

								<!-- <AppBtn
                                    icon
                                    text
                                >
                                    <AppIcon name=heart size="1.3rem" prefix="far fa-" />
                                </AppBtn> -->
							</div>
						</div>

						<div class="product-modal__unit">
							* {{ modelOpen.price }} ₽ за 1 {{ modelOpen.unitName }}
						</div>

						<div class="product-modal__title">
							{{ modelOpen.suplierName }}
						</div>

						<div class="product-modal__text">
							<span v-html="convertNewLinesToBr(modelOpen.description)"></span>
						</div>
					</div>
				</div>
			</div>
		</template>
	</AppModals>
</template>

<script>
import BasketCalc from "../component/basket-calc";
import { mapMutations, mapGetters } from "vuex";

export default {
	data() {
		return {
			value: 1,
			modelOpen: {
				id: null,
				name: "",
				price: "",
				calc: "",
				categoryName: "",
				description: "",
				unitName: "",
				suplierName: ""
			}
		};
	},

	props: {
		id: {
			type: Number,
			default: -1
		}
	},
	mounted() {
		this.value = this.modelOpen.calc || 1;
	},
	computed: {
		...mapGetters(["countInBasket"]),
		calcPrice() {
			return this.value * this.modelOpen.price;
		}
	},
	methods: {
		...mapMutations(["setProductCount"]),
		convertNewLinesToBr(str) {
			if (str) {
				str = str.replace(/(?:\r\n|\r|\n)/g, "<br />");
				return str;
			}
			return "";
		},

		async modalClose() {
			this.$modal.hide("product-info");
		},

		addBasket() {
			const count =
				this.value +
				this.countInBasket({
					id: this.modelOpen.id,
					apartmentId: this.$route.query.apartmentId
				});
			this.setProductCount({
				product: this.modelOpen,
				count,
				apartmentId: this.$route.query.apartmentId
			});
			this.$modal.hide("product-info");
		},

		changeBasket({ val }) {
			this.value = val;
		},
		hideModal() {
			this.value = 1;
		},

		openInfo(event) {
			this.value = 1;
			this.modelOpen = { ...event };
		}
	},
	components: {
		BasketCalc
	}
};
</script>
