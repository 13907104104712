<template>
    <div>
        <AppTable
            :header="header"
            :data="getCounterArch"
            messageNoData='Счетчики отсутствуют'
            rowTemplate="dataRowTemplate"
            rounded
            ref="tableCounterArch"
            masterRow
        >
            <template v-slot:thead="{item}">
                <span>{{ item.name }}</span>
            </template>

            <template #dataRowTemplate="{item, index}">
                <td @click="openInfoCounter(index)">
                    <span class="app-table__title-mob mr-10">
                        Счетчик: 
                    </span>
                    <span> {{item.serviceName}}  {{ item.counterNumber ? `№ ${item.counterNumber}` : ''}} </span>
                    <span 
                        class="counter-table__col-counter-info" 
                        v-if="getVerificationNextDateStyle(item.verificationDate, item.verificationInterval) == 'danger-color'"
                    >
                        <i class="fas fa-exclamation-circle danger-color"></i>
                    </span>
                </td>
                <td>
                    <span class="app-table__title-mob">
                        Предыдущее значение: 
                    </span>
                    {{ item.value + ' ' + item.unitName}}
                </td>
                <td>
                    <span class="app-table__title-mob">
                        Дата закрытия: 
                    </span>
                    <span class="counter-table__col-text mr-2">{{dateReturnFormat(item.closingDate)}}</span>
                </td>
                <td>
                    <AppBtn
                        @click.native="openHistory(item.counterID)"
                        outlined
                    >
                        История
                    </AppBtn>
                </td>
            </template>

            <template #masterRow="{item}" v-show="item.showInfo">
                <td colspan="6">
                    <div class="counter-table__info">
                        <div class="counter-table__info-block">
                            <div class="counter-table__info-item">
                                <span>Счетчик:</span> {{item.serviceName || '-' }}
                            </div>
                            <div class="counter-table__info-item">
                                <span>Серийный номер:</span> {{item.serialNumber || '-'}}
                            </div>
                            <div class="counter-table__info-item">
                                <span>Производитель:</span> {{item.mark || '-'}}
                            </div>
                            <div class="counter-table__info-item">
                                <span>Модель:</span> {{item.model || '-'}}
                            </div>
                        </div>
                        <div class="counter-table__info-block counter-table__info-block--border">
                            <div class="counter-table__info-item">
                                <span>Дата поверки:</span> {{dateReturnFormat(item.verificationDate) || '-'}}
                            </div>
                            <div class="counter-table__info-item">
                                <span>Интервал поверки:</span> {{item.verificationInterval ?  item.verificationInterval + ' лет' : '-'}}
                            </div>
                            <div :class="'counter-table__info-item ' + getVerificationNextDateStyle(item.verificationDate, item.verificationInterval)" >
                                <span>Следующая дата поверки:</span> 
                                {{getVerificationNextDate(item.verificationDate, item.verificationInterval) || ''}}
                            </div>
                        </div>
                    </div>
                </td>
            </template>
        </AppTable>
    </div>
</template>

<script>
import { dateReturnFormat } from '@/plugins/methods'
import { mapGetters } from 'vuex'

export default {
    data() {
        return {
            header: [
                {
                    name: 'Счетчик',
                    width: '40%'
                },
                {
                    name: 'Предыдущее значение',
                    width: '20%'
                },
                {
                    name: 'Дата закрытия',
                    width: '20%'
                },
                {
                    name: '',
                    width: '20%'
                },
            ]
        }
    },
    props: {

    },
    computed: {
        ...mapGetters([
            'getCounterArch',
        ])
    },
    methods: {
         getVerificationNextDateStyle(date, interval) {
            this.$nextTick()
			if(!date || !interval) return 'danger-color'

			let dateNext = new Date(date);
			let dateNow = new Date();

			dateNext.setFullYear(dateNext.getFullYear() + interval);

			let daysLag = Math.ceil(( dateNext.getTime() - dateNow.getTime()) / (1000 * 3600 * 24));

			if(daysLag >= 180) return 'secondary-color'
			if(daysLag >= 60 && daysLag < 180) return 'warning-color'
			if(daysLag < 60) return 'danger-color'
			if(!daysLag) return 'danger-color'
		},

		dateReturnFormat (date) {
			return dateReturnFormat(date, false)
		},
		getVerificationNextDate(date, interval) {
			if(!date || !interval) return
			let dateNext = new Date(date);
			dateNext.setFullYear(dateNext.getFullYear() + interval);
			return dateReturnFormat(dateNext, false)
		},

		openInfoCounter(index) {
            this.$refs.tableCounterArch.openMaster(index)
		},

        openHistory(id) {
            this.$modal.show('counter-history', {counterId: id})
        },

    },
    components: {
   
    }
}
</script>