<template>
    <div>
        <h3 class="medium secondary-color mb-3">
            {{ this.$route.params.text || 'Добавление лицевого счета' }}
        </h3>

        <QrCode v-if="canScanQrCode" @manualStep="manualStep" />

        <div class="row" v-else>
            <div class="col-12 col-xl-5 col-nt-5 mb-2" style="z-index: 100">
                <AppCard>
                    <template #body>
                        <AppStepper :steps="stepsCount" :active="active" :stepOfHide="stepOfHide">
                            <template #1>
                                <AppStepperHeader title="ИНН" />

                                <AppStepperContent>
                                    <InnOrg @stepNext="stepNext" @hideStep="hideStep" />
                                </AppStepperContent>
                            </template>

                            <template #2>
                                <AppStepperHeader title="Номер лицевого счета или договора" />
                                <AppStepperContent>
                                    <Account @stepNext="stepNext" @addStep="addStep" @hideStep="hideStep" />
                                </AppStepperContent>
                            </template>
                        </AppStepper>
                    </template>
                </AppCard>
            </div>
            <div class="col-12 col-xl-7 col-nt-7 mt-3--lg">
                <AppCard>
                    <template #body>
                        <div class="account-add__instruction">
                            <h4 class="medium secondary-color">Как добавить лицевой счет?</h4>
                            <div class="mt-2 secondary-3-color">
                                Вся необходимая информация указана в квитанциях, которые вы регулярно получаете от своей
                                управляющей компании, ТСЖ или обслуживающей организации.
                                Даже если квитанции вам не присылают, эту информацию всегда можно получить по телефону.
                                <div class="medium mt-3 mb-1">Вам понадобятся: </div>
                                <ul class="account-add__list">
                                    <li>ИНН организации, которая выставляет вам квитанции;</li>
                                    <li>Номер вашего лицевого счета;</li>
                                </ul>
                                <div class="medium mt-4 mb-1">Для юридических лиц список другой:</div>
                                <ul class="account-add__list">
                                    <li>ИНН организации, с которой заключен договор;</li>
                                    <li>Номер договора;</li>
                                    <li>ИНН вашей организации;</li>
                                </ul>
                            </div>
                        </div>
                    </template>
                </AppCard>
            </div>
        </div>
    </div>
</template>

<script>
import InnOrg from './steps/inn-org'
import Account from './steps/ls'
import QrCode from './qr-code/qr-code';

export default {
    data() {
        return {
            active: 1,
            test: '',
            stepsCount: 2,
            canScanQrCode: false,
            stepOfHide: []
        }
    },
    mounted() {
        console.log("test route", this.$route)
    },
    methods: {
        stepNext(step) {
            this.active = step
        },
        addStep(steps) {
            this.stepsCount = steps
        },
        hideStep(hidden) {
            this.stepOfHide = hidden
        },
        testCanScan() {
            let windowWidth = document.getElementById('app').offsetWidth
            if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
                if (/Android|webOS|iPhone|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) && windowWidth < 500) {
                    this.canScanQrCode = true
                } else {
                    this.canScanQrCode = false
                }
            } else {
                this.canScanQrCode = false
            }
        },
        manualStep() {
            this.canScanQrCode = false
        }
    },
    components: {
        InnOrg,
        Account,
        QrCode,
    }
}
</script>