<template>
	<AppCard class="card-supplier-sub-cat">
		<template #body>
			<div
				class="card-supplier-sub-cat__image"
				:style="`background: url(${image})`"
				tabindex="0"
				@click="cardCLick"
				@keyup.space="cardCLick"
				@keyup.enter="cardCLick"
			></div>
			<div class="card-supplier-sub-cat__title">
				{{ title }}
			</div>
		</template>
	</AppCard>
</template>

<script>
export default {
	data() {
		return {};
	},
	props: {
		title: {
			type: String,
			default: ""
		},
		image: {
			type: String,
			default: ""
		},
		fullInfo: {
			type: Object,
			default: () => {}
		},
		redirect: {
			type: Object
		}
	},
	methods: {
		cardCLick() {
			this.$router.push(this.redirect);
			this.$emit("clickCard", this.fullInfo.id);
		}
	}
};
</script>
