<template>
    <div class="app-icon-svg__block">
        <svg class="app-icon-svg" :style="`width: ${width}; fill: ${color}; ${styleCss}`">
            <use :xlink:href="`/img/sprite.svg#${name}`"></use>
        </svg>
    </div>
</template>

<script>

export default {
    data() {
        return {

        }
    },
    props: {
        name: {
            type: String,
            default: ''
        },
        height: {
            type: String,
            default: '12px'
        },
        color: {
            type: String,
            default: '#FFF' 
        },
        width: {
            type: String,
            default: '12px'
        },
        styleCss: {
            type: String,
            default: ''
        }
    }
}
</script>

