import $axios from '@/plugins/axios-instance'
import { router } from '../router'

export default {
	state: {
		theme: ''
	},
	getters: {
		getTheme(state) {
			return state.theme
		},
	},
	mutations: {
		setTheme(state, theme) {
			state.theme = theme
		},
	},
	actions: {
		toggleTheme(context, data) {
			context.commit('setTheme', data)
		},
	}
}
