<template>
    <AppGlobalLayout
        :loading="loading"
        :error="error"
        @reload="toLoadInfo"
    >
		
    	<div slot=body>
			<modalTicketError />

			<div class="">
				<div class="col-12">
					<AppTabs
						mobileRevers
						:data="[
							{
								name: 'Информация',
								iconSvg: 'info-list',
								href: 'info'
							},
							{
								name: 'Начисления',
								iconSvg: 'money',
								href: 'calculate',
								show: !!getAccrualsList.data.length,
							},
							{
								name: 'Счетчики',
								iconSvg: 'speed',
								//show: !!getCounterList.counters.length,
								href: 'counter'
							},
							{
								name: 'Контакты',
								iconSvg: 'contact',
								show: !!getOrganizationInfo.contacts.length,
								href: 'news'
							},

						]"
						class="mt-2"
					>
						<template v-slot:content="{active}">
							<div v-if="active == 0">
								<accountInfo />
							</div>
							<div v-if="active == 1">
								<accountCalculate />
							</div>
							<div v-if="active == 2">
								<accountCounter />
							</div>
							<div v-if="active == 3">
								<Contacts />
							</div>
						</template>
					</AppTabs>
				</div>

			</div>






		</div>
	</AppGlobalLayout>
</template>

<script>
import accountNews from './account-news/account-news'
import Contacts from './account-tabs/contacts'
import accountInfo from './account-tabs/account-info'
import accountCalculate from './account-tabs/account-calculate'
import accountCounter from './account-tabs/account-counter/account-counter'
import { mapGetters } from 'vuex'
import { dateReturnFormat } from '@/plugins/methods'
import modalTicketError from './modals/account-ticketError'


export default {
	data () {
		return {
			tabs: 0,
			loading: false,
			error: false,
			widthWindow: document.getElementById('app').offsetWidth 
		}
	},
	computed: {
		...mapGetters([
			'getAccountDetails', 'getOrganizationInfo', 'getCounterList', 'getAccrualsList'
		]),
		tabsContentMobile() {
			if(this.widthWindow > 490) {
				return true
			} else if(this.tabs == 0) {
				return true
			} else {
				return false
			}
		}
	},
	mounted() {
		
	},
	methods: {
		show (modalName, accountId) {
			this.$modal.show(modalName, { allPayment: false, accountId: accountId})
		},
		dateReturnFormat (date) {
			return dateReturnFormat(date, false)
		},


		async toLoadInfo () {
			this.loading = true
			this.error = false
			try {

				await this.$store.dispatch('accountDetails', this.$route.params.id)

				await this.$store.dispatch('organizationInfo', {
					accountId: this.$route.params.id,
					organizationId: this.getAccountDetails.organizationId
				})

				await this.$store.dispatch('counterList', this.$route.params.id)
				await this.$store.dispatch('accrualsList', this.$route.params.id)
			} catch (e) {
                if(e.response) {
                    this.$errorsMod(e.response)
                } else {
					this.error = true
					this.$errorsMod(e)
                }
			}
			this.loading = false
			
			this.$nextTick(function () {
				if(this.$route.query.error) {
					this.openModal()
				}
			})
		},
		openModal() {
			this.$modal.show('accountTicketError', this.$route.query)
			let tempAccountID = this.$route.path.split('/')
			let acountid = tempAccountID[tempAccountID.length-1] || 0
			history.pushState(null, null, `/account/info/${acountid}`);
            location.hash = '';
        },
	},
	created () {
		this.toLoadInfo()
	},
	components: {
		accountNews, accountInfo, accountCalculate, accountCounter, modalTicketError, Contacts
	}
}
</script>
