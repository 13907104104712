<template>
	<AppGlobalLayout :loading="loadingPage" :error="error">
		<div slot="body" style="max-width: 820px; margin-top: 20px">
			<!-- <div class="mb-2">В этом разделе отображаются активированные промокоды, предоставляющие скидки на оплату и
				различные бонусы.</div> -->
			<AppCard class="card-discount">
				<template #body>
					<div class="card-discount__content">
						<div>
							<h3 class="card-discount__title">Активируйте промокод</h3>
							<div class="card-discount__subtitle">И получите скидку на оплату и бонусы!</div>
							<div class="discount-form">
								<AppInput style="max-width: 240px; font-size: 18px;" id="code" placeholder="Введите код купона"
									type="text" :value="code" @input="code = $event.trim()" big />

								<AppBtn v-if="code" style="height: 3rem" @click.native="sendCoupon">
									<i class="fas fa-arrow-up"></i>
								</AppBtn>
							</div>
						</div>
						<div class="card-discount__img">
							<img v-if="getTheme === 'dark-theme'" src="/img/dark-theme/discounts-dark.svg" width="255px" />
							<img v-else src="/img/discounts.svg" width="255px" />
						</div>
					</div>

				</template>
			</AppCard>
			<h3 class="mt-2 medium secondary-color">История</h3>
			<div class="discount-table">
				<AppTable :header="headerTable" :data="history" messageNoData='История скидок пуста'
					emptyImage="/img/request-empty.svg" emptyText="История скидок пуста">
					<template v-slot:thead="{ item }">
						<span>{{ item.name }}</span>
					</template>

					<template #tbody="{ item }">
						<td>
							<span class="app-table__title-mob">Название:</span>
							{{ item.name }}
						</td>
						<td>
							<span class="app-table__title-mob">Дата старта:</span>
							{{ new Date(item.startDate).toLocaleDateString() }}
						</td>
						<td>
							<span class="app-table__title-mob">Дата окончания:</span>
							{{ new Date(item.endDate).toLocaleDateString() }}
						</td>
						<!-- <td>
							<span class="app-table__title-mob">Процент скидки:</span>
							{{ item.discountPercent }}
						</td>
						<td>
							<span class="app-table__title-mob">Минимальный процент:</span>
							{{ item.lowLimitPercent }}
						</td> -->

					</template>
				</AppTable>

			</div>
		</div>
	</AppGlobalLayout>
</template>

<script>
import { mapGetters } from "vuex";

export default {
	data() {
		return {
			loadingPage: false,
			error: false,
			code: '',
			history: [],
			headerTable: [
				{
					name: 'Название:',
				},
				{
					name: 'Дата старта:',
				},
				{
					name: 'Дата окончания:',
				},
				// {
				// 	name: 'Процент скидки:',
				// },
				// {
				// 	name: 'Минимальный процент:',
				// },

			]
		};
	},
	async mounted() {
		this.loadHistory()
	},
	computed: {
		...mapGetters(["getTheme"]),
	},
	methods: {
		async sendCoupon() {
			const code = this.code
			this.code = ''
			try {
				await this.$store.dispatch('sendCouponCode', code)
				await this.loadHistory()
			} catch (e) {
				if (e.response) {
					this.$errorsMod(e.response)
				} else {
					this.error = true
					this.$errorsMod(e)
				}
			}

		},
		async loadHistory() {
			this.loading = true
			this.error = false
			try {
				const result = await this.$store.dispatch('fetchDiscountsHistory', this.code)
				this.history = result
			} catch (e) {
				if (e.response) {
					this.$errorsMod(e.response)
				} else {
					this.error = true
					this.$errorsMod(e)
				}
			}
			this.loading = false
		}

	},
	components: {
	},

};
</script>
<style scoped>
.discount-form {
	display: flex;
	gap: 1em;
	align-items: end;
	/* flex-wrap: wrap; */
}

.card-discount {
	display: flex;
}

.card-discount__title {
	color: var(--color-secondary);
	margin-bottom: 13px;
	padding-top: 15px;
}

.card-discount__subtitle {
	font-size: 18px;
	margin-bottom: 35px;
}

.card-discount__img {
	margin-right: 50px;
	width: 190px;

	@media screen and (max-width: 768px) {
		display: none;
	}
}

.card-discount__content {
	padding: 0 10px;
	width: 100%;
	display: flex;
	justify-content: space-between;
}
</style>


