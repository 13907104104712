<template>
	<AppGlobalLayout :loading="loading" :error="error" @reload="loadInfo">
		<div slot="body">
			<AccountCounter />
		</div>
	</AppGlobalLayout>
</template>

<script>
import AccountCounter from "../account/account-page/account-tabs/account-counter/account-counter.vue";
export default {
	data() {
		return {
			loading: true,
			error: false
		};
	},
	mounted() {
		this.loadInfo();
	},
	methods: {
		async loadInfo() {
			this.loading = true;
			this.error = false;
			try {
				await this.$store.dispatch("counterList", this.$route.params.id);
			} catch (e) {
				this.error = true
				this.$errorsMod(e.response);
			}
			setTimeout(() => {
				this.loading = false;
			}, 200);
		}
	},
	components: {
		AccountCounter
	}
};
</script>
