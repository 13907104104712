<template>
	<AppTable :header="headerTable" :data="getInsurance.history"
		messageNoData='У Вас нет привязанных лицевых счетов или договоров. Нажмите на кнопку "Добавить", чтобы добавить ЛС или договор.'
		:childRow="false" class="insurance-history-table">
		<template v-slot:thead="{ item }">
			<span>{{ item.name }}</span>
		</template>

		<template v-slot:tbody="{ item, index }">
			<td @click="$emit('click-card', item.insuranceId)">
				<span>№ {{ item.number }}</span>
			</td>

			<td @click="$emit('click-card', item.insuranceId)">
				<span>
					{{ item.providerName }}
				</span>
			</td>

			<td @click="$emit('click-card', item.insuranceId)">
				<span>
					<InsuranceState type="string" :activeStatus="item.status" />
				</span>
			</td>

			<td class="insurance-history-table__date" @click="$emit('click-card', item.insuranceId)">
				<span>
					<InsurancePeriod :index="index" />
				</span>
			</td>

		</template>
	</AppTable>
</template>

<script>
import InsurancePeriod from './insurance-period.vue'
import { mapGetters } from 'vuex'

export default {
	components: {
		InsurancePeriod,
	},
	data() {
		return {

			headerTable: [
				{
					name: "Номер страхования",
					width: "25%"
				},
				{
					name: "Компания страховщик",
					width: "25%"
				},
				{
					name: "Статус",
					width: "25%"
				},
				{
					name: "Период действия страховки",
					width: "25%"
				},

			]
		};
	},
	computed: {
		...mapGetters(['getInsurance'])
	}
};
</script>

<style scoped>
.insurance-history-table__date {
	position: static;
	padding-left: 24px;
}
</style>
