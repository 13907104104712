<template>
	<AppModals
		name="paper-receipts"
		@beforeOpen="beforeOpen"
		:maxWidth="800"
		:width="800"
		:hideFooter="true"
	>
		<template #content>
			<div class="paper-modal-content">
				<div class="title">Мы за сохранение природы</div>
				<div class="image">
					<img src="@/img/paper-modal/receipts.png" />
				</div>
				<template v-if="invoiceDisabled">
					<div class="text">
						Сейчас у Вас отключено получение бумажных квитанций.
					</div>
					<div class="btns">
						<AppBtn @click="submit" color="danger">Получать снова</AppBtn>
						<AppBtn @click="cancel" outlined>Отказаться</AppBtn>
					</div>
				</template>
				<template v-else>
					<div class="text">
						Откажитесь от получения бумажных квитанций и получайте их в
						электронном виде.
					</div>

					<div class="btns">
						<AppBtn @click="submit" color="danger">Отказаться</AppBtn>
						<AppBtn @click="cancel" outlined>Получать данные</AppBtn>
					</div>
				</template>
			</div>
		</template>
	</AppModals>
</template>

<script>
import { mapActions } from "vuex";

export default {
	props: {
		invoiceDisabled: {
			required: true
		}
	},
	methods: {
		...mapActions(["paperReceiptsSubscription"]),
		async beforeOpen() {
			this.app;
		},
		async submit() {
			const payload = {
				id: this.$route.params.id,
				isDisabled: !this.invoiceDisabled
			};
			this.$modal.hide("paper-receipts");
			await this.paperReceiptsSubscription(payload);
			this.$emit("refresh");
		},
		cancel() {
			this.$modal.hide("paper-receipts");
		}
	}
};
</script>
<style scoped>
.paper-modal-content {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	width: 80%;
	height: 80%;
	margin: 30px auto;
	font-size: 1em;
}
.title {
	color: var(--color-primary);
	text-align: center;
	font-size: 2.5em;
	font-weight: 700;
}
.image {
	margin-top: 40px;
	width: 353px;
}
.image > img {
	object-fit: contain;
	width: 100%;
	height: 100%;
}
.text {
	margin-top: 30px;
	color: var(--color-secondary-three);
	text-align: center;
	font-size: 1.5em;
	font-weight: 500;
}
.btns {
	margin-top: 50px;
	display: flex;
	justify-content: center;
	gap: 2em;
	width: 100%;
}
.btns > button {
	flex-grow: 1;
	height: 3em;
	font-size: 1.2em;
}
::v-deep .app-modal {
	height: auto;
}
@media (max-width: 768px) {
	.image {
		display: none;
	}
	.btns {
		flex-direction: column;
	}
}
@media (max-width: 540px) {
	.paper-modal-content {
		width: 90%;
	}

	.title {
		font-size: 1.2em;
	}
	.text {
		font-size: 1em;
	}
	.btns {
		font-size: 0.8em;
		gap: 1em;
	}
}
</style>
