<template>
    <AppModals
        name="counter-rename"
        title="Переименовать счетчик"
		cancelButtonText="Закрыть"
		@beforeOpen="beforeOpen"
        confirmButtonText="Переименовать "
        @confirm="renameCounter"
        :disabledConfirm="!$v.value.maxLength"
        :loadingConfirm="loading"
    >
        <template  #content="{}">
            <div class="modal-content">
                <div>
                    <AppInput 
                        placeholder="Введите новое название"
                        v-model.trim="$v.value.$model"
                        :error="$v.value.$error"
                        required
                        maxLength
                        :maxLengthError="$v.value.maxLength"
                        :requiredError="$v.value.required"
                        label="Наименование"
                    >
                        <template slot="maxLength">Не более 100 символов</template>
                    </AppInput>

                    <div style="margin: 20px 0">Придумайте своё название для вашего счетчика.</div>
                </div>
			</div>
        </template>
    </AppModals>
</template>

<script>
import { required, maxLength } from 'vuelidate/lib/validators'

export default {
	data () {
		return {
            loading: false,
            value: '',
            counter: null,
            name: '',
            accountId: null
		}
	},
	props: {

    },
    
    mounted() {
        
    },

	methods: {
		modalClose () {
			this.$modal.hide('counter-rename')
        },
        
		async renameCounter () {

            this.$v.$touch()

            //if(!this.$v.$invalid) {
                this.loading = true    

                let formData = {
                    id: this.counter.counterID,
                    name: this.value.trim() || null,
                    accountId: this.accountId
                }

                try {
                    await this.$store.dispatch('counterRename', formData)
                    this.$modal.hide('counter-rename')
                } catch (e) {
                    this.$errorsMod(e.response)
                }

                this.loading = false
            //}
        },

        async beforeOpen (event) {
            this.value = event.counter.description || event.counter.serviceName
            this.counter = {...event.counter}
            this.accountId = event.accountId
		}
    },
    validations: {
		value: {
			maxLength: maxLength(100)
		},
	},
}
</script>


<style scoped>

</style>