<template>
	<!-- <AppGlobalLayout :loading="loading" :error="error" @reload="userRequestList"> -->
	<div slot="body">
		<addRequest @sendRequest="sendRequest" :loading="requestLoading" />

		<AppCard
			classCss="main-banner-card mb-4"
			:style="[getTheme === 'dark-theme' ? darkStyle : defaultStyle]"
		>
			<template #body>
				<div class="main-banner">
					<div class="main-banner__title">
						Отправьте заявку в управляющую организацию
					</div>
					<div class="main-banner__description">
						Задайте свой вопрос или сообщите о проблеме.
						<br /><br />
					</div>

					<AppBtn @click.native="show('add-application')" classCss="mb-2">
						Создать заявку
					</AppBtn>
				</div>
			</template>
		</AppCard>

		<div class="row">
			<div class="col-md-12 col-12">
				<AppTable
					:header="headerTable"
					:data="getUserRequestList.data"
					messageNoData="Заявки отсутствуют"
					@rowClick="routerLink"
					emptyImage="/img/request-empty.svg"
					emptyText="Заявки отсутствуют"
				>
					<template v-slot:thead="{ item }">
						<span>{{ item.name }}</span>
					</template>

					<template #tbody="{ item }">
						<td>
							<span class="app-table__title-mob">№ заявки:</span>
							{{ item.requestNumber }}
						</td>
						<td>
							<span class="app-table__title-mob">Дата и время:</span>
							{{ dateReturnFormat(item.createdDate) }}
						</td>
						<td>
							<span class="app-table__title-mob">Поставщик:</span>
							{{ item.organizationName }}
						</td>
						<td>
							<span class="app-table__title-mob">Потребитель:</span>
							{{ item.address }}
						</td>
						<td>
							<span class="app-table__title-mob">Тема:</span>
							{{ item.subject }}
						</td>
						<td>
							<span class="app-table__title-mob">Статус:</span>
							<span class="applications-table__status">{{
								getStatus(item.status)
							}}</span>
						</td>
					</template>
				</AppTable>

				<div class="d-flex just-center">
					<div class="mt-3">
						<v-pagination
							v-if="getUserRequestList.totalPages > 1"
							:value="currentPage"
							@input="changePagin"
							:page-count="totalPage"
							:classes="defaultClass"
							:labels="paginationAnchorTexts"
						>
						</v-pagination>
					</div>
				</div>
			</div>
		</div>
		<snackbar ref="snackbar" />
	</div>
	<!-- </AppGlobalLayout> -->
</template>

<script>
import addRequest from "./modal/add-request";
import { mapGetters } from "vuex";
import { dateReturnFormat } from "@/plugins/methods";
import vPagination from "vue-plain-pagination";
import { getStatus } from "./methods/status";

export default {
	data() {
		return {
			requestLoading: false,
			loading: true,
			error: false,
			currentPage: 1,
			paginationAnchorTexts: {
				prev: "Назад",
				next: "Вперед"
			},
			defaultClass: {
				ul: "pagination",
				li: "page-item",
				liActive: "active",
				liDisable: "disabled",
				button: "page-link"
			},
			darkClass: {
				ul: "pagination",
				li: "page-item",
				liActive: "active",
				liDisable: "disabled",
				button: "page-link"
			},
			darkStyle: {
				background: "var(--sidebar-color)"
			},
			defaultStyle: {
				backgroundImage: "url('/img/fon-request.png') !important"
			},
			headerTable: [
				{
					name: "№",
					width: "6%"
				},
				{
					name: "Дата и время",
					width: "15%"
				},
				{
					name: "Поставщик",
					width: "18%"
				},
				{
					name: "Потребитель",
					width: "32%"
				},
				{
					name: "Тема",
					width: "17%"
				},
				{
					name: "Статус",
					width: "12%"
				}
			]
		};
	},
	mounted() {
		this.userRequestList(0);
	},
	methods: {
		getStatus(status) {
			return getStatus(status);
		},
		changePagin(page) {
			this.currentPage = page;
			this.userRequestList(page - 1);
		},
		show(modalName) {
			this.$modal.show(modalName);
		},
		routerLink(item) {
			this.$router.push({ path: `/request/${item.id}` });
		},
		async userRequestList(page) {
			this.loading = true;
			this.error = false;

			try {
				await this.$store.dispatch("userRequestList", { page });
			} catch (e) {
				if (e.response) {
					this.$errorsMod(e.response);
				} else {
					this.error = true;
					this.$errorsMod(e);
				}
			}
			this.loading = false;
		},

		dateReturnFormat(date) {
			return dateReturnFormat(date, true);
		},
		async sendRequest(requestData) {
			this.requestLoading = true;
			try {
				await this.$store.dispatch("userRequestCreate", requestData);
				this.currentPage = 1;
				this.$modal.hide("add-application");
				await this.userRequestList(0);
				this.$refs.snackbar.showSnackbar(
					"Заявка успешно отправлена",
					"success"
				);
			} catch (error) {
				console.log(error);
			} finally {
				this.requestLoading = false;
			}
		}
	},
	computed: {
		...mapGetters(["getUserRequestList", "getTheme"]),

		totalPage() {
			let total = this.getUserRequestList.totalPages / 10;
			return Math.ceil(total);
		}
	},

	components: {
		addRequest,
		vPagination //AddRequest
	}
};
</script>

<style></style>
