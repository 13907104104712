<template>
	<div>
		<ModalConfirm />
		<DeleteItem />
		<ModalSuccess />

		<h3
			class="medium mb-3"
			v-if="getTotalCartCount"
			:style="getTheme === 'dark-theme' ? 'color:#FEFDFF' : ''"
		>
			Оформление заказа
		</h3>
		<div class="cart__empty" v-if="getTotalCartCount == 0">
			<div class="cart__empty-text">Ваша корзина пуста</div>
			<img v-if="getTheme !== 'dark-theme'" src="/img/empty-cart.svg" />
			<img v-else src="/img/dark-theme/full-order-man-dark.svg" />
		</div>

		<div
			class="cart group"
			v-for="[apartmentId, group] in Object.entries(getCart).filter(
				item => item[1].length
			)"
		>
			<div class="cart-item__list">
				<div class="group-title">
					Адрес заказа: <span>{{ apartmentName(apartmentId) }}</span>
				</div>
				<div
					class="cart-item__card mb-3"
					v-for="(item, index) in group"
					:key="item.id"
				>
					<AppCard>
						<template #body>
							<div class="cart-item__delete">
								<AppBtn
									icon
									text
									@click.native="
										$modal.show('delete-item-cart', {id: item.id, apartmentId})
									"
								>
									<AppIcon name="times" style="font-size: 1.25rem" />
								</AppBtn>
							</div>
							<div class="cart-item__title">
								{{ item.name }}
							</div>
							<div class="cart-item__content">
								<div class="cart-item__description">
									<div style="display: flex; justify-content: space-between;">
										<div>
											<div class="cart-item__sub-title">
												{{ item.suplierName }}
											</div>

											<div class="cart-item__text" style="font-size: 15px">
												<span>{{ item.description }}</span>
											</div>

											<div class="cart-item__unit">
												{{ item.price }} ₽
												<span v-if="item.unitName">за {{ item.unitName }}</span>
											</div>
										</div>
										<div>
											<AppBtn
												icon
												text
												style="font-size: 2em;"
												v-if="getFavouriteIds.some(val => item.id === val)"
												@click="removeFromFavourites(item.id)"
											>
												<i
													style="color: var(--color-danger)"
													class="fas fa-heart"
												></i>
											</AppBtn>
											<AppBtn
												icon
												text
												style="font-size: 2em;"
												@click="addToFavourites(item.id)"
												v-else
											>
												<i
													style="color: var(--color-danger)"
													class="far fa-heart"
												></i>
											</AppBtn>
										</div>
									</div>
									<div class="cart-item__footer">
										<div class="cart-item__price">
											<div class="cart-item__price-text">Цена:</div>
											<div class="cart-item__number">
												{{ $numberToString(+item.price * +item.count) }} ₽
											</div>
										</div>
										<BasketCalc
											:value="item.count"
											:fullItem="item"
											@input="
												setProductCount({
													product: item,
													apartmentId,
													count: $event.val
												})
											"
											@remove="
												removeProductFromCart({
													id: item.id,
													apartmentId
												})
											"
											class="mr-2"
										/>
									</div>
								</div>
							</div>
						</template>
					</AppCard>
				</div>
			</div>

			<div class="cart-info">
				<div class="cart-info__text">
					Итого {{ group.length }} товара на сумму:
				</div>
				<div class="cart-info__calc">
					{{
						$numberToString(
							group.reduce((ac, val) => ac + val.price * val.count, 0)
						)
					}}
					₽
				</div>
				<div class="cart-info__button">
					<AppBtn @click="$modal.show('confirm-order', { apartmentId })">
						Подтверждаю заказ
					</AppBtn>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { mapMutations, mapGetters } from "vuex";
import BasketCalc from "../suplier/component/basket-calc";
import ModalConfirm from "./modals/modal-confirm";
import ModalSuccess from "./modals/modal-success";
import DeleteItem from "./modals/delete-item";

export default {
	data() {
		return {};
	},
	computed: {
		...mapGetters([
			"getCart",
			"getTotalCartCount",

			"getFavouriteIds",
			"getTheme",
			"getActiveTownId",

			"getApartment"
		])
	},
	methods: {
		...mapMutations(["setProductCount", "removeProductFromCart"]),
		deleteItem() {},
		editItem() {},
		changeBasket({ val, item }) {
			this.setChangeCart({ ...item, calc: val });
		},
		async addToFavourites(id) {
			await this.$store.dispatch("addToFavourites", id);
			await this.$store.dispatch("fetchFavourites", this.getActiveTownId);
		},
		async removeFromFavourites(id) {
			await this.$store.dispatch("removeFromFavourites", id);
			await this.$store.dispatch("fetchFavourites", this.getActiveTownId);
		},
		apartmentName(apartmentId) {
			if (this.getApartment.data.length) {
				const apartment = this.getApartment.data.find(
					item => item.id == apartmentId
				);
				if (apartment) {
					return apartment.address;
				}
			}
			return "Адрес не выбран";
		}
	},
	mounted() {
		if (!this.getApartment.data.length) {
			this.$store.dispatch("apartmentList");
		}
	},
	components: {
		BasketCalc,
		ModalConfirm,
		DeleteItem,
		ModalSuccess
	}
};
</script>
<style scoped>
.group {
	margin-top: 1em;
	border-bottom: 2px dotted var(--color-secondary-three);
}
.group-title {
	margin-bottom: 1em;
	font-size: 1.2em;
}
.group-title > span {
	color: var(--color-secondary-two);
	margin-left: 1em;
}
</style>
