import $axios from '@/plugins/axios-instance'

export default {
	state: {
		notificationsList: []
	},
	getters: {
		getNotifyList (state) {
			return state.notificationsList
		}
	},
	actions: {

		notificationsList (context, data) {
            return $axios.get(`/alerts/List`)
            .then((response) => {
				context.state.notificationsList = response.data
            }).catch((e)=>{
                throw(e)
            })
		},

		notificationReaded (context, data) {
			//context.state.notificationsList.splice(data.index, 1)

			return $axios.get(`/alerts/AlertReaded/${data.id}`)
            .then((response) => {
				context.state.notificationsList.splice(data.index, 1)
            }).catch((e)=>{
                throw(e)
            })
		}

	}
}
