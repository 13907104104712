<template>
    <div class="card-product__root" @mouseover="calcHeightCard">
        
        <AppCard >
            <template #body>
                <div class="card-product__body">
                    <div 
                        class="card-product__card" 
                        tabindex="0" 
                        @click="cardCLick"
                        @keyup.space="cardCLick"
                        @keyup.enter="cardCLick"
                    >
                        <div class="card-product__image" :style="`background: url(${imageUrl})`">
                        </div>
                        <div class="card-product__price">
                            <div class="card-product__debt">
                                {{ $digitNumber(price * -1)  }} ₽
                            </div>
                        </div>
                    </div>
                    <div class="card-product__description">
                        <div class="card-product__visible-block">
                            <div class="card-product__title">
                                {{ title }}
                            </div>
                            <AppBtn icon text classes="card-product__add-card" @click.native="addToCard">
                                <AppIcon name="shopping-cart" size="1.25rem" style="color: #A5A5A4" />
                            </AppBtn>
                        </div>
                        <div class="card-product__hidden-block" :style="`min-height: ${height}px; height: 100%;`">
                            <div class="card-product__text" ref="calcHeight">
                                {{ description }}
                            </div>
                            <div class="pr-4 pl-4">
                                <AppBtn block @click.native="addToCard">
                                    Добавить в корзину
                                </AppBtn>
                            </div>
                        </div>
                    </div>
                </div>
            </template>
        </AppCard>
    </div>
</template>

<script>
import { mapMutations } from 'vuex'

export default {
    data() {
        return {
            height: 140,
        }
    },
    props: {
        index: {
            type: Number,
            default: 0
        },
        price: {
            type: Number | String,
            default: 0
        },
        title: {
            type: String,
            default: ''
        },
        description: {
            type: String,
            default: ''
        },
        imageUrl: {
            type: String,
            default: ''
        },
        fullInfo: {
            type: Object,
            default: () => {}
        }

    },
    mounted() {
        this.calcHeightCard()
    },
    methods: {
        ...mapMutations([
            'setAddCart'
        ]),
        calcHeightCard() {
            this.$nextTick(() => {
                if(this.$refs.calcHeight) {
                    let calcHeight = this.$refs.calcHeight.offsetHeight + 100
                    this.height = calcHeight
                }
            })

            
        },

        addToCard() {
            this.setAddCart({...this.fullInfo, calc: 1})
        },

        cardCLick() {
            this.$emit('clickToCard', this.fullInfo)
        }
    }
}
</script>