<template>
	<div class="card-wrapper">
		<AppCard>
			<template #body>
				<div class="description-wrapper">
					<div class="icon-wrapper">
						<img v-if="logo" width="50" style="border-radius: 20%"
							:src="`http://cdn.jfservice.ru/brands/${logo}`" />
						<img v-else src="/img/payment-item.svg" />
					</div>
					<div :style="getTheme === 'dark-theme' && { color: '#cdcdd6' }" class="title-text">
						{{ pan.split('').splice(0, 4).join('') + ' **** **** ' + pan.split('').splice(-4, 4).join('') }}
						<div v-if="expDate" :style="getTheme === 'dark-theme' && { color: '#cdcdd6' }"
							class="title-text ml-4">
							{{ expDate && $addString(expDate, 2, "/") }}
						</div>
					</div>

					<div>
						<AppBtn @click="removeCardModal(id)" color="danger">Удалить</AppBtn>
					</div>
				</div>
			</template>
		</AppCard>
	</div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
	data() {
		return {};
	},
	mounted() { },
	computed: {
		...mapGetters(["getTheme"]),
	},
	methods: {
		async removeCardModal(id) {
			this.$emit("removeCardModal");
			this.$modal.show("delete-card", { id });
		},
	},
	props: {
		expDate: String,
		id: String,
		logo: String,
		pan: String,
	},
	components: {},
};
</script>

<style scoped lang="sass">
.icon-wrapper
	width: 50px
	height: 50px

.title-text
	color: #171715
	font-weight: 400
	font-size: 16px
	white-space: nowrap

.card-wrapper
	width: 450px
	@media screen and (max-width: 490px)
		width: 100%
	
.description-wrapper
	display: grid
	grid-template-columns: 50px 1fr 100px
	gap: 20px
	@media screen and (max-width: 450px)
		grid-template-columns: 50px 1fr
		>div:last-child
			grid-column-start: 1
			grid-column-end: 3
			justify-content: end
	>div
		display: flex
		justify-content: center
		align-items: center
		&:nth-child(2)
			justify-content: start
</style>
