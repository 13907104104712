<template>
	<div class="buy-insurance__list">
		<div class="loading-wrapper" v-if="getInsurancePrepareLoading">
			<AppLoading :height="70" />
		</div>

		<div v-else>
			<AppTable
				:header="headerTable"
				:data="getInsurancePrepare"
				messageNoData='У Вас нет привязанных лицевых счетов или договоров. Нажмите на кнопку "Добавить", чтобы добавить ЛС или договор.'
				:childRow="false"
				class="insurance-history-table"
			>
				<template v-slot:thead="{ item }">
					<span>{{ item.name }}</span>
				</template>

				<template v-slot:tbody="{ item }">
					<td @click="$emit('click-card', item.id)">
						<span> {{ item.address }}</span>
					</td>

					<td @click="$emit('click-card', item.id)">
						<span>
							{{ item.program.providerName }}
						</span>
					</td>

					<td
						class="insurance-history-table__date"
						@click="$emit('click-card', item.id)"
					>
						<span>
							<InsurancePeriod
								:date="{ dateStart: null, dateEnd: item.lastInsuranceDate }"
							/>
						</span>
					</td>

					<td
						class="insurance-history-table__price"
						@click="$emit('click-card', item.id)"
					>
						<span class="app-table__title-mob">
							Цена:
						</span>
						<span>
							{{ item.program.price + "₽" }}
						</span>
					</td>

					<td @click="$emit('click-card', item.id)">
						<div class="periodCell">
							<span class="app-table__title-mob">
								Месяцы:
							</span>
							<span v-if="item.canInsurance && item.maxDurationMonths > 0">
								<Counter
									:value="item.monthCount"
									:maxValue="item.maxDurationMonths"
									@input="changeCount(item.apartmentId, $event.val)"
								/>
							</span>
							<div v-else class="monthCell">
								<span class="cantInsurance">
									Недоступно
								</span>
								<span
									v-tooltip="{
										content:
											item.message ||
											'Вы используете максимально возможный срок страховки',
										trigger: 'hover',
										placement: 'top'
									}"
								>
									<AppIcon name="question-circle" style="color: #A5A5A4" />
								</span>
							</div>
						</div>
					</td>
				</template>
			</AppTable>
			<template
				v-if="
					getInsurancePrepare.length &&
						getInsurancePrepare.some(item => item.canInsurance)
				"
			>
				<div class="buy-insurance__footer">
					<template v-if="isGroup">
						<div class="buy-insurance__total">
							Итого по страховке:
							<span class="buy-insurance__sum"
								>{{ $numberToString(getTotalPrice) }} ₽</span
							>
						</div>

						<AppBtn
							class="mb-2"
							v-if="this.getInsurancePrepare.length > 0 && this.getTotalPrice"
							@click="prepareToPay"
						>
							Застраховать
						</AppBtn>
						<AppBtn outlined @click="backToPaymentPage"> Назад </AppBtn>
					</template>
					<div class="insurance-provider-links">
						<p>
							*С правилами можно ознакомиться по
							<a
								:href="
									`https://cdn.jfservice.ru/insurance/${getInsurancePrepare[0].program.insuranceRulesOferta}`
								"
								target="_blank"
							>
								ссылке
							</a>
						</p>
						<p>
							*Условия страховки отражены в
							<a
								:href="
									`https://cdn.jfservice.ru/insurance/${getInsurancePrepare[0].program.programRulesOferta}`
								"
								target="_blank"
							>
								полисе-оферте
							</a>
						</p>
					</div>
				</div>
				<payment-footer v-if="!isGroup" />
			</template>
		</div>
	</div>
</template>

<script>
import BuyInsuranceCard from "./components/buy-insurance-card.vue";
import InsurancePeriod from "../insurance/components/insurance-period.vue";
import Counter from "../../suplier/component/basket-calc.vue";
import PaymentFooter from "./components/payment-footer.vue";
import { mapGetters } from "vuex";

export default {
	components: {
		BuyInsuranceCard,
		InsurancePeriod,
		Counter,
		PaymentFooter
	},
	data() {
		return {
			preparingData: [],
			headerTable: [
				{
					name: "Адрес страхования",
					width: "45%"
				},
				{
					name: "Компания страховщик",
					width: "20%"
				},
				{
					name: "Период действия",
					width: "15%"
				},
				{
					name: "Цена",
					width: "10%"
				},
				{
					name: "Количество месяцев",
					width: "10%"
				}
			]
		};
	},
	methods: {
		changeCount(id, count) {
			if (count <= 12) {
				this.$store.commit("setInsurancePrepareItemCount", { id, count });
			}
		},
		async loadData() {
			if (this.isGroup && this.getApartmentIds.length) {
				await this.$store.dispatch(
					"fetchInsurancePrepare",
					this.getApartmentIds
				);
			} else if (this.isGroup && !this.getApartmentIds.length) {
				this.$router.push({ name: "paymentPage" });
			} else if (!this.isGroup && this.$route.params.apartmentId) {
				await this.$store.dispatch("fetchInsurancePrepare", [
					this.$route.params.apartmentId
				]);
			}
		},
		prepareToPay() {
			if (this.getInsurancePrepare.length > 0 && this.getTotalPrice) {
				this.$store.commit(
					"setInsurancePrepare",
					this.getInsurancePrepare.filter(
						item => item.canInsurance && item.monthCount
					)
				);
				this.$router.push({
					name: "paymentPage",
					query: { from: "insurance" }
				});
			}
		},
		backToPaymentPage() {
			this.$router.push({ name: "paymentPage" });
		}
	},
	computed: {
		...mapGetters([
			"getInsurancePrepareLoading",
			"getInsurancePrepare",
			"getInsurancePrepareItemCount",
			"getTotalPrice",
			"getApartmentIds"
		]),
		isGroup() {
			return this.$route.params.apartmentId === "group";
		}
	},
	mounted() {
		this.loadData();
	}
};
</script>

<style scoped>
.insurance-provider-links {
	margin-top: 2em;
	color: var(--color-secondary-two);
}

.insurance-provider-links p {
	margin-bottom: 1em;
}

.insurance-provider-links a {
	text-decoration: underline;
}

.buy-insurance__card {
	margin-bottom: 25px;
}

.buy-insurance__total {
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-bottom: 20px;
	margin-top: 20px;
	color: #777776;
	font-size: 16px;
}

.buy-insurance__sum {
	color: var(--color-primary);
	font-size: 36px;
	font-weight: 500;
}

.insurance-history-table__price {
	padding-left: 35px;
	font-size: 20px;
	font-weight: 500;
}

.buy-insurance__footer {
	margin-left: auto;
	max-width: 400px;
	display: flex;
	flex-direction: column;
}
.monthCell {
	display: flex;
	justify-content: center;
}
.periodCell {
	display: flex;
	align-items: center;
	justify-content: center;
}

@media only screen and (max-width: 767px) {
	.buy-insurance__footer {
		max-width: 100%;
	}
	.monthCell {
		display: flex;
		justify-content: center;
	}

	.periodCell {
		justify-content: space-between;
	}
}

.loading-wrapper {
	height: 300px;
	display: flex;
	justify-content: center;
	align-items: center;
}

.cantInsurance {
	color: var(--color-warning);
	margin-right: 0.5em;
}
.max-duration {
	color: var(--color-success);
}
</style>
