<template>
	<div>
		<div
			v-show="sumSize > 7"
			class="shake mt-2 danger-color"
			style="margin-bottom: 10px"
		>
			Вы можете прикреплять к заявке файлы размером не более 7 МБ.
		</div>
		<div class="request-files-downloader">
			<div
				class="request-files-downloader__form"
				:class="{
					disabledUploader: disabledUploader,
					successUploader: successUploader,
				}"
			>
				<div class="request-files-downloader__download">
					<input
						type="file"
						@change="previewFiles"
						multiple
						@dragenter="dragover"
						@dragleave="dragleave"
						@dragend="dragend"
						@drop="drop"
						accept=".pdf,.jpg, .png, .jpeg, .txt, .doc, .docx, .rtf"
						:disabled="false"
					/>
					<div class="d-flex align-center">
						<div class="request-files-downloader__image">
							<img src="/img/files.svg" />
						</div>
						<div class="request-files-downloader__description">
							<div class="request-files-downloader__title-text">
								Выберите файл
							</div>
							<div class="request-files-downloader__sub-title-text">
								или перетащите его сюда
							</div>
						</div>
					</div>
				</div>
			</div>

			<div class="request-files-downloader__sub-text">
				Не более 7 Мб. (JPEG, PNG, PDF, DOC)
			</div>

			<div class="request-files-downloader__preview">
				<div
					class="request-files-downloader__items"
					v-for="(item, index) in arrayFiles"
					:key="index + 100"
				>
					<template v-if=" Number(item.size / 1000000).toFixed(2) <= 10 " >
						<div class="request-files-downloader__name">
							{{ item.name }}
						</div>
						<div class="request-files-downloader__right">
							<div class="request-files-downloader__size">
								{{ Number(item.size / 1000000).toFixed(2) }} мб
							</div>
							<div
								class="request-files-downloader__delete"
								@click="deleteItemFile(index)"
							>
								<AppIcon name="times" size="16px" />
							</div>
						</div>
					</template>
				</div>
			</div>
		</div>
	</div>
</template>


<script>
export default {
	data() {
		return {
			disabledUploader: false,
			successUploader: false,
			arrayFiles: [],
		};
	},
	props: {
		/* arrayFiles: {
            type: Array,
            default: []
        } */
	},
	computed: {
		sumSize() {
			return this.arrayFiles.reduce(function (total, item) {
				return total + item.size / 1000000;
			}, 0);
		},
	},
	methods: {
		dragover(event) {
			this.validationFiles(event);
		},
		dragleave(event) {
			this.disabledUploader = false;
			this.successUploader = false;
		},
		dragend(event) {
			this.disabledUploader = false;
			this.successUploader = false;
		},
		drop(event) {
			this.validationFiles(event);
			setTimeout(() => {
				this.disabledUploader = false;
				this.successUploader = false;
			}, 300);
		},
		deleteItemFile(index) {
			this.arrayFiles.splice(index, 1);
			this.$emit("changeList", this.arrayFiles);
		},
		previewFiles(event) {
			if (!this.disabledUploader)
				this.arrayFiles = [...this.arrayFiles, ...event.target.files];
			this.changeFiles();
		},
		changeFiles() {
			this.$emit("changeList", this.arrayFiles);
		},
		validationFiles(event) {
			let supportTypes = [
				"application/pdf",
				"image/jpeg",
				"image/png",
				"image/jpg",
				"text/plain",
				"application/msword",
				"application/vnd.openxmlformats-officedocument.wordprocessingml.document",
			];

			event.dataTransfer.items.forEach((item) => {
				if (item.type === "") {
					this.disabledUploader = true;
					return;
				}

				if (supportTypes.indexOf(item.type) === -1) {
					this.disabledUploader = true;
					return;
				} else {
					this.successUploader = true;
				}
			});
		},
	},
};
</script>

<style>
.disabledUploader {
	cursor: no-drop;
	border: 2px dashed #fa665c !important;
}

.successUploader {
	border: 2px dashed #54852c !important;
}
</style>