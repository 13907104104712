<template>
    <AppModals 
        name="delete-user" 
        title="Внимание" 
        confirmButtonText="Удалить" 
        colorConfirm="danger"
        @confirm="deleteUser">
        <template v-slot:content="{}">
            <div>
                <div style="text-align: left; color: #777776" class="mt-3 mb-3">
                    Удаление аккаунта приведет к безвозвратной потере истории платежей, переданных показаний, заказов и
                    отправленных заявок. Вы действительно хотите удалить?
                </div>
            </div>
        </template>

    </AppModals>
</template>

<script>
import { mapActions } from 'vuex'
export default {
    data() {
        return {
            userId: ''
        }
    },
    methods: {
        ...mapActions(['removeUser']),
        async deleteUser() {
            this.$modal.hide('delete-user')
            await this.$store.dispatch('removeUser')
            window.$cookies.remove('time_token_die', '/', process.env.VUE_APP_COOCKIE)
            window.location.replace(process.env.VUE_APP_REDIRECT)
        }
    }

}
</script>