import $axios from "@/plugins/axios-instance";

export default {
	state: {
		gazServiceProviders: []
	},
	getters: {
		getGazServiceProviders(state) {
			return state.gazServiceProviders;
		}
	},
	mutations: {
		setGazServiceProviders(state, payload) {
			state.gazServiceProviders = payload;
		}
	},
	actions: {
		fetchGazServiceInfo({ commit }, unitId) {
			return $axios
				.get(`/gaz/${unitId}`)
				.then(response => {
					commit("setGazServiceProviders", response.data);
					return response.data;
				})
				.catch(e => {
					throw e;
				});
		}
	}
};
