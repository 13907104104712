<template>
    <div class="page-error">

        <div class="page-error__img">
            <img v-if="getTheme !== 'dark-theme'" src="/img/404.svg"  />
             <img v-else src="/img/dark-theme/404-dark.svg"  />
        </div>

        <div class="page-error__text">
            Странно... такой страницы почему-то нет :(
        </div>

        <a class="page-error__link" href="/">
            вернуться на главную
        </a>

    </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {

    ata() {
		return {};
	},
	computed: {
		...mapGetters([
			"getTheme",
		])
	},
}

</script>

<style>

    .error404 {
        font-size: 150px;
        font-weight: bold;
        color: #363636;
        animation: shadow  3s ease-out;
        animation-fill-mode: forwards;
    }

    .text404 {
        font-size: 20px;
        color: #363636;
    }

    .return {
        margin: 30px;
    }
    .return a {
        font-size: 14px;
        padding: 13px;
        background: #82b1ff;
        color: #FFF;
        border-radius: 3px;

    }
    div {
            animation-name: mymove;
        }
    @keyframes shadow {
        from {
            transform: scale(1)
            }
        10% {
            transform: scale(1.1)
            }
        20% {
            transform: scale(1)
            }
        30% {
            transform: scale(1.1)
            }
        40% {
            transform: scale(1)
            }
        50% {
            transform: scale(1.1)
            }
        60% {
            transform: scale(1)
            }
        80% {
            transform: scale(1.1)
            }
        80% {
            transform: scale(1)
            }
        to {
           transform: scale(1)
            }
    }
</style>
