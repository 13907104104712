<template>
    <AppModals
        name="account-move"
        title="Переместить ЛС / Договор"
		cancelButtonText="Закрыть"
		@beforeOpen="beforeOpen"
        confirmButtonText="Переместить "
        @confirm="renameAccount"
        :disabledConfirm="!$v.selectedAddress.required"
        :loadingConfirm="getAccountMove.loading"
        :maxWidth="700"
		:width="700"
    >
        <template  #content="{}">
            <div class="modal-content">
                <div>
                    <AppSelect 
                        placeholder="Выберите адрес"
                        label="Адрес"
                        valueSelect="id"
                        :options="filteredApartments"
                        textSelect="address"
                        v-model="$v.selectedAddress.$model"
                        :error="$v.selectedAddress.$error ? 'Выберите адрес' : '' "
                        subTitle="description"
                        searchable
                    />
                </div>
                <div style="margin: 30px 0">Вы можете прикрепить этот лицевой счет или договор к любому другому помещению.</div>
			</div>
        </template>
    </AppModals>
</template>

<script>
import { required } from 'vuelidate/lib/validators'
import { mapGetters } from 'vuex'

export default {
	data () {
		return {
            accountId: null,
            selectedAddress: null
		}
	},
	props: {

    },
    
    mounted() {
        
    },
    computed: {
        ...mapGetters([
            'getAccountMove', 'getApartment'
        ]),
        filteredApartments() {
            return this.getFilteredApartmentData();
        }
    },
	methods: {
		modalClose () {
			this.$modal.hide('account-move')
        },

        getFilteredApartmentData () {
            return this.getApartment.data.filter((item) => item.id.toString() !== this.$route.params.id.toString())
        },
        
		async renameAccount () {
            this.$v.$touch()

            if(!this.$v.selectedAddress.$invalid) {
                const formData = {
                    accountId: this.accountId,
                    apartmentId: this.selectedAddress.id
                }

                try {
                    await this.$store.dispatch('accountMove', formData)
                    this.$modal.hide('account-move')
                } catch (e) {
                    this.$errorsMod(e.response)
                }
            }
        },

        async getApartmentList() {
            try {
                await this.$store.dispatch('apartmentList')
            } catch (e) {
                this.$errorsMod(e.response)
            }
        },

        async beforeOpen (event) {
            this.getApartmentList()
            this.accountId = event.accountId
		}
    },
    validations: {
        selectedAddress: {
            required,
        },
	},
}
</script>


<style scoped>

</style>