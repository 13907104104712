<template>
	<label
		class="toggle-control"
		:class="[getTheme === 'dark-theme' ? defaultClass : '', darkClass]"
	>
		<input type="checkbox" checked="checked" />
		<span
			:style="getTheme === 'dark-theme' && { backgroundColor: '#151515' }"
			class="control"
		></span>
	</label>
</template>

<script>
import { mapGetters } from "vuex";

export default {
	data() {
		return {
			defaultClass: "toggle-control",
			darkClass: "toggle-control-dark",
		};
	},
	computed: {
		...mapGetters(["getTheme"]),
	},
};
</script>

<style scoped lang="sass">
$toggle-background-color-on: #fff
$toggle-background-color-off: #fff
$toggle-control-color: #e6575d
$toggle-control-color-dark: #E95151
$toggle-width: 55px
$toggle-height: 30px
$toggle-gutter: 2px
$toggle-radius: 50%
$toggle-control-speed: 0.15s
$toggle-control-ease: ease-in

$toggle-radius: $toggle-height / 2
$toggle-control-size: $toggle-height - ($toggle-gutter * 1)

.toggle-control
    display: block
    position: relative
    padding-left: $toggle-width
    margin-bottom: 12px
    cursor: pointer
    font-size: 22px
    user-select: none

    input
        position: absolute
        opacity: 0
        cursor: pointer
        height: 0
        width: 0

    input:checked ~ .control
        background-color: $toggle-background-color-on

        &:after
            left: $toggle-width - $toggle-control-size - $toggle-gutter
            background-color: #33bc7d

    .control
        position: absolute
        top: 0
        left: 35px
        height: $toggle-height
        width: $toggle-width
        border: 1px solid #a5a5a4
        border-radius: $toggle-radius
        background-color: $toggle-background-color-off
        transition: background-color $toggle-control-speed $toggle-control-ease
        @media only screen and (max-width: 768px - 1)
            left: 10px

        &:after
            content: ""
            position: absolute
            left: 0px
            top: 0px
            width: $toggle-control-size
            height: $toggle-control-size
            border-radius: $toggle-radius
            background: $toggle-control-color
            transition: left $toggle-control-speed $toggle-control-ease

.toggle-control-dark
    display: block
    position: relative
    padding-left: $toggle-width
    margin-bottom: 12px
    cursor: pointer
    font-size: 22px
    user-select: none

    input
        position: absolute
        opacity: 0
        cursor: pointer
        height: 0
        width: 0

    input:checked ~ .control
        background-color: $toggle-background-color-on

        &:after
            left: $toggle-width - $toggle-control-size - $toggle-gutter
            background-color: #1DC571

    .control
        position: absolute
        top: 0
        left: 35px
        height: $toggle-height
        width: $toggle-width
        border: 1px solid #a5a5a4
        border-radius: $toggle-radius
        background-color: $toggle-background-color-off
        transition: background-color $toggle-control-speed $toggle-control-ease
        @media only screen and (max-width: 768px - 1)
            left: 10px

        &:after
            content: ""
            position: absolute
            left: 0px
            top: 0px
            width: $toggle-control-size
            height: $toggle-control-size
            border-radius: $toggle-radius
            background: $toggle-control-color-dark
            transition: left $toggle-control-speed $toggle-control-ease
</style>