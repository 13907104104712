<template>
    <AppModals
        name="suplier-history"
        :title="'Заказ № ' + id"
        @beforeOpen="beforeOpen"
        hideConfirm
        greyBack
        :width="700"
        :maxWidth="700"
        cancelButtonText="Закрыть"
    >
		<template  #content="{paramsModal}">
            <div class="suplier-order">
                
                <div class="suplier-order__content">
                    <div class="suplier-order__row">
                        <div class="suplier-order__title-b">
                            Дата: 
                        </div>
                        <div class="suplier-order__title-t">
                            {{  dateReturnFormat(paramsModal.date) }}
                        </div>
                    </div>
                    <div class="suplier-order__row">
                        <div class="suplier-order__title-b">
                            Поставщик: 
                        </div>
                        <div class="suplier-order__title-t">
                            {{  paramsModal.suplierName }}
                        </div>
                    </div>
                    <div class="suplier-order__row">
                        <div class="suplier-order__title-b">
                            Адрес: 
                        </div>
                        <div class="suplier-order__title-t">
                            {{  paramsModal.address }}
                        </div>
                    </div>
                    <div class="suplier-order__row">
                        <div class="suplier-order__title-b">
                            Телефон: 
                        </div>
                        <div class="suplier-order__title-t">
                            {{  paramsModal.phone }}
                        </div>
                    </div>
                    <div class="suplier-order__row">
                        <div class="suplier-order__title-b">
                            Сумма заказа: 
                        </div>
                        <div class="suplier-order__title-t">
                            {{  paramsModal.sum }} ₽
                        </div>
                    </div>
                    <div class="suplier-order__row" style="align-items: flex-start;">
                        <div class="suplier-order__title-b">
                            Комментарий: 
                        </div>
                        <div v-if="paramsModal.description" class="suplier-order__title-t" >
                            <p v-for="item in paramsModal.description.replace(/]/g, ' ').split('[')">{{item}}</p>
                        </div>
                    </div>
                </div>
                <div class="suplier-order__row" v-if="paramsModal.statusDescription">
                    <div class="suplier-order__title-b">
                        Комментарий поставщика: 
                    </div>
                    <div class="suplier-order__title-t">
                        {{ paramsModal.statusDescription }}
                    </div>
                </div>
            </div>


             
                <div class="suplier-order__cart">

                    <AppTable 
                        :header="headerTable"
                        :data="paramsModal.items"
                        messageNoData='Список пуст'
                    >
                        <template v-slot:thead="{item}">
                            <span>{{ item.name }}</span>
                        </template>
                        <template v-slot:tbody="{item}">
                            <td>
                                {{ item.name }}
                            </td>
                            <td>
                                <span class="app-table__title-mob">
									Количество: 
								</span>
                                {{ item.count }}
                            </td>
                            <td>
                                <span class="app-table__title-mob">
									Сумма: 
								</span>
                                {{ item.sum }} р.
                            </td>
                        </template>
                    </AppTable>

                </div>

		</template>
    </AppModals>
</template>

<script>
import { dateReturnFormat } from '@/plugins/methods';

export default {
	data () {
		return {
            id: 0,
            headerTable: [
                {
                    name: 'Наименование',
                    width: '40%'
                },
                {
                    name: 'Количество',
                    width: '30%'
                },
                {
                    name: 'Цена',
                    width: '30%'
                },
            ]
		}
	},
	props: {
		model: {
            type: Object,
            default: () => ({})
        },
	},
	computed: {

	},
	methods: {
        dateReturnFormat (date) {
			return dateReturnFormat(date, true)
		},
		async modalClose () {
			this.$modal.hide('suplier-history');
		},
        beforeOpen(model) {
            this.id = model.id
        }
	}
}
</script>

