<template>
	<AppModals
		name="requested-service-modal"
		@beforeOpen="beforeOpen"
		:maxWidth="600"
		:width="600"
		:hideFooter="true"
		title="Спасибо за оплату!"
	>
		<template #content>
			<div class="paper-modal-content">
				<div class="image">
					<img src="@/img/tech-service/Paper.svg" />
				</div>
				<div class="text">
					В течение трех рабочих дней с вами свяжется оператор для согласования
					даты проведения технического обслуживания газового оборудования.
				</div>
				<div class="text">
					Будьте на связи!
				</div>
			</div>
		</template>
	</AppModals>
</template>
<script>
export default {
	methods: {
		beforeClose() {},
		beforeOpen() {},
		cancel() {}
	}
};
</script>
<style scoped>
.paper-modal-content {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	width: 80%;
	height: 80%;
	margin: 30px auto;
	font-size: 1em;
}
::v-deep .app-modal__title {
	margin-right: -40px;
	flex-grow: 1;
	color: var(--color-primary);
	text-align: center;
	font-size: 1.4em;
	font-weight: 700;
}
.image {
	width: 100px;
}
.image > img {
	object-fit: contain;
	width: 100%;
	height: 100%;
}
.text {
	margin-top: 1em;
	color: var(--color-secondary-three);
	text-align: justify;
	font-size: 1em;
	font-weight: 500;
}
@media only screen and (max-width: 767px) {
	::v-deep .app-modal {
		height: auto;
		font-size: .9em
	}
}
</style>
