import { render, staticRenderFns } from "./MessageStatus.vue?vue&type=template&id=c8e4e6ba&scoped=true&"
var script = {}
import style0 from "./MessageStatus.vue?vue&type=style&index=0&id=c8e4e6ba&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c8e4e6ba",
  null
  
)

export default component.exports