<template>
	<div class="btn-toggle" :class="{ 'btn-toggle_checked': checked }">
		<input 
			type="checkbox" 
			id="btn-toggle" 
			:checked="checked"
      		@change="$emit('change', $event.target.checked)"
		/>
		<label for="btn-toggle" />
	</div>
</template>

<script>
export default {
	model: {
		prop: 'checked',
		event: 'change'
	},
	props: {
		checked: {
			type: Boolean,
			default: false,
		}
	},
};
</script>

<style scoped>
.btn-toggle {
	box-sizing: border-box;
	display: inline-block;
	font-size: 7px;
	width: 6.4em;
	background: var(--color-danger);
	border-radius: 1.8em;
	transition: 0.3s ease-out;
	padding: 0.4em;
}

.btn-toggle_checked {
	background: var(--color-success);
}

.btn-toggle input {
	display: none;
}

.btn-toggle label {
	display: block;
	height: 2.8em;

	position: relative;
	width: 100%;
	cursor: pointer;
}

.btn-toggle label::before {
	content: "";
	position: absolute;
	z-index: 2;
	left: 0;
	top: 0;

	display: block;
	width: 2.8em;
	height: 2.8em;

	background: #ffffff;
	border-radius: 1.4em;
	transition: 0.25s ease-in-out;
}

.btn-toggle input:checked+label::before {
	left: calc(100% - 2.8em);
}

.btn-toggle input:checked:active+label::before {
	left: calc(100% - 3.2em);
}
</style>
