<template>
	<div>
		<div class="page-wrapper">
			<div class="form-container">
				<div class="text">
					Техническое обслуживание внутридомового (внутриквартирного) газового
					оборудования согласно Постановлению Правительства РФ №410 должно
					проводиться не реже 1 раза в год и является обязательным условием
					эксплуатации газового оборудования.
				</div>
				<div class="text">
					Стоимость ТО зависит от состава газоиспользующего оборудования и
					определяется действующим прейскурантом исполнителя.
				</div>

				<h5 v-if="this.getGazServiceProviders.length">
					{{
						this.getGazServiceProviders.length === 1
							? `Поставщик: ${this.getGazServiceProviders[0].organizationName}`
							: "Выбор поставщика"
					}}
				</h5>

				<div
					style="height: 50px; max-width: 400px"
					v-if="this.getGazServiceProviders.length > 1"
				>
					<AppSelect
						white
						placeholder="Выберите поставщика"
						valueSelect="id"
						:options="getGazServiceProviders"
						textSelect="organizationName"
						@selected="setGazProvider"
						:value="$v.selectedProvider.$model"
						:error="$v.selectedProvider.$error ? 'Выберите поставщика' : false"
					/>
				</div>
			</div>

			<div v-if="selectedProvider">
				<div class="info-row">
					<div class="info-row-title">Адрес</div>
					<div class="info-row-text">{{ selectedProvider.address }}</div>
				</div>
				<div class="info-row">
					<div class="info-row-title">Номер лицевого счёта</div>
					<div class="info-row-text">{{ selectedProvider.accountName }}</div>
				</div>
				<div class="info-row">
					<div class="info-row-title">Дата последнего ТО</div>
					<div class="info-row-text">{{ lastDate }}</div>
				</div>
				<div class="info-row">
					<div class="info-row-title">Сумма</div>
					<div class="info-row-text">{{ selectedProvider.toPay }} ₽</div>
				</div>

				<div style="margin: 1.5em 0; max-width: 400px">
					<AppInput
						white
						id="phone"
						type="text"
						v-mask="phoneMask"
						label="Номер телефона"
						v-model="$v.phoneNumber.$model"
						:error="$v.phoneNumber.$error"
						:requiredError="$v.phoneNumber.required"
						:maxLengthError="$v.phoneNumber.testPhone"
					>
						<template v-if="!$v.phoneNumber.required" slot="required"
							>Заполните это поле</template
						>
						<template v-else slot="maxLength"
							>Введите корректный номер телефона</template
						>
					</AppInput>
				</div>

				<div class="agree">
					<AppCheckbox v-model="$v.isAgree.$model" :error="$v.isAgree.$error">
						<template #label>
							С
							<a
								style="color: inherit; transition: none"
								@click.stop
								target="_blank"
								:href="`https://cdn.jfservice.ru/gaz/${selectedProvider.rules}`"
								>условиями</a
							>
							предоставления услуги согласен
						</template>
					</AppCheckbox>
				</div>
			</div>
		</div>
		<div>
			<PaymentFooter
				ref="paymentFooterRef"
				:gazPayments="gazPayments"
				:isAgree="isAgree"
				@submit="submit"
			/>
		</div>
	</div>
</template>
<script>
import PaymentFooter from "./components/payment-footer.vue";
import { mapGetters } from "vuex";
import { required } from "vuelidate/lib/validators";

let testPhone = val => {
	if (val) {
		const normalisePhone = val.replace(/\D+/g, "");
		return /^[78]\d{10}$/.test(normalisePhone);
	} else {
		return true;
	}
};

let testIsAgree = val => {
	return val;
};

export function phoneMask(value) {
	let start = [/[\+\d]/];
	if (value.charAt(0) === "+") {
		start = [...start, /7/];
	}

	const next = [
		" ",
		"(",
		/\d/,
		/\d/,
		/\d/,
		")",
		" ",
		/\d/,
		/\d/,
		"-",
		/\d/,
		/\d/,
		"-",
		/\d/,
		/\d/,
		/\d/
	];
	return [...start, ...next];
}

export default {
	components: {
		PaymentFooter
	},
	data() {
		return {
			isAgree: false,
			selectedProvider: null,
			phoneNumber: ""
		};
	},
	computed: {
		...mapGetters(["getGazServiceProviders"]),
		phoneMask() {
			if (this.phoneNumber && this.phoneNumber[0] === "7") {
				return "+# (###) ###-##-##";
			} else if (this.phoneNumber && this.phoneNumber[0] === "8") {
				return "8 (###) ###-##-##";
			} else if (
				this.phoneNumber.length > 1 &&
				this.phoneNumber.includes("+7")
			) {
				return "+7 (###) ###-##-##";
			} else if (
				this.phoneNumber.length &&
				this.phoneNumber[0] !== "+" &&
				this.phoneNumber[0] !== "8"
			) {
				return "+7 (###) ###-##-##";
			}
			return phoneMask;
		},
		gazPayments() {
			if (this.selectedProvider) {
				const { accountId, providerId, toPay } = this.selectedProvider;
				return {
					accountId,
					providerId,
					amount: toPay,
					phoneNumber: this.normalisePhone
				};
			} else {
				return null;
			}
		},
		lastDate() {
			if (this.selectedProvider && this.selectedProvider.lastDate) {
				const date = new Date(this.selectedProvider.lastDate);
				return `${date.toLocaleDateString("ru")}`;
			} else {
				return "---";
			}
		},
		normalisePhone() {
			if (this.phoneNumber) {
				const onlyDigits = this.phoneNumber.replace(/\D+/g, "");
				return "+7" + onlyDigits.slice(onlyDigits.length - 10);
			}
		}
	},
	methods: {
		async selectProvider() {
			if (!this.getGazServiceProviders.length) {
				await this.$store.dispatch(
					"fetchGazServiceInfo",
					this.$route.params.unitId
				);
				if (!this.getGazServiceProviders.length) {
					this.$router.push({ name: "account" });
				} else if (this.getGazServiceProviders.length === 1) {
					this.selectedProvider = this.getGazServiceProviders[0];
				}
			} else if (this.getGazServiceProviders.length === 1) {
				this.selectedProvider = this.getGazServiceProviders[0];
			}
		},
		setGazProvider(provider) {
			this.selectedProvider = { ...provider };
		},

		submit() {
			this.$v.$touch();
			if (!this.$v.$error) {
				this.$refs["paymentFooterRef"].paymentSubmit();
			}
		}
	},
	mounted() {
		this.selectProvider();
	},
	validations: {
		phoneNumber: {
			required,
			testPhone
		},
		isAgree: {
			testIsAgree
		},
		selectedProvider: {
			required
		}
	}
};
</script>
<style scoped>
.page-wrapper {
}
.form-container {
	max-width: 600px;
	margin-bottom: 1em;
}
.text {
	color: var(--color-secondary);
	margin-bottom: 2em;
}

.info-row {
	padding: 0.7em;
	border-bottom: 1px dashed var(--color-secondary-four);
}
.info-row-title {
	color: var(--color-secondary-three);
}
.info-row-text {
	margin-top: 0.5em;
	color: var(--color-secondary);
}
.agree {
	margin-top: 1em;
}
@media screen and (max-width: 1035px) {
	.page-wrapper {
	}
}
@media screen and (max-width: 735px) {
	.page-wrapper {
		margin-bottom: 3em;
	}
}
@media screen and (min-width: 1537px) {
	#phone {
		font-size: 14px;
	}
	.page-wrapper {
		font-size: 14px;
	}
}
</style>
