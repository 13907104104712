import axios from 'axios';

const $axios = axios.create({
    baseURL: process.env.VUE_APP_URL,
    timeout: 100000,
    withCredentials: true,
});

$axios.interceptors.response.use(
    (resp) => {
        let v = resp.headers['ver'] || 'default'

        if(v !== localStorage.getItem('ver') && resp.config.method == 'get'){
            localStorage.setItem('ver', v)
        }
    return Promise.resolve(resp)
})

export default $axios;