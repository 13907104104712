<template>
	<AppGlobalLayout :loading="loadingPage" :error="error" @reload="fetchNotificationSettings">
		<div slot="body">
		<AppCard class="wrapper mt-4 pa-4">
			<h4 slot="header" class="mb-4 ">Настройка уведомлений</h4>
			<div slot="body">
				<div class="usersettings header mb-4"
					:style="`grid-template-columns: 1fr repeat(${totalChannels.length}, 20%)`">
					<div>Оповещение</div>
					<div v-for="title in totalChannels"> {{title}} </div>
				</div>
				<div v-for="(setting, settingIndex) in getNotificationSettings" class="mb-3">
					<div slot="body">
						<div class="content-modal-approve__save-head usersettings"
							:style="`grid-template-columns: 1fr repeat(${totalChannels.length}, 20%)`">
							<div>{{setting.notificationTitle}}</div>
							<div v-for="title in totalChannels">
								<div class="notidication-tittle">{{title}}</div>
								<label v-if="setting.channels.find(item=>item.channelTitle === title)"
									class="content-modal-approve__save-toggle"
									:class="setting.channels.find(item=>item.channelTitle === title).enabled ? 'active':''">
									<input :checked="setting.channels.find(item=>item.channelTitle === title).enabled"
										@change="(e)=>save(e, setting.channels.find(item=>item.channelTitle === title).settingId, settingIndex)"
										type="checkbox">
									<div></div>
								</label>
								<label v-else class="content-modal-approve__save-toggle disabled">
									<input disabled type="checkbox">
									<div></div>
								</label>
							</div>
						</div>
					</div>
				</div>

			</div>
		</AppCard>
		</div>
	</AppGlobalLayout>
</template>

<script>
import { mapGetters } from "vuex";



export default {
	data() {
		return {
			loadingPage: false,
			error: false,
			totalChannels: [],
		};
	},
	mounted() {
		this.fetchNotificationSettings()
	},
	computed: {
		...mapGetters(["getNotificationSettings"]),
	},
	methods: {
		async fetchNotificationSettings() {
			this.loadingPage = true;
			this.error = false;
			try {
				await this.$store.dispatch("fetchNotificationSettings");
				this.settingsTotalChannels(this.getNotificationSettings)
			} catch (e) {
				if (e.response) {
					this.$errorsMod(e.response);
				} else {
					this.error = true;
					this.$errorsMod(e);
				}
			}
			this.loadingPage = false;
		},
		async save(e, id, settingIndex) {
			try {
				await this.$store.dispatch("postNotificationSetting", { id, enabled: e.target.checked, settingIndex })

			} catch (error) {
				if (error.response) {
					this.$errorsMod(error.response);
				} else {
					this.$errorsMod(error);
				}
				e.target.checked = this.getNotificationSettings[settingIndex].channels.find(item => item.settingId === id).enabled
			}
		},
		settingsTotalChannels(settings) {
			let arr = []
			settings.forEach(item => {
				item.channels.forEach(value => {
					if (arr.find(val => val === value.channelTitle)) {
						return
					} else {
						arr.push(value.channelTitle)
					}
				})
			});
			this.totalChannels = [...arr]
		},

	},
	components: {
	},

};
</script>
<style scoped lang="css">
.wrapper {
	max-width: 1000px;
}

.wrapper h4 {
	font-weight: 500;
	font-size: 1.25rem;
	color: var(--color-secondary);
}

.usersettings {
	display: grid;
	color: var(--color-secondary);
}

.usersettings.header {
	color: var(--color-secondary-three);
}

.usersettings>div {
	display: flex;
	align-items: center;
	justify-content: flex-start;
}

.notidication-tittle {
	display: none;
	color: var(--color-secondary-three);
}

.btn-wrapper {
	width: 200px;
	margin: 100px 0 0 auto;
}

@media screen and (max-width: 1024px) {
	.usersettings {
		display: block;
		color: var(--color-secondary);
	}

	.usersettings>div {
		display: flex;
		align-items: center;
		justify-content: space-between;
		margin-bottom: 10px;
		gap: 10px;
		font-size: 0.9em;

	}

	.notidication-tittle {
		display: block;
	}
}

@media screen and (max-width: 500px) {
	.usersettings>div {
		font-size: 0.9em;
	}

	.app-tabs__info>div:last-child {
		display: none;
	}
}
</style>

    
