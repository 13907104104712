import $axios from '@/plugins/axios-instance'

export default {
	state: {
		suplierInfo: {
			data: [],
			loading: true,
			error: false
		},
		suplierCategoryInfo: {
			data: [],
			loading: true,
			error: false
		},
	},
	getters: {
		getSuplierInfo(state) {
			return state.suplierInfo
		},
		getSuplierCategoryInfo(state) {
			return state.suplierCategoryInfo
		},

	},
	mutations: {
		setSuplierInfoLoad(state, load) {
			state.suplierInfo.loading = load
		},
		setSuplierInfoData(state, data) {
			state.suplierInfo.data = data
		},


		setSuplierCategoryInfoLoad(state, load) {
			state.suplierCategoryInfo.loading = load
		},
		setSuplierCategoryInfoData(state, data) {
			state.suplierCategoryInfo.data = data.map(item => ({
				...item,
				calc: 1
			}))
		},


	},

	actions: {
		suplierInfo (context, id) {
			context.commit('setSuplierInfoLoad', true)
			return $axios.get(`/Suplier/Details?suplierId=${id}`)
				.then( async (response) => {
					
					context.commit('setSuplierInfoData', response.data)
					if(response.data.catalog.length > 0) {
						await context.dispatch('suplierCategoryInfo', response.data.catalog[0].id)
					}
					
					return response.data
				}).catch((e)=>{
					throw(e)
				}).finally(() => {
					context.commit('setSuplierInfoLoad', false)
				})
		},

		suplierCategoryInfo (context, id) {
			context.commit('setSuplierCategoryInfoLoad', true)
			return $axios.get(`/Suplier/CategoryInfo?categoryId=${id}`)
				.then((response) => {
					context.commit('setSuplierCategoryInfoData', response.data)
					return response.data
				}).catch((e)=>{
					throw(e)
				}).finally(() => {
					context.commit('setSuplierCategoryInfoLoad', false)
				})
		},

	}
}
